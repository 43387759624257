
import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Chart } from "chart.js";
import { AbstractComponent, Atendimento, ChartHelper, DateUtil, Empresa, EmpresaService, Franquia, FranquiaService, Localizacao, LocalizacaoService, Pager, RelatorioSenhaService, SearchParams, Senha, UtilHelper } from 'lib-smart-core';
import { Constantes } from '../../../../environments/constantes';
import { RelatorioSenhaHelper } from './relatorio-senha.helper';

@Component({
  selector: 'relatorio-senha',
  templateUrl: './relatorio-senha.component.html'
})
export class RelatorioSenhaComponent extends AbstractComponent implements OnInit {

  @ViewChild('matDateRangePickerCancelSenha') matDateRangePickerCancelSenha: ElementRef;

  canvas: any;
  canvas2: any;
  ctx: any;
  ctx2: any;

  mostrarGrafico: Boolean = false;
  idFranquiaSelecionada = '';

  empresa: Empresa;

  franquia: Franquia;
  atendimentos: Atendimento[];

  historico: Atendimento;
  franquias: Franquia[];

  model: any = {
    ano: new Date().getFullYear(),
    mes: new Date().getMonth() + 1,
  };

  pager: Pager<Senha> = new Pager<Senha>({ perPage: 50 });

  chartPie: Chart;
  chartArea: Chart;

  exporting: boolean = false;
  localizacoes: Localizacao[];
  locationEnabled: boolean = false;
  idLocalizacaoSelecionada = '';

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  loadingCharts: boolean = false;

  constructor(
    private franquiaService: FranquiaService,
    private relatorioSenhaService: RelatorioSenhaService,
    private empresaService: EmpresaService,
    private localizacaoService: LocalizacaoService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setMonth(endDate.getMonth() - 1);
    this.range.setValue({ start: startDate, end: endDate });
  }

  ngAfterViewInit() {
    this.canvas = document.getElementById('relatorio-senha');
    this.ctx = this.canvas.getContext('2d');

    this.canvas2 = document.getElementById('relatorio-group-filas');
    this.ctx2 = this.canvas2.getContext('2d');

    let idEmpresa: string = super.getIDEmpresaSession();
    if (idEmpresa) {
      this.getEmpresa(idEmpresa);
      this.getFranquiasPorEmpresa(idEmpresa);
    }
    this.setupLocationInit();
  }

  pagerReport() {
    this.searchParams = {
      empresa: super.getIDEmpresaSession(),
      startDate: DateUtil.toFormart(this.range.get('start').value, 'yyyy-MM-dd'),
      endDate: DateUtil.toFormart(this.range.get('end').value, 'yyyy-MM-dd'),
      franquia: this.idFranquiaSelecionada,
      localizacao: this.idLocalizacaoSelecionada,
    }
    if (!!this.searchParams.startDate && !!this.searchParams.endDate) {
      this.relatorioSenhaService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
        next: (pager: Pager<Senha>) => {
          this.pager = pager;
        },
        error: (err) => this.alertService.error(err.error.message)
      });
    }
  }

  public loadCustomDateRange(event) {
    const days = +event.target.value;

    if (!!days && days === 0) {
      return;
    }

    let startDate = new Date();
    let endDate = new Date();
    const daysOff = {
      7: DateUtil.getDateAddDays(-7),
      15: DateUtil.getDateAddDays(-15),
      30: DateUtil.getDateAddDays(-30),
      90: DateUtil.getDateAddDays(-90),
      180: DateUtil.getDateAddDays(-180),
      365: DateUtil.getDateAddDays(-365),
    };
    startDate = daysOff[days];

    this.matDateRangePickerCancelSenha.nativeElement.click();

    this.range.setValue({ start: startDate, end: endDate });
    this.loadPage(1);
  }

  private buildCharts() {
    this.loadingCharts = true;
    return this.buildArrayChartsRecursive(1, 5000, this.searchParams)
      .then((list: Senha[]) => {
        this.setupChartArea(list);
        this.setupChartPie(list);
        this.loadingCharts = false;
      });
  }

  private buildArrayChartsRecursive(page: number, perPage: number, searchParams: Partial<SearchParams>, total: number = 0, list: Array<Senha> = new Array<Senha>()): Promise<Senha[]> {
    return this.relatorioSenhaService.getAllPagerQuerySearch(page, perPage, null, searchParams)
      .toPromise()
      .then((pager: Pager<Senha>) => {
        list.push(...pager.list.map(elem => elem));
        return ((page * perPage) >= pager.total) ? list : this.buildArrayChartsRecursive((page + 1), perPage, searchParams, pager.total, list)
      });
  }

  setupChartArea(list: Array<Senha>) {
    if (!!this.chartArea) {
      this.chartArea.destroy();
    }

    const map: Map<string, number> = new Map<string, number>();
    list.forEach((senha: Senha) => {
      const key: string = String(DateUtil.toFormart(senha.data, 'dd/MM'));
      if (map.has(key)) {
        map.set(key, +map.get(key) + 1);
      } else {
        map.set(key, 1);
      }
    });

    const labels = [];
    const data = []
    map.forEach((value: number, key: string) => {
      labels.push(key);
      data.push(value);
    });

    this.chartArea = new Chart(this.ctx, {
      type: 'line',
      data: {
        labels: labels.reverse(),
        datasets: [{
          data: data.reverse(),
          label: 'Quantidade de Senhas',
          borderWidth: ChartHelper.colorsDefault.borderWidth,
          backgroundColor: ChartHelper.colorsDefault.backgroundColor[0],
          borderColor: ChartHelper.colorsDefault.borderColor[0],
        }]
      },
      options: ChartHelper.optionsDefault as any
    });
  }

  setupChartPie(list: Array<Senha>) {
    if (!!this.chartPie) {
      this.chartPie.destroy();
    }

    const map: Map<string, number> = new Map<string, number>();
    const mapName: Map<string, string> = new Map<string, string>();

    list.forEach((senha: Senha) => {
      const key: string = senha.fila._id;
      if (map.has(key)) {
        map.set(key, +map.get(key) + 1);
      } else {
        map.set(key, 1);
        mapName.set(key, senha.fila.nome);
      }
    });

    const labels = [];
    const data = [];
    const colors = [];
    map.forEach((value: number, key: string) => {
      labels.push(mapName.get(key));
      data.push(value);
      colors.push(ChartHelper.getRandomColor());
    });

    this.chartPie = new Chart(this.ctx2, {
      type: 'doughnut',
      data: {
        labels: labels.reverse(),
        datasets: [{
          data: data.reverse(),
          label: 'Senhas por Fila',
          backgroundColor: colors,
          hoverOffset: 4
        }]
      },
      options: {
        legend: { display: data.length > 5 ? false : true, position: 'top' },
      } as any
    });
  }

  getEmpresa(id: string) {
    this.empresaService.getById(id).subscribe(
      empresa => {
        this.empresa = empresa;
      },
      err => this.alertService.error(err.error.message)
    );
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      franquias => {
        this.franquias = franquias;
        this.franquia = JSON.parse(localStorage.getItem(Constantes.currentFranquia));
        if (this.franquia != undefined) {
          this.idFranquiaSelecionada = this.franquia._id;
        }
        this.pagerReport();
        this.buildCharts();
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getDuracao(dataInicio: Date, dataFim: Date) {
    return UtilHelper.getDuracao(dataInicio, dataFim);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.pagerReport();
    if (page === 1) {
      this.buildCharts();
    }
  }

  exportarCSV() {
    this.exporting = true;
    new RelatorioSenhaHelper(this.relatorioSenhaService).reportCSVSenhas(this.searchParams)
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

  exportarExcel() {
    this.exporting = true;
    new RelatorioSenhaHelper(this.relatorioSenhaService).reportXLSLSenhas(this.searchParams)
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

  exportarPDF() {
    this.exporting = true;
    new RelatorioSenhaHelper(this.relatorioSenhaService).reportPDFSenhas(this.searchParams, this.chartArea, this.chartPie)
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

  setupLocationInit() {
    this.locationEnabled = super.getCurrentUserUser()?.empresa?.locationEnabled || false;
  }

}
