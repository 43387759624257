<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Usuários</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>
        <a [routerLink]="['/usuario']">Usuários</a>
      </li>
      <li class="active">
        <strong *ngIf="isNew">Adicionar</strong>
        <strong *ngIf="!isNew">Alterar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-8">
      <div class="ibox float-e-margins">

        <div class="ibox-content">

          <form (ngSubmit)="onSubmit()" #usuarioForm="ngForm" novalidate>
            <div class="form-group">
              <label>Nome</label>
              <input type="text" placeholder="Insira o Nome do Usuário" class="form-control" name="nome" id="nome"
                #nome="ngModel" [(ngModel)]="usuario.nome" required>
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="text" placeholder="Insira o Email do Usuário" class="form-control" name="email" id="email"
                #email="ngModel" [(ngModel)]="usuario.email" required>
            </div>

            <div class="form-group">
              <a *ngIf="usuario.admin" href="javascript:;" (click)="desativarAdmin()">
                <i class="fa fa-toggle-on fa-3x"></i>
              </a>
              <a *ngIf="!usuario.admin" href="javascript:;" (click)="ativarAdmin()">
                <i class="fa fa-toggle-off fa-3x"></i>
              </a>
              <span>
                <label>Administrador</label>
                <small>Perfil de administracão geral de toda a empresa</small>
              </span>
            </div>
            <div class="form-group">
              <a *ngIf="usuario.manager" href="javascript:;" (click)="desativarGerente()">
                <i class="fa fa-toggle-on fa-3x"></i>
              </a>
              <a *ngIf="!usuario.manager" href="javascript:;" (click)="ativarGerente()">
                <i class="fa fa-toggle-off fa-3x"></i>
              </a>
              <span>
                <label>Gerente</label>
                <small>Perfil de administração somente nos estabelecimentos selecionados</small>
              </span>
            </div>
            <div class="form-group">
              <a *ngIf="!usuario.admin && !usuario.manager" href="javascript:;">
                <i class="fa fa-toggle-on fa-3x"></i>
              </a>
              <a *ngIf="usuario.admin || usuario.manager" href="javascript:;" (click)="ativarAtendente()">
                <i class="fa fa-toggle-off fa-3x"></i>
              </a>
              <span>
                <label>Atendente</label>
                <small>Perfil de atendimento</small>
              </span>
            </div>

            <div class="form-group" *ngIf="!usuario.admin && (isAdmin() || isManager()) ">
              <label for="localImage">Estabelecimentos</label>
              <tag-input #input [(ngModel)]="franquiasSelecionadas" [ngModelOptions]="{standalone: true}"
                [theme]="'bootstrap'" [dragZone]="'zone1'" [modelAsStrings]="true" [allowDupes]="false"
                [blinkIfDupe]="true" [hideForm]="true" [onAdding]="transform">
                <ng-template let-item="item" let-index="index">
                  <span *ngIf="item">
                    {{ item.nome }}
                  </span>
                  <delete-icon *ngIf="item" (click)="input.removeItem(item, index)"></delete-icon>
                </ng-template>
              </tag-input>

            </div>
            <div class="form-group" *ngIf="!usuario.admin && (isAdmin() || isManager()) ">

              <div class="button-list mt-4">
                <button type="button" class="btn-rounded btn btn-outline-dark" (click)="newAttendanceAttribute(item)"
                  *ngFor="let item of franquias">{{item.nome}}</button>
              </div>
            </div>

            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!usuarioForm.form.valid">Salvar</button>
              <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>Alterar Imagem Usuário</h5>
        </div>
        <div class="ibox-content" [ngClass]="{'sk-loading': loadingImage}">
          <div class="sk-spinner sk-spinner-wave">
            <div class="sk-rect1"></div>
            <div class="sk-rect2"></div>
            <div class="sk-rect3"></div>
            <div class="sk-rect4"></div>
            <div class="sk-rect5"></div>
          </div>
          <div class="form-group" style="display: none">
            <input type="file" class="form-control" id="localImage" (change)="onChange($event)"
              accept=".jpg, .jpeg, .png">
          </div>
          <div class="panel-body text-center">
            <img *ngIf="usuario.image" src="{{usuario.image}}" style="max-width: 100%;" />
          </div>
          <hr class="m-y-0">
          <div class="panel-body text-center">
            <button type="button" class="btn btn-primary" (click)="openFileBrowser($event)">Alterar</button>&nbsp;
            <button type="button" class="btn btn-danger" (click)="removerImagem()">
              <i class="fa fa-trash"></i>
            </button>
            <div class="m-t-2 text-muted font-size-12">JPG, JPEG ou PNG. Tamano máximo 1MB</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>