import { AfterContentInit, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractComponent, AuthenticationService, ConstantService, Usuario, UsuarioService } from 'lib-smart-core';
import { timer } from 'rxjs';

@Component({
  templateUrl: 'login.component.html'
})
export class LoginComponent extends AbstractComponent implements OnInit, AfterContentInit {

  model: any = {};

  @ViewChild('btnAbrirModalEsqueciMinhaSenha') btnAbrirModalEsqueciMinhaSenha: ElementRef;
  @ViewChild('btnFecharModalEsqueciMinhaSenha') btnFecharModalEsqueciMinhaSenha: ElementRef;

  loading = false;

  constructor(
    private constantService: ConstantService,
    private authenticationService: AuthenticationService,
    private usuarioService: UsuarioService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.usuarioSenha = {} as Usuario;

    // reset login status
    this.constantService.setFranquiaSession(null);
    this.constantService.setMesaSession(null);
    this.constantService.setLocalizacaoSession(null);
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngAfterContentInit(): void {
    const esqueciMinhaSenhaModal = this.getQueryParam('esqueciMinhaSenhaModal');
    if (!!esqueciMinhaSenhaModal && esqueciMinhaSenhaModal === 'true') {
      timer(2000).subscribe(() => {
        this.btnAbrirModalEsqueciMinhaSenha.nativeElement.click();
      });
    }
  }

  login() {
    this.loading = true;
    this.authenticationService.login(this.model.email, this.model.senha).subscribe(
      data => {
        // this.router.navigate([this.returnUrl]);
        window.location.href = '/';
      },
      err => {
        console.log(err);
        this.loading = false;
        this.alertService.error(err.error.message);
      }
    );
  }

  usuarioSenha: Usuario;

  novoEsqueciMinhaSenha() {
    this.usuarioSenha = {} as Usuario;
  }

  esqueciMinhaSenha() {
    console.log('esqueciMinhaSenha')
    this.usuarioService.esqueciMinhaSenha(this.usuarioSenha.email.trim()).subscribe(
      (usuario: Usuario) => {
        this.closeModalEsqueciMinhaSenha();
        this.alertService.warning('Enviamos um email com o título \'Esqueci Minha Senha\' para ' + usuario.email + '. Acesse sua caixa para redefinir sua senha de acesso.');
      },
      err => {
        this.alertService.error(err.error.message);
        this.loading = false;
      }
    );
  }

  closeModalEsqueciMinhaSenha() {
    // const event = new Event('click', { bubbles: true });
    // this.renderer.invokeElementMethod(this.btnFecharModalEsqueciMinhaSenha.nativeElement, 'dispatchEvent', [event]);
    this.btnFecharModalEsqueciMinhaSenha.nativeElement.click();
  }

}
