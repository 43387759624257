import { Component, Injector, OnInit } from '@angular/core';
import { Chart } from "chart.js";
import { AbstractComponent, ChartHelper, Feedback, FeedbackService, Franquia, FranquiaService, Pager, Questionario, QuestionarioService } from 'lib-smart-core';
import QRCode from 'qrcode';
import * as watermark from 'watermarkjs';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'smartavalia-dashboard',
  templateUrl: './smartavalia-dashboard.component.html'
})
export class SmartAvaliaDashboardComponent extends AbstractComponent implements OnInit {

  pager: Pager<Feedback> = new Pager<Feedback>();

  questionario: Questionario;
  franquias: Franquia[];
  franquiaSelecionada: Franquia;

  imgSmartAvalia;
  urlSmartAvalia: string;

  canvas: any;
  ctx: any;

  idFranquiaSelecionada = '';
  model: any = {
    ano: new Date().getFullYear(),
    mes: new Date().getMonth() + 1,
  };

  chartLine: any;

  constructor(
    private feedbackService: FeedbackService,
    private franquiaService: FranquiaService,
    private questionarioService: QuestionarioService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.setupCanvasAndContexts();
    const idQuestionario: string = super.getParam('idQuestionario');
    if (idQuestionario) {
      this.getQuestionarioPorId(idQuestionario.toString());
      this.getFranquiasPorEmpresa();

    }
  }

  setupCanvasAndContexts() {
    this.canvas = document.getElementById('canvas-chart-area');
    this.ctx = this.canvas.getContext('2d');
  }

  onChangeData() {
    this.loadChartArea();
    this.getFeedbacksPorQuestionario();
  }

  loadChartArea() {

    let backEnd = null;
    if (this.idFranquiaSelecionada === '') {
      backEnd = this.feedbackService.getFeedbackGraficoPorQuestionario(this.questionario._id, this.model.mes, this.model.ano);
    } else {
      backEnd = this.feedbackService.getFeedbackGraficoPorQuestionarioPorFranquia(this.questionario._id, this.model.mes, this.model.ano, this.idFranquiaSelecionada);
    }

    // console.log(Chart.defaults.global)

    backEnd.subscribe(
      (data: any) => {

        let labels = data.labels;
        let datasets = data.datasets;

        let index = 0;
        datasets.forEach(element => {
          if (index > 5) {
            index = 0;
          }
          element.backgroundColor = ChartHelper.colorsDefault.backgroundColor[index];
          element.borderColor = ChartHelper.colorsDefault.borderColor[index];
          element.borderWidth = ChartHelper.colorsDefault.borderWidth;
          index++;
        });

        if (!!this.chartLine) {
          this.chartLine.destroy();
        }
        this.chartLine = new Chart(this.ctx, {
          type: 'line',
          data: {
            labels: labels,
            datasets: datasets
          },
          options: ChartHelper.optionsDefault as any
        });
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )

    // var chartColor2 = ChartHelper.getRandomColors(1)[0];
    // var chartColor1 = ChartHelper.getRandomColors(1)[0];
    // new Chart(this.ctx, {
    //   type: 'line',
    //   data: {
    //     labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    //     datasets: [{
    //       label: 'avaliações',
    //       data: [ChartHelper.getRandomData(3000, 900), ChartHelper.getRandomData(3000, 900), ChartHelper.getRandomData(3000, 900), ChartHelper.getRandomData(3000, 900), ChartHelper.getRandomData(3000, 900), ChartHelper.getRandomData(3000, 900), 1273],
    //       // borderWidth: 3,
    //        backgroundColor: ChartHelper.hexToRgba(chartColor1, 0.5),
    //       // borderColor: chartColor1,
    //     }, {
    //       label: 'avaliações',
    //       data: [ChartHelper.getRandomData(3000, 900), ChartHelper.getRandomData(3000, 900), ChartHelper.getRandomData(3000, 900), ChartHelper.getRandomData(3000, 900), ChartHelper.getRandomData(3000, 900), ChartHelper.getRandomData(3000, 900), 1273],
    //       // borderWidth: 3,
    //       // backgroundColor: ChartHelper.hexToRgba(chartColor2, 0.5),
    //       // borderColor: chartColor2,
    //     }]
    //   },
    //   options: {
    //     legend: { display: true },
    //     responsive: true,
    //     // display: false
    //   }
    // });
  }

  getQuestionarioPorId(idQuestionario: string) {
    this.questionarioService.getById(idQuestionario).subscribe(
      (questionario: Questionario) => {
        this.questionario = questionario;
        this.loadChartArea();
        this.getFeedbacksPorQuestionario();
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  getFranquiasPorEmpresa() {
    return this.franquiaService.getFranquiasPorEmpresa(super.getIDEmpresaSession()).subscribe(
      (franquia: Franquia[]) => {
        this.franquias = franquia
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getFeedbacksPorQuestionario();
  }

  getFeedbacksPorQuestionario() {
    let backEnd = null;
    if (this.idFranquiaSelecionada === '') {
      backEnd = this.feedbackService.getFeedbacksPorQuestionarioPagerPorFranquia(this.questionario._id, this.pager.page, this.pager.perPage, this.model.mes, this.model.ano);
    } else {
      backEnd = this.feedbackService.getFeedbacksPorQuestionarioPagerPorFranquia2(this.questionario._id, this.pager.page, this.pager.perPage, this.model.mes, this.model.ano, this.idFranquiaSelecionada);
    }

    return backEnd.subscribe(
      (pager: Pager<Feedback>) => {
        this.pager = pager;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  generateBannerSmartAvalia(franquia: Franquia) {
    // const _self = this;
    this.imgSmartAvalia = null;
    this.urlSmartAvalia = null;
    // console.log('setupCertificado')
    // console.log(this.curso.imagemCertificado)
    this.franquiaSelecionada = franquia;

    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", './assets/img/SmartAvaliaQRcode.jpg');
    xhr.responseType = "blob"; // force the HTTP response, response-type header to be blob
    xhr.onload = () => {
      // console.log('xhr.onload')
      // console.log(xhr.response)
      blob = xhr.response;//xhr.response is now a blob object
      let metadata = {
        type: 'image/jpeg'
      };
      let file = new File([blob], "SmartAvaliaQRcode.jpg", metadata);

      const baseUrl = (environment.smartAvaliaUrl)
      const URL = `${baseUrl}/feedback/${franquia.codigo}/${this.questionario._id}`;
      this.urlSmartAvalia = URL;

      // With promises
      QRCode.toDataURL(URL, { errorCorrectionLevel: 'L', scale: 20 })
        .then(qrcodeData => {
          // console.log('image qrcode')
          // console.log(qrcodeData)
          watermark([file, qrcodeData])
            .image(watermark.image.center())
            .then(img => {
              // _self.waterMarkImage.nativeElement.src = img.src;
              this.imgSmartAvalia = img.src;
              // _self.showCertificado = true;
            });
        })
        .catch(err => {
          console.error(err)
        })

    }
    xhr.send();

  }

}
