<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Dashboard</h2>
    <ol class="breadcrumb">
      <li>
        Indicadores, gráficos e informações gerencias dos estabelecimentos
      </li>
    </ol>
  </div>
</div>

<div class="wrapper-content">
  <div class="row m-t-md">
    <div class="col-sm-12">
      <div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Total de Senhas Emitidas</h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{totalAtendimentos}}</h1>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Total de Locais de Atendimento
          </h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{totalMesas}}</h1>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Total de Usuários</h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{totalUsuarios}}</h1>
          <!-- <small>Total de usuários</small> -->
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>
            Total de Estabelecimentos
          </h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{totalFranquias}}</h1>
          <!-- <small>Total de estabelecimentos</small> -->
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox">
        <div class="ibox-title" style="display: flex; justify-content:space-between; align-items: center;">
          <h5>Senhas e Atendimentos</h5>
          <div class="ibox-tools" style="display: flex; justify-content:end; align-items: center;">
            <form #atendimentoForm="ngForm" novalidate>
              <mat-form-field class="custom-date-range-field" appearance="outline">
                <mat-label>Selecione o período</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                  <input matStartDate formControlName="start" placeholder="Start date">
                  <input matEndDate formControlName="end" placeholder="End date" (dateChange)="loadPage()">
                </mat-date-range-input>
                <!-- <mat-hint>dd/mm/aaaa - dd/mm/aaaa</mat-hint> -->
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker>
                  <mat-date-range-picker-actions>
                    <select class="form-control" name="prefixo" id="prefixo" (change)="loadCustomDateRange($event)">
                      <option [value]="0">Customizado</option>
                      <option [value]="7">Últimos 7 dias</option>
                      <option [value]="15">Últimos 15 dias</option>
                      <option [value]="30">Últimos 30 dias</option>
                      <option [value]="90">Últimos 90 dias</option>
                      <option [value]="180">Útlimos 6 meses</option>
                      <option [value]="365">Útlimos 12 meses</option>
                    </select>
                    <button matDateRangePickerCancel #matDateRangePickerCancelAtendimento
                      style="display: none">Close</button>
                    <button mat-raised-button color="primary" matDateRangePickerApply>OK</button>
                  </mat-date-range-picker-actions>
                </mat-date-range-picker>

                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Data de início
                  inválida</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Data de fim inválida</mat-error>
              </mat-form-field>


              <mat-form-field class="custom-select-field" appearance="outline">
                <mat-label>Estabelecimento</mat-label>
                <mat-select [(ngModel)]="idFranquiaSelecionada" name="idFranquiaSelecionada" id="idFranquiaSelecionada"
                  (selectionChange)="loadPage()">
                  <mat-option [value]="''"> Todos</mat-option>
                  <mat-option *ngFor="let f of franquias" [value]="f._id">
                    {{f.nome}} {{!!f.nomeFantasia ? ' | ' + f.nomeFantasia : ''}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </form>
          </div>
        </div>
        <div class="ibox-content" [ngClass]="{'sk-loading': loadingCharts}">
          <div class="sk-spinner sk-spinner-wave">
            <div class="sk-rect1"></div>
            <div class="sk-rect2"></div>
            <div class="sk-rect3"></div>
            <div class="sk-rect4"></div>
            <div class="sk-rect5"></div>
          </div>
          <div class="row">
            <div class="col-lg-9">
              <canvas id="canvas-chart-senha-atendimento" height="100"></canvas>
            </div>
            <div class="col-lg-3">
              <ul class="stat-list">
                <li>
                  <h2 class="no-margins">{{totalSenhas.TOTAL_SENHAS_EMITIDAS}}</h2>
                  <small>Total de senhas emitidas</small>
                  <div class="stat-percent">{{totalSenhas.PERC_SENHAS_EMITIDAS}}%</div>
                  <div class="progress progress-mini">
                    <div [style.width]="(totalSenhas.PERC_SENHAS_EMITIDAS)+'%'" class="progress-bar"></div>
                  </div>
                </li>
                <li>
                  <h2 class="no-margins ">{{totalSenhas.TOTAL_SENHAS_CHAMADAS}}</h2>
                  <small>Total de senhas chamadas</small>
                  <div class="stat-percent">{{totalSenhas.PERC_SENHAS_CHAMDAS}}%</div>
                  <div class="progress progress-mini">
                    <div [style.width]="totalSenhas.PERC_SENHAS_CHAMDAS+'%'" class="progress-bar"></div>
                  </div>
                </li>
                <li>
                  <h2 class="no-margins ">{{totalSenhas.TOTAL_SENHAS_ATENDIMENTO}}</h2>
                  <small>Total de senhas com atendimentos iniciados</small>
                  <div class="stat-percent">{{totalSenhas.PERC_SENHAS_ATENDIMENTO}}%</div>
                  <div class="progress progress-mini">
                    <div [style.width]="(totalSenhas.PERC_SENHAS_ATENDIMENTO)+'%'" class="progress-bar"></div>
                  </div>
                </li>
                <li>
                  <h2 class="no-margins ">{{totalSenhas.TOTAL_SENHAS_NORMAL}}</h2>
                  <small>Total de senhas para atendimento normal</small>
                  <div class="stat-percent">{{totalSenhas.PERC_SENHAS_NORMAL}}%</div>
                  <div class="progress progress-mini">
                    <div [style.width]="(totalSenhas.PERC_SENHAS_NORMAL) +'%'" class="progress-bar"></div>
                  </div>
                </li>
                <li>
                  <h2 class="no-margins ">{{totalSenhas.TOTAL_SENHAS_PREFERENCIAL}}</h2>
                  <small>Total de senhas para atendimento preferencial</small>
                  <div class="stat-percent">{{totalSenhas.PERC_SENHAS_PREFERENCIAL}}%</div>
                  <div class="progress progress-mini">
                    <div [style.width]="(totalSenhas.PERC_SENHAS_PREFERENCIAL)+'%'" class="progress-bar"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="ibox-footer" style="display:flex; justify-content: end;">
          <div class="btn-group">
            <a [routerLink]="['/relatorio/senhas']" class="btn btn-sm btn-white">Ir para o relatório completo</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <div class="ibox">
        <div class="ibox-title">
          <h5>Tipos de Atendimento
          </h5>
          <div class="ibox-tools">

          </div>
        </div>
        <div class="ibox-content">
          <canvas id="canvas-chart-tipo-atendimento"></canvas>
        </div>
        <div class="ibox-footer" style="display:flex; justify-content: end;">
          <div class="btn-group">
            <a [routerLink]="['/relatorio/tipos/atendimento']" class="btn btn-sm btn-white">Ir para o relatório
              completo</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="ibox">
        <div class="ibox-title">
          <h5>Avaliação</h5>
          <div class="ibox-tools">

          </div>
        </div>
        <div class="ibox-content">
          <canvas id="canvas-chart-feedback"></canvas>
        </div>
        <div class="ibox-footer" style="display:flex; justify-content: end;">
          <div class="btn-group">
            <a [routerLink]="['/relatorio/avaliacoes']" class="btn btn-sm btn-white">Ir para o relatório completo</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>