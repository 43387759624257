export const environment = {
  production: true,
  apiUrl: 'https://hml-api.smartline.com.br/api',
  baseUrl: 'https://hml-api.smartline.com.br',
  smartProUrl: 'http://hml-controle.smartline.com.br',
  smartLiteUrl: 'https://hml-lite.smartline.com.br',
  smartPagerUrl: 'https://hml-pager.smartline.com.br',
  smartAvaliaUrl: 'https://hml-avalia.smartline.com.br',
  smartTVUrl: 'https://hml-tv.smartline.com.br',
  socketUrl: 'https://hml-socket.smartline.com.br',
  mapbox: {
    accessToken: 'pk.eyJ1IjoiaW5kaGlvIiwiYSI6ImNsMGg0NTYyNDA0eTQzYm56NG4zaXc4M2MifQ.DOz4yhMDEA98ZE0f_Dez0w',
    latitude: -23.5558,
    longitude: -46.6396
  },
  firebase: {
    apiKey: "AIzaSyBuzHW0mpLQgDBeN430u92_D2l731zcvZ0",
    authDomain: "smartline-e103d.firebaseapp.com",
    databaseURL: "https://smartline-e103d.firebaseio.com",
    projectId: "smartline-e103d",
    storageBucket: "smartline-e103d.appspot.com",
    messagingSenderId: "505523847230",
    appId: "1:505523847230:web:f6b02e220a92221433116f",
    measurementId: "G-4Z1XSBVS36"
  }
};
