
import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Atendimento, Empresa, EmpresaService, Franquia, FranquiaService, Pager, Senha, SenhaService } from 'lib-smart-core';
import * as moment from 'moment'; // add this 1 of 4
import { Constantes } from "../../../../environments/constantes";
import { RelatorioClientesHelper } from './relatorio-clientes.helper';

@Component({
  selector: 'relatorio-clientes',
  templateUrl: './relatorio-clientes.component.html'
})
export class RelatorioClientesComponent extends AbstractComponent implements OnInit {

  canvas: any;
  ctx: any;

  mostrarGrafico: Boolean = false;
  idFranquiaSelecionada = '';

  empresa: Empresa;

  franquia: Franquia;
  atendimentos: Atendimento[];

  historico: Atendimento;
  franquias: Franquia[];

  model: any = {
    ano: new Date().getFullYear(),
    mes: new Date().getMonth() + 1,
  };

  pager: Pager<Senha> = new Pager<Senha>({ perPage: 100 });

  exporting: boolean = false;

  constructor(
    private franquiaService: FranquiaService,
    private senhaService: SenhaService,
    private empresaService: EmpresaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model.dataStr = moment(new Date()).format('DD/MM/YYYY');
    var dataAtual = new Date();
    this.model.date = { date: { year: dataAtual.getFullYear(), month: dataAtual.getMonth() + 1, day: dataAtual.getDate() } };

    let idEmpresa: string = super.getIDEmpresaSession();
    if (idEmpresa) {
      this.getEmpresa(idEmpresa);
      this.getFranquiasPorEmpresa(idEmpresa);
    }
  }

  ngAfterViewInit() {
    this.pagerEmpresaMensal();
  }

  pagerEmpresaMensal() {
    let backEnd = this.senhaService.pagerClientes(this.pager.page, this.pager.perPage, super.getIDEmpresaSession());

    backEnd.subscribe(
      (pager: Pager<Senha>) => {
        this.pager = pager;
      },
      err => {
        console.log(err);
        this.alertService.error(err.error.message);
      }
    );
  }

  getEmpresa(id: string) {
    this.empresaService.getById(id).subscribe(
      empresa => {
        this.empresa = empresa;
      },
      err => this.alertService.error(err.error.message)
    );
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      franquias => {
        this.franquias = franquias;
        this.franquia = JSON.parse(localStorage.getItem(Constantes.currentFranquia));
        if (this.franquia != undefined) {
          this.idFranquiaSelecionada = this.franquia._id;
        }
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.pagerEmpresaMensal();
  }

  exportarCSV() {
    this.exporting = true;
    new RelatorioClientesHelper(this.senhaService).reportCSVLeads(super.getIDEmpresaSession())
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

  exportarExcel() {
    this.exporting = true;
    new RelatorioClientesHelper(this.senhaService).reportXLSXLeads(super.getIDEmpresaSession())
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }
  
  exportarPDF() {
    this.exporting = true;
    new RelatorioClientesHelper(this.senhaService).reportPDFLeads(super.getIDEmpresaSession())
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

}