import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractComponent, AvaliacaoService, Franquia, FranquiaService } from 'lib-smart-core';

@Component({
  selector: 'avaliacao-modal-franquia',
  templateUrl: './avaliacao-modal-franquia.component.html'
})

export class AvaliacaoModalFranquiaComponent extends AbstractComponent implements OnInit {

  @Input() idFranquia: string;
  @Input() pontuacao: number = 0;

  franquia: Franquia;

  star_full: string = 'fa fa-star fa-lg';
  star_empty: string = 'fa fa-star-o fa-lg';
  star_half: string = 'fa fa-star-half-o fa-lg';

  star_1: string;
  star_2: string;
  star_3: string;
  star_4: string;
  star_5: string;

  data: any;

  constructor(
    private avaliacaoService: AvaliacaoService,
    private franquiaService: FranquiaService,
    public injector: Injector
  ) {
    super(injector);
    this.star_1 = this.star_empty;
    this.star_2 = this.star_empty;
    this.star_3 = this.star_empty;
    this.star_4 = this.star_empty;
    this.star_5 = this.star_empty;
  }

  ngOnInit() {
    this.getFranquiaPorId();
    this.getResultadoAvaliacaoFranquiaDetalhada();
  }

  getFranquiaPorId() {
    this.franquiaService.getById(this.idFranquia).subscribe(
      franquia => {
        this.franquia = franquia;
      },
      err => this.alertService.error(err.error.message)
    );
  }

  getResultadoAvaliacaoFranquiaDetalhada() {
    this.avaliacaoService.getResultadoAvaliacaoFranquiaDetalhada(this.idFranquia).subscribe(
      data => {
        this.data = data;
        console.log(data);
      },
      err => this.alertService.error(err.error.message)
    );
  }

  getPercentualStr(valor: number): string {
    let width = 0;
    if (this.data) {
      width = (((valor) / (this.data.totalAvaliacoes)) * 100);
    }

    let str = width.toString();
    if (str.startsWith('0')) {
      str = '1';
    } else {
      if (str.indexOf('.') != -1) {
        str = str.substring(0, str.indexOf('.'));
      }
    }
    // console.log(width);
    return str + '%';
  }

}