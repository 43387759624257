<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Estabelecimento</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li class="active">
        <strong>Estabelecimentos</strong>
      </li>
    </ol>
  </div>
</div>

<div class="row wrapper wrapper-content pb-0 animated fadeInRight">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>Filtro</h5>
      </div>
      <div class="ibox-content">
        <div class="input-group">
          <span class="input-group-addon" id="sizing-addon2"><i class="fa fa-search"></i></span>
          <input type="text" class="form-control shadow-inset-2" #searchBox (keyup)="search(searchBox.value)"
            placeholder="Procurar...">
        </div>

      </div>
    </div>
  </div>
</div>

<div class="row wrapper wrapper-content animated fadeInRight">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title d-flex justify-content-between align-items-center">
        <h5>Estabelecimentos ({{pager.total}})</h5>
        <div class="ibox-tools">
          <!-- <div class="input-group">
            <span class="input-group-addon" id="sizing-addon2"><i class="fa fa-search"></i></span>
            <input type="text" class="form-control shadow-inset-2" #searchBox (keyup)="search(searchBox.value)"
              placeholder="Procurar...">
          </div> -->
        </div>
      </div>
      <div class="ibox-content min-h pb-0">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Avaliação</th>
                <th>Nome</th>
                <th>Código</th>
                <th>Cidade/UF</th>
                <th>Bloqueado?</th>
                <th width="15%" class="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let franquia of franquias">
                <th>
                  <feedback-embeded [rating]="franquia.rating"></feedback-embeded>
                </th>
                <th>
                  {{franquia.nome}}<br />
                  <small>{{franquia.nomeFantasia ?? franquia.email}}</small>
                </th>
                <td>{{franquia.codigo}}</td>
                <td>{{franquia.cidade}}/{{franquia.estado}}</td>
                <td>
                  <span class="label label-danger" style="margin-right: 2px;"
                    *ngIf="franquia.bloqueado">Bloqueado</span>
                  <span class="label label-primary" style="margin-right: 2px;"
                    *ngIf="!franquia.bloqueado">Liberado</span>
                </td>
                <td class="text-right">
                  <div class="input-group-btn">
                    <a class="btn btn-white btn-sm" title="Editar" *ngIf="isAdmin() || isManager()"
                      [routerLink]="['/franquia/edit/', franquia._id]">
                      <i class="fa fa-edit"></i>
                    </a>
                    <button data-toggle="dropdown" class="btn btn-primary btn-sm dropdown-toggle"
                      title="Configurações do Estabelecimento" type="button">
                      <i class="fa fa-cogs"></i> <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                      <li *ngIf="isAdmin() || isManager()">
                        <a [routerLink]="['/fila/', franquia._id]">
                          <i class="fa fa-street-view"></i> Filas
                        </a>
                      </li>
                      <li *ngIf="isAdmin() || isManager()">
                        <a [routerLink]="['/mesa/', franquia._id]">
                          <i class="fa fa-desktop"></i> Locais
                        </a>
                      </li>
                      <li>
                        <a title="TV" [routerLink]="['/tv/', franquia._id]">
                          <i class="fa fa-tv"></i> TVs
                        </a>
                      </li>
                      <li class="divider"></li>
                      <li>
                        <a title="Configurações" [routerLink]="['/config/edit/', franquia._id]">
                          <i class="fa fa-cog"></i> Parametrizações
                        </a>
                      </li>
                      <li class="divider"></li>
                      <li *ngIf="isAdmin() || isManager()">
                        <a title="Tablet" (click)="setupQRCodeFranquia(franquia._id)" data-toggle="modal"
                          data-target="#qrcodetablet">
                          <i class="fa fa-tablet"></i> Simulador
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-center" *ngIf="pager.total > pager.perPage">
            <paginator class="pagination" [pager]="pager" (onPager)="loadPage($event)"></paginator>
          </div>

          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
      
          <div class="modal inmodal" id="qrcodetablet" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content animated bounceInRight">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
                      class="sr-only">Close</span></button>
                  <i class="fa fa-tablet modal-icon"></i>
                  <h4 class="modal-title">Simulador</h4>
                  <small class="font-bold">O QRCode abaixo indica a URL que deverá ser utilizada para
                    este estabelecimento.
                  </small>
                </div>
                <div class="modal-body">
                  <div align="center">
                    <qrcode [qrdata]="URL_TABLET_FRANQUIA" [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
                    <p>
                      <br /><br /> <a href="{{URL_TABLET_FRANQUIA}}" target="_blank">{{URL_TABLET_FRANQUIA}}</a>
                    </p>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal inmodal" id="qrcodetv" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content animated bounceInRight">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
                      class="sr-only">Close</span></button>
                  <i class="fa fa-tv modal-icon"></i>
                  <h4 class="modal-title">TV</h4>
                  <small class="font-bold">O QRCode abaixo indica a URL que deverá ser utilizada para
                    esta estabelecimento.
                  </small>
                </div>
                <div class="modal-body">
                  <div align="center">
                    <qrcode [qrdata]="URL_TV_FRANQUIA" [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
                    <p>
                      <br /><br /> <a href="{{URL_TV_FRANQUIA}}" target="_blank">{{URL_TV_FRANQUIA}}</a>
                    </p>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>