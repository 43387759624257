import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractComponent,
  Atendimento,
  AtendimentoService,
  Comentario,
  ComentarioService,
  Config,
  ConfigService,
  Empresa,
  Fila,
  FilaService,
  Franquia,
  FranquiaService,
  Pager,
  Senha,
  SenhaService,
  TipoAtendimento,
  TipoAtendimentoService,
  Usuario,
} from 'lib-smart-core';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Constantes } from '../../../environments/constantes';
import { AtualizarSenhaComponent } from './atualizar-senha/atualizar-senha.component';
import { id } from 'date-fns/locale';

@Component({
  selector: 'atendimento-form',
  templateUrl: './atendimento-form.component.html',
})
export class AtendimentoFormComponent
  extends AbstractComponent
  implements OnInit
{
  pager: Pager<Comentario> = new Pager({ perPager: 5 });

  atendimento: Atendimento;
  franquia: Franquia;
  config: Config;
  fila: Fila;
  filas: Fila[];
  senha: Senha;
  loading: boolean = false;
  tipoAtendimentoObrigatorio: boolean = false;

  tipoAtendimentos: TipoAtendimento[];
  tipoAtendimentosMain: TipoAtendimento[];
  tipoAtendimentosSelecionados: TipoAtendimento[];

  @ViewChild('modalAtualizarSenha')
  modalAtualizarSenha: AtualizarSenhaComponent;
  @ViewChild('btnAtualizarSenha') btnAtualizarSenha: ElementRef;
  loadingChamar = false;

  listObservable: Observable<Array<TipoAtendimento>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  franquias: Franquia[];
  franquiaSelecionada: Franquia;

  empresa: Empresa = this.getEmpresaSession();

  idFilaOrigem: string;

  constructor(
    private filaService: FilaService,
    private configService: ConfigService,
    private senhaService: SenhaService,
    private atendimentoService: AtendimentoService,
    private tipoAtendimentoService: TipoAtendimentoService,
    private comentarioService: ComentarioService,
    public injector: Injector,
    private franquiaService: FranquiaService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.tipoAtendimentos = new Array<TipoAtendimento>();
    this.tipoAtendimentosSelecionados = new Array<TipoAtendimento>();
    // this.atendimento = new Atendimento(null, null, new Date(), null, null, null, null, null, super.getIDCurrentUserSession());
    this.atendimento = {} as Atendimento;
    this.atendimento.dataInicio = new Date();
    this.atendimento.usuario = {
      _id: super.getIDCurrentUserSession(),
    } as Usuario;

    this.franquia = JSON.parse(
      localStorage.getItem(Constantes.currentFranquia)
    );
    this.franquiaSelecionada = this.franquia;
    let idSenha: string = super.getParam('idSenha');
    let idFila: string = super.getParam('idFila');
    this.idFilaOrigem = idFila;
    // this.senha = new Senha(null, '', new Date(), null, null, '', '', '', '', false, false, '', null);
    this.senha = {} as Senha;
    this.senha.data = new Date();
    this.senha.compareceu = false;
    this.senha.saiuDaFila = false;

    if (idSenha && idFila) {
      // this.atendimento = new Atendimento(null, '', new Date(), null, this.franquia._id, this.franquia.empresa, null, idSenha, super.getIDCurrentUserSession());
      this.atendimento = {} as Atendimento;
      this.atendimento.dataInicio = new Date();
      this.atendimento.franquia = { _id: this.franquia._id } as Franquia;
      this.atendimento.empresa = {
        _id: super.getIDEmpresaSession(),
      } as Empresa;
      this.atendimento.senha = { _id: idSenha } as Senha;
      this.atendimento.usuario = {
        _id: super.getIDCurrentUserSession(),
      } as Usuario;
      this.getSenhaPorId(idSenha, idFila);
    }
    this.getConfigPorFranquia();
    this.getFilasPorFranquia(this.franquiaSelecionada._id); // carregar encaminhar
    this.getTipoAtendimentoMaisUsados(idFila); // carregar tipos de atendimentos mais usados
    this.getFranquias(super.getIDEmpresaSession());
  }

  getSenhaPorId(id: string, idFila: string) {
    return this.senhaService.getById(id).subscribe(
      (senha) => {
        this.senha = senha;
        this.getFilaPorId(idFila);
      },
      (err) => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getFilaPorId(id: string) {
    return this.filaService.getById(id).subscribe(
      (fila) => {
        this.fila = fila;
        this.salvarAtendimentoInicial();
      },
      (err) => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getFilasPorFranquia(id: string) {
    return this.filaService.getFilasPorFranquia(id).subscribe(
      (filas) => {
        this.filas = filas;
      },
      (err) => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getConfigPorFranquia() {
    return this.configService.getConfigPorFranquia(this.franquia._id).subscribe(
      (config) => {
        this.config = config;
        this.tipoAtendimentoObrigatorio =
          this.config.tipoAtendimentoObrigatorio || false;
      },
      (err) => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getRestanteTipoAtendimentos(tipoAtendimentosMaisUsados) {
    return this.tipoAtendimentoService.getAll().subscribe(
      (tipoAtendimentosAll) => {
        let newArrayTipoAtendimentos = [...tipoAtendimentosMaisUsados];
        let idsMaisUsados = tipoAtendimentosMaisUsados.map((item) => item._id);
        newArrayTipoAtendimentos.push(
          ...tipoAtendimentosAll.filter(
            (item) => !idsMaisUsados.includes(item._id)
          )
        );
        this.tipoAtendimentos = newArrayTipoAtendimentos;
        this.tipoAtendimentosMain = newArrayTipoAtendimentos;
        this.loading = false;
      },
      (err) => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getTipoAtendimentoMaisUsados(idFila) {
    this.loading = true;
    return this.tipoAtendimentoService
      .getTipoAtendimentoMaisUsados(idFila)
      .subscribe(
        (tipoAtendimentosMaisUsados) => {
          this.getRestanteTipoAtendimentos(tipoAtendimentosMaisUsados);
          this.setupObservableSearch();
        },
        (err) => {
          this.alertService.error(err.error.message);
        }
      );
  }

  salvarAtendimentoInicial(): void {
    this.atendimento.fila = { _id: this.fila._id } as Fila;
    this.atendimentoService.create(this.atendimento).subscribe(
      (atendimento) => {
        this.atendimento = atendimento;
      },
      (err) => {
        this.alertService.error(err.error.message);
      }
    );
  }

  concluirAtendimento(): void {
    this.atendimento.dataFim = new Date();
    this.atendimento.tipoAtendimentos = this.tipoAtendimentosSelecionados;
    this.atendimentoService.concluirAtendimento(this.atendimento).subscribe({
      next: (atendimento) => {
        this.alertService.success('Operação realizada com sucesso');
        this.router.navigate(['/atendimento-filas', this.fila._id]);
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  encaminhar(idFilaEncaminhar: string, idFranquiaEncaminhar: string): void {
    this.concluirAtendimento();
    this.senhaService
      .encaminhar(idFilaEncaminhar, this.senha._id, idFranquiaEncaminhar)
      .subscribe(
        (atendimento) => {
          this.alertService.success('Operação realizada com sucesso');
          this.router.navigate(['/atendimento-filas', this.fila._id]);
        },
        (err) => {
          this.alertService.error(err.error.message);
        }
      );
  }

  newTipoAtendimento(item: TipoAtendimento) {
    if (item) {
      if (
        !this.tipoAtendimentosSelecionados ||
        this.tipoAtendimentosSelecionados.length === 0
      ) {
        this.tipoAtendimentosSelecionados = new Array<TipoAtendimento>();
      }
      if (!this.tipoAtendimentosSelecionados.includes(item)) {
        this.tipoAtendimentosSelecionados.push(item);
      }
    }
  }

  public transform(value: string): Observable<string> {
    return of(value);
  }

  atualizarDados(modal) {
    this.modalAtualizarSenha.open(modal);
  }

  exibeModalAlterarSenha(senha: Senha) {
    this.senha = senha;
  }

  alterarSenha() {
    this.senha.permiteCadastro = true;
    this.senha.permiteWhatsApp = true;
    this.senha.app = 'LITE';

    this.senha.campos = [] as any;

    if (!!this.senha.fila.campos && this.senha.fila.campos.length > 0) {
      this.senha.fila.campos.forEach((campo: any, index: number) => {
        const newCampoValue = {
          key: campo.key,
          value: (document.getElementById('campo_' + index) as any).value
        }
        this.senha.campos.push(newCampoValue);
      });
    }

    this.senhaService.update(this.senha).subscribe(
      () => {
        this.alertService.success('Operação realizada com sucesso');
        this.btnAtualizarSenha.nativeElement.click();
      },
      (err) => this.alertService.error(err.error.message)
    );
  }

  setupObservableSearch() {
    this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(
        switchMap((term) => {
          this.tipoAtendimentos = this.tipoAtendimentosMain.filter((item) =>
            item.nome.toLowerCase().includes(term?.toLowerCase())
          );
          return this.tipoAtendimentos;
        })
      )
      .subscribe(() => (this.loading = false));
  }

  search(termo: string) {
    this.loading = true;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

  getComentarios() {
    const searchParams = {
      senha: this.senha._id,
    };
    return this.comentarioService
      .getAllPagerQuerySearch(
        this.pager.page,
        this.pager.perPage,
        this.searchString,
        this.searchParams
      )
      .subscribe(
        (pager: Pager<Comentario>) => {
          this.pager = pager;
        },
        (err) => this.alertService.error(err.error.message)
      );
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getComentarios();
  }

  getFranquias(id: string) {
    return this.franquiaService.getFranquiasPorEmpresa(id).subscribe(
      (franquias) => {
        this.franquias = franquias;
      },
      (err) => {
        this.alertService.error(err.error.message);
      }
    );
  }

  onChangeFranquia(event) {
    this.franquiaSelecionada._id = event.target.value; // franquia selecionada
    this.getFilasPorFranquia(this.franquiaSelecionada._id);
  }
}
