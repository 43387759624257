<div>
  <section class="container-fluid">
    <div class="sso-tablet">

      <div class="widget style1 row m-b-lg m-t-lg">
        <div class="col-xs-12 col-sm-12">
          <div class="logo text-center">
            <logo-image *ngIf="empresa" [empresa]="empresa" [idFranquia]="idFranquia"
              [imgStyles]="'max-height: 200px'"></logo-image>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!isNormalPreferencial">
        <div class="row" *ngFor="let fila of filas">
          <div class="col-xs-12 col-md-4 col-md-offset-4" *ngIf="fila.visivelTablet">
            <button *ngIf="!fila.preferencial" class="btn btn-primary btn-xlg btn-block" style="height: 55px;"
              (click)="gerarNovaSenha(fila)">{{fila.nome
              | uppercase}}</button>
            <button *ngIf="fila.preferencial" class="btn btn-primary btn-xlg btn-block" style="height: 55px;"
              (click)="selecionarFila(fila)">{{fila.nome
              | uppercase}}</button>
            <br />
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isNormalPreferencial">
        <div class="row">
          <div class="col-xs-12 col-md-4 col-md-offset-4">
            <button class="btn btn-primary btn-xlg btn-block" style="height: 55px;"
              (click)="gerarNovaSenhaPreferencia(filaSelecionada, false)">NORMAL</button>
            <br />
            <button class="btn btn-primary btn-xlg btn-block" style="height: 55px;"
              (click)="gerarNovaSenhaPreferencia(filaSelecionada, true)">PREFERENCIAL</button>
            <br />
            <button class="btn btn-secondary btn-xlg btn-block" style="height: 55px;"
              (click)="setNormalPreferencial(false)">VOLTAR</button>
            <br />
          </div>
        </div>
      </ng-container>

      <div class="widget style1 row m-b-lg m-t-lg">
        <div class="col-xs-12 col-sm-12">
          <div class="logo text-center" *ngIf="senhaSelecionada" style="color:black;">
            <h1><strong>{{senhaSelecionada?.senha}}</strong></h1>
            <h3>{{filaSelecionada?.nome | uppercase}}</h3><br />
            <qrcode [qrdata]="getQRCodeValue(senhaSelecionada?._id)" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
            <br />
            <br />
            <h5>{{senhaSelecionada?.created_at | date: 'dd/MM/yyyy HH:mm:ss'}}</h5>
            <h5>www.smartline.com.br</h5>
          </div>
        </div>
      </div>

    </div>

  </section>

</div>