import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Config, ConfigService, Empresa, EmpresaService, Franquia, FranquiaService } from 'lib-smart-core';
import QRCode from 'qrcode';
import * as watermark from 'watermarkjs';

@Component({
  selector: 'smartpager',
  templateUrl: './smartpager.component.html'
})
export class SmartPagerComponent extends AbstractComponent implements OnInit {

  empresa: Empresa;
  config: Config;
  franquias: Franquia[];

  imgCertificados: any[];

  imgSmartFilas: any;
  imgSmartAvalia: any;

  constructor(
    private configService: ConfigService,
    private franquiaService: FranquiaService,
    private empresaService: EmpresaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.imgCertificados = [];
    this.config = {} as Config;
    let id: string = super.getIDEmpresaSession();
    if (id) {
      this.loadEmpresa();
      this.getFranquiasPorEmpresa(id);
    }
  }

  loadEmpresa() {
    this.empresaService.getById(super.getIDEmpresaSession()).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
      }, err => this.alertService.error(err.error.message)
    );
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    return this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      (franquia: Franquia[]) => {
        this.franquias = franquia;
        this.setupQRCodes();

      },
      err => {
        this.alertService.error(err.error.message)
      }
    )
  }

  getConfigPorFranquia(idFranquia: string) {
    return this.configService.getConfigPorFranquia(idFranquia).subscribe(
      (config: Config) => {
        return this.config = config
      },
      err => {
        this.alertService.error(err.error.message)
      }
    )
  }

  setupQRCodes() {
    console.log('setupCertificado');
    const _self = this;
    // console.log('setupCertificado')
    // console.log(this.curso.imagemCertificado)
    if (this.franquias && this.franquias.length > 0) {
      this.imgCertificados = [];
      this.franquias.forEach((franquia: Franquia) => {

        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", './assets/img/SmartQRcode.jpg');
        xhr.responseType = "blob"; // force the HTTP response, response-type header to be blob
        xhr.onload = function () {
          // console.log('xhr.onload')
          // console.log(xhr.response)
          blob = xhr.response;//xhr.response is now a blob object
          let metadata = {
            type: 'image/jpeg'
          };
          let file = new File([blob], "certificado.jpg", metadata);

          // With promises
          QRCode.toDataURL(franquia.codigo, { errorCorrectionLevel: 'H', scale: 30 })
            .then(url => {
              let qrcode = new File([blob], "qrcode.jpg", metadata);
              console.log('image qrcode')
              console.log(url)
              watermark([file, url])
                .image(watermark.image.center())
                .then(img => {
                  // _self.waterMarkImage.nativeElement.src = img.src;
                  _self.imgCertificados.push(img.src);
                  // _self.showCertificado = true;
                });

            })
            .catch(err => {
              console.error(err)
            })

        }
        xhr.send();

      });
      console.log('this.imgCertificados');
      console.log(this.imgCertificados)
    }
  }

  generateBannerSmartFilas(franquia: Franquia) {
    const _self = this;
    this.imgSmartFilas = null;
    // console.log('setupCertificado')
    // console.log(this.curso.imagemCertificado)

    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", './assets/img/SmartQRcode.jpg');
    xhr.responseType = "blob"; // force the HTTP response, response-type header to be blob
    xhr.onload = function () {
      // console.log('xhr.onload')
      // console.log(xhr.response)
      blob = xhr.response;//xhr.response is now a blob object
      let metadata = {
        type: 'image/jpeg'
      };
      let file = new File([blob], "SmartQRcode.jpg", metadata);

      // With promises
      QRCode.toDataURL("https://lite.smartline.com.br/filas/" + franquia.codigo, { errorCorrectionLevel: 'L', scale: 20 })
        .then(qrcodeData => {
          // console.log('image qrcode')
          // console.log(qrcodeData)
          watermark([file, qrcodeData])
            .image(watermark.image.center())
            .then(img => {
              // _self.waterMarkImage.nativeElement.src = img.src;
              _self.imgSmartFilas = img.src;
              // _self.showCertificado = true;
            });
        })
        .catch(err => {
          console.error(err)
        })

    }
    xhr.send();

  }

  generateBannerSmartAvalia(franquia: Franquia) {
    const _self = this;
    this.imgSmartAvalia = null;
    // console.log('setupCertificado')
    // console.log(this.curso.imagemCertificado)

    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", './assets/img/SmartAvaliaQRcode.jpg');
    xhr.responseType = "blob"; // force the HTTP response, response-type header to be blob
    xhr.onload = function () {
      // console.log('xhr.onload')
      // console.log(xhr.response)
      blob = xhr.response;//xhr.response is now a blob object
      let metadata = {
        type: 'image/jpeg'
      };
      let file = new File([blob], "SmartAvaliaQRcode.jpg", metadata);

      // With promises
      QRCode.toDataURL("https://lite.smartline.com.br/avaliacao/" + franquia.codigo, { errorCorrectionLevel: 'L', scale: 20 })
        .then(qrcodeData => {
          // console.log('image qrcode')
          // console.log(qrcodeData)
          watermark([file, qrcodeData])
            .image(watermark.image.center())
            .then(img => {
              // _self.waterMarkImage.nativeElement.src = img.src;
              _self.imgSmartAvalia = img.src;
              // _self.showCertificado = true;
            });
        })
        .catch(err => {
          console.error(err)
        })

    }
    xhr.send();
  }

  getImagemCertificadoByIndex(index: number) {
    return (this.imgCertificados[index]);
  }

}
