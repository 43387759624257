
import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractComponent } from 'lib-smart-core';

@Component({
  selector: 'feedback-embeded',
  templateUrl: './feedback-embeded.component.html'
})
export class FeedbackEmbededComponent extends AbstractComponent implements OnInit {

  @Input() rating: number;

  colorRating: string = '';

  star_full: string = 'fa fa-star fa-2x';
  star_empty: string = 'fa fa-star-o fa-2x';
  star_half: string = 'fa fa-star-half-o fa-2x';

  star_1: string;
  star_2: string;
  star_3: string;
  star_4: string;
  star_5: string;

  constructor(
    public injector: Injector
  ) {
    super(injector);
    this.star_1 = this.star_empty;
    this.star_2 = this.star_empty;
    this.star_3 = this.star_empty;
    this.star_4 = this.star_empty;
    this.star_5 = this.star_empty;
  }

  ngOnInit() {
    if (this.rating) {
      if (this.rating === 0) {
        this.star_1 = this.star_empty;
        this.star_2 = this.star_empty;
        this.star_3 = this.star_empty;
        this.star_4 = this.star_empty;
        this.star_5 = this.star_empty;
      } else if (this.rating === 1 || (this.rating > 0.75 && this.rating < 1.25)) {
        this.star_1 = this.star_full;
      } else if (this.rating === 2 || (this.rating > 1.75 && this.rating < 2.25)) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
      } else if (this.rating === 3 || (this.rating > 2.75 && this.rating < 3.25)) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_full;
      } else if (this.rating === 4 || (this.rating > 3.75 && this.rating < 4.25)) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_full;
        this.star_4 = this.star_full;
      } else if (this.rating === 5 || (this.rating > 4.75)) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_full;
        this.star_4 = this.star_full;
        this.star_5 = this.star_full;
      } else if (this.rating > 0 && this.rating < 0.75) {
        this.star_1 = this.star_half;
      } else if (this.rating >= 0.75 && this.rating < 1.75) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_half;
      } else if (this.rating >= 1.75 && this.rating < 2.75) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_half;
      } else if (this.rating >= 2.75 && this.rating < 3.75) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_full;
        this.star_4 = this.star_half;
      } else if (this.rating >= 3.75 && this.rating < 4.75) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_full;
        this.star_4 = this.star_full;
        this.star_5 = this.star_half;
      } else if (this.rating >= 4.75) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_full;
        this.star_4 = this.star_full;
        this.star_5 = this.star_full;
      }

      this.setupColor();
    }
  }

  /**
    5 - background-color: rgb(14, 157, 88);
    4 - background-color: rgb(191, 208, 71);
    3 - background-color: rgb(255, 193, 5);
    2 - background-color: rgb(239, 126, 20);
    1 - background-color: rgb(211, 98, 89);
  */
  private setupColor() {
    if (this.rating <= 1) {
      this.colorRating = 'rgb(211, 98, 89)';
    } else if (this.rating > 1 && this.rating <= 2) {
      this.colorRating = 'rgb(239, 126, 20)';
    } else if (this.rating > 2 && this.rating <= 3) {
      this.colorRating = 'rgb(255, 193, 5)';
    } else if (this.rating > 3 && this.rating <= 4) {
      this.colorRating = 'rgb(191, 208, 71)';
    } else if (this.rating > 4 && this.rating <= 5) {
      this.colorRating = 'rgb(14, 157, 88)';
    }
  }

}



