<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Fila</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/atendimento']">Iniciando</a>
      </li>
      <li>
        <a [routerLink]="['/relatorio/atendimentos']"> Histórico de Atendimentos</a>
      </li>
      <li>
        <a>Visualizar Atendimento</a>
      </li>
      <li class="active">
        <strong>{{atendimento?.senha.senha}}</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">

        <div class="ibox-content">
          <form #atendimentoForm="ngForm" novalidate>
            <div class="form-group">
              <label>Senha</label>
              <output>{{atendimento.senha.senha}}</output>
            </div>
            <div class="form-group">
              <label>Atendido por</label>
              <output>{{atendimento.usuario.nome}}</output>
            </div>
            <div class="form-group">
              <label>Data de Início</label>
              <output>{{atendimento.dataInicio | date: 'dd/MM/yyyy HH:mm'}}</output>
            </div>
            <div class="form-group">
              <label>Data de Fim</label>
              <output>{{atendimento.dataFim | date: 'dd/MM/yyyy HH:mm'}}</output>
            </div>
            <div class="form-group">
              <label>Descrição</label>
              <output>{{atendimento.descricao}}</output>
            </div>
            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="button" class="btn btn-white" (click)="goBack()">Voltar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>