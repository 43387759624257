<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Questionário</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>
        <a [routerLink]="['/playlist']">SmartAvalia</a>
      </li>
      <li>
        <a [routerLink]="['/playlist']">Questionário</a>
      </li>
      <li class="active">
        <strong *ngIf="isNew">Adicionar</strong>
        <strong *ngIf="!isNew">Alterar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-8">
      <div class="ibox float-e-margins">

        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
            <div class="form-group">
              <label>Nome do Questionário</label>
              <input type="text" placeholder="Nome do Questionário" class="form-control" name="nome" id="nome"
                #nome="ngModel" [(ngModel)]="questionario.nome" required>
            </div>
            <div class="hr-line-dashed"></div>

            <ng-container *ngIf="questionario?._id">

              <div class="form-group">
                <label>Perguntas</label>
                <div class="ibox-tools" *ngIf="perguntas?.length > 0">
                  <a class="btn btn-primary btn-xs" data-toggle="modal" href="#modelPerguntaForm"
                    (click)="addPergunta()">
                    <i class="fa fa-plus"></i> Adicionar Pergunta</a>
                </div>
                <table class="table table-striped" *ngIf="perguntas?.length > 0">
                  <thead>
                    <tr>
                      <th>Título</th>
                      <th>Tipo</th>
                      <th width="15%" style="text-align: right;">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let pergunta of perguntas">
                      <th>
                        {{pergunta.titulo}}
                      </th>
                      <td>
                        <span *ngIf="pergunta.tipo === 'NOTA'">5 estrelas</span>
                        <span *ngIf="pergunta.tipo === 'SIM_NAO'">Sim ou Não</span>
                      </td>
                      <td style="text-align: right;">
                        <a class="btn btn-white btn-sm btn-bitbucket" data-toggle="modal" href="#modelPerguntaForm"
                          (click)="editPergunta(pergunta)">
                          <i class="fa fa-edit"></i>
                        </a>
                        <a class="btn btn-danger btn-sm btn-bitbucket" data-toggle="modal"
                          href="#deleteModelQuestionarioPergunta" (click)="selecionarPergunta(pergunta)">
                          <i class="fa fa-trash"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="row" *ngIf="perguntas?.length === 0">
                  <br class="xs-80">
                  <div class="blank-slate text-center">
                    <div class="blank-slate-icon">
                      <i class="fa fa-star-half-o fa-3x"></i>
                    </div>
                    <h3 class="blank-slate-title">Perguntas</h3>
                    <p class="blank-slate-lead">Não foram encontrados perguntas cadastrados.</p>
                    <a href="javascript:;" class="btn btn-primary" data-toggle="modal" href="#modelPerguntaForm"
                      (click)="addPergunta()">Adicionar Pergunta</a>
                  </div>
                  <br class="xs-80">
                </div>

              </div>
              <div class="hr-line-dashed"></div>

              <div class="form-group d-flex align-items-center">
                <div class="m-r-sm">
                  <a *ngIf="questionario.habilitarComentario" href="javascript:;"
                    (click)="questionario.habilitarComentario=false">
                    <i class="fa fa-toggle-on fa-3x"></i>
                  </a>
                  <a *ngIf="!questionario.habilitarComentario" href="javascript:;"
                    (click)="questionario.habilitarComentario=true">
                    <i class="fa fa-toggle-off fa-3x"></i>
                  </a>
                </div>
                <div>
                  <h3 class="no-margins">Avaliação escrita</h3>
                  <p class="no-paddings no-margins">Quando ativo, o campo comentário será apresentado na tela de
                    avaliação.</p>

                </div>
              </div>
              <!--            <div class="hr-line-dashed"></div>

            <div class="form-group">
              <label>Mensagem de boas-vindas</label>
              <textarea class="form-control" rows="5" placeholder="Mensagem de boas-vindas" name="descricao"
                id="descricao" #descricao="ngModel" [(ngModel)]="questionario.descricao"></textarea>
            </div> -->

              <div class="hr-line-dashed"></div>

              <div class="form-group d-flex align-items-center">
                <div class="m-r-sm">
                  <a *ngIf="questionario.dadosContato" href="javascript:;" (click)="desativarDadosContato()">
                    <i class="fa fa-toggle-on fa-3x"></i>
                  </a>
                  <a *ngIf="!questionario.dadosContato" href="javascript:;" (click)="ativarDadosContato()">
                    <i class="fa fa-toggle-off fa-3x"></i>
                  </a>
                </div>
                <div>
                  <h3 class="no-margins">Dados do Cliente </h3>
                  <p class="no-paddings no-margins">Quando ativo, os dados são solicitados durante a avaliação</p>

                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group d-flex align-items-center">
                <div>
                  <h3 class="no-margins">Avalição Externa</h3>
                  <p class="no-paddings no-margins">Quando informado os campos Label e URL e a nota da avaliação for
                    maior
                    que 4.5, será apresentado o botão de avaliação externa na tela de obrigado, após avaliação.</p>
                </div>
              </div>

              <div class="form-group">
                <label>Label</label>
                <input type="text"
                  placeholder="Ex: Seu feedback é importante para a <nome da sua empresa>. Poste também uma avaliação no nosso perfil do google."
                  class="form-control" name="labelAvaliacaoExterna" id="urlAvaliacaoExterna"
                  #labelAvaliacaoExterna="ngModel" [(ngModel)]="questionario.labelAvaliacaoExterna">

              </div>
              <div class="form-group">
                <label>URL</label>
                <input type="text" placeholder="Ex: https://g.page/r/Cb20oKG-hIWsEB0/review" class="form-control"
                  name="urlAvaliacaoExterna" id="urlAvaliacaoExterna" #urlAvaliacaoExterna="ngModel"
                  [(ngModel)]="questionario.urlAvaliacaoExterna">
                <small>Ex: link de avaliação do google - <a
                    href="https://g.page/r/Cb20oKG-hIWsEB0/review">https://g.page/r/Cb20oKG-hIWsEB0/review</a></small>
              </div>
              
            </ng-container>
            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!f.form.valid">
                {{ questionario?._id ? 'Salvar' : 'Continuar' }}
              </button>
              <button type="button" class="btn btn-white" (click)="cancel()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="device device-iphone-x">
        <div class="device-frame">
          <div class="device-content">
            <h1>{{questionario.nome}}</h1>
            <div *ngFor="let pergunta of perguntas">
              <p><strong>{{pergunta.titulo}}</strong></p>
              <p>
                <span *ngIf="pergunta.tipo === 'NOTA'" class="p1">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </span>
                <span *ngIf="pergunta.tipo === 'SIM_NAO'" class="p2">
                  <button class="btn btn-default">Sim</button>
                  <button class="btn btn-default">Não</button>
                </span>
              </p>
            </div>
            <div *ngIf="questionario.habilitarComentario">
              <p><strong>Gostaria de deixar algum comentário?</strong></p>
              <textarea readonly style="width:100%; border: 1px solid #ccc; resize: none;" size="false" cols="30"
                rows="3"></textarea>
            </div>
          </div>
        </div>
        <div class="device-stripe"></div>
        <div class="device-header"></div>
        <div class="device-sensors"></div>
        <div class="device-btns"></div>
        <div class="device-power"></div>
        <div class="device-home"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="modelPerguntaForm" tabindex="-1" role="dialog" aria-hidden="true">
  <form #f2="ngForm" novalidate>
    <div class="modal-dialog">
      <div class="modal-content animated bounceInRight">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
            <span class="sr-only">Close</span>
          </button>
          <h4 class="modal-title">Pergunta</h4>
        </div>
        <div class="modal-body">

          <div class="form-group">
            <label>Titulo</label>
            <input type="text" placeholder="Titulo da Pergunta" class="form-control" name="titulo" id="titulo"
              #titulo="ngModel" [(ngModel)]="pergunta.titulo" required>
          </div>
          <div class="form-group">
            <label>Tipo</label>
            <select class="form-control" name="tipo" id="tipo" #tipo="ngModel" [(ngModel)]="pergunta.tipo" required>
              <option value="NOTA">5 estrelas</option>
              <option value="SIM_NAO">Sim ou Não</option>
            </select>
          </div>

          <div class="form-group" *ngIf="pergunta.tipo === 'SIM_NAO'">
            <label>Resposta Positiva</label>
            <select class="form-control" name="respostaPositiva" id="respostaPositiva" #respostaPositiva="ngModel"
              [(ngModel)]="pergunta.respostaPositiva" required>
              <option value="SIM">Sim</option>
              <option value="NAO">Não</option>
            </select>
            <p>
              <small>Nesta opção você irá indicar qual resposta você espera ser positiva.</small>
            </p>
            <p>
              <small>Exemplo: Você indicaria nosso estabelecimento? (espera-se que a resposta seja SIM)</small><br />
              <small>Exemplo: Sua comida chegou fria? (espera-se que a resposta seja NÃO)</small>
            </p>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="onSubmitPergunta()"
            [disabled]="!f2.form.valid">Salvar</button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal inmodal" id="deleteModelQuestionarioPergunta" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Remover Pergunta</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Esta ação não poderá ser desfeita. Tem certeza que deseja excluir o questionário
            <b>{{perguntaSelecionada?.titulo}}</b>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deletePergunta()">Confirmar</button>
      </div>
    </div>
  </div>
</div>