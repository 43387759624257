<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Localização</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li>
        <a [routerLink]="['/localizacao']">Localização</a>
      </li>
      <li class="active">
        <strong *ngIf="isNew">Adicionar</strong>
        <strong *ngIf="!isNew">Alterar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-4">
      <div class="ibox float-e-margins">

        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
            <div class="form-group">
              <label>Nome</label>
              <input type="text" placeholder="Insira o nome" class="form-control" name="nome" id="nome" #nome="ngModel"
                [(ngModel)]="localizacao.nome" required>
            </div>

            <div class="form-group">
              <label>CEP</label>
              <div class="input-group">
                <input type="text" placeholder="Insira o CEP" class="form-control" name="cep" id="cep" #cep="ngModel"
                  [(ngModel)]="localizacao.cep" required>
                <span class="input-group-btn">
                  <button (click)="searchingCep()" type="button" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    {{loading ? 'Procurar...' : 'Procurar'}}
                  </button>
                </span>
              </div>
            </div>
            <div class="form-group">
              <label>Endereço</label>
              <input type="text" placeholder="Insira o endereço" class="form-control" name="endereco" id="endereco"
                #endereco="ngModel" [(ngModel)]="localizacao.endereco" required>
            </div>
            <div class="form-group">
              <label>Número</label>
              <input type="text" placeholder="Insira o número" class="form-control" name="numero" id="numero"
                #numero="ngModel" [(ngModel)]="localizacao.numero" required>
            </div>
            <div class="form-group">
              <label>Bairro</label>
              <input type="text" placeholder="Insira o bairro" class="form-control" name="bairro" id="bairro"
                #bairro="ngModel" [(ngModel)]="localizacao.bairro" required>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label>Cidade</label>
                  <input type="text" placeholder="Insira a cidade" class="form-control" name="cidade" id="cidade"
                    #cidade="ngModel" [(ngModel)]="localizacao.cidade" required>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>UF</label>
                  <select name="estado" id="estado" [(ngModel)]="localizacao.estado" #estado="ngModel" class="form-control"
                      required>
                      <option value="AC">AC</option>
                      <option value="AL">AL</option>
                      <option value="AP">AP</option>
                      <option value="AM">AM</option>
                      <option value="BA">BA</option>
                      <option value="CE">CE</option>
                      <option value="DF">DF</option>
                      <option value="ES">ES</option>
                      <option value="GO">GO</option>
                      <option value="MA">MA</option>
                      <option value="MT">MT</option>
                      <option value="MS">MS</option>
                      <option value="MG">MG</option>
                      <option value="PA">PA</option>
                      <option value="PB">PB</option>
                      <option value="PR">PR</option>
                      <option value="PE">PE</option>
                      <option value="PI">PI</option>
                      <option value="RJ">RJ</option>
                      <option value="RN">RN</option>
                      <option value="RS">RS</option>
                      <option value="RO">RO</option>
                      <option value="RR">RR</option>
                      <option value="SC">SC</option>
                      <option value="SP">SP</option>
                      <option value="SE">SE</option>
                      <option value="TO">TO</option>
                  </select>
              </div>
              </div>
            </div>
            <div class="form-group">
              <label>Complemento</label>
              <textarea class="form-control" rows="2" name="complemento" id="complemento" #complemento="ngModel"
                [(ngModel)]="localizacao.complemento"></textarea>
            </div>

            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!f.form.valid">Salvar</button>
              <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="row">
        <div class="col-12">
          <map-component [draggable]="true" (endedLngLat)="endedLngLat($event)"></map-component>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox-content">

        <div class="form-group">
          <label></label>
          <button type="button" class="btn btn-primary btn-xs" data-toggle="modal" data-target="#modalFranquias">
            <i class="fa fa-plus"></i> Adicionar Estabelecimento
          </button>
        </div>

        <div class="form-group">
          <label></label>

          <table class="table table-condensed" *ngIf="franquiasSelecionadas?.length">
            <thead>
              <tr>
                <th>Estabelecimento</th>
                <th>Código</th>
                <th>Cidade/UF</th>
                <th width="10%" style="text-align: right;">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let fs of franquiasSelecionadas; let i = index">
                <td scope="row">{{fs.nome}}</td>
                <td>{{fs.codigo}}</td>
                <td>{{fs.cidade}}/{{fs.estado}}</td>
                <td style="text-align: right;">
                  <button type="button" class="btn btn-danger fa fa-trash" (click)="excluirFranquiaSelecionada(i)"
                    style="text-align: right;"></button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="panel text-center" *ngIf="!franquiasSelecionadas?.length">
            <div class="panel-body">
              <p class="p-y-1">
                <i class="fa fa-cubes font-size-46 line-height-1 text-primary"></i>
              </p>
              <p>
                <strong>Estabelecimentos</strong>
              </p>
              <p class="m-b-4">Nenhum registro encontrado.</p>
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>

</div>

<div class="modal inmodal" id="modalFranquias" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Procurar Estabelecimento</h4>
        <small class="font-bold">Localize os estabelecimentos que procura</small>
      </div>
      <div class="modal-body">
        <form #f2="ngForm" novalidate>
          <div class="form-group">

            <label>Pesquise os estabelecimento por nome</label>
            <input #searchBox (keyup)="search(searchBox.value)" [(ngModel)]="model.searchBox"
              [ngModelOptions]="{standalone: true}" placeholder="Pesquise os estabelecimento por nome"
              class="form-control">

            <table class="table table-condensed" *ngIf="franquiasModal?.length">
              <thead>
                <tr>
                  <th>Estabelecimento</th>
                  <th>Código</th>
                  <th>Cidade/UF</th>
                  <th>Empresa</th>
                  <!-- <th>Grupo</th> -->
                  <th width="10%" style="text-align: right;">Associar</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fm of franquiasModal; let i = index">
                  <th scope="row">{{fm.nome}}</th>
                  <td>{{fm.codigo}}</td>
                  <td>{{fm.cidade}}/{{fm.estado}}</td>
                  <td>{{fm.empresa ? fm.empresa.nome : 'Não associado'}}</td>
                  <!-- <td>{{fm.grupo ? fm.grupo.nome : 'Não associado'}}</td> -->
                  <td style="text-align: right;">
                    <button type="button" class="btn btn-primary fa fa-plus" (click)="selecionarFraquia(fm)"
                      [disabled]="franquiaJaAdicionada(fm._id)"></button>
                  </td>
                </tr>
              </tbody>
            </table>

            <br />

            <div class="panel text-center" *ngIf="!franquiasModal?.length">
              <div class="panel-body">
                <p class="p-y-1">
                  <i class="fa fa-cubes font-size-46 line-height-1 text-primary"></i>
                </p>
                <p>
                  <strong>Estabelecimentos</strong>
                </p>
                <p class="m-b-4">Nenhum registro encontrado.</p>
              </div>
            </div>

          </div>
          <!-- <div class="modal-footer">
                            <button type="button" class="btn" data-dismiss="modal">Cancelar</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="delete()">Confirmar</button>
                          </div> -->
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
