import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Howl } from 'howler';
import { AbstractComponent, Chamada, Config, ConfigService, ConstantService, Fila, FilaService, Franquia, FranquiaService, Mesa, NovaSenhaComponent, Pager, Senha, SenhaService, SocketService, TV, TVService } from 'lib-smart-core';
import { timer } from 'rxjs';
import { Constantes } from "../../../environments/constantes";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'atendimento-filas',
  templateUrl: './atendimento-filas.component.html',
  styleUrls: ['./atendimento-filas.component.css']
})
export class AtendimentoFilaComponent extends AbstractComponent implements OnInit, OnDestroy {

  @ViewChild('senhaJaChamada') senhaJaChamada: ElementRef;
  @ViewChild('senhaAnonima') senhaAnonima: ElementRef;
  @ViewChild('btnFecharModalSenhaManual') btnFecharModalSenhaManual: ElementRef;
  @ViewChild('btnCloseModalChamadaManualInicio') btnCloseModalChamadaManualInicio: ElementRef;
  @ViewChild('btnCloseModalNovaSenha') btnCloseModalNovaSenha: ElementRef;

  @ViewChild(NovaSenhaComponent) novaSenhaComponent: NovaSenhaComponent;

  loadingChamar = false;
  loadingRechamar = false;
  loadingChamadaManual = false;

  franquia: Franquia;
  mesa: Mesa;

  config: Config;
  filas: Fila[];
  filaSelecionada: Fila;
  paramFilaSelecionada
  // chamadas: Chamada[];
  chamadasPreferencial: Chamada[];
  senhaSelecionada: Senha;
  senhaSelecionadaQRCode: Senha;
  urlSenhaSelecionada: string;
  dadosSenha: Senha;

  idFilaSelecionadaParam: string = null;
  previsaoAtendimentoMinutos: number = 3;

  URL_TABLET_FRANQUIA: string;
  URL_TV_FRANQUIA: string;

  MAP_TOTAL: Map<string, number> = new Map<string, number>();

  modelSenhaJaChamada: any = {};
  modelSenhaAnonima: any = {};
  modelSenhaChamadaManualInicio: any = {};
  senhaManual: string;

  tvs: TV[];

  pager: Pager<Chamada> = new Pager<Chamada>({ perPage: 5 });

  viewHistorico: boolean = false;

  loading: boolean = false;

  @ViewChild('btnAtualizarSenha') btnAtualizarSenha: ElementRef;

  isNormalPreferencial: boolean = false;

  isShowMenuFilas: boolean = false;

  constructor(
    private socketService: SocketService,
    private franquiaService: FranquiaService,
    private filaService: FilaService,
    private tvService: TVService,
    private senhaService: SenhaService,
    private constantService: ConstantService,
    private configService: ConfigService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.franquia = JSON.parse(localStorage.getItem(Constantes.currentFranquia));
    this.mesa = JSON.parse(localStorage.getItem(Constantes.currentMesa));
    this.carregarFilasPorFranquia(this.franquia._id);
    this.dadosSenha = {} as Senha;
    this.dadosSenha.data = new Date();
    this.dadosSenha.compareceu = false;
    this.dadosSenha.saiuDaFila = false;

    var paramFilaSelecionada = super.getParam("idFilaSelecionada");
    if (paramFilaSelecionada) {
      this.idFilaSelecionadaParam = paramFilaSelecionada;
    }
    this.configSocket();
    this.loadTVs();
    this.senhaService.previsaoAtendimento(this.franquia._id).subscribe(previsao => this.previsaoAtendimentoMinutos = previsao);
  }

  override ngOnDestroy(): void {
    this.socketService.disconnect();
  }

  loadTVs() {
    this.tvService.getTVsPorFranquiaPager(1, 10, this.franquia._id).subscribe(
      (pager: Pager<TV>) => {
        console.log('loadTVs')
        console.log(pager)
        this.tvs = pager.list;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  timeoutExecuteCarregarLista(): void {
    this.carregarChamadaPorFila(this.filaSelecionada._id)
  }

  iniciarNovoAtendimento() {
    this.constantService.setFranquiaSession(null);
    this.constantService.setMesaSession(null);
    this.constantService.setLocalizacaoSession(null);
    localStorage.removeItem(Constantes.currentFranquia);
    localStorage.removeItem(Constantes.currentMesa);
    localStorage.removeItem(Constantes.currentLocalizacao);
    this.router.navigate(['/atendimento']);
  }

  carregarFilasPorFranquia(idFranquia: string): void {
    this.filaService.getFilasPorFranquia(idFranquia).subscribe(
      filas => {
        this.filas = filas;
        if (this.filas != null && this.filas.length > 0) {
          if (this.idFilaSelecionadaParam != null && this.idFilaSelecionadaParam != '0') {
            for (let i = 0; i < this.filas.length; i++) {
              if (this.filas[i]._id == this.idFilaSelecionadaParam) {
                this.filaSelecionada = filas[i];
                break;
              }
            }
          } else {
            this.filaSelecionada = filas[0];
          }
          this.carregarChamadaPorFila(this.filaSelecionada._id);
        }
        this.atualizarListaChamadaReload();
        this.getConfigPorFranquia();
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getConfigPorFranquia() {
    return this.configService.getConfigPorFranquia(this.franquia._id).subscribe(
      (config: Config) => {
        this.config = config;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  loadPage(page: number) {
    this.pager.page = page;
    if (this.viewHistorico) {
      this.carregarHistoricoFranquia();
    } else {
      this.carregarChamadaPorFila(this.filaSelecionada._id);
    }
  }

  carregarChamadaPorFila(idFila: string) {
    // console.log('carregarChamadaPorFila');
    this.loading = true;
    this.viewHistorico = false;
    this.senhaService.getChamadaDiariaPorFilaPager(this.pager.page, this.pager.perPage, idFila).subscribe(
      (pager: Pager<Chamada>) => {
        this.pager = pager;
        this.setFilaSelcionada(idFila);
        this.carregarCountFilas();
        this.loading = false;
      },
      err => {
        this.loading = false;
        this.alertService.error(err.error.message);
      }
    );
    this.senhaService.getChamadaDiariaPorFilaPagerPreferencial(this.pager.page, 5, idFila).subscribe(
      pager => {
        this.loading = false;
        this.chamadasPreferencial = pager.list;
      },
      err => {
        this.loading = false;
        this.alertService.error(err.error.message);
      }
    );
  }

  carregarCountFilas() {
    this.senhaService.countChamadaAllFilas(this.franquia._id).subscribe(
      (data: any) => {
        if (!!data && data.length > 0) {
          data.forEach((cc: any) => {
            this.MAP_TOTAL.set(cc._id, cc.count);
          });
        }
      }
    )
  }

  temDadosListaChamadaCorrente(): Boolean {
    var result: Boolean = false;
    if (this.pager.total > 0) {
      result = true;
    }
    return result;
  }

  getTotalPorFila(idFila: string): number {
    return this.MAP_TOTAL.get(idFila);
  }

  isFilaCurrent(idFila: string): Boolean {
    return this.filaSelecionada === null ? false : (idFila === this.filaSelecionada._id);
  }

  setFilaSelcionada(idFila: string): void {
    // this.filaService.getById(idFila).subscribe(
    //   fila => {
    this.filaSelecionada = this.filas.filter((fila: Fila) => fila._id === idFila)[0];
    //   },
    //   err => {
    //     this.alertService.error(err.error.message);
    //   }
    // );
  }

  testeLimpaTimeout(timer) {
    clearTimeout(timer);
  }

  carregarSpinnerLoadingChamar() {
    this.loadingChamar = true;
    let subscription = timer(4500).subscribe( // tempo necessario para tocar a musica na TV
      () => {
        this.loadingChamar = false;
        subscription.unsubscribe();
      }
    );
  }

  carregarSpinnerLoadingRechamar() {
    this.loadingRechamar = true;
    let subscription;
    subscription = timer(4500).subscribe( // tempo necessario para tocar a musica na TV
      () => {
        this.loadingRechamar = false;
        subscription.unsubscribe();
      }
    );
  }

  chamarProximaSenha() {
    this.carregarSpinnerLoadingChamar();
    this.pager.page = 1;
    let idFila = this.filaSelecionada._id;
    let idMesa = this.mesa._id;
    this.senhaService.chamarProximaSenha(idFila, idMesa).subscribe(
      senha => {
        if (senha == null) {
          this.alertService.warning("Não existem senhas na fila " + this.filaSelecionada.nome.toUpperCase());
        }
        this.senhaSelecionada = senha;
        // this.carregarChamadaPorFila(this.filaSelecionada._id);
      },
      err => {
        this.alertService.warning(err.error.message);
      }
    );
  }

  chamarProximaSenhaPreferencial() {
    const senhaPreferencial = this.chamadasPreferencial[0].senha;
    this.chamarSenha(senhaPreferencial?.senha)
    console.log(senhaPreferencial?.senha);
  }

  chamarSenha(senha: string) {
    this.carregarSpinnerLoadingRechamar();
    this.pager.page = 1;
    this.senhaService.chamarSenhaNaChamada(this.filaSelecionada?._id, this.mesa?._id, senha).subscribe(
      senha => {
        this.senhaSelecionada = senha;
        this.carregarChamadaPorFila(this.filaSelecionada._id);
        this.modelSenhaJaChamada.senha = '';
        this.btnFecharModalSenhaManual.nativeElement.click();
      },
      err => this.alertService.error(err.error.message)
    );
  }

  chamarProximaSenhaManual() {
    this.carregarSpinnerLoadingChamar();
    this.pager.page = 1;
    let idFila = this.filaSelecionada._id;
    let idMesa = this.mesa._id;
    this.senhaService.chamarProximaSenhaManual(idFila, idMesa, this.filaSelecionada.chamadaManualInicio).subscribe(
      senha => {
        if (senha == null) {
          this.alertService.warning("Não existem senhas na fila " + this.filaSelecionada.nome.toUpperCase());
        }
        this.senhaSelecionada = senha;
        this.idFilaSelecionadaParam = idFila;
        this.carregarFilasPorFranquia(this.franquia._id);
      },
      err => {
        this.alertService.warning(err.error.message);
      }
    );
  }

  chamarSenhaNaChamada() {
    this.carregarSpinnerLoadingRechamar();
    this.pager.page = 1;
    let idFila = this.filaSelecionada._id;
    let idMesa = this.mesa._id;
    let senhaChamada = this.modelSenhaJaChamada.senha;
    this.senhaService.chamarSenhaNaChamada(idFila, idMesa, senhaChamada).subscribe(
      senha => {
        this.senhaSelecionada = senha;
        this.carregarChamadaPorFila(this.filaSelecionada._id);
        this.modelSenhaJaChamada.senha = '';
      },
      err => this.alertService.error(err.error.message)
    );
  }

  abrirModalSenhaAnonimia() {
    let subscription = timer(500).subscribe( // tempo necessario para tocar a musica na TV
      () => {
        this.modelSenhaAnonima.senha = '';
        this.senhaAnonima.nativeElement.focus();
        subscription.unsubscribe();
      }
    );
  }

  chamarSenhaAnonima() {
    this.carregarSpinnerLoadingChamar();
    this.senhaService.chamadaAnonima(this.franquia._id, this.filaSelecionada._id, this.modelSenhaAnonima.senha).subscribe(
      () => {
        this.modelSenhaAnonima.senha = '';
        this.senhaAnonima.nativeElement.focus();
      },
      err => this.alertService.error(err.error.message)
    );
  }

  rechamarSenha() {
    this.carregarSpinnerLoadingRechamar();
    this.pager.page = 1;
    let idSenha = this.senhaSelecionada._id;
    this.senhaService.rechamarSenha(idSenha).subscribe(() => {
      this.btnFecharModalSenhaManual.nativeElement.click();
    });
  }

  setupQRCodeFranquia() {
    let baseUrl = environment.smartProUrl;
    this.URL_TABLET_FRANQUIA = baseUrl + '/app/tablet/' + this.franquia.empresa._id + '/' + this.franquia._id;
    this.URL_TV_FRANQUIA = baseUrl + '/app/tv/' + this.franquia.empresa._id + '/' + this.franquia._id;
  }

  public getURLTV(idTV: string): string {
    return environment.smartTVUrl + '/app/itv/' + idTV;
  }

  public getURLTVAnonima(idTV: string): string {
    return environment.smartTVUrl + '/app/itv/' + idTV + '/true';
  }

  atualizarListaChamada(): void {
    const { idFila, count, pager, pagerPreferencial } = this.carregar_lista_data;

    if (!!count && count.length > 0) {
      count.forEach((cc: any) => {
        this.MAP_TOTAL.set(cc._id, cc.count);
      });
    }

    if (this.filaSelecionada && this.filaSelecionada._id === idFila) {

      if (!!pager) {
        this.pager.total = pager.total;
        this.pager.page = pager.page;
        this.pager.list = pager.list;
      }

      if (!!pagerPreferencial) {
        this.chamadasPreferencial = pagerPreferencial.list;
      }

    }
  }

  atualizarListaChamadaReload(): void {
    if (this.filaSelecionada) {
      this.carregarChamadaPorFila(this.filaSelecionada._id);
    }
  }

  //################ socket
  //################ inicio
  //################ socket

  @ViewChild('btnAtualizarChamada') btnAtualizarChamada: ElementRef;

  carregar_lista_data: any;

  configSocket(): void {
    this.socketService.connect();

    const sname = 'carregar_lista_' + this.franquia._id;
    this.socketService.listen(sname).subscribe(
      (data: any) => {

        this.carregar_lista_data = data;

        this.btnAtualizarChamada.nativeElement.click();

        let countChamadas: number = 0;
        this.MAP_TOTAL.forEach((value: number, key: string) => {
          countChamadas += value;
        });

        if (countChamadas === 1) {
          const sound = new Howl({
            src: ['../../../assets/audio/alerta_chamada2.mp3']
          });
          sound.play();
        }
      }
    )
  }

  //################ socket
  //################ fim
  //################ socket

  openModalSenhaManual(chamada: Chamada): void {
    this.senhaManual = '';
    this.senhaManual = chamada.senha.senha;
    this.senhaSelecionada = chamada.senha;

    // console.log('entrou no focus...');
    // let subscription = timer(500).subscribe( // tempo necessario para tocar a musica na TV
    //   () => {
    //     this.senhaJaChamada.nativeElement.focus();
    //     subscription.unsubscribe();
    //   }
    // );
  }

  selecionarSenhaNaChamadaQRCode = (senha) => {
    this.senhaSelecionadaQRCode = senha;
    this.urlSenhaSelecionada = `${environment.smartLiteUrl}/painel/${this.franquia.codigo}/senha/${senha._id}/`;
  }

  carregarHistoricoFranquia() {
    this.loading = true;
    this.senhaService.historicoChamadasFullDay(this.pager.page, this.pager.perPage, this.franquia._id).subscribe(
      (pager: Pager<Chamada>) => {
        this.pager = pager;
        this.filaSelecionada = null;
        this.viewHistorico = true;
        this.loading = false;
      },
      err => this.alertService.error(err.error.message)
    );
  }

  completeLeftZeros(value, size) {
    value = value.toString();
    while (value.length < size) {
      value = "0" + value;
    }
    return value;
  }

  onSubmitAlterarChamadaManualInicio() {
    this.loadingChamadaManual = true;
    this.filaService.alterarSenhaChamadaManualInicio(
      this.filaSelecionada._id,
      this.modelSenhaChamadaManualInicio.chamadaManualInicio).subscribe(
        () => {
          this.loadingChamadaManual = false;
          this.idFilaSelecionadaParam = this.filaSelecionada._id;
          this.carregarFilasPorFranquia(this.franquia._id);
          this.alertService.success('Operação realizada com sucesso');
          this.btnCloseModalChamadaManualInicio.nativeElement.click();
        },
        err => {
          this.loadingChamadaManual = false;
          this.alertService.error(err.error.message);
        }
      );
  }

  selecionarFilaTab(idFila: any) {
    this.pager.page = 1;
    this.pager.total = 0;
    this.pager.list = new Array<Chamada>();
    this.chamadasPreferencial = new Array<Chamada>();
    this.carregarChamadaPorFila(idFila);
    this.isShowMenuFilas = false;
  }

  selecionarHistoricoTab() {
    this.pager.page = 1;
    this.pager.total = 0;
    this.pager.list = new Array<Chamada>();
    this.chamadasPreferencial = new Array<Chamada>();
    this.carregarHistoricoFranquia();
    this.isShowMenuFilas = false;
  }

  exibeModalAlterarSenha(senha: Senha) {
    this.dadosSenha = senha;
  }

  alterarSenha() {
    this.loading = true;

    this.dadosSenha.permiteCadastro = true;
    this.dadosSenha.permiteWhatsApp = true;
    this.dadosSenha.app = 'LITE';

    this.senhaService.update(this.dadosSenha).subscribe(() => {
      this.loading = false;
      this.alertService.success('Operação realizada com sucesso');
      this.btnAtualizarSenha.nativeElement.click();
    }, (err) => this.alertService.error(err.error.message))
  }

  // setNormalPreferencial(status: boolean) {
  //   this.isNormalPreferencial = status;
  // }

  // selecionarFila(fila: Fila) {
  //   this.filaSelecionada = fila;
  //   this.setNormalPreferencial(true)
  // }

  exibirModalGerarNovaSenha() {
    this.novaSenhaComponent.exibirModalGerarNovaSenha();
  }

  // gerarNovaSenhaMetadata(fila) {
  //   this.loading = true;
  //   const metadados = {
  //     permiteCadastro: true,
  //     permiteWhatsApp: true,
  //     app: 'LITE'
  //   }
  //   if (!!this.dadosSenha.nome) {
  //     metadados['nome'] = this.dadosSenha.nome;
  //   }
  //   if (!!this.dadosSenha.numero) {
  //     metadados['numero'] = this.dadosSenha.numero;
  //   }
  //   if (!!this.dadosSenha.email) {
  //     metadados['email'] = this.dadosSenha.email;
  //   }

  //   this.senhaService.gerarNovaSenhaMetadata(fila._id, metadados).subscribe(
  //     (senha: Senha) => {
  //       this.loading = false;
  //       this.btnCloseModalNovaSenha.nativeElement.click();
  //       this.alertService.success('Operação realizada com sucesso');
  //       this.setNormalPreferencial(false);
  //     }, err => {
  //       this.loading = false;
  //       this.alertService.error(err.error.message);
  //     }
  //   );
  // }

  // gerarNovaSenhaPreferencialMetadata(fila, preferencial = false) {
  //   this.loading = true;
  //   const metadados = {
  //     permiteCadastro: true,
  //     permiteWhatsApp: true,
  //     app: 'LITE'
  //   }
  //   if (!!this.dadosSenha.nome) {
  //     metadados['nome'] = this.dadosSenha.nome;
  //   }
  //   if (!!this.dadosSenha.numero) {
  //     metadados['numero'] = this.dadosSenha.numero;
  //   }
  //   if (!!this.dadosSenha.email) {
  //     metadados['email'] = this.dadosSenha.email;
  //   }

  //   this.senhaService.gerarNovaSenhaPreferencialMetadata(fila._id, preferencial, metadados).subscribe(
  //     (senha: Senha) => {
  //       this.loading = false;
  //       this.btnCloseModalNovaSenha.nativeElement.click();
  //       this.alertService.success('Operação realizada com sucesso');
  //       this.setNormalPreferencial(false);
  //     }, err => {
  //       this.loading = false;
  //       this.alertService.error(err.error.message);
  //     }
  //   );
  // }

  relaodPage() {
    window.location.reload();
  }

  showMenuFilas() {
    this.isShowMenuFilas = !this.isShowMenuFilas;
  }

}
