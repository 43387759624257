<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>SmartFilas Lite</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li class="active">
        <strong>Lite</strong>
      </li>
    </ol>
  </div>
</div>

<div class="row wrapper wrapper-content pb-0 animated fadeInRight">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>Filtro</h5>
      </div>
      <div class="ibox-content">
        <div class="input-group">
          <span class="input-group-addon" id="sizing-addon2"><i class="fa fa-search"></i></span>
          <input type="text" class="form-control shadow-inset-2" #searchBox (keyup)="search(searchBox.value)"
            placeholder="Procurar...">
        </div>

      </div>
    </div>
  </div>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>QRCode Filas para SmartFilas Lite</h5>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Avaliação</th>
                  <th>Nome</th>
                  <th>Código</th>
                  <th>Cidade/UF</th>
                  <th>Bloqueado?</th>
                  <th width="15%" style="text-align: right;">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let franquia of franquias">
                  <th>
                    <feedback-embeded [rating]="franquia?.rating"></feedback-embeded>
                  </th>
                  <th>
                    {{franquia.nome}}<br />
                    <small>{{franquia.email}}</small>
                  </th>
                  <td>{{franquia.codigo}}</td>
                  <td>{{franquia.cidade}}/{{franquia.estado}}</td>
                  <td>
                    <span class="label label-danger" style="margin-right: 2px;"
                      *ngIf="franquia.bloqueado">Bloqueado</span>
                    <span class="label label-primary" style="margin-right: 2px;"
                      *ngIf="!franquia.bloqueado">Liberado</span>
                  </td>
                  <td style="text-align: right;">

                    <!-- <a class="btn btn-white btn-sm" title="TV" (click)="setupQRCodeFranquia(franquia._id)" data-toggle="modal" data-target="#qrcodetv">
                        TV
                    </a> -->

                    <a class="btn btn-primary btn-sm btn-bitbucket" data-toggle="modal" href="#modalBannerSmartFilas"
                      (click)="generateBannerSmartFilas(franquia)" title="Gerar QRCode SmartFilas">
                      <i class="fa fa-qrcode"></i>
                    </a>
                    <!-- <div class="input-group-btn">
                      <button data-toggle="dropdown" class="btn btn-white btn-sm dropdown-toggle" type="button">
                        <i class="fa fa-qrcode"></i> <span class="caret"></span></button>
                      <ul class="dropdown-menu"> -->
                    <!-- <li>
                          <a title="Banner Filas" (click)="generateBannerSmartFilas(franquia)" data-toggle="modal"
                            data-target="#modalBannerSmartFilas"><i class="fa fa-qrcode"></i> Banner Filas</a>
                        </li> -->
                    <!-- <li>
                          <a title="Banner Avaliação"  (click)="generateBannerSmartAvalia(franquia)" data-toggle="modal" data-target="#modalBannerSmartAvalia"><i class="fa fa-qrcode"></i> Banner Avaliação</a>
                        </li> -->
                    <!-- </ul>
                    </div> -->
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="text-center" *ngIf="pager.total > pager.perPage">
              <paginator class="pagination" [pager]="pager" (onPager)="loadPage($event)"></paginator>
            </div>

            <!-- <div class="row">
              <div class="col-lg-6" *ngFor="let item of imgCertificados">
                <img alt="image" class="rounded image-lg-full" style="width: 100%;" [src]="item">
              </div>
            </div> -->



            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal inmodal" id="modalBannerSmartFilas" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content animated bounceInRight">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
              class="sr-only">Close</span></button>
          <!-- <i class="fa fa-qrcode modal-icon"></i> -->
          <h4 class="modal-title">QRCode SmartFilas Lite</h4>
          <h2>{{franquiaSelecionada?.nome}} | {{franquiaSelecionada?.codigo}}</h2>
          <small class="font-bold">
            <a href="{{urlSmartFilas}}" target="_blank">{{urlSmartFilas}}</a>
          </small>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-7">
              <img alt="image" *ngIf="imgSmartFilas !== null" class="rounded image-lg-full" style="width: 100%;"
                [src]="imgSmartFilas">
            </div>
            <div class="col-lg-5">
              <div class="jumbotron">
                <h2><b>Baixar QRCode</b></h2>
                <p>Este QRCode irá permitir seus clientes entrarem na fila do seu estabelecimento.</p>
                <p><a download="SmartFilas_{{franquiaSelecionada?.codigo}}.png" href="{{imgSmartFilas}}" target="_blank"
                    class="btn btn-primary btn-block" role="button"><i class="fa fa-download"></i> Baixar QRCode</a>
                </p>
                <p>
                  <a href="javascript:void(0)" (click)="copyDynamicLink()" class="btn btn-primary btn-block" role="button">
                    <i class="fa fa-copy"></i> Copiar Link
                  </a>
                </p>
              </div>
              <div class="jumbotron" *ngIf="configSelecionado?.habilitarQRCode">
                <h2><b>Link QRCode + Filas</b></h2>
                <p>Recomendamos o uso deste link em tablets, oferecendo aos seus clientes a facilidade de escanear o QRCode diretamente no dispositivo ou selecionar uma fila, mantendo o QRCode visível para os próximos clientes.</p>
                <p>
                  <a href="javascript:void(0)" (click)="copyDynamicLinkContinuo()" class="btn btn-primary btn-block" role="button">
                    <i class="fa fa-copy"></i> Copiar Link
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="modal inmodal" id="modalBannerSmartAvalia" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content animated bounceInRight">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
              class="sr-only">Close</span></button>
          <i class="fa fa-tv modal-icon"></i>
          <h4 class="modal-title">Banner SmartAvalia</h4>
          <small class="font-bold">O QRCode abaixo indica a URL que deverá ser utilizada para o SmartAvalia.</small>
        </div>
        <div class="modal-body">
          <img alt="image" *ngIf="imgSmartAvalia !== null" class="rounded image-lg-full" style="width: 100%;"
            [src]="imgSmartAvalia">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        </div>
      </div>
    </div>
  </div> -->