<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Localização</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li class="active">
        <strong>Localizações</strong>
      </li>
    </ol>
  </div>
</div>

<div class="row wrapper wrapper-content pb-0 animated fadeInRight">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>Filtro</h5>
      </div>
      <div class="ibox-content">
        <div class="input-group">
          <span class="input-group-addon" id="sizing-addon2"><i class="fa fa-search"></i></span>
          <input type="text" class="form-control shadow-inset-2" #searchBox (keyup)="search(searchBox.value)"
            placeholder="Procurar...">
        </div>

      </div>
    </div>
  </div>
</div>

<div class="row wrapper wrapper-content animated fadeInRight">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>Localizações</h5>
        <div class="ibox-tools">
          <a [routerLink]="['/localizacao/add']" class="btn btn-primary btn-xs">
            <i class="fa fa-plus"></i> Adicionar Localização</a>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped" *ngIf="pager.total > 0">
            <thead>
              <tr>
                <th width="20%">Nome</th>
                <th>Endereço</th>
                <th>CEP</th>
                <th>Cidade/UF</th>
                <th>Bairro</th>
                <th width="10%" style="text-align: right;">Ativo?</th>
                <th width="10%" style="text-align: right;">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of pager.list">
                <th>{{item.nome}}</th>
                <td>{{item.endereco}}</td>
                <td>{{item.cep}}</td>
                <td>{{item.cidade}}/{{item.estado}}</td>
                <td>{{item.bairro}}</td>
                <td style="text-align: right;">
                  <a *ngIf="item.ativo" href="javascript:;" (click)="desativar(item)"><i
                      class="fa fa-toggle-on fa-3x"></i></a>
                  <a *ngIf="!item.ativo" href="javascript:;" (click)="ativar(item)"><i
                      class="fa fa-toggle-off fa-3x"></i></a>
                </td>
                <td style="text-align: right;">
                  <a class="btn btn-white btn-sm btn-bitbucket" title="Editar"
                    [routerLink]="['/localizacao/edit', item._id]">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a class="btn btn-danger btn-sm btn-bitbucket" title="Remover" data-toggle="modal" href="#deleteModel"
                    (click)="selecionar(item)">
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table" *ngIf="pager.total === 0">
            <thead>
              <tr>
                <th class="text-center">Nenhum registro encontrado!</th>
              </tr>
            </thead>
          </table>

          <div class="text-center" *ngIf="pager.total > pager.perPage">
            <paginator class="pagination" [pager]="pager" (onPager)="loadPage($event)"></paginator>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="deleteModel" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Remover Localização</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Esta ação não poderá ser desfeita. Tem certeza que deseja excluir a localização
            <b>{{localizacaoSelecionada?.nome}}</b>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="delete()">Confirmar</button>
      </div>
    </div>
  </div>
</div>