<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>TipoAtendimentos</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li>
        <a [routerLink]="['/tipoAtendimento']">TipoAtendimentos</a>
      </li>
      <li class="active">
        <strong *ngIf="isNew">Adicionar</strong>
        <strong *ngIf="!isNew">Alterar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">

        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
            <div class="form-group">
              <label>Nome</label>
              <input type="text" placeholder="Insira o Nome do Tipo de Atendimento" class="form-control" name="nome"
                id="nome" #titulo="ngModel" [(ngModel)]="tipoAtendimento.nome" required>
            </div>
            <div class="form-group">
              <label>Descrição</label>
              <textarea class="form-control" rows="5" placeholder="Informe a descrição" name="descricao" id="descricao"
                #mensagem="ngModel" [(ngModel)]="tipoAtendimento.descricao"></textarea>
            </div>

            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!f.form.valid">Salvar</button>
              <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>