<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Fila</h2>
    <ol class="breadcrumb hidden-xs">
      <li>
        <a [routerLink]="['/atendimento']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li>
        <a>Atendimento</a>
      </li>
      <li>
        <a [routerLink]="['/atendimento-filas', 0]"> Filas de Atendimento</a>
      </li>
      <li>
        <a href="#">{{ fila?.nome }}</a>
      </li>
      <li class="active">
        <strong>{{ senha?.senha }}</strong>
      </li>
    </ol>
  </div>
</div>
<div class="animated fadeInRight">


  <div class="widget-senhas" *ngIf="senha" style="margin-top: 0; margin-bottom: 20px;">
    <div class="widget white-bg b-r-lg bg-muted p-sm widget-senhas__senha-atual chamada-senha">
      <h1 class="no-margins">
        Senha {{ senha?.senha }}
        <span *ngIf="senha?.nome">
          - <strong>{{ senha?.nome }}</strong>
        </span>
        <span *ngIf="senha?.numero"> - {{ senha?.numero }} </span>
      </h1>

      <div>
        {{ senha?.data | date : "dd/MM/yyyy HH:mm:ss" }}
        <button
          class="btn btn-primary"
          data-toggle="modal"
          (click)="exibeModalAlterarSenha(senha)"
          data-target="#alterarSenha"
        >
          Atualizar dados
        </button>
      </div>
    </div>
  </div>

  <form #atendimentoForm="ngForm" novalidate>
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Descrição do Atendimento</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    placeholder="Anotações e informações sobre este atendimento"
                    name="descricao"
                    id="descricao"
                    #descricao="ngModel"
                    [(ngModel)]="atendimento.descricao"
                    maxlength="500"
                  ></textarea>
                  <small>Limite máximo de 500 caractéres</small>
                </div>
              </div>
            </div>

            <!-- comentarios do atendimento -->
            <div class="row" style="display: none">
              <div class="col-md-12">
                <div class="widget white-bg widget-senhas">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-center">Data/Hora</th>
                        <th class="text-center">Descrição</th>
                        <th class="text-right">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of pager.list; let i = index">
                        <td class="text-center">
                          {{ item.created_at | date : "dd/MM/yyyy HH:mm:ss" }}
                        </td>
                        <td class="text-center hidden-xs">
                          {{ item.descricao }}
                        </td>
                        <td class="text-right">
                          <!-- <button type="button" class="btn btn-sm btn-primary" title="Atualizar dados senha" data-toggle="modal"
                            (click)="exibeModalAlterarSenha(chamada.senha)" data-target="#alterarSenha">
                            <i class="fa fa-edit"></i>
                          </button>
                          <button type="button" class="btn btn-sm btn-primary" title="Chamar Manual" data-toggle="modal"
                            (click)="openModalSenhaManual(chamada)" data-target="#modalSenhaJaChamada">
                            <i class="glyphicon glyphicon-volume-up"></i>
                          </button> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    class="text-center"
                    *ngIf="!loading && pager.total > pager.perPage"
                  >
                    <div
                      class="text-center"
                      *ngIf="pager.total > pager.perPage"
                    >
                      <paginator
                        class="pagination"
                        [pager]="pager"
                        (onPager)="loadPage($event)"
                      ></paginator>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div>
      <div class="col-lg-5"> -->

        <div class="ibox float-e-margins">
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="localImage"
                    >Selecione o(s) tipo(s) de atendimento relacionado(s)</label
                  >
                  <small style="color: red" *ngIf="tipoAtendimentoObrigatorio"
                    >* campo obrigatório</small
                  >
                  <tag-input
                    #input
                    [(ngModel)]="tipoAtendimentosSelecionados"
                    [ngModelOptions]="{ standalone: true }"
                    [theme]="'bootstrap'"
                    [dragZone]="'zone1'"
                    [modelAsStrings]="true"
                    [allowDupes]="false"
                    [blinkIfDupe]="true"
                    [hideForm]="true"
                    [onAdding]="transform"
                  >
                    <ng-template let-item="item" let-index="index">
                      <span *ngIf="item">
                        {{ item.nome }}
                      </span>
                      <delete-icon
                        *ngIf="item"
                        (click)="input.removeItem(item, index)"
                      ></delete-icon>
                    </ng-template>
                  </tag-input>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <span class="input-group-addon" id="sizing-addon2"
                      ><i class="fa fa-search"></i
                    ></span>
                    <input
                      type="text"
                      class="form-control shadow-inset-2"
                      #searchBox
                      (keyup)="search(searchBox.value)"
                      placeholder="Procurar..."
                    />
                  </div>

                  <img
                    *ngIf="loading"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                  />

                  <div class="button-list mt-4">
                    <button
                      type="button"
                      class="btn-rounded btn btn-outline-dark"
                      (click)="newTipoAtendimento(item)"
                      *ngFor="let item of tipoAtendimentos"
                    >
                      {{ item.nome }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal inmodal fade in"
              id="alterarSenha"
              tabindex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content animated bounceInRight">
                  <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                      <span aria-hidden="true">&times;</span
                      ><span class="sr-only">Close</span>
                    </button>
                    <i class="fa fa-tablet modal-icon"></i>
                    <h4 class="modal-title">Atualizar Dados</h4>
                  </div>
                  <div class="modal-body" style="padding: 15px;">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Senha</label>
                          <input
                            type="text"
                            readonly
                            disabled
                            class="form-control"
                            name="senha"
                            id="senha"
                            [(ngModel)]="senha.senha"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Data</label>
                          <input
                            type="text"
                            readonly
                            disabled
                            class="form-control"
                            name="data"
                            id="data"
                            [value]="senha.data | date : 'dd/MM/yyyy HH:mm:ss'"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Nome</label>
                      <input
                        type="text"
                        placeholder="Insira o Nome"
                        class="form-control"
                        name="nome"
                        id="nome"
                        #nome="ngModel"
                        [(ngModel)]="senha.nome"
                      />
                    </div>
                    <div class="form-group">
                      <label>Telefone</label>
                      <input
                        type="text"
                        placeholder="Insira o Telefone"
                        mask="(00) 0000-0000||(00) 00000-0000"
                        [showMaskTyped]="true"
                        [dropSpecialCharacters]="false"
                        class="form-control"
                        name="telefone"
                        id="telefone"
                        #numero="ngModel"
                        [(ngModel)]="senha.numero"
                      />
                    </div>
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        placeholder="Insira o Email"
                        class="form-control"
                        name="email"
                        id="email"
                        #email="ngModel"
                        [(ngModel)]="senha.email"
                      />
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-white"
                      #btnAtualizarSenha
                      data-dismiss="modal"
                    >
                      Fechar
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      (click)="alterarSenha()"
                    >
                      Salvar
                      <img
                        *ngIf="loadingChamar"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-content">
            <div class="form-group">
              <div *ngIf="tipoAtendimentoObrigatorio">
                <button
                  type="button"
                  class="btn btn-primary"
                  [disabled]="tipoAtendimentosSelecionados.length === 0"
                  (click)="concluirAtendimento()"
                >
                  Finalizar
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  title="TV"
                  data-toggle="modal"
                  data-target="#filas"
                  [disabled]="tipoAtendimentosSelecionados.length === 0"
                >
                  Encaminhar
                </button>
                <button type="button" class="btn btn-white" (click)="goBack()">
                  Cancelar
                </button>
              </div>

              <div *ngIf="!tipoAtendimentoObrigatorio">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="concluirAtendimento()"
                >
                  Finalizar
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  title="TV"
                  data-toggle="modal"
                  data-target="#filas"
                >
                  Encaminhar
                </button>
                <button type="button" class="btn btn-white" (click)="goBack()">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal inmodal fade in"
  id="alterarSenha"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span
          ><span class="sr-only">Close</span>
        </button>
        <i class="fa fa-tablet modal-icon"></i>
        <h4 class="modal-title">Atualizar Dados</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Senha</label>
              <input
                type="text"
                readonly
                disabled
                class="form-control"
                name="senha"
                id="senha"
                [(ngModel)]="senha.senha"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Data</label>
              <input
                type="text"
                readonly
                disabled
                class="form-control"
                name="data"
                id="data"
                [value]="senha.data | date : 'dd/MM/yyyy HH:mm:ss'"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Nome</label>
          <input
            type="text"
            placeholder="Insira o Nome"
            class="form-control"
            name="nome"
            id="nome"
            #nome="ngModel"
            [(ngModel)]="senha.nome"
          />
        </div>
        <div class="form-group">
          <label>Telefone</label>
          <input
            type="text"
            placeholder="Insira o Telefone"
            mask="(00) 0000-0000||(00) 00000-0000"
            [showMaskTyped]="true"
            [dropSpecialCharacters]="false"
            class="form-control"
            name="telefone"
            id="telefone"
            #numero="ngModel"
            [(ngModel)]="senha.numero"
          />
        </div>
        <div class="form-group">
          <label>Email</label>
          <input
            type="text"
            placeholder="Insira o Email"
            class="form-control"
            name="email"
            id="email"
            #email="ngModel"
            [(ngModel)]="senha.email"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-white"
          #btnAtualizarSenha
          data-dismiss="modal"
        >
          Fechar
        </button>
        <button type="submit" class="btn btn-primary" (click)="alterarSenha()">
          Salvar
          <img
            *ngIf="loadingChamar"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
          />
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal inmodal"
  id="filas"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span
          ><span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Filas</h4>
        <small class="font-bold"
          >O QRCode abaixo indica a URL que deverá ser utilizada para este
          estabelecimento.</small
        >
        <small class="font-bold">Selecione a fila para encaminhar a senha</small>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="empresa.encaminhamentoEntreFranquias">
          <div class="col-md-12">
            <div class="form-group">
              <label>Estabelecimento<span class="text-danger">*</span></label>
              <select
                name="franquia"
                id="franquia"
                (change)="onChangeFranquia($event)"
                class="form-control"
              >
                <option *ngFor="let item of franquias" value="{{ item._id }}">
                  {{ item.nome }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <table class="table table-hover">
          <tbody>
            <tr *ngFor="let fila of filas">
              <td class="project-status">
                <span class="label label-primary" *ngIf="fila.visivelTablet">Visível</span>
                <span class="label label-default" *ngIf="!fila.visivelTablet">Não Visível</span>
              </td>
              <td class="project-title">
                <a href="project_detail.html">{{ fila.nome }}</a>
                <br />
                <small>{{ fila.sigla }} | {{ fila.prefixo }}</small>
              </td>
              <td class="project-actions">
                <a
                  class="btn btn-white btn-sm"
                  (click)="encaminhar(fila._id, franquiaSelecionada._id)"
                  data-dismiss="modal"
                  *ngIf="idFilaOrigem !== fila._id"
                  ><i class="fa fa-folder"></i> Encaminhar
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>
