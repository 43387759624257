<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-4">
    <h2>Relatório de Clientes</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>
        <a>Relatórios</a>
      </li>
      <li class="active">
        <strong>Relatório de Clientes</strong>
      </li>
    </ol>
  </div>
  <div class="col-sm-8">
    <div class="title-action" *ngIf="pager.total > 0" >
      <button type="button" [disabled]="exporting" (click)="exportarCSV()" class="btn btn-primary">
        <img *ngIf="exporting"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        {{ exporting ? ' Exportando...' : 'Exportar CSV' }}
      </button>
      <button type="button" [disabled]="exporting" (click)="exportarExcel()" class="btn btn-primary">
        <img *ngIf="exporting"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        {{ exporting ? ' Exportando...' : 'Exportar XLSX' }}
      </button>
      <button type="button" [disabled]="exporting" (click)="exportarPDF()" class="btn btn-primary">
        <img *ngIf="exporting"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        {{ exporting ? ' Exportando...' : 'Exportar PDF' }}
      </button>
    </div>
  </div>
</div>

<div class="wrapper wrapper-content animated fadeIn">

  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Relatório de Clientes ({{pager.total}})</h5>
          <div class="text-right">
            <div class="btn-group">
              <!-- <button type="button" class="btn btn-xs btn-white">Detalhar Atendimentos</button> -->
            </div>
          </div>
        </div>

        <div class="ibox-content">
          <div class="table-responsive">

            <table class="table table-striped" *ngIf="pager.total > 0">
              <thead>
                <tr>
                  <th class="text-center">Data</th>
                  <th class="text-center">Estabelecimento</th>
                  <th class="text-center">Nome</th>
                  <th class="text-center hidden-xs">Telefone</th>
                  <th class="text-center hidden-xs">E-mail</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of pager.list; let i = index">
                  <th class="text-center">{{item.created_at | date: 'dd/MM/yyyy HH:mm:ss'}}</th>
                  <th class="text-center">{{item.franquia.nome}}</th>
                  <td class="text-center">{{item.nome}}</td>
                  <td class="text-center hidden-xs">{{item.numero}}</td>
                  <td class="text-center hidden-xs">{{item.email}}</td>
                </tr>
              </tbody>
            </table>

            <div class="panel text-center" *ngIf="pager.total === 0">
              <div class="panel-body">
                <p class="p-y-1">
                  <i class="fa fa-table font-size-46 line-height-1 text-primary"></i>
                </p>
                <p>
                  <strong>Relatório de Clientes</strong>
                </p>
                <p class="m-b-4">Nenhum registro encontrado.</p>
              </div>
            </div>

            <div class="text-center" *ngIf="pager.total > pager.perPage">
              <paginator class="pagination" [pager]="pager" (onPager)="loadPage($event)"></paginator>
            </div>

            <br />
            <br />

          </div>
        </div>
      </div>
    </div>
  </div>
</div>