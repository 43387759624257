import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { FeedbackEmbededComponent } from './feedback-embeded.component';
import { FeedbackModalAnaliticoComponent } from './feedback-modal-analitico.component';
import { FeedbackModalSinteticoComponent } from './feedback-modal-sintetico.component';
import { QuestionarioFormComponent } from './questionario-form.component';
import { SmartAvaliaDashboardComponent } from './smartavalia-dashboard.component';
import { SmartAvaliaComponent } from './smartavalia.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    SmartAvaliaComponent,
    SmartAvaliaDashboardComponent,
    QuestionarioFormComponent,
    FeedbackModalSinteticoComponent,
    FeedbackModalAnaliticoComponent,
    FeedbackEmbededComponent,
  ],
  exports: [
    SmartAvaliaComponent,
    SmartAvaliaDashboardComponent,
    QuestionarioFormComponent,
    FeedbackModalSinteticoComponent,
    FeedbackModalAnaliticoComponent,
    FeedbackEmbededComponent,
  ],
  providers: [
  ]
})

export class SmartAvaliaModule { }
