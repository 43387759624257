import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { AtendimentoFilaComponent } from './atendimento-filas.component';
import { AtendimentoFormComponent } from './atendimento-form.component';
import { AtendimentoInfoComponent } from './atendimento-info.component';
import { AtendimentoComponent } from './atendimento.component';
import { AtualizarSenhaComponent } from './atualizar-senha/atualizar-senha.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    AtendimentoComponent,
    AtendimentoFilaComponent,
    AtendimentoFormComponent,
    AtendimentoInfoComponent,
    AtualizarSenhaComponent
  ],
  exports: [
    AtendimentoComponent,
    AtendimentoFilaComponent,
    AtendimentoFormComponent,
    AtendimentoInfoComponent,
    AtualizarSenhaComponent
  ],
  providers: [
  ]
})

export class AtendimentoModule { }
