import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AlertService, AuthenticationService, ConstantService, Empresa, EmpresaService, Franquia, Localizacao, Mesa } from 'lib-smart-core';
import { Constantes } from '../environments/constantes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  userLogged: Boolean = false;
  FRANQUIA_SESSION: Franquia = null;
  MESA_SESSION: Mesa = null;
  LOCALIZACAO_SESSION: Localizacao = null;

  smartFilas: boolean = false;
  smartFilasLite: boolean = false;

  constructor(
    router: Router,
    private alertService: AlertService,
    private empresaService: EmpresaService,
    private constantService: ConstantService,
    private authenticationService: AuthenticationService,
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.constantService.getFranquiaSession().subscribe(franquia => this.FRANQUIA_SESSION = franquia);
        this.constantService.getMesaSession().subscribe(mesa => this.MESA_SESSION = mesa);
        this.constantService.getLocalizacaoSession().subscribe(localizacao => this.LOCALIZACAO_SESSION = localizacao);
      }
    });
  }

  ngOnInit(): void {
    this.verifyUserLogged();
    this.FRANQUIA_SESSION = JSON.parse(localStorage.getItem(Constantes.currentFranquia));
    this.MESA_SESSION = JSON.parse(localStorage.getItem(Constantes.currentMesa));
    this.LOCALIZACAO_SESSION = JSON.parse(localStorage.getItem(Constantes.currentLocalizacao));

    const currentUser: any = this.currentUser();
    if (!!currentUser && !!currentUser.usuario && !!currentUser.usuario.empresa) {
      this.getEmpresa(currentUser.usuario.empresa._id);
    }
  }

  getEmpresa(idEmpresa: string) {
    this.empresaService.getById(idEmpresa).subscribe(
      (empresa: Empresa) => {
        this.smartFilas = empresa.smartFilas;
        this.smartFilasLite = empresa.smartFilasLite;
      },
      err => this.alertService.error(err.error.message)
    )
  }

  verifyUserLogged(): Boolean {
    let currentUser = this.currentUser();
    if (currentUser) {
      this.userLogged = true;
      // this.updateUserSession();
    } else {
      this.userLogged = false;
    }
    return this.userLogged;
  }

  currentUser(): any {
    return JSON.parse(localStorage.getItem(Constantes.currentUser));
  }

  logout() {
    this.authenticationService.logout();
    window.location.href = '/';
  }

  // updateUserSession() {
  //   this.authenticationService.refreshJwtTokenUserLogged().subscribe();
  // }

}
