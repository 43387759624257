import { AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Chart } from "chart.js";
import { AbstractComponent, ChartHelper, DateUtil, Empresa, EmpresaService, Feedback, Franquia, FranquiaService, Pager, SearchParams } from 'lib-smart-core';
import { RelatorioFeedbackService } from '../../../../../../lib-smart-core/src/lib/services/reports/relatorio-feedback.service';
import { Constantes } from "../../../../environments/constantes";
import { RelatorioAvaliacaoHelper } from './relatorio-avaliacao.helper';

@Component({
  selector: 'relatorio-avaliacao',
  templateUrl: './relatorio-avaliacao.component.html'
})
export class RelatorioAvaliacaoComponent extends AbstractComponent implements OnInit, AfterViewInit {

  @ViewChild('matDateRangePickerCancelAvaliacao') matDateRangePickerCancelAvaliacao: ElementRef;

  pager: Pager<Feedback> = new Pager<Feedback>({ perPage: 50 });

  list: any[];

  loading: boolean = false;
  exporting: boolean = false;

  empresa: Empresa;
  franquia: Franquia;
  franquias: Franquia[];

  idFranquiaSelecionada: string = '';
  model: any = {
    ano: new Date().getFullYear(),
    mes: new Date().getMonth() + 1,
  };

  canvas: any;
  ctx: any;
  canvas2: any;
  ctx2: any;
  chartLine: any;
  chartPie: any;

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  loadingCharts: boolean = false;

  constructor(
    private relatorioFeedbackService: RelatorioFeedbackService,
    private empresaService: EmpresaService,
    private franquiaService: FranquiaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setMonth(endDate.getMonth() - 1);
    this.range.setValue({ start: startDate, end: endDate });


  }

  ngAfterViewInit() {
    this.canvas = document.getElementById('relatorio-avaliacao');
    this.ctx = this.canvas.getContext('2d');
    this.canvas2 = document.getElementById('relatorio-avaliacao-pie');
    this.ctx2 = this.canvas2.getContext('2d');
    let idEmpresa: string = super.getIDEmpresaSession();
    if (idEmpresa) {
      this.getEmpresa(idEmpresa);
      this.getFranquiasPorEmpresa(idEmpresa);
    }

  }

  pagerReport() {
    this.searchParams = {
      empresa: super.getIDEmpresaSession(),
      startDate: DateUtil.toFormart(this.range.get('start').value, 'yyyy-MM-dd'),
      endDate: DateUtil.toFormart(this.range.get('end').value, 'yyyy-MM-dd'),
      franquia: this.idFranquiaSelecionada,
    }
    if (!!this.searchParams.startDate && !!this.searchParams.endDate) {
      this.relatorioFeedbackService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
        next: (pager: Pager<Feedback>) => {
          this.pager = pager;
        },
        error: (err) => this.alertService.error(err.error.message)
      });
    }
  }

  public loadCustomDateRange(event) {
    const days = +event.target.value;

    if (!!days && days === 0) {
      return;
    }

    let startDate = new Date();
    let endDate = new Date();
    const daysOff = {
      7: DateUtil.getDateAddDays(-7),
      15: DateUtil.getDateAddDays(-15),
      30: DateUtil.getDateAddDays(-30),
      90: DateUtil.getDateAddDays(-90),
      180: DateUtil.getDateAddDays(-180),
      365: DateUtil.getDateAddDays(-365),
    };
    startDate = daysOff[days];

    this.matDateRangePickerCancelAvaliacao.nativeElement.click();

    this.range.setValue({ start: startDate, end: endDate });
    this.loadPage(1);
  }

  private buildCharts() {
    this.loadingCharts = true;
    return this.buildArrayChartsRecursive(1, 5000, this.searchParams)
      .then((list: Feedback[]) => {
        this.setupChartArea(list);
        this.setupChartDoughnut(list);
        this.loadingCharts = false;
      });
  }

  private buildArrayChartsRecursive(page: number, perPage: number, searchParams: Partial<SearchParams>, total: number = 0, list: Array<Feedback> = new Array<Feedback>()): Promise<Feedback[]> {
    return this.relatorioFeedbackService.getAllPagerQuerySearch(page, perPage, null, searchParams)
      .toPromise()
      .then((pager: Pager<Feedback>) => {
        list.push(...pager.list.map(elem => elem));
        return ((page * perPage) >= pager.total) ? list : this.buildArrayChartsRecursive((page + 1), perPage, searchParams, pager.total, list)
      });
  }

  setupChartArea(list: Array<Feedback>) {

    if (!!this.chartLine) {
      this.chartLine.destroy();
    }

    const map: Map<string, number> = new Map<string, number>();
    list.forEach((senha: Feedback) => {
      const key: string = String(DateUtil.toFormart(senha.created_at, 'dd/MM'));
      if (map.has(key)) {
        map.set(key, +map.get(key) + 1);
      } else {
        map.set(key, 1);
      }
    });

    const labels = [];
    const data = []
    map.forEach((value: number, key: string) => {
      labels.push(key);
      data.push(value);
    });

    this.chartLine = new Chart(this.ctx, {
      type: 'line',
      data: {
        labels: labels.reverse(),
        datasets: [{
          data: data.reverse(),
          label: 'Quantidade de Avalições no período',
          borderWidth: ChartHelper.colorsDefault.borderWidth,
          backgroundColor: ChartHelper.colorsDefault.backgroundColor[0],
          borderColor: ChartHelper.colorsDefault.borderColor[0],
        }]
      },
      options: ChartHelper.optionsDefault as any
    });

  }

  setupChartDoughnut(list: Array<Feedback>) {

    if (!!this.chartPie) {
      this.chartPie.destroy();
    }

    const map: Map<string, number> = new Map<string, number>();
    const mapName: Map<string, string> = new Map<string, string>();

    list.forEach((atendimento: Feedback) => {
      const key: string = atendimento.franquia._id;
      if (map.has(key)) {
        map.set(key, +map.get(key) + 1);
      } else {
        map.set(key, 1);
        mapName.set(key, atendimento.franquia.nome);
      }
    });

    const labels = [];
    const data = []
    map.forEach((value: number, key: string) => {
      labels.push(mapName.get(key));
      data.push(value);
    });

    this.chartPie = new Chart(this.ctx2, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [{
          label: 'Quantidade de Avaliações por Estabelecimento',
          data: data,
          backgroundColor: ['#3179ce', '#fd7272', '#e55799', '#00ccbd', '#edad58', '#b878d1',],
          hoverOffset: 4
        }]
      },
      options: {
        legend: { display: true, position: 'top' },
      } as any
    });
  }

  getEmpresa(id: string) {
    this.empresaService.getById(id).subscribe(
      empresa => {
        this.empresa = empresa;
      },
      err => this.alertService.error(err.error.message)
    );
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      franquias => {
        this.franquias = franquias;
        this.franquia = JSON.parse(localStorage.getItem(Constantes.currentFranquia));
        if (this.franquia != undefined) {
          this.idFranquiaSelecionada = this.franquia._id;
        }
        this.pagerReport();
        this.buildCharts();
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.pagerReport();
    if (page === 1) {
      this.buildCharts();
    }
  }

  exportarCSV() {
    this.exporting = true;
    new RelatorioAvaliacaoHelper(this.relatorioFeedbackService).reportCSVAvaliacoes(this.searchParams)
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

  exportarExcel() {
    this.exporting = true;
    new RelatorioAvaliacaoHelper(this.relatorioFeedbackService).reportXLSXAvaliacoes(this.searchParams)
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

  exportarPDF() {
    this.exporting = true;
    new RelatorioAvaliacaoHelper(this.relatorioFeedbackService).reportPDFAvaliacoes(this.searchParams, this.chartLine, this.chartPie)
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

}