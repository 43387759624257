<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>TV</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li>
        <a [routerLink]="['/franquia']">Estabelecimentos</a>
      </li>
      <li>
        <a [routerLink]="['/franquia']">{{ franquia?.nome }}</a>
      </li>
      <li>
        <a [routerLink]="['/tv/', franquia?._id]">TVs</a>
      </li>
      <li class="active">
        <strong *ngIf="isNew">Adicionar</strong>
        <strong *ngIf="!isNew">Alterar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <form (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Layout</h5>
          </div>

          <div class="ibox-content">
            <div class="form-group">
              <div class="row">
                <div class="col-xs-6 col-md-2">
                  <div class="text-center" style="border: 1px solid #efefef; padding: 10px">
                    <h4>Padrão</h4>
                    <img src="./assets/img/layout_default.jpg" style="width: 100%; max-width: 250px"
                      alt="layout default" />
                    <br />
                    <br />
                    <a href="javascript:void(0)" role="button" (click)="selecionarLayout('DEFAULT')" [ngClass]="{
                        'btn-primary': tv.layout === 'DEFAULT',
                        'btn-white': tv.layout !== 'DEFAULT'
                      }" class="btn btn-block btn-lg">
                      {{
                      tv.layout === "DEFAULT" ? "Selecionado" : "Selecionar"
                      }}
                    </a>
                  </div>
                </div>
                <div class="col-xs-6 col-md-2">
                  <div class="text-center" style="border: 1px solid #efefef; padding: 10px">
                    <h4>Opção 1</h4>
                    <img src="./assets/img/layout_1.jpg" style="width: 100%; max-width: 250px" alt="layout default" />
                    <br />
                    <br />
                    <a href="javascript:void(0)" role="button" (click)="selecionarLayout('LAYOUT_1')" [ngClass]="{
                        'btn-primary': tv.layout === 'LAYOUT_1',
                        'btn-white': tv.layout !== 'LAYOUT_1'
                      }" class="btn btn-block btn-lg">
                      {{
                      tv.layout === "LAYOUT_1" ? "Selecionado" : "Selecionar"
                      }}
                    </a>
                  </div>
                </div>
                <div class="col-xs-6 col-md-2">
                  <div class="text-center" style="border: 1px solid #efefef; padding: 10px">
                    <h4>Opção 2</h4>
                    <img src="./assets/img/layout_2.jpg" style="width: 100%; max-width: 250px" alt="layout default" />
                    <br />
                    <br />
                    <a href="javascript:void(0)" role="button" (click)="selecionarLayout('LAYOUT_2')" [ngClass]="{
                        'btn-primary': tv.layout === 'LAYOUT_2',
                        'btn-white': tv.layout !== 'LAYOUT_2'
                      }" class="btn btn-block btn-lg">
                      {{
                      tv.layout === "LAYOUT_2" ? "Selecionado" : "Selecionar"
                      }}
                    </a>
                  </div>
                </div>
                <div class="col-xs-6 col-md-2">
                  <div class="text-center" style="border: 1px solid #efefef; padding: 10px">
                    <h4>Opção 3</h4>
                    <img src="./assets/img/layout_3.png" style="width: 100%; max-width: 250px" alt="layout default" />
                    <br />
                    <br />
                    <a href="javascript:void(0)" role="button" (click)="selecionarLayout('LAYOUT_3')" [ngClass]="{
                        'btn-primary': tv.layout === 'LAYOUT_3',
                        'btn-white': tv.layout !== 'LAYOUT_3'
                      }" class="btn btn-block btn-lg">
                      {{
                      tv.layout === "LAYOUT_3" ? "Selecionado" : "Selecionar"
                      }}
                    </a>
                  </div>
                </div>
                <div class="col-xs-6 col-md-2" *ngIf="tv?.empresa?.smartPager">
                  <div class="text-center" style="border: 1px solid #efefef; padding: 10px">
                    <h4>Opção 4</h4>
                    <img src="./assets/img/layout_4.png" style="width: 100%; max-width: 250px" alt="layout default" />
                    <br />
                    <br />
                    <a href="javascript:void(0)" role="button" (click)="selecionarLayout('LAYOUT_4')" [ngClass]="{
                        'btn-primary': tv.layout === 'LAYOUT_4',
                        'btn-white': tv.layout !== 'LAYOUT_4'
                      }" class="btn btn-block btn-lg">
                      {{
                      tv.layout === "LAYOUT_4" ? "Selecionado" : "Selecionar"
                      }}
                    </a>
                  </div>
                </div>
                <div class="col-xs-6 col-md-2">
                  <div class="text-center" style="border: 1px solid #efefef; padding: 10px">
                    <h4>Opção 5</h4>
                    <img src="./assets/img/layout_5.png" style="width: 100%; max-width: 250px" alt="layout 5" />
                    <br />
                    <br />
                    <a href="javascript:void(0)" role="button" (click)="selecionarLayout('LAYOUT_5')" [ngClass]="{
                        'btn-primary': tv.layout === 'LAYOUT_5',
                        'btn-white': tv.layout !== 'LAYOUT_5'
                      }" class="btn btn-block btn-lg">
                      {{
                      tv.layout === "LAYOUT_5" ? "Selecionado" : "Selecionar"
                      }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Dados</h5>
          </div>

          <div class="ibox-content">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Nome</label>
                  <input type="text" placeholder="Titulo da TV" class="form-control" name="nome" id="nome"
                    #nome="ngModel" [(ngModel)]="tv.nome" required />
                </div>
                <div class="form-group">
                  <label>Descrição</label>
                  <input type="text" placeholder="Descrição da TV" class="form-control" name="descricao" id="descricao"
                    #descricao="ngModel" [(ngModel)]="tv.descricao" required />
                </div>
                <div class="form-group">
                  <label for="localImage">Playlist</label>
                  <select [(ngModel)]="tv.playlist" class="form-control" [ngModelOptions]="{ standalone: true }"
                    required>
                    <option *ngFor="let p of playlists" [value]="p._id">
                      {{ p.nome }}
                    </option>
                  </select>
                </div>

                <hr class="hr-line-dashed" />

                <div class="form-group">
                  <label>Filas Selecionadas</label><br />

                  <ng-container *ngFor="let fila of filasSelecionadas" class="form-inline">
                    <button type="button" class="btn btn-success" disabled="true">
                      {{ fila.nome }}
                    </button>
                  </ng-container>
                  <ng-container class="form-inline" *ngIf="filasSelecionadas?.length === 0">
                    <button type="button" class="btn btn-success" disabled="true">
                      Todas as filas
                    </button>
                  </ng-container>
                </div>

                <div class="form-group">
                  <label>Filas deste estabelecimento</label>
                  <div style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      font-weight: 700;
                      border-bottom: 1px solid #efefef;
                      padding: 10px;
                    ">
                    <div>Nome</div>
                    <div>Associar</div>
                  </div>
                  <div class="col-md-6" style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      border-bottom: 1px solid #efefef;
                      padding: 10px;
                    " *ngFor="let fm of filas; let i = index">
                    <div>{{ fm.nome }}</div>
                    <div>
                      <button type="button" class="btn btn-primary fa fa-plus" (click)="selecionarFila(fm)"
                        *ngIf="!filaJaAdicionada(fm._id)"></button>
                      <button type="button" class="btn btn-primary fa fa-trash" (click)="excluirFilaSelecionada(fm)"
                        *ngIf="filaJaAdicionada(fm._id)"></button>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!f.form.valid">
                Salvar
              </button>
              <button type="button" class="btn btn-white" (click)="goBack()">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="row">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h5>Configurações Adicionais</h5>
            </div>

            <div class="ibox-content">
              <div class="row">
                <div class="col-md-12">

                  <div class="form-group">

                    <div class="form-group d-flex align-items-center">
                      <div class="m-r-sm">
                        <a *ngIf="tv.showAlerta" href="javascript:;" (click)="ativarDesativar('showAlerta')">
                          <i class="fa fa-toggle-on fa-3x"></i>
                        </a>
                        <a *ngIf="!tv.showAlerta" href="javascript:;" (click)="ativarDesativar('showAlerta')">
                          <i class="fa fa-toggle-off fa-3x"></i>
                        </a>
                      </div>
                      <div>
                        <h3 class="no-margins">Emitir Alerta Sonoro</h3>
                        <p class="no-paddings no-margins">
                          Quando ativo, ao invés de chamar a senha por voz, o
                          sistema irá emitir um alerta sonoro.
                        </p>
                      </div>
                    </div>

                    <hr class="hr-line-dashed" />
                    <div class="form-group d-flex align-items-center">
                      <div class="m-r-sm">
                        <a *ngIf="tv.showNome" href="javascript:;" (click)="ativarDesativar('showNome')">
                          <i class="fa fa-toggle-on fa-3x"></i>
                        </a>
                        <a *ngIf="!tv.showNome" href="javascript:;" (click)="ativarDesativar('showNome')">
                          <i class="fa fa-toggle-off fa-3x"></i>
                        </a>
                      </div>
                      <div>
                        <h3 class="no-margins">Visualizar Nome do Cliente</h3>
                        <p class="no-paddings no-margins">
                          Quando ativo, o nome do cliente da senha emitida será
                          exibido no painel, caso tenha sido informado.
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr class="hr-line-dashed" />

                  <div class="form-group">
                    <label for="localImage">Tipos de Tempo Previstos no Painel</label>
                    <select [(ngModel)]="tv.tipoTempoPrevistoAtendimento" class="form-control" [ngModelOptions]="{ standalone: true }"
                      required>
                    <option [value]="'NENHUM'">Nenhum</option>
                    <option [value]="'MEDIA_CHAMADA'">Cálculo baseado na média das chamadas de senhas</option>
                    <option [value]="'TEMPO_ESPECIFICO'">Tempo específico de atendimento no estabelecimento</option>
                    </select>
                  </div>

                  <div class="form-group" *ngIf="tv.tipoTempoPrevistoAtendimento === 'TEMPO_ESPECIFICO'">
                    <label>Tempo específico de atendimento (em minutos)</label>
                    <input type="text" maxlength="3" (keypress)="keyPressOnlyNumbers($event)" placeholder="Ex.: 90, 30, 5" class="form-control" name="tempoEspecificoAtendimento" id="tempoEspecificoAtendimento"
                      #tempoEspecificoAtendimento="ngModel" [(ngModel)]="tv.tempoEspecificoAtendimento" required />
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row" *ngIf="tv.layout === 'LAYOUT_5'">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h5>Imagem de fundo</h5>
            </div>

            <div class="ibox-content">
              <div class="row">
                <div class="col-md-12">

                  <div class="form-group">

                    <div class="form-inline">
                      <div class="images">
                        <div class="images__body">
                          <div class="images" [ngClass]="{ 'sk-loading': loadingImage }">
                            <div class="widget-head-color-box images__widget p-lg text-center">
                              <div class="form-group" style="display: none">
                                <input type="file" class="form-control" id="localBgImage" (change)="onChange($event)"
                                  accept=".jpg, .jpeg, .png" />
                              </div>
                              <div class="text-center">
                                <img *ngIf="tv.bgImage" src="{{ tv.bgImage }}" style="max-height: 200px" />
                              </div>
                            </div>
                            <div class="widget-text-box images__widget-bottom text-center">
                              <div class="text-center">
                                <button type="button" class="btn btn-primary" [disabled]="loadingImage"
                                  *ngIf="isAdmin()" (click)="openFileBrowser($event)">
                                  {{
                                  tv.bgImage ? "Alterar" : "Adicionar"
                                  }}</button>&nbsp;
                                <button type="button" class="btn btn-danger" (click)="removerImagem()"
                                  [disabled]="loadingImage" *ngIf="isAdmin() && tv.bgImage">
                                  <i class="fa fa-trash"></i>
                                </button>
                                <img *ngIf="loadingImage"
                                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                                <div class="m-t-2 text-muted font-size-12">
                                  JPG, JPEG ou PNG. Dimensões recomendadas 1920 x
                                  1080 com tamanho máximo 3MB.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Opacidade da imagem de fundo</label>
                    <div class="form-inline">
                      <input type="color" class="form-control" name="bgImageColor" id="bgImageColor"
                        style="width: 50px; padding: 0" [(ngModel)]="tv.bgImageColor" />
                      <input type="text" class="form-control" name="bgImageColor2" id="bgImageColor2"
                        style="width: 100px; padding: 0" [(ngModel)]="tv.bgImageColor" />
                      <input type="range" min="0" max="100" step="1" [value]="tv.bgImageAlpha" id="bgImageAlpha"
                        #bgImageAlpha style="width: 100px; padding: 0" [ngStyle]="{
                        'accent-color': tv?.bgImageColor,
                      }" [(ngModel)]="tv.bgImageAlpha" (input)="setAlpha(bgImageAlpha.value)" />
                    </div>
                    {{ tv.bgImageAlpha }}
                  </div>
                  <div class="form-group">
                    <label>Cor do fundo</label>
                    <div class="form-inline">
                      <input type="color" class="form-control" name="bgColor" id="bgColor"
                        style="width: 50px; padding: 0" [(ngModel)]="tv.bgColor" />
                      <input type="text" class="form-control" name="bgColor2" id="bgColor2"
                        style="width: 100px; padding: 0" [(ngModel)]="tv.bgColor" />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Cor do texto</label>
                    <div class="form-inline">
                      <input type="color" class="form-control" name="textColor" id="textColor"
                        style="width: 50px; padding: 0" [(ngModel)]="tv.textColor" />
                      <input type="text" class="form-control" name="textColor" id="textColor"
                        style="width: 100px; padding: 0" [(ngModel)]="tv.textColor" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>