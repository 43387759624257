<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Usuário</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li class="active">
        <strong>Usuários</strong>
      </li>
    </ol>
  </div>
</div>

<div class="row wrapper wrapper-content pb-0 animated fadeInRight">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>Filtro</h5>
      </div>
      <div class="ibox-content">
        <div class="input-group">
          <span class="input-group-addon" id="sizing-addon2"><i class="fa fa-search"></i></span>
          <input type="text" class="form-control shadow-inset-2" #searchBox (keyup)="search(searchBox.value)"
            placeholder="Procurar...">
        </div>

      </div>
    </div>
  </div>
</div>

<div class="row wrapper wrapper-content animated fadeInRight">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>Usuários ({{pager.total}})</h5>
        <div class="ibox-tools">
          <a [routerLink]="['/usuario/add']" class="btn btn-primary btn-xs" *ngIf="isAdmin() || isManager()">
            <i class="fa fa-plus"></i> Adicionar</a>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th width="5%">Imagem</th>
                <th>Nome/Email</th>
                <th width="10%">Função</th>
                <th width="10%">Estabelecimentos</th>
                <th width="15%" style="text-align: right;">Status</th>
                <th width="15%" style="text-align: right;">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let usuario of pager.list">
                <td>
                  <img class="img-circle" [src]="getUsuarioImage(usuario)" style="width: 42px;" />
                </td>
                <td>
                  {{usuario.nome}}<br />
                  <small>{{usuario.email}}</small>
                </td>
                <td>
                  <span class="label label-info" *ngIf="usuario.admin">Administrador</span>
                  <span class="label label-warning" *ngIf="!usuario.admin && usuario.manager">Gerente</span>
                  <span class="label" *ngIf="!usuario.admin && !usuario.manager">Atendente</span>
                </td>
                <td>
                  <span class="label" *ngFor="let item of usuario.franquias">{{item.nome}}</span>
                </td>
                <td style="text-align: right;">
                  <span class="label label-primary" *ngIf="!usuario.bloqueado">ATIVO</span>
                  <span class="label label-danger" *ngIf="usuario.bloqueado">BLOQUEADO</span>
                </td>
                <td style="text-align: right;">
                  <a class="btn btn-white btn-sm btn-bitbucket" title="Editar"
                    [routerLink]="['/usuario/edit', usuario._id]">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a *ngIf="!usuario?.bloqueado" class="btn btn-info btn-sm btn-bitbucket" title="Bloquear"
                    data-toggle="modal" data-target="#modalBlockUsuarioList" (click)="selecionar(usuario)">
                    <i class="fa fa-times-circle"></i>
                  </a>
                  <a *ngIf="usuario?.bloqueado" class="btn btn-info btn-sm btn-bitbucket" title="Desbloquear"
                    data-toggle="modal" data-target="#modalUnblockUsuarioList" (click)="selecionar(usuario)">
                    <i class="fa fa-check-circle"></i>
                  </a>
                  <a class="btn btn-primary btn-sm btn-bitbucket" title="Alterar Senha"
                    [routerLink]="['/usuario/edit/senha', usuario._id]">
                    <i class="fa fa-lock"></i>
                  </a>
                  <a class="btn btn-danger btn-sm btn-bitbucket" title="Remover" data-toggle="modal" href="#deleteModel"
                    (click)="selecionar(usuario)">
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-center" *ngIf="pager.total > pager.perPage">
            <paginator class="pagination" [pager]="pager" (onPager)="loadPage($event)"></paginator>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="modalBlockUsuarioList" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Bloquear Usuário</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Tem certeza que deseja <strong>BLOQUEAR</strong> o usuário <strong>{{usuarioSelecionado?.nome}}</strong>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="bloquear()" data-dismiss="modal">Confirmar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="modalUnblockUsuarioList" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Desbloquear Usuário</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Tem certeza que deseja <strong>DESBLOQUEAR</strong> o usuário <strong>{{usuarioSelecionado?.nome}}</strong>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="desbloquear()" data-dismiss="modal">Confirmar</button>
      </div>
    </div>
  </div>
</div>


<div class="modal inmodal" id="deleteModel" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Remover Usuário</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Esta ação não poderá ser desfeita. Tem certeza que deseja excluir o usuário
            <b>{{usuarioSelecionado?.nome}}</b>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="delete()">Confirmar</button>
      </div>
    </div>
  </div>
</div>