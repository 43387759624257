import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractComponent, Config, ConfigService, Empresa, EmpresaService, Fila, FilaService, Franquia, FranquiaService, Senha, SenhaService } from 'lib-smart-core';
import { environment } from "../../../environments/environment";

@Component({

  selector: 'app-tablet',
  templateUrl: './app-tablet.component.html'
})

export class AppTabletComponent extends AbstractComponent implements OnInit, OnDestroy {

  senhaSelecionada: Senha;
  filaSelecionada: Fila;

  idEmpresa: string;
  idFranquia: string;

  empresa: Empresa;
  franquia: Franquia;
  config: Config;
  filas: Fila[];
  isNormalPreferencial: boolean = false;

  constructor(
    private configService: ConfigService,
    private filaService: FilaService,
    private senhaService: SenhaService,
    private empresaService: EmpresaService,
    private franquiaService: FranquiaService,
    public injector: Injector
  ) {
    super(injector);
    document.getElementById("page-wrapper").classList.add("page-wrapper-login");
    document.getElementById("MENU_NOTV").remove();
    document.getElementById("SIDE_NOTV").remove();
    document.getElementById("FOOTER_NOTV").remove();
  }

  ngOnInit() {
    document.getElementById('page-wrapper').classList.add('white-bg')
    this.idEmpresa = super.getParam('idEmpresa');
    this.idFranquia = super.getParam('idFranquia');
    if (this.idEmpresa && this.idFranquia) {
      this.loadEmpresa();
      this.loadConfig();
      this.loadBotoes();
      this.loadFranquia();
    }
  }

  override ngOnDestroy(): void {
    document.getElementById("page-wrapper").classList.remove("white-bg");
  }

  loadEmpresa() {
    this.empresaService.getPublic(this.idEmpresa).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadFranquia() {
    this.franquiaService.getById(this.idFranquia).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadConfig() {
    this.configService.getConfigPorFranquia(this.idFranquia).subscribe(
      (config: Config) => {
        this.config = config;
      }, err => this.alertService.error(err.error.message)
    );
  }

  loadBotoes() {
    this.filaService.getFilasPorFranquiaPublic(this.idFranquia).subscribe(
      (filas: Fila[]) => {
        this.filas = filas;
      }, err => this.alertService.error(err.error.message)
    );
  }

  gerarNovaSenhaPreferencia(fila: Fila, preferencial: boolean = false) {
    console.log("preferencial", preferencial)
    this.senhaService.gerarNovaSenhaPreferencial(fila._id, preferencial).subscribe(
      (senha: Senha) => {
        console.log('_id >> ' + senha._id);
        this.senhaSelecionada = senha;
        this.filaSelecionada = fila;
        this.setNormalPreferencial(false);
      }, err => this.alertService.error(err.error.message)
    );
  }

  gerarNovaSenha(fila: Fila) {
    this.senhaService.gerarNovaSenhaPublic(fila._id).subscribe(
      (senha: Senha) => {
        console.log('_id >> ' + senha._id);
        this.senhaSelecionada = senha;
        this.filaSelecionada = fila;
        this.setNormalPreferencial(false);
      }, err => this.alertService.error(err.error.message)
    );
  }

  getQRCodeValue(idSenha: string): string {
    let value = "";
    if (idSenha) {
      value = environment.smartLiteUrl + '/painel/' + this.franquia.codigo + '/senha/' + idSenha;
    }
    return value;
  }

  setNormalPreferencial(status: boolean) {
    this.isNormalPreferencial = status;
  }

  selecionarFila(fila: Fila) {
    this.filaSelecionada = fila;
    this.setNormalPreferencial(true)
  }

}
