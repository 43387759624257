import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Franquia, FranquiaService, Mesa, MesaService } from 'lib-smart-core';

@Component({
  selector: 'mesa-lista',
  templateUrl: './mesa-lista.component.html'
})
export class MesaListaComponent extends AbstractComponent implements OnInit {

  idFranquia: string;
  franquia: Franquia;
  mesas: Mesa[];

  mesaSelecionada: Mesa;

  constructor(
    private mesaService: MesaService,
    private franquiaService: FranquiaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let idFranquia: string = super.getParam('idFranquia');
    if (idFranquia) {
      this.idFranquia = idFranquia;
      this.getFranquiaPorId(idFranquia);
      this.getMesasPorFranquia(idFranquia.toString());
    }
  }

  getFranquiaPorId(idFranquia: string) {
    return this.franquiaService.getById(idFranquia).subscribe(
      (franquia: Franquia) => {
        return this.franquia = franquia
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  getMesasPorFranquia(idFranquia: string) {
    return this.mesaService.getMesasPorFranquia(idFranquia).subscribe(
      (mesas: Mesa[]) => {
        this.mesas = mesas
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  selecionar(mesa: Mesa) {
    this.mesaSelecionada = mesa;
  }

  delete() {
    return this.mesaService.delete(this.mesaSelecionada._id).subscribe(
      () => {
        this.getMesasPorFranquia(this.idFranquia);
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    )
  }

}