import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, EmpresaService } from 'lib-smart-core';

@Component({
  templateUrl: './empresa-forms.component.html'
})
export class EmpresaFormsComponent extends AbstractComponent implements OnInit {

  empresa: Empresa;
  private isNew: boolean = true;

  constructor(
    private empresaService: EmpresaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.empresa = {} as Empresa;
    let id: string = super.getIDEmpresaSession();
    if (id != null || id != undefined) {
      console.log('carregando empresa id >> ' + id);
      this.isNew = false;
      this.getEmpresa(id);
    }
  }

  getEmpresa(id: string) {
    this.empresaService.getById(id)
      .subscribe(
        empresa => {
          this.empresa = empresa;
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
  }

  onSubmit(): void {
    this.empresaService.create(this.empresa)
      .subscribe(
        empresa => {
          super.goBack();
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
  }

}
