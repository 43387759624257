<div class="modal inmodal" id="modalAvaliacaoFranquia_{{franquia?._id}}" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <i class="fa fa-stars modal-icon"></i>
        <h4 class="modal-title">Avaliação do Estabelecimento</h4>
        <small class="font-bold">Os dados abaixo representam as avaliações ocorridas no estabelecimento
          <strong>{{franquia?.nome}}</strong>.</small>
      </div>
      <div class="modal-body">

        <div class="row">

          <div class="col-lg-4">
            <div class="widget  p-lg text-center">
              <div class="m-b-md">
                <!-- <i class="fa fa-flash fa-4x"></i> -->
                <h1 class="m-xs" style="font-size: 100px">{{pontuacao | currency: ' '}}</h1>
                <!-- <h3 class="font-bold no-margins">
                                    {{franquia?.nome}}
                                </h3> -->
                <!-- <small>amount</small> -->
                <avaliacao-embeded [pontuacao]="pontuacao"></avaliacao-embeded>
              </div>

              <div class="row">
                <div class="col-12 text-center">
                  <span> Total de Avaliações </span>
                  <h2 class="font-bold">{{data?.totalAvaliacoes}}</h2>
                </div>
              </div>
            </div>

          </div>

          <div class="col-lg-8">


            <div class="row">
              <div class="ibox-content text-left">
                <h3>Como você avalia a nosso estabelecimento?</h3>
                <div>
                  <div>
                    <span>5 <i class="fa fa-star"></i></span>
                    <small class="float-right"> - {{data?.stars5_2}} avaliações</small>
                  </div>
                  <div class="progress progress-small">
                    <div [ngStyle]="{'width': getPercentualStr(data?.stars5_2) }" class="progress-bar"
                      style="background-color: green"></div>
                  </div>

                  <div>
                    <span>4 <i class="fa fa-star"></i></span>
                    <small class="float-right"> - {{data?.stars4_2}} avaliações</small>
                  </div>
                  <div class="progress progress-small">
                    <div [ngStyle]="{'width': getPercentualStr(data?.stars4_2) }" class="progress-bar"
                      style="background-color: yellowgreen"></div>
                  </div>

                  <div>
                    <span>3 <i class="fa fa-star"></i></span>
                    <small class="float-right"> - {{data?.stars3_2}} avaliações</small>
                  </div>
                  <div class="progress progress-small">
                    <div [ngStyle]="{'width': getPercentualStr(data?.stars3_2) }" class="progress-bar"
                      style="background-color: yellow"></div>
                  </div>

                  <div>
                    <span>2 <i class="fa fa-star"></i></span>
                    <small class="float-right"> - {{data?.stars2_2}} avaliações</small>
                  </div>
                  <div class="progress progress-small">
                    <div [ngStyle]="{'width': getPercentualStr(data?.stars2_2) }" class="progress-bar"
                      style="background-color: tomato"></div>
                  </div>

                  <div>
                    <span>1 <i class="fa fa-star"></i></span>
                    <small class="float-right"> - {{data?.stars1_2}} avaliações</small>
                  </div>
                  <div class="progress progress-small">
                    <div [ngStyle]="{'width': getPercentualStr(data?.stars1_2) }" class="progress-bar"
                      style="background-color: red"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="ibox-content text-left">
                <h3>Como você avalia o atendimento recebido?</h3>
                <div>
                  <div>
                    <span>5 <i class="fa fa-star"></i></span>
                    <small class="float-right"> - {{data?.stars5_1}} avaliações</small>
                  </div>
                  <div class="progress progress-small">
                    <div [ngStyle]="{'width': getPercentualStr(data?.stars5_1) }" class="progress-bar"
                      style="background-color: green"></div>
                  </div>

                  <div>
                    <span>4 <i class="fa fa-star"></i></span>
                    <small class="float-right"> - {{data?.stars4_1}} avaliações</small>
                  </div>
                  <div class="progress progress-small">
                    <div [ngStyle]="{'width': getPercentualStr(data?.stars4_1) }" class="progress-bar"
                      style="background-color: yellowgreen"></div>
                  </div>

                  <div>
                    <span>3 <i class="fa fa-star"></i></span>
                    <small class="float-right"> - {{data?.stars3_1}} avaliações</small>
                  </div>
                  <div class="progress progress-small">
                    <div [ngStyle]="{'width': getPercentualStr(data?.stars3_1) }" class="progress-bar"
                      style="background-color: yellow"></div>
                  </div>

                  <div>
                    <span>2 <i class="fa fa-star"></i></span>
                    <small class="float-right"> - {{data?.stars2_1}} avaliações</small>
                  </div>
                  <div class="progress progress-small">
                    <div [ngStyle]="{'width': getPercentualStr(data?.stars2_1) }" class="progress-bar"
                      style="background-color: tomato"></div>
                  </div>

                  <div>
                    <span>1 <i class="fa fa-star"></i></span>
                    <small class="float-right"> - {{data?.stars1_1}} avaliações</small>
                  </div>
                  <div class="progress progress-small">
                    <div [ngStyle]="{'width': getPercentualStr(data?.stars1_1) }" class="progress-bar"
                      style="background-color: red"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="ibox-content text-left">
                <h3>Você recomendaria nosso estabelecimento para outro consultor?</h3>
                <div>
                  <div>
                    <span>SIM <i class="fa fa-star"></i></span>
                    <small class="float-right"> - {{data?.stars5_3}} avaliações</small>
                  </div>
                  <div class="progress progress-small">
                    <div [ngStyle]="{'width': getPercentualStr(data?.stars5_3) }" class="progress-bar"
                      style="background-color: green"></div>
                  </div>

                  <div>
                    <span>NÃO <i class="fa fa-star"></i></span>
                    <small class="float-right"> - {{data?.stars1_3}} avaliações</small>
                  </div>
                  <div class="progress progress-small">
                    <div [ngStyle]="{'width': getPercentualStr(data?.stars1_3) }" class="progress-bar"
                      style="background-color: red"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>



      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>