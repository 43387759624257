import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'lib-smart-core';
import { AppTabletComponent } from './modules/apps/app-tablet.component';
import { AtendimentoFilaComponent } from './modules/atendimento/atendimento-filas.component';
import { AtendimentoFormComponent } from './modules/atendimento/atendimento-form.component';
import { AtendimentoInfoComponent } from './modules/atendimento/atendimento-info.component';
import { AtendimentoComponent } from './modules/atendimento/atendimento.component';
import { AvisoFormsComponent } from './modules/aviso/aviso-forms.component';
import { AvisoComponent } from './modules/aviso/aviso.component';
import { ConfigFormsComponent } from './modules/config/config-forms.component';
import { ConfigListaComponent } from './modules/config/config-lista.component';
import { ImagesFormComponent } from './modules/config/images/images-form/images-form.component';
import { QRCodeTemplateFormComponent } from './modules/config/qrcode-template/qrcode-template-form/qrcode-template-form.component';
import { EmpresaComponent } from './modules/empresa/empresa.component';
import { HomeComponent } from './modules/empresa/home.component';
import { FilaFormsComponent } from './modules/fila/fila-forms.component';
import { FilaListaComponent } from './modules/fila/fila-lista.component';
import { FranquiaFormsComponent } from './modules/franquia/franquia-forms.component';
import { FranquiaListaComponent } from './modules/franquia/franquia-lista.component';
import { LocalizacaoFormsComponent } from './modules/localizacao/localizacao-forms.component';
import { LocalizacaoComponent } from './modules/localizacao/localizacao.component';
import { LoginComponent } from './modules/login/login.component';
import { MesaFormsComponent } from './modules/mesa/mesa-forms.component';
import { MesaListaComponent } from './modules/mesa/mesa-lista.component';
import { PagamentoComponent } from './modules/pagamento/pagamento.component';
import { PlaylistFormsComponent } from './modules/playlist/playlist-forms.component';
import { PlaylistListaComponent } from './modules/playlist/playlist-lista.component';
import { RedirectComponent } from './modules/redirect/redirect.component';
import { RelatorioAtendimentoComponent } from './modules/relatorio/relatorio-atendimento/relatorio-atendimento.component';
import { RelatorioAvaliacaoComponent } from './modules/relatorio/relatorio-avaliacao/relatorio-avaliacao.component';
import { RelatorioClientesComponent } from './modules/relatorio/relatorio-clientes/relatorio-clientes.component';
import { RelatorioRankingAtendimentoComponent } from './modules/relatorio/relatorio-ranking-atendimento/relatorio-ranking-atendimento.component';
import { RelatorioSenhaComponent } from './modules/relatorio/relatorio-senha/relatorio-senha.component';
import { RelatorioTipoAtendimentoComponent } from './modules/relatorio/relatorio-tipos-atendimento/relatorio-tipo-atendimento.component';
import { RelatorioComponent } from './modules/relatorio/relatorio.component';
import { QuestionarioFormComponent } from './modules/smartavalia/questionario-form.component';
import { SmartAvaliaDashboardComponent } from './modules/smartavalia/smartavalia-dashboard.component';
import { SmartAvaliaComponent } from './modules/smartavalia/smartavalia.component';
import { SmartFilaLiteComponent } from './modules/smartfilas-lite/smartfilas-lite.component';
import { SmartPagerComponent } from './modules/smartpager/smartpager.component';
import { SuporteComponent } from './modules/suporte/suporte.component';
import { TipoAtendimentoFormsComponent } from './modules/tipo-atendimento/tipo-atendimento-forms.component';
import { TipoAtendimentoComponent } from './modules/tipo-atendimento/tipo-atendimento.component';
import { TVFormsComponent } from './modules/tv/tv-forms.component';
import { TVListaComponent } from './modules/tv/tv-lista.component';
import { UsuarioEsqueciMinhaSenhaComponent } from './modules/usuario/usuario-esqueci-minha-senha.component';
import { UsuarioFormsComponent } from './modules/usuario/usuario-forms.component';
import { UsuarioListaComponent } from './modules/usuario/usuario-lista.component';
import { UsuarioSenhaFormsComponent } from './modules/usuario/usuario-senha-forms.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },

  { path: 'redirect', component: RedirectComponent },

  // esqueci minha senha
  { path: 'usuario/esqueci/minha/senha/:id', component: UsuarioEsqueciMinhaSenhaComponent },

  // empresa
  { path: 'empresa', component: EmpresaComponent, canActivate: [AuthGuard] },

  // config
  { path: 'config', component: ConfigListaComponent, canActivate: [AuthGuard] },
  { path: 'config/edit/:idFranquia', component: ConfigFormsComponent, canActivate: [AuthGuard] },
  { path: 'config/imagens/add/:idEmpresa', component: ImagesFormComponent, canActivate: [AuthGuard] },
  { path: 'config/imagens/edit/:index/empresa/:idEmpresa', component: ImagesFormComponent, canActivate: [AuthGuard] },
  { path: 'config/qrcode-template/add/:idEmpresa', component: QRCodeTemplateFormComponent, canActivate: [AuthGuard] },
  { path: 'config/qrcode-template/edit/:index/empresa/:idEmpresa', component: QRCodeTemplateFormComponent, canActivate: [AuthGuard] },

  // usuario
  { path: 'usuario', component: UsuarioListaComponent, canActivate: [AuthGuard] },
  { path: 'usuario/add', component: UsuarioFormsComponent, canActivate: [AuthGuard] },
  { path: 'usuario/edit/:id', component: UsuarioFormsComponent, canActivate: [AuthGuard] },
  { path: 'usuario/edit/senha/:id', component: UsuarioSenhaFormsComponent, canActivate: [AuthGuard] },

  // franquia
  { path: 'franquia', component: FranquiaListaComponent, canActivate: [AuthGuard] },
  { path: 'franquia/add/empresa/:idEmpresa', component: FranquiaFormsComponent, canActivate: [AuthGuard] },
  { path: 'franquia/edit/:id', component: FranquiaFormsComponent, canActivate: [AuthGuard] },

  // fila
  { path: 'fila/:idFranquia', component: FilaListaComponent, canActivate: [AuthGuard] },
  { path: 'fila/add/franquia/:idFranquia', component: FilaFormsComponent, canActivate: [AuthGuard] },
  { path: 'fila/edit/:id', component: FilaFormsComponent, canActivate: [AuthGuard] },

  // mesa
  { path: 'mesa/:idFranquia', component: MesaListaComponent, canActivate: [AuthGuard] },
  { path: 'mesa/add/franquia/:idFranquia', component: MesaFormsComponent, canActivate: [AuthGuard] },
  { path: 'mesa/edit/:id', component: MesaFormsComponent, canActivate: [AuthGuard] },

  // playlist
  { path: 'playlist', component: PlaylistListaComponent, canActivate: [AuthGuard] },
  { path: 'playlist/add', component: PlaylistFormsComponent, canActivate: [AuthGuard] },
  { path: 'playlist/edit/:id', component: PlaylistFormsComponent, canActivate: [AuthGuard] },

  // tv
  { path: 'tv/:idFranquia', component: TVListaComponent, canActivate: [AuthGuard] },
  { path: 'tv/add/franquia/:idFranquia', component: TVFormsComponent, canActivate: [AuthGuard] },
  { path: 'tv/edit/:id', component: TVFormsComponent, canActivate: [AuthGuard] },

  // atendimento
  { path: 'atendimento', component: AtendimentoComponent, canActivate: [AuthGuard] },
  { path: 'atendimento-filas/:idFilaSelecionada', component: AtendimentoFilaComponent, canActivate: [AuthGuard] },
  { path: 'atendimento-form/:idSenha/:idFila', component: AtendimentoFormComponent, canActivate: [AuthGuard] },
  { path: 'atendimento-info/:idAtendimento', component: AtendimentoInfoComponent, canActivate: [AuthGuard] },

  // aviso
  { path: 'aviso', component: AvisoComponent, canActivate: [AuthGuard] },
  { path: 'aviso/add', component: AvisoFormsComponent, canActivate: [AuthGuard] },
  { path: 'aviso/edit/:id', component: AvisoFormsComponent, canActivate: [AuthGuard] },

  // tipo de atendimento
  { path: 'tipo-atendimento', component: TipoAtendimentoComponent, canActivate: [AuthGuard] },
  { path: 'tipo-atendimento/add', component: TipoAtendimentoFormsComponent, canActivate: [AuthGuard] },
  { path: 'tipo-atendimento/edit/:id', component: TipoAtendimentoFormsComponent, canActivate: [AuthGuard] },

  // pagamento
  { path: 'pagamento', component: PagamentoComponent, canActivate: [AuthGuard] },

  // relatorio
  { path: 'relatorio', component: RelatorioComponent, canActivate: [AuthGuard] },
  { path: 'relatorio/avaliacoes', component: RelatorioAvaliacaoComponent, canActivate: [AuthGuard] },
  { path: 'relatorio/atendimentos', component: RelatorioAtendimentoComponent, canActivate: [AuthGuard] },
  { path: 'relatorio/senhas', component: RelatorioSenhaComponent, canActivate: [AuthGuard] },
  { path: 'relatorio/clientes', component: RelatorioClientesComponent, canActivate: [AuthGuard] },
  { path: 'relatorio/ranking/atendimento', component: RelatorioRankingAtendimentoComponent, canActivate: [AuthGuard] },
  { path: 'relatorio/tipos/atendimento', component: RelatorioTipoAtendimentoComponent, canActivate: [AuthGuard] },

  // suporte
  { path: 'suporte', component: SuporteComponent, canActivate: [AuthGuard] },

  // apps
  // { path: 'app/itv/:idTV', component: AppTVComponent },
  // { path: 'app/tv/:idEmpresa/:idFranquia', component: AppTVComponent },
  { path: 'app/tablet/:idEmpresa/:idFranquia', component: AppTabletComponent },

  // smarts
  { path: 'smartpager', component: SmartPagerComponent, canActivate: [AuthGuard] },
  { path: 'smartavalia', component: SmartAvaliaComponent, canActivate: [AuthGuard] },
  { path: 'smartfilas-lite', component: SmartFilaLiteComponent, canActivate: [AuthGuard] },
  { path: 'smartavalia/dashboard/:idQuestionario', component: SmartAvaliaDashboardComponent, canActivate: [AuthGuard] },

  // questionario
  { path: 'questionario/add', component: QuestionarioFormComponent, canActivate: [AuthGuard] },
  { path: 'questionario/edit/:id', component: QuestionarioFormComponent, canActivate: [AuthGuard] },

  // localizacao
  { path: 'localizacao', component: LocalizacaoComponent, canActivate: [AuthGuard] },
  { path: 'localizacao/add', component: LocalizacaoFormsComponent, canActivate: [AuthGuard] },
  { path: 'localizacao/edit/:id', component: LocalizacaoFormsComponent, canActivate: [AuthGuard] },

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {

}
