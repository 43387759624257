
import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AbstractComponent, Atendimento, DateUtil, Empresa, EmpresaService, Franquia, FranquiaService, RelatorioRankingAtendimentoService } from 'lib-smart-core';
import { Constantes } from "../../../../environments/constantes";
import { RelatorioRankingAtendimentoHelper } from './relatorio-ranking-atendimento.helper';

@Component({
  selector: 'relatorio-ranking-atendimento',
  templateUrl: './relatorio-ranking-atendimento.component.html'
})
export class RelatorioRankingAtendimentoComponent extends AbstractComponent implements OnInit {

  @ViewChild('matDateRangePickerCancelRankingAtendimento') matDateRangePickerCancelRankingAtendimento: ElementRef;

  canvas: any;
  ctx: any;

  mostrarGrafico: Boolean = false;
  idFranquiaSelecionada = '';

  empresa: Empresa;

  franquia: Franquia;
  atendimentos: Atendimento[];

  historico: Atendimento;
  franquias: Franquia[];

  model: any = {
    ano: new Date().getFullYear(),
    mes: new Date().getMonth() + 1,
  };

  list: Array<any>;

  exporting: boolean = false;

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(
    private franquiaService: FranquiaService,
    private relatorioRankingAtendimentoService: RelatorioRankingAtendimentoService,
    private empresaService: EmpresaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setMonth(endDate.getMonth() - 1);
    this.range.setValue({ start: startDate, end: endDate });

    let idEmpresa: string = super.getIDEmpresaSession();
    if (idEmpresa) {
      this.getEmpresa(idEmpresa);
      this.getFranquiasPorEmpresa(idEmpresa);
    }
  }

  ngAfterViewInit() {
    this.pagerReport();
  }

  pagerReport() {
    this.searchParams = {
      empresa: super.getIDEmpresaSession(),
      startDate: DateUtil.toFormart(this.range.get('start').value, 'yyyy-MM-dd'),
      endDate: DateUtil.toFormart(this.range.get('end').value, 'yyyy-MM-dd'),
      franquia: this.idFranquiaSelecionada,
    }
    if (!!this.searchParams.startDate && !!this.searchParams.endDate) {
      this.relatorioRankingAtendimentoService.getAllPagerQuerySearch(0, 0, this.searchString, this.searchParams).subscribe({
        next: (data: any) => {
          this.list = data;
        },
        error: (err) => this.alertService.error(err.error.message)
      });
    }
  }

  public loadCustomDateRange(event) {
    const days = +event.target.value;

    if (!!days && days === 0) {
      return;
    }

    let startDate = new Date();
    let endDate = new Date();
    const daysOff = {
      7: DateUtil.getDateAddDays(-7),
      15: DateUtil.getDateAddDays(-15),
      30: DateUtil.getDateAddDays(-30),
      90: DateUtil.getDateAddDays(-90),
      180: DateUtil.getDateAddDays(-180),
      365: DateUtil.getDateAddDays(-365),
    };
    startDate = daysOff[days];

    this.matDateRangePickerCancelRankingAtendimento.nativeElement.click();

    this.range.setValue({ start: startDate, end: endDate });
    this.pagerReport();
  }

  getEmpresa(id: string) {
    this.empresaService.getById(id).subscribe(
      empresa => {
        this.empresa = empresa;
      },
      err => this.alertService.error(err.error.message)
    );
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      franquias => {
        this.franquias = franquias;
        this.franquia = JSON.parse(localStorage.getItem(Constantes.currentFranquia));
        if (this.franquia != undefined) {
          this.idFranquiaSelecionada = this.franquia._id;
        }
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  exportarCSV() {
    this.exporting = true;
    new RelatorioRankingAtendimentoHelper(this.relatorioRankingAtendimentoService).reportCSVRanking(this.searchParams)
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

  exportarExcel() {
    this.exporting = true;
    new RelatorioRankingAtendimentoHelper(this.relatorioRankingAtendimentoService).reportXLSXRanking(this.searchParams)
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

  exportarPDF() {
    this.exporting = true;
    new RelatorioRankingAtendimentoHelper(this.relatorioRankingAtendimentoService).reportPDFRanking(this.searchParams)
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

}