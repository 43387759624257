<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>{{questionario?.nome}}</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>

        <strong><a [routerLink]="['/smartavalia']">SmartAvalia</a></strong>
      </li>
      <li class="active">
        <strong>{{questionario?.nome}} </strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>Dashboard</h5>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">

            <div class="row  border-bottom white-bg dashboard-header">
              <div class="col-lg-2">
                <form class=" " novalidate>
                  <ul class="stat-list">
                    <li>
                      <h2 class="no-margins">Mês e Ano</h2>
                      <div class="form-group">
                        <select class="form-control" id="grid-input-5" [(ngModel)]="model.mes"
                          [ngModelOptions]="{standalone: true}" (change)="onChangeData()">
                          <option value="1">Janeiro</option>
                          <option value="2">Fevereiro</option>
                          <option value="3">Março</option>
                          <option value="4">Abril</option>
                          <option value="5">Maio</option>
                          <option value="6">Junho</option>
                          <option value="7">Julho</option>
                          <option value="8">Agosto</option>
                          <option value="9">Setembro</option>
                          <option value="10">Outubro</option>
                          <option value="11">Novembro</option>
                          <option value="12">Dezembro</option>
                        </select>
                        <select class="form-control" id="grid-input-5" [(ngModel)]="model.ano"
                          [ngModelOptions]="{standalone: true}" (change)="onChangeData()">
                          <option value="2017">2017</option>
                          <option value="2018">2018</option>
                          <option value="2019">2019</option>
                          <option value="2020">2020</option>
                          <option value="2021">2021</option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                        </select>
                      </div>
                    </li>
                    <li>
                      <h2 class="no-margins ">Estabelecimento</h2>
                      <div class="form-group">
                        <select [(ngModel)]="idFranquiaSelecionada" name="idFranquiaSelecionada"
                          id="idFranquiaSelecionada" class="form-control" [ngModelOptions]="{standalone: true}"
                          (change)="onChangeData()">
                          <option value="" checked>Todos</option>
                          <option *ngFor="let f of franquias" [value]="f._id">{{f.nome}}</option>
                        </select>
                      </div>
                    </li>

                  </ul>
                </form>
              </div>
              <div class="col-md-10">

                <canvas id="canvas-chart-area" width="300" height="75"
                  style="display: inline-block; width: 300px; height: 75px; vertical-align: top;"></canvas>

              </div>

            </div>

            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Estabelecimento</th>
                  <th>Código</th>
                  <th>Cidade/UF</th>
                  <th>Avaliação</th>
                  <th width="15%" style="text-align: right;">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let franquia of franquias">
                  <th>
                    {{franquia.nome}}<br />
                    <small>{{franquia.email}}</small>
                  </th>
                  <td>{{franquia.codigo}}</td>
                  <td>{{franquia.cidade}}/{{franquia.estado}}</td>
                  <th>
                    <feedback-embeded [rating]="franquia.rating"></feedback-embeded>
                    <feedback-modal-sintetico [idQuestionario]="questionario._id" [idFranquia]="franquia._id"
                      [rating]="franquia.rating"></feedback-modal-sintetico>
                    <feedback-modal-analitico [idQuestionario]="questionario._id" [idFranquia]="franquia._id"
                      [rating]="franquia.rating"></feedback-modal-analitico>
                  </th>
                  <td style="text-align: right;">

                    <!-- <a class="btn btn-white btn-sm" title="TV" (click)="setupQRCodeFranquia(franquia._id)" data-toggle="modal" data-target="#qrcodetv">
                        TV
                      </a> -->

                    <a class="btn btn-white btn-sm " href="#modalFeedbackGeral_{{franquia._id}}" data-toggle="modal"
                      data-target="#modalFeedbackGeral_{{franquia._id}}" title="Feedback de Avaliação Sintético">
                      <i class="fa fa-globe"></i>
                    </a>
                    <a class="btn btn-white btn-sm " href="#modalFeedbackFranquia_{{franquia._id}}" data-toggle="modal"
                      data-target="#modalFeedbackFranquia_{{franquia._id}}" title="Feedback de Avaliação Analítico">
                      <i class="fa fa-tasks"></i>
                    </a>
                    <!-- <button data-toggle="dropdown" class="btn btn-white btn-sm dropdown-toggle" type="button">
                        <i class="fa fa-cogs"></i> <span class="caret"></span></button> -->
                    <!-- <ul class="dropdown-menu">
                        <li>
                          <a title="QRCode Avaliação" (click)="generateBannerSmartAvalia(franquia)" data-toggle="modal"
                            data-target="#modalBannerSmartAvalia2"><i class="fa fa-qrcode"></i>
                            Banner Avaliação</a>
                        </li>
                      </ul> -->

                    <!-- <a class="btn btn-primary btn-sm btn-bitbucket" data-toggle="modal" href="#modalBannerSmartAvalia2"
                      (click)="generateBannerSmartAvalia(franquia)" title="Gerar QRCode SmartAvalia">
                      <i class="fa fa-qrcode"></i>
                    </a> -->
                  </td>
                </tr>

              </tbody>
            </table>


            <div class="user-feedbacks">

              <div class="user-feedbacks__item" *ngFor="let item of pager.list">
                <div class="contact-box">
                  <feedback-embeded [rating]="item.rating"></feedback-embeded>
                  <hr>
                  <h2 class="product-main-price">{{ item.comentario}} </h2>
                  <hr>
                  <div class="row">
                    <div class="small text-right">
                      <i class="fa fa-map-marker"> </i> {{ item.franquia.nome}} |
                      <i class="fa fa-calendar"> </i> {{ item.created_at | date : 'dd/MM/yyyy HH:mm'}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center" *ngIf="pager.total > pager.perPage">
              <paginator class="pagination" [pager]="pager" (onPager)="loadPage($event)"></paginator>
            </div>

            <!-- <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br /> -->

          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal inmodal" id="modalBannerSmartAvalia2" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content animated bounceInRight">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
              class="sr-only">Close</span></button>
          <i class="fa fa-qrcode modal-icon"></i>
          <h4 class="modal-title">QRCode SmartAvalia</h4>
          <small class="font-bold">
            <a href="{{urlSmartAvalia}}" target="_blank">{{urlSmartAvalia}}</a>
          </small>
        </div>
        <div class="modal-body">

          <div class="row" *ngIf="imgSmartAvalia !== null">
            <div class="col-lg-7">
              <img alt="image" *ngIf="imgSmartAvalia !== null" class="rounded image-lg-full" style="width: 100%;"
                [src]="imgSmartAvalia">
            </div>
            <div class="col-lg-5">
              <div class="jumbotron">
                <h1>QRCode </h1>
                <p>Este QRCode irá permitir seus clientes acessarem este questionário e avaliar seu estabelecimento.</p>
                <p><a download="SmartAvalia_{{franquiaSelecionada?.codigo}}.png" href="{{imgSmartAvalia}}"
                    target="_blank" class="btn btn-primary btn-block" role="button"><i class="fa fa-download"></i>
                    Baixar QRCode</a>
                </p>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        </div>
      </div>
    </div>
  </div>