<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>SmartAvalia</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li class="active">
        <strong>SmartAvalia</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>SmartAvalia - Questionários de Avaliação</h5>
          <div class="ibox-tools">
            <a [routerLink]="['/questionario/add']" class="btn btn-primary btn-xs" *ngIf="isAdmin() || isManager()">
              <i class="fa fa-plus"></i> Adicionar</a>
          </div>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Criado em</th>
                  <th>Avaliação escrita?</th>
                  <th>Dados do cliente?</th>
                  <th width="15%" style="text-align: right;">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of list">
                  <th>
                    {{item.nome}}&nbsp;&nbsp;
                    <span class="badge badge-primary" *ngIf="item.principal">Principal</span>
                  </th>
                  <td>{{item?.created_at | date: 'dd/MM/yyyy'}}</td>
                  <td>
                    <span class="label label-primary" *ngIf="item.habilitarComentario">Ativado</span>
                    <span class="label label-warning" *ngIf="!item.habilitarComentario">Desativado</span>
                  </td>
                  <td>
                    <span class="label label-primary" *ngIf="item.dadosContato">Ativado</span>
                    <span class="label label-warning" *ngIf="!item.dadosContato">Desativado</span>
                  </td>
                  <td class="text-right">
                    <a class="btn btn-white btn-sm btn-bitbucket" title="Overview do Questionário"
                      [routerLink]="['/smartavalia/dashboard/', item._id]">
                      <i class="fa fa-home"></i>
                    </a>
                    <a class="btn btn-white btn-sm btn-bitbucket" title="Editar" *ngIf="isAdmin() || isManager()"
                      [routerLink]="['/questionario/edit/', item._id]">
                      <i class="fa fa-edit"></i>
                    </a>
                    <a class="btn btn-danger btn-sm btn-bitbucket" title="Remover" data-toggle="modal"
                      href="#deleteModelQuestionario" *ngIf="isAdmin() || isManager() && !item.principal"
                      (click)="selecionar(item)">
                      <i class="fa fa-trash"></i>
                    </a>
                    <a class="btn btn-primary btn-sm btn-bitbucket" title="Gerar QRCode SmartAvalia" data-toggle="modal"
                      href="#modalSmartAvaliaBannerFranquia" (click)="selecionar(item)">
                      <i class="fa fa-link"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- <div class="row">
              <div class="col-lg-6" *ngFor="let item of imgCertificados">
                <img alt="image" class="rounded image-lg-full" style="width: 100%;" [src]="item">
              </div>
            </div> -->

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal inmodal" id="deleteModelQuestionario" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content animated bounceInRight">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
            <span class="sr-only">Close</span>
          </button>
          <h4 class="modal-title">Remover questionário</h4>
        </div>
        <div class="modal-body">
          <div align="center">
            <p>
              <strong>ATENÇÃO!</strong>
            </p>
            <p>
              Esta ação não poderá ser desfeita. Caso você tenha algum tipo de material impresso como: banner, acrílico,
              cartões, etc... este link não estará mais disponível aos seus clientes.
            </p>
            <p>
              Tem certeza que deseja excluir o questionário <b>{{questionarioSelecionado?.nome}}</b>, links e QRCodes
              associados?
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="delete()">Confirmar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal inmodal" id="modalSmartAvaliaBannerFranquia" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content animated bounceInRight">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
              class="sr-only">Close</span></button>
          <i class="fa fa-qrcode modal-icon"></i>
          <h4 class="modal-title">{{title}}</h4>
        </div>
        <div class="modal-body">

          <table class="table table-striped">
            <tbody>
              <tr *ngFor="let franquia of franquias">
                <th>
                  {{franquia.nome}}<br />
                  <small>{{franquia.email}}</small>
                </th>
                <td>{{franquia.codigo}}</td>
                <td>{{franquia.cidade}}/{{franquia.estado}}</td>
                <td style="text-align: right;">
                  <button type="button" class="btn btn-primary fa fa-qrcode"
                    title="Utilizado para QRCode e envio de links de avaliação."
                    (click)="generateBannerSmartAvalia(franquia, false)"></button>
                  <button type="button" class="btn btn-primary fa fa-tablet" title="Para ser utilizado totem."
                    (click)="generateBannerSmartAvalia(franquia)"></button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="row" *ngIf="imgSmartAvalia !== null">
            <div class="col-lg-7">
              <img alt="image" *ngIf="imgSmartAvalia !== null" class="rounded image-lg-full" style="width: 100%;"
                [src]="imgSmartAvalia">
            </div>
            <div class="col-lg-5">
              <div class="jumbotron">
                <h1>QRCode </h1>
                <p>Este QRCode irá permitir seus clientes acessarem este questionário e avaliar seu estabelecimento.</p>
                <p><a download="SmartAvalia_{{franquiaSelecionada?.codigo}}.png" href="{{imgSmartAvalia}}"
                    target="_blank" class="btn btn-primary btn-block" role="button"><i class="fa fa-download"></i>
                    Baixar QRCode</a>
                <p>
                  <a href="javascript:void(0)" (click)="copyDynamicLink()" class="btn btn-primary btn-block"
                    role="button" title="Copie para compartilhar a avalição por e-mail, whatsapp, etc.">
                    <i class="fa fa-copy"></i>
                    Copiar Link</a>
                </p>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="isCopyLink">
            <div class="col-md-12">
              <div class="jumbotron" style="text-align: center">
                <h1 style="font-size: 12px; font-weight: 600; color: #337ab7">{{urlSmartAvalia}}</h1>
                <p style="font-size: 16px;">Copie esse link para avaliação em totem.</p>
                <p><a href="javascript:void(0)" (click)="copyDynamicLink()" class="btn btn-primary" role="button"><i
                      class="fa fa-copy"></i>
                    Copiar Link</a>
                </p>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal" (click)="closeModal()">Fechar</button>
        </div>
      </div>
    </div>
  </div>