<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
  <!-- <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button> -->
</div>
<div class="modal-body">
  <form>
    <div class="mb-3">
      <label for="dateOfBirth">Date of birth</label>
      <div class="input-group">

      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Save</button> -->
</div>