import { Component, Injector } from '@angular/core';
import { AbstractComponent, Localizacao, LocalizacaoService, Pager } from 'lib-smart-core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'localizacao-component',
  templateUrl: './localizacao.component.html'
})
export class LocalizacaoComponent extends AbstractComponent {

  pager: Pager<Localizacao> = new Pager<Localizacao>({ perPage: 25 });

  listObservable: Observable<Pager<Localizacao>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  localizacaoSelecionada: Localizacao;

  constructor(
    private localizacaoService: LocalizacaoService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getLocalizacoesPorGrupo();
  }

  getLocalizacoesPorGrupo() {
    this.searchParams = {
      empresa: super.getIDEmpresaSession()
    }
    return this.localizacaoService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe(
      (pager: Pager<Localizacao>) => {
        this.pager = pager;
        this.setupObservableSearch();
      },
      err => this.alertService.error(err.error.message)
    )
  }

  selecionar(localizacao: Localizacao) {
    this.localizacaoSelecionada = localizacao;
  }

  delete() {
    return this.localizacaoService.delete(this.localizacaoSelecionada._id).subscribe(
      () => {
        this.getLocalizacoesPorGrupo();
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    )
  }

  ativar(localizacao: Localizacao) {
    this.localizacaoService.ativar(localizacao._id).subscribe(
      () => {
        this.getLocalizacoesPorGrupo();
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    );
  }

  desativar(localizacao: Localizacao) {
    this.localizacaoService.desativar(localizacao._id).subscribe(
      () => {
        this.getLocalizacoesPorGrupo();
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    );
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.localizacaoService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Localizacao>) => {
      this.pager = pager;
    });
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getLocalizacoesPorGrupo();
  }

}
