import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Aviso, AvisoService, Empresa, Franquia, FranquiaService } from 'lib-smart-core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({

  selector: 'aviso-forms',
  templateUrl: './aviso-forms.component.html'
})
export class AvisoFormsComponent extends AbstractComponent implements OnInit {

  searchBox: string;
  model: any;

  aviso: Aviso;
  isNew: boolean = true;

  franquiasModal: Franquia[];
  franquiasSelecionadas: Franquia[];

  usuariosObs: Observable<Franquia[]>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  constructor(
    private avisoService: AvisoService,
    private franquiaService: FranquiaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model = {};
    this.franquiasSelecionadas = [];

    this.aviso = {} as Aviso;
    let idEmpresa: string = super.getIDEmpresaSession();
    if (idEmpresa) {
      this.aviso.empresa = { _id: idEmpresa } as Empresa;
      this.getFranquiasPorEmpresa(idEmpresa);
      this.setupFranquiasLoad(idEmpresa);
    }
    let id: string = super.getParam('id');
    if (id) {
      this.isNew = false;
      this.getAvisoPorId(id.toString());
    }
  }

  search(termo: string) {
    this.termoDaBusca.next(termo);
  }

  setupFranquiasLoad(idEmpresa: string) {
    this.usuariosObs = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        console.log(term);
        return term ? this.franquiaService.searchEmpresa(idEmpresa, term) : this.franquiaService.getFranquiasPorEmpresa(idEmpresa);
      }));
    this.usuariosObs.subscribe((franquias: Franquia[]) => {
      console.log(franquias);
      return this.franquiasModal = franquias;
    });
  }

  loadModalFranquias() {
    this.searchBox = '';
    this.model.searchBox = '';
    this.termoDaBusca.next('');
  }

  getAvisoPorId(id: string) {
    return this.avisoService.getById(id).subscribe(
      (aviso) => {
        this.aviso = aviso
        this.franquiasSelecionadas = this.aviso.franquias
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      (franquias) => {
        this.franquiasModal = franquias;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  excluirFranquiaSelecionada(index: number) {
    this.franquiasSelecionadas.splice(index, 1);
  }

  selecionarFraquia(franquia: Franquia) {
    this.franquiasSelecionadas.push(franquia);
  }

  franquiaJaAdicionada(id: string) {
    let result = false;
    for (let i = 0; i < this.franquiasSelecionadas.length; i++) {
      let franquia: Franquia = this.franquiasSelecionadas[i];
      if (franquia._id.toString() === id.toString()) {
        result = true;
        break;
      }
    };
    return result;
  }

  onSubmit(): void {
    console.log('add aviso');
    console.log(this.franquiasSelecionadas);
    this.aviso.franquias = this.franquiasSelecionadas;

    console.log(this.aviso);

    if (this.isNew) {
      this.avisoService.create(this.aviso).subscribe(
        franquia => {
          this.alertService.success('Operação realizada com sucesso');
          super.goBack();
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
    } else {
      this.avisoService.update(this.aviso).subscribe(
        usuario => {
          this.alertService.success('Operação realizada com sucesso');
          super.goBack();
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
    }

  }

}