import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Chart } from "chart.js";
import { AbstractComponent, Atendimento, ChartHelper, DateUtil, Empresa, EmpresaService, Franquia, FranquiaService, Pager, RelatorioAtendimentoService, SearchParams, UtilHelper } from 'lib-smart-core';
import { Constantes } from "../../../../environments/constantes";
import { RelatorioAntedimentoHelper } from './relatorio-atendimento.helper';

@Component({
  selector: 'relatorio-atendimento',
  templateUrl: './relatorio-atendimento.component.html'
})
export class RelatorioAtendimentoComponent extends AbstractComponent implements OnInit {

  @ViewChild('matDateRangePickerCancelAtendimento') matDateRangePickerCancelAtendimento: ElementRef;

  canvas: any;
  ctx: any;

  canvas2: any;
  ctx2: any;

  canvas3: any;
  ctx3: any;

  mostrarGrafico: Boolean = false;
  idFranquiaSelecionada = '';

  empresa: Empresa;

  franquia: Franquia;
  atendimentos: Atendimento[];

  historico: Atendimento;
  franquias: Franquia[];

  model: any = {
    ano: new Date().getFullYear(),
    mes: new Date().getMonth() + 1,
  };

  pager: Pager<Atendimento> = new Pager<Atendimento>({ perPage: 50 });

  chartPie: any;
  chartArea: any;
  chartBar: any;

  exporting: boolean = false;

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  loadingCharts: boolean = false;

  constructor(
    private franquiaService: FranquiaService,
    private relatorioAtendimentoService: RelatorioAtendimentoService,
    private empresaService: EmpresaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setMonth(endDate.getMonth() - 1);
    this.range.setValue({ start: startDate, end: endDate });
  }

  ngAfterViewInit() {
    this.canvas2 = document.getElementById('relatorio-atendimentos-atendentes');
    this.ctx2 = this.canvas2.getContext('2d');

    this.canvas3 = document.getElementById('relatorio-tipos-atendimento');
    this.ctx3 = this.canvas3.getContext('2d');

    let idEmpresa: string = super.getIDEmpresaSession();
    if (idEmpresa) {
      this.getEmpresa(idEmpresa);
      this.getFranquiasPorEmpresa(idEmpresa);
    }
  }

  pagerReport() {
    this.searchParams = {
      empresa: super.getIDEmpresaSession(),
      startDate: DateUtil.toFormart(this.range.get('start').value, 'yyyy-MM-dd'),
      endDate: DateUtil.toFormart(this.range.get('end').value, 'yyyy-MM-dd'),
      franquia: this.idFranquiaSelecionada,
    }
    if (!!this.searchParams.startDate && !!this.searchParams.endDate) {
      this.relatorioAtendimentoService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
        next: (pager: Pager<Atendimento>) => {
          this.pager = pager;
        },
        error: (err) => this.alertService.error(err.error.message)
      });
    }
  }

  public loadCustomDateRange(event) {
    const days = +event.target.value;

    if (!!days && days === 0) {
      return;
    }

    let startDate = new Date();
    let endDate = new Date();
    const daysOff = {
      7: DateUtil.getDateAddDays(-7),
      15: DateUtil.getDateAddDays(-15),
      30: DateUtil.getDateAddDays(-30),
      90: DateUtil.getDateAddDays(-90),
      180: DateUtil.getDateAddDays(-180),
      365: DateUtil.getDateAddDays(-365),
    };
    startDate = daysOff[days];

    this.matDateRangePickerCancelAtendimento.nativeElement.click();

    this.range.setValue({ start: startDate, end: endDate });
    this.loadPage(1);
  }

  private buildCharts() {
    this.loadingCharts = true;
    return this.buildArrayChartsRecursive(1, 5000, this.searchParams)
      .then((list: Atendimento[]) => {
        this.setupChartBars(list);
        this.setupChartPie(list);
        this.loadingCharts = false;
      });
  }

  private buildArrayChartsRecursive(page: number, perPage: number, searchParams: Partial<SearchParams>, total: number = 0, list: Array<Atendimento> = new Array<Atendimento>()): Promise<Atendimento[]> {
    return this.relatorioAtendimentoService.getAllPagerQuerySearch(page, perPage, null, searchParams)
      .toPromise()
      .then((pager: Pager<Atendimento>) => {
        list.push(...pager.list.map(elem => elem));
        return ((page * perPage) >= pager.total) ? list : this.buildArrayChartsRecursive((page + 1), perPage, searchParams, pager.total, list)
      });
  }

  setupChartBars(list: Array<Atendimento>) {

    if (!!this.chartBar) {
      this.chartBar.destroy();
    }

    const map: Map<string, number> = new Map<string, number>();
    const mapName: Map<string, string> = new Map<string, string>();

    list.forEach((atendimento: Atendimento) => {
      atendimento.tipoAtendimentos.forEach(element => {
        const key: string = element._id;
        if (map.has(key)) {
          map.set(key, +map.get(key) + 1);
        } else {
          map.set(key, 1);
          mapName.set(key, element.nome);
        }
      });
    });

    const labels = [];
    const data = []
    map.forEach((value: number, key: string) => {
      labels.push(mapName.get(key));
      data.push(value);
    });


    this.chartBar = new Chart(this.ctx3, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'Total',
          data: data,
          backgroundColor: ['#fd7272', '#e55799', '#00ccbd', '#edad58', '#b878d1', '#3179ce', '#fd7272', '#e55799', '#00ccbd', '#edad58', '#b878d1', '#3179ce', '#fd7272', '#e55799', '#00ccbd', '#edad58', '#b878d1', '#3179ce', '#fd7272', '#e55799', '#00ccbd', '#edad58', '#b878d1'],
          hoverOffset: 4
        }] as any
      },
      options: {
        legend: { display: data.length > 5 ? false : true, position: 'top' },
        scales: {
          yAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              stepSize: 1,
            },
          }]
        }
      } as any
    });

  }

  setupChartPie(list: Array<Atendimento>) {

    if (!!this.chartPie) {
      this.chartPie.destroy();
    }

    const map: Map<string, number> = new Map<string, number>();
    const mapName: Map<string, string> = new Map<string, string>();

    list.forEach((atendimento: Atendimento) => {
      const key: string = atendimento.usuario._id;
      if (map.has(key)) {
        map.set(key, +map.get(key) + 1);
      } else {
        map.set(key, 1);
        mapName.set(key, atendimento.usuario.nome);
      }
    });

    const labels = [];
    const data = []
    const colors = []
    map.forEach((value: number, key: string) => {
      labels.push(mapName.get(key));
      data.push(value);
      colors.push(ChartHelper.getRandomColor());
    });

    this.chartPie = new Chart(this.ctx2, {
      type: 'pie',
      data: {
        labels,
        datasets: [{
          label: 'Atendimentos realizados por Atendente',
          data,
          backgroundColor: colors,
          hoverOffset: 4
        }]
      },
      options: {
        legend: { display: data.length > 5 ? false : true, position: 'top' },
      } as any
    });
  }

  getEmpresa(id: string) {
    this.empresaService.getById(id).subscribe(
      empresa => {
        this.empresa = empresa;
      },
      err => this.alertService.error(err.error.message)
    );
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      franquias => {
        this.franquias = franquias;
        this.franquia = JSON.parse(localStorage.getItem(Constantes.currentFranquia));
        if (this.franquia != undefined) {
          this.idFranquiaSelecionada = this.franquia._id;
        }
        this.pagerReport();
        this.buildCharts();

      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getDuracao(dataInicio: Date, dataFim: Date) {
    return UtilHelper.getDuracao(dataInicio, dataFim);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.pagerReport();
    if (page === 1) {
      this.buildCharts();
    }
  }

  exportarCSV() {
    this.exporting = true;
    new RelatorioAntedimentoHelper(this.relatorioAtendimentoService).reportCSVAtendimentos(this.searchParams)
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

  exportarExcel() {
    this.exporting = true;
    new RelatorioAntedimentoHelper(this.relatorioAtendimentoService).reportXLSXAtendimentos(this.searchParams)
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

  exportarPDF() {
    this.exporting = true;
    new RelatorioAntedimentoHelper(this.relatorioAtendimentoService).reportPDFSenhas(this.searchParams, this.chartBar, this.chartPie)
      .then(() => this.exporting = false)
      .finally(() => this.exporting = false)
      .catch(() => this.exporting = false);
  }

}