<div class="row">
  <div class="col-lg-12">
    <div class="images">
      <div class="images__header">
        <div>
          <h3>Imagens Adicionais</h3>
          <p>Crie imagens especificas para estabelecimentos</p>
        </div>
        <div>
          <a class="btn btn-primary btn-xs" [routerLink]="['/config/imagens/add/', empresa?._id]">
            <i class="fa fa-plus"></i> Adicionar Imagem
          </a>
        </div>
      </div>
      <div class="images__body">
        <div class="row" style="display: flex; flex-wrap: wrap;">

          <div class="col-lg-12" *ngIf="!empresa?.images?.length">
            <div class="widget-text-box text-center">
              <p class="p-y-1">
                <i class="fa fa-cubes font-size-46 line-height-1 text-primary"></i>
              </p>
              <p>
                <strong>Imagens Adicionais</strong>
              </p>
              <p class="m-b-4">Nenhum registro encontrado.</p>
            </div>
          </div>

          <div class="col-lg-4" *ngFor="let item of empresa.images; let index = index">
            <div class="widget-head-color-box images__widget-body images__widget-body--radius p-lg text-center">
              <img *ngIf="item.image" src="{{item.image}}" style="max-width: 200px" />
            </div>
            <div class="widget-text-box images__widget-bottom text-center">
              <div class="text-center">
                <button class="btn btn-xs btn-primary"
                  [routerLink]="['/config/imagens/edit/', index, 'empresa', empresa?._id]"><i class="fa fa-edit"></i>
                </button>
                <button (click)="removeImageAdd(item.index)" class="btn btn-xs btn-danger">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>