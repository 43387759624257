<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Mesa</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li>
        <a [routerLink]="['/playlist']">Playlist</a>
      </li>
      <li class="active">
        <strong *ngIf="isNew">Adicionar</strong>
        <strong *ngIf="!isNew">Alterar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">

        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
            <div class="form-group">
              <label>Nome</label>
              <input type="text" placeholder="Insira o Nome da Playlist" class="form-control" name="nome" id="nome"
                #nome="ngModel" [(ngModel)]="playlist.nome" required>
            </div>
            <div class="form-group">
              <label>Descrição</label>
              <input type="text" placeholder="Insira Descrição da Mesa" class="form-control" name="descricao"
                id="descricao" #descricao="ngModel" [(ngModel)]="playlist.descricao" required>
            </div>
            <div class="form-group">
              <label>URL</label>
              <input type="text" placeholder="Insira URL da Mesa" class="form-control" name="url" id="url"
                #descricao="ngModel" [(ngModel)]="playlist.url" required>
            </div>
            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!f.form.valid">Salvar</button>
              <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>