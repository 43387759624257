import { Component, Injector, OnInit } from '@angular/core';
import { NavigationStart } from '@angular/router';
import { AbstractComponent, AuthenticationService, ConstantService, Empresa, EmpresaService, Franquia, Usuario, UsuarioService, UtilHelper } from 'lib-smart-core';
import { timer } from 'rxjs';
import { Constantes } from '../../../environments/constantes';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'menu-component',
  templateUrl: './menu.component.html'
})
export class MenuComponent extends AbstractComponent implements OnInit {

  model: any = {};

  imageDefaultProfile: string = UtilHelper.no_image_user;

  userLogged: Usuario;
  empresa: Empresa;
  userAPI: Usuario;

  smartFilas: boolean = false;
  smartFilasLite: boolean = false;
  smartAvalia: boolean = false;
  smartPager: boolean = false;
  locationEnabled: boolean = false;
  apiEnabled: boolean = false;

  FRANQUIA_SESSION: Franquia;

  apiRef: string = 'https://apis.smartline.com.br/';
  postmanDoc: string = 'https://drive.google.com/file/d/1cZRQf739KRI8ltpQSLZcgSFPF5R1sCNS/view?usp=drive_link';

  constructor(
    private constantService: ConstantService,
    private usuarioService: UsuarioService,
    private empresaService: EmpresaService,
    private authenticationService: AuthenticationService,
    public injector: Injector
  ) {
    super(injector);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.constantService.getFranquiaSession().subscribe(franquia => this.FRANQUIA_SESSION = franquia);
      }
    });
  }

  ngOnInit(): void {
    this.empresa = {} as Empresa;
    this.userLogged = super.getCurrentUserUser();
    this.FRANQUIA_SESSION = JSON.parse(localStorage.getItem(Constantes.currentFranquia));
    if (this.userLogged) {
      if (this.userLogged.image != "false" && this.userLogged.image != null && this.userLogged.image != undefined) {
        this.imageDefaultProfile = UtilHelper.getUsuarioImage(this.userLogged);
      }
      if (this.userLogged.empresa && this.userLogged.empresa._id) {
        this.getEmpresa();
      }
    }
  }

  logout() {
    this.authenticationService.logout();
    window.location.href = '/';
  }

  goToSmartPager() {
    const urlRedirect = `${environment.smartPagerUrl}/redirect?email=${this.getEmailUsuarioLogado()}&idEmpresa=${this.empresa._id}`;
    window.open(urlRedirect, '_blank');
  }

  goToSuporte() {
    window.open('https://suporte.smartline.com.br', '_blank');
  }

  getEmpresa(): any {
    this.empresaService.getById(this.userLogged.empresa._id).subscribe({
      next: (empresa: Empresa) => {
        this.empresa = empresa;
        this.smartFilas = empresa.smartFilas;
        this.smartFilasLite = empresa.smartFilasLite;
        this.smartAvalia = empresa.smartAvalia;
        this.smartPager = empresa.smartPager;
        this.locationEnabled = empresa.locationEnabled;
        this.apiEnabled = empresa.apiEnabled;
        if (this.apiEnabled) {
          this.getAPIUser();
        }
      },
      error: err => this.alertService.error(err.error.message)
    });
  }

  private getAPIUser() {
    this.usuarioService.getUserAPI().subscribe({
      next: (userAPI: Usuario) => this.userAPI = userAPI,
      error: (err) => this.alertService.error(err.error.message)
    });
  }

  alterarSenha() {
    this.model = {
      senhaAtual: '',
      novaSenha: '',
      confirmaNovaSenha: ''
    }
  }

  confirmarAlterarSenha(): void {
    this.usuarioService.updateSenhaMain(super.getIDCurrentUserSession(), this.model).subscribe(
      () => {
        this.alertService.success('Senha alterada com sucesso! Sua sessão irá expirar em 5 segundos e então faça login novamente!');
        timer(7000).subscribe(() => {
          this.logout();
        });
      },
      err => {
        this.alertService.error(err.error.message)
      }
    );
  }

  public get showReports() {
    let result = false;
    let emailLogged = this.userLogged.email;
    let impersonate: boolean = Boolean(super.getImpersonateSession());
    if (impersonate) {
      const emails = super.emailOwners;
      for (let i = 0; i < emails.length; i++) {
        if (emailLogged.startsWith(emails[i])) {
          result = true;
          break;
        }
      }
    } else {
      result = (this.isAdmin() || this.isManager());
    }
    return result;
  }

}