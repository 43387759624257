import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, Franquia, FranquiaService, Mesa, MesaService } from 'lib-smart-core';

@Component({
  selector: 'mesa-forms',
  templateUrl: './mesa-forms.component.html'
})
export class MesaFormsComponent extends AbstractComponent implements OnInit {

  idFranquia: string;
  franquia: Franquia;
  mesa: Mesa;
  isNew: boolean = true;

  constructor(
    private mesaService: MesaService,
    private franquiaService: FranquiaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.mesa = {} as Mesa;
    this.mesa.empresa = { _id: this.getIDEmpresaSession() } as Empresa;
    let idFranquia: string = super.getParam('idFranquia');
    if (idFranquia) {
      this.mesa = {} as Mesa;
      this.mesa.franquia = { _id: idFranquia } as Franquia;
      this.mesa.empresa = { _id: this.getIDEmpresaSession() } as Empresa;
      this.idFranquia = idFranquia;
      this.getFranquiaPorId(idFranquia);
    } else {
      let id: string = super.getParam('id');
      if (id) {
        this.isNew = false;
        this.getFilaPorId(id.toString());
      }
    }
  }

  getFranquiaPorId(idFranquia: string) {
    return this.franquiaService.getById(idFranquia).subscribe(
      (franquia: Franquia) => {
        return this.franquia = franquia
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  getFilaPorId(id: string) {
    return this.mesaService.getById(id).subscribe(
      (mesa) => {
        this.mesa = mesa
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  onSubmit(): void {
    if (this.isNew) {
      this.mesaService.create(this.mesa)
        .subscribe(
          mesa => {
            this.alertService.success('Operação realizada com sucesso');
            super.goBack();
          },
          err => {
            this.alertService.error(err.error.message);
          }
        );
    } else {
      this.mesaService.update(this.mesa)
        .subscribe(
          mesa => {
            this.alertService.success('Operação realizada com sucesso');
            super.goBack();
          },
          err => {
            this.alertService.error(err.error.message);
          }
        );
    }

  }

}