import { saveAs } from 'file-saver';
import { RelatorioTipoAtendimentoService, SearchParams } from 'lib-smart-core';
import * as moment from 'moment'; // add this 1 of 4
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';

export class RelatorioTipoAtendimentoHelper {

  private itensPerPage: number = 5000;

  constructor(
    private relatorioTipoAtendimentoService: RelatorioTipoAtendimentoService,
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  public reportCSVTiposAtendimento(searchParams: Partial<SearchParams>) {
    return this.buildingArrayTipoAtendimentoBarChart(searchParams)
      .then((list: any) => {
        this.generateCSV(list, searchParams);
      });
  }

  private buildingArrayTipoAtendimentoBarChart(searchParams: Partial<SearchParams>) {
    return this.relatorioTipoAtendimentoService.getAllPagerQuerySearch(1, this.itensPerPage, null, searchParams).toPromise();
  }

  private generateCSV(items: any[], searchParams: Partial<SearchParams>) {
    let data: Array<any> = new Array<any>();
    items.forEach((item: any) => {
      let name = item.name;
      let count = item.count;
      data.push({ name, count });
    });

    const header = Object.keys({
      name: 'item.name',
      count: 'item.count',
    });
    let csv = data.map(row => header.map(fieldName => row[fieldName]).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    const startDateSplited = String(searchParams["startDate"]).split('-');
    const startDateDate = new Date(Date.UTC(+startDateSplited[0], +startDateSplited[1] - 1, +startDateSplited[2], 0, 0, 0));
    startDateDate.setTime(startDateDate.getTime() + startDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const endDateSplited = String(searchParams["endDate"]).split('-');
    const endDateDate = new Date(Date.UTC(+endDateSplited[0], +endDateSplited[1] - 1, +endDateSplited[2], 0, 0, 0));
    endDateDate.setTime(endDateDate.getTime() + endDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const startDate = moment(startDateDate).format('DDMMYYYY');
    const endDate = moment(endDateDate).format('DDMMYYYY');

    const blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `Ranking de Tipos de Atendimento ${startDate}_${endDate}.csv`);
  }

  public reportXLSXTiposAtendimento(searchParams: Partial<SearchParams>) {
    return this.buildingArrayTipoAtendimentoBarChart(searchParams)
      .then((list: any) => {
        console.log(list)
        this.generateXLSX(list, searchParams);
      });
  }

  private generateXLSX(items: any[], searchParams: Partial<SearchParams>) {
    let data: Array<any> = new Array<any>();
    items.forEach((item: any) => {
      let name = item.name;
      let count = item.count;
      data.push({ name, count });
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const startDateSplited = String(searchParams["startDate"]).split('-');
    const startDateDate = new Date(Date.UTC(+startDateSplited[0], +startDateSplited[1] - 1, +startDateSplited[2], 0, 0, 0));
    startDateDate.setTime(startDateDate.getTime() + startDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const endDateSplited = String(searchParams["endDate"]).split('-');
    const endDateDate = new Date(Date.UTC(+endDateSplited[0], +endDateSplited[1] - 1, +endDateSplited[2], 0, 0, 0));
    endDateDate.setTime(endDateDate.getTime() + endDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const startDate = moment(startDateDate).format('DDMMYYYY');
    const endDate = moment(endDateDate).format('DDMMYYYY');

    XLSX.writeFile(wb, `Ranking de Tipos de Atendimento ${startDate}_${endDate}.xlsx`);
  }

  reportPDFLeads(searchParams: Partial<SearchParams>) {
    return this.buildingArrayTipoAtendimentoBarChart(searchParams)
      .then((list: any) => {
        this.generatePDFRelatorioLeads(searchParams, list);
      });
  }

  generatePDFRelatorioLeads(searchParams: Partial<SearchParams>, list: any[]) {

    let body: any[] = [
      [
        { text: 'Tipo de Atendimento', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Total', style: 'tableHeader', bold: true, fontSize: 8 },
      ],
    ];

    list.forEach((item: any) => {
      body.push([
        { text: item.name, fontSize: 8 },
        { text: item.count, fontSize: 8 },
      ]);
    });

    const startDateSplited = String(searchParams["startDate"]).split('-');
    const startDateDate = new Date(Date.UTC(+startDateSplited[0], +startDateSplited[1] - 1, +startDateSplited[2], 0, 0, 0));
    startDateDate.setTime(startDateDate.getTime() + startDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const endDateSplited = String(searchParams["endDate"]).split('-');
    const endDateDate = new Date(Date.UTC(+endDateSplited[0], +endDateSplited[1] - 1, +endDateSplited[2], 0, 0, 0));
    endDateDate.setTime(endDateDate.getTime() + endDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const startDate = moment(startDateDate).format('DD/MM/YYYY');
    const endDate = moment(endDateDate).format('DD/MM/YYYY');

    const titleDocument = `Relatório de Tipos de Atendimentos - ${startDate}_${endDate}`;

    let docDefinition = {
      // pageOrientation: 'landscape',
      header: { text: 'Relatório de Atendimentos', bold: true, margin: [0, 20, 0, 8], alignment: 'center' },
      footer: {
        columns: [
          { text: titleDocument, fontSize: 10, bold: true, margin: [10, 20, 10, 8] },
          { text: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'), fontSize: 10, bold: true, margin: [10, 20, 10, 8], alignment: 'right' }
        ]
      },
      content: [
        { text: 'Filtro: ' + startDate + ' - ' + endDate, style: 'header', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        // { image: barChartURL, alignment: 'center' },

        { text: '', style: 'header', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        { text: `Relatórios de Atendimentos (${list.length})`, style: 'header', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: body
          },
          layout: 'lightHorizontalLines'
        },
      ]
    };

    // pdfMake.createPdf(docDefinition).open();
    // pdfMake.createPdf(docDefinition).open({}, window);

    pdfMake.createPdf(docDefinition).download(titleDocument + '.pdf');
  }

}