<div class="row">
  <div class="col-lg-12">
    <div class="images">
      <div class="images__header">
        <div>
          <h3>Templates QRCode Adicionais</h3>
          <p>Adicione template de QRCode especificos para estabelecimentos</p>
        </div>
        <div>
          <a class="btn btn-primary btn-xs" [routerLink]="['/config/qrcode-template/add/', empresa?._id]">
            <i class="fa fa-plus"></i> Adicionar template
          </a>
        </div>
      </div>
      <div class="images__body">
        <div class="row" style="display: flex; flex-wrap: wrap;">
          <div class="col-lg-12" *ngIf="!empresa?.qrCodeTemplates?.length">
            <div class="widget-text-box text-center">
              <p class="p-y-1">
                <i class="fa fa-cubes font-size-46 line-height-1 text-primary"></i>
              </p>
              <p>
                <strong>QRCode Templates </strong>
              </p>
              <p class="m-b-4">Nenhum registro encontrado.</p>
            </div>
          </div>

          <div class="col-lg-4" *ngFor="let item of empresa.qrCodeTemplates; let index = index">
            <div class="images__widget">
              <div class="images__widget-header">
                {{item.name}}
              </div>
              <div class="widget-head-color-box images__widget-body p-lg text-center">

                <img *ngIf="item.image" src="{{item.image}}" style="max-width: 200px; margin-bottom: 20px;" />
                <div class="badge badge-primary p-2 px-3">{{item.type}}</div>
              </div>
              <div class="widget-text-box images__widget-bottom text-center">
                <div class="text-center">
                  <button class="btn btn-xs btn-primary"
                    [routerLink]="['/config/qrcode-template/edit/', index, 'empresa', empresa?._id]"><i
                      class="fa fa-edit"></i>
                  </button>
                  <button (click)="removeQRCodeTemplate(item.index)" class="btn btn-xs btn-danger">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>