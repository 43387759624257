import { Component, Injector } from '@angular/core';
import { AbstractComponent, Aviso, AvisoService, Pager } from 'lib-smart-core';

@Component({
  selector: 'aviso-component',
  templateUrl: './aviso.component.html'
})
export class AvisoComponent extends AbstractComponent {

  pager: Pager<Aviso> = new Pager<Aviso>({ perPage: 25 });

  avisoSelecionado: Aviso;

  constructor(
    private avisoService: AvisoService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getAvisosPorGrupo();
  }

  getAvisosPorGrupo() {
    return this.avisoService.getPagerAvisosPorEmpresa(this.pager.page, this.pager.perPage, super.getIDEmpresaSession()).subscribe(
      (pager: Pager<Aviso>) => {
        this.pager = pager;
      },
      err => this.alertService.error(err.error.message)
    )
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getAvisosPorGrupo();
  }

  selecionar(aviso: Aviso) {
    this.avisoSelecionado = aviso;
  }

  delete() {
    return this.avisoService.delete(this.avisoSelecionado._id).subscribe(
      () => {
        this.getAvisosPorGrupo();
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    )
  }

  ativar(aviso: Aviso) {
    this.avisoService.ativar(aviso._id).subscribe(
      () => {
        this.getAvisosPorGrupo();
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    );
  }

  desativar(aviso: Aviso) {
    this.avisoService.desativar(aviso._id).subscribe(
      () => {
        this.getAvisosPorGrupo();
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    );
  }

}