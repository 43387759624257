<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Fila</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li>
        <a [routerLink]="['/franquia']">Estabelecimentos</a>
      </li>
      <li>
        <a [routerLink]="['/franquia']">{{franquia?.nome}}</a>
      </li>
      <li class="active">
        <strong>Filas</strong>
      </li>
    </ol>
  </div>
</div>
<div class="row wrapper wrapper-content animated fadeIn">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>Filas | Estabelecimento {{franquia?.nome}}</h5>
        <div class="ibox-tools">
          <a [routerLink]="['/fila/add/franquia', idFranquia]" class="btn btn-primary btn-xs">
            <i class="fa fa-plus"></i> Adicionar</a>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th colspan="2">#</th>
                <th>Nome</th>
                <!-- <th>Sigla</th> -->
                <th>Prefixo</th>
                <th>Chamada</th>
                <th class="text-right" width="12%">Totem/Celular</th>
                <th class="text-right" width="12%">Dados do Cliente</th>
                <th class="text-right" width="12%">Chamada Manual</th>
                <th class="text-right" width="12%">Normal/Preferencial</th>
                <th class="text-right" width="10%">Ações</th>
              </tr>
            </thead>
            <tbody cdkDropList (cdkDropListDropped)="drop($event)">
              <tr *ngFor="let fila of filas" cdkDrag>
                <th cdkDragHandle>
                  <i class="fa fa-bars"></i>
                </th>
                <th>{{fila.ordem}}</th>
                <th>{{fila.nome}}</th>
                <!-- <td>{{fila.sigla}}</td> -->
                <td>{{fila.prefixo !== '' ? fila.prefixo : 'Nenhum prefixo'}}</td>
                <td>{{fila.tipoChamada !== '' ? getNomeChamada(fila.tipoChamada) : 'Nenhum tipo de chamada'}}
                </td>
                <td class="text-right">
                  <span class="label label-primary" *ngIf="fila.visivelTablet">Visível Tablet</span>
                  <span class="label label-warning" *ngIf="!fila.visivelTablet">Não Visível
                    Tablet</span>
                </td>
                <td class="text-right">
                  <span class="label label-primary" *ngIf="fila.dadosContato">Sim</span>
                  <span class="label label-warning" *ngIf="!fila.dadosContato">Não</span>
                </td>
                <td class="text-right">
                  <span class="label label-primary" *ngIf="fila.chamadaManual">Ativada</span>
                  <span class="label label-warning" *ngIf="!fila.chamadaManual">Desativada</span>
                </td>
                <td class="text-right">
                  <span class="label label-primary" *ngIf="fila.preferencial">Ativado</span>
                  <span class="label label-warning" *ngIf="!fila.preferencial">Desativado</span>
                </td>
                <td class="text-right">
                  <a class="btn btn-white btn-sm btn-bitbucket" title="Editar" [routerLink]="['/fila/edit/', fila._id]">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a class="btn btn-danger btn-sm btn-bitbucket" title="Remover" data-toggle="modal" href="#deleteModel"
                    (click)="selecionar(fila)">
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="deleteModel" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Remover Fila</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Esta ação não poderá ser desfeita. Tem certeza que deseja excluir a fila
            <b>{{filaSelecionada?.nome}}</b>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="delete()">Confirmar</button>
      </div>
    </div>
  </div>
</div>