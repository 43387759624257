import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, ConstantService, Franquia, FranquiaService, Localizacao, LocalizacaoService, Mesa, MesaService, SenhaService } from 'lib-smart-core';
import { Constantes } from '../../../environments/constantes';

@Component({
  selector: 'atendimento',
  templateUrl: './atendimento.component.html'
})
export class AtendimentoComponent extends AbstractComponent implements OnInit {

  idFranquiaSelecionada = '';
  idMesaSelecionada = '';
  idLocalizacaoSelecionada = '';

  franquias: Franquia[];
  mesas: Mesa[];
  localizacoes: Localizacao[];

  locationEnabled: boolean = false;

  constructor(
    private franquiaService: FranquiaService,
    private constantService: ConstantService,
    private mesaService: MesaService,
    private localizacaoService: LocalizacaoService,
    private senhaService: SenhaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    console.log('carregando atendimento inicio');
    let currentFranquia = JSON.parse(localStorage.getItem(Constantes.currentFranquia));
    let currentMesa = JSON.parse(localStorage.getItem(Constantes.currentMesa));
    let currentUser = JSON.parse(localStorage.getItem(Constantes.currentUser));

    if (currentFranquia && currentMesa) {
      this.router.navigate(['/atendimento-filas', 0]);
    } else {
      let idEmpresa: string = super.getIDEmpresaSession();
      if (idEmpresa) {
        if (currentUser.usuario.admin === false && currentUser.usuario.manager === false) {
          this.franquias = new Array<Franquia>();
          this.franquias.push(...currentUser.usuario.franquias);
          // this.getMesasPorFranquia(currentUser.usuario.franquia._id);
          // this.idFranquiaSelecionada = currentUser.usuario.franquia._id;
        } else if (currentUser.usuario.manager === true) {
          this.franquias = new Array<Franquia>();
          this.franquias.push(...currentUser.usuario.franquias);
          // this.getMesasPorFranquia(currentUser.usuario.franquia._id);
          // this.idFranquiaSelecionada = currentUser.usuario.franquia._id;
        } else {
          this.getFranquiasPorEmpresa(idEmpresa);
        }

        this.setupLocationInit();

      }
    }
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      franquias => {
        this.franquias = franquias;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getMesasPorFranquia(idFranquia: string) {
    if (idFranquia === '') {
      this.mesas = [];
    } else {
      this.mesaService.getMesasPorFranquia(idFranquia).subscribe(
        mesas => {
          this.mesas = mesas;
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
    }
  }

  getLocalizacaoPorFranquia(idFranquia: string) {
    if (idFranquia === '') {
      this.localizacoes = [];
    } else {
      this.localizacaoService.listByFranquia(idFranquia).subscribe(
        localizacoes => {
          this.localizacoes = localizacoes;
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
    }
  }

  onChangeFranquia(event: Event): void {
    const value: string = (<HTMLSelectElement>event.srcElement).value;
    this.getMesasPorFranquia(value);
    this.getLocalizacaoPorFranquia(value);
  }

  setupLocationInit() {
    this.locationEnabled = super.getCurrentUserUser()?.empresa?.locationEnabled || false;
  }

  async continuar() {

    if (this.idFranquiaSelecionada == null || this.idFranquiaSelecionada == '' || this.idFranquiaSelecionada == undefined) {
      this.alertService.warning('O campo Franquia deve ser selecionado');
      return;
    }

    if (this.idMesaSelecionada == null || this.idMesaSelecionada == '' || this.idMesaSelecionada == undefined) {
      this.alertService.warning('O campo Mesa/Guichê deve ser selecionado');
      return;
    }

    if (this.locationEnabled) {
      if (this.idLocalizacaoSelecionada == null || this.idLocalizacaoSelecionada == '' || this.idLocalizacaoSelecionada == undefined) {
        this.alertService.warning('O campo Localização deve ser selecionado');
        return;
      }
    }

    this.franquiaService.getById(this.idFranquiaSelecionada).subscribe(
      franquia => {

        if (franquia.bloqueado === true) {
          this.alertService.warning('Estabelecimento BLOQUEADO!');
          return;
        }

        this.constantService.setFranquiaSession(franquia);
        localStorage.setItem(Constantes.currentFranquia, JSON.stringify(franquia));

        this.mesaService.getById(this.idMesaSelecionada).subscribe(
          mesa => {
            this.constantService.setMesaSession(mesa);
            localStorage.setItem(Constantes.currentMesa, JSON.stringify(mesa));

            if (this.locationEnabled && !!this.idLocalizacaoSelecionada) {
              this.localizacaoService.getById(this.idLocalizacaoSelecionada).subscribe(
                (localizacao: Localizacao) => {
                  this.constantService.setLocalizacaoSession(localizacao);
                  localStorage.setItem(Constantes.currentLocalizacao, JSON.stringify(localizacao));

                  /**
                   * tipo o check-in
                   */
                  this.senhaService.gerarSenhaZeroParaLocalizacao(this.idFranquiaSelecionada, this.idLocalizacaoSelecionada).subscribe(
                    () => {
                      this.router.navigate(['/atendimento-filas', 0]);
                    }
                  );
                }
              );
            } else {
              this.router.navigate(['/atendimento-filas', 0]);
            }
          }
        );
      }
    );
  }

}
