import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { AvisoFormsComponent } from './aviso-forms.component';
import { AvisoComponent } from './aviso.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    AvisoComponent,
    AvisoFormsComponent,
  ],
  exports: [
    AvisoComponent,
    AvisoFormsComponent,
  ],
  providers: []
})

export class AvisoModule { }