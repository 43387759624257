import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { AppTabletComponent } from './app-tablet.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    AppTabletComponent
  ],
  exports: [
    AppTabletComponent
  ],
  providers: [
  ]
})
export class AppsModule { }
