import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Fila, FilaService, Franquia, FranquiaService } from 'lib-smart-core';

@Component({
  selector: 'fila-lista',
  templateUrl: './fila-lista.component.html',
  styleUrls: ['fila-lista.component.css'],
})
export class FilaListaComponent extends AbstractComponent implements OnInit {

  idFranquia: string;
  franquia: Franquia;
  filas: Fila[];

  filaSelecionada: Fila;

  constructor(
    private filaService: FilaService,
    private franquiaService: FranquiaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let idFranquia: string = super.getParam('idFranquia');
    if (idFranquia) {
      this.idFranquia = idFranquia;
      this.getFranquiaPorId(idFranquia.toString());

    }
  }

  getFranquiaPorId(idFranquia: string) {
    return this.franquiaService.getById(idFranquia).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia
        this.getFilasPorFranquia(idFranquia);
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  getFilasPorFranquia(idFranquia: string) {
    return this.filaService.getFilasPorFranquia(idFranquia).subscribe(
      (filas: Fila[]) => {
        this.filas = filas
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  selecionar(fila: Fila) {
    this.filaSelecionada = fila;
  }

  delete() {
    return this.filaService.delete(this.filaSelecionada._id).subscribe(
      () => {
        this.getFilasPorFranquia(this.idFranquia);
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    )
  }

  updateOrdemFilas() {

    const filasToUpdate: Array<Partial<Fila>> = new Array<Partial<Fila>>();
    this.filas.forEach((fila, index) => {
      filasToUpdate.push({
        _id: fila._id,
        ordem: (index + 1)
      });
    });

    return this.filaService.updateOrdemFilas(filasToUpdate).subscribe({
      next: () => {
        this.getFilasPorFranquia(this.idFranquia);
        this.alertService.success('Operação realizada com sucesso');
      },
      error: (err) => this.alertService.error(err.error.message)
    });
  }

  drop(event) {
    moveItemInArray(this.filas, event.previousIndex, event.currentIndex);
    this.updateOrdemFilas();
  }

}