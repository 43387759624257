import { Component, Injector, OnInit } from '@angular/core';
import { Howl } from 'howler';
import {
  AbstractComponent,
  Fila,
  FilaService,
  Franquia,
  FranquiaService,
} from 'lib-smart-core';

@Component({
  selector: 'fila-forms',
  templateUrl: './fila-forms.component.html',
})
export class FilaFormsComponent extends AbstractComponent implements OnInit {
  loading: boolean = false;

  idFranquia: string;
  franquia: Franquia;
  fila: Fila;
  isNew: boolean = true;

  isNewCampo: boolean = false;

  tipoChamadas: Array<string>;

  campo: {
    show: boolean;
    key: string;
    required: boolean;
  } = {} as any;

  campos: [
    {
      show: boolean;
      key: string;
      required: boolean;
    }
  ] = [] as any;

  constructor(
    private filaService: FilaService,
    private franquiaService: FranquiaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.fila = {} as Fila;
    this.fila.tipoVoz = 'MALE';
    this.fila.tipoChamada = '';
    this.fila.prefixo = '';
    this.fila.chamadaManualInicio = 1;
    this.fila.chamadaManual = false;
    this.fila.visivelTablet = true;
    this.fila.dadosContato = false;
    this.tipoChamadas = this.filaHelper.getTipoChamada(this.fila.tipoVoz);

    let idFranquia: string = super.getParam('idFranquia');
    if (idFranquia) {
      this.fila.franquia = { _id: idFranquia } as Franquia;
      this.idFranquia = idFranquia;
      this.getFranquiaPorId(idFranquia);
    } else {
      let id: string = super.getParam('id');
      if (id) {
        this.isNew = false;
        this.getFilaPorId(id.toString());
      } else {
        this.tipoChamadas = this.filaHelper.getTipoChamada('MALE');
      }
    }
  }

  getFranquiaPorId(idFranquia: string) {
    this.franquiaService.getById(idFranquia).subscribe(
      (franquia: Franquia) => {
        this.franquia = franquia;
      },
      (err) => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getFilaPorId(id: string) {
    this.filaService.getById(id).subscribe(
      (fila) => {
        this.tipoChamadas = this.filaHelper.getTipoChamada(fila.tipoVoz);
        this.fila = fila;
        this.franquia = fila.franquia;
        this.campos = this.fila.campos || ([] as any);
      },
      (err) => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getTipoChamadaPorTipoVoz(tipoVoz: string) {
    this.tipoChamadas = this.filaHelper.getTipoChamada(tipoVoz);
    this.fila.tipoChamada = '';
  }

  override getNomeChamada(tipoChamada: string) {
    return this.filaHelper.getNomeChamada(tipoChamada);
  }

  onSubmit(): void {
    this.fila.sigla = this.fila.nome.toUpperCase();
    if (this.fila.showCampos) {
      this.fila.campos = this.campos;
    }
    if (this.isNew) {
      this.filaService.create(this.fila).subscribe(
        (franquia) => {
          this.alertService.success('Operação realizada com sucesso');
          super.goBack();
        },
        (err) => {
          this.alertService.error(err.error.message);
        }
      );
    } else {
      this.filaService.update(this.fila).subscribe(
        (franquia) => {
          this.alertService.success('Operação realizada com sucesso');
          super.goBack();
        },
        (err) => {
          this.alertService.error(err.error.message);
        }
      );
    }
  }

  ativarChamadaManual() {
    this.fila.chamadaManual = true;
    this.fila.visivelTablet = false;
    this.fila.dadosContato = false;
    this.fila.preferencial = false;
  }

  desativarChamadaManual() {
    this.fila.chamadaManual = false;
  }

  ativarVisivelTablet() {
    this.fila.visivelTablet = true;
    this.fila.chamadaManual = false;
  }

  desativarVisivelTablet() {
    this.fila.visivelTablet = false;
  }

  ativarDadosContato() {
    this.fila.dadosContato = true;
    this.fila.chamadaManual = false;
  }

  desativarDadosContato() {
    this.fila.dadosContato = false;
  }

  ativarDadosContatoNome() {
    this.fila.showNome = true;
  }

  desativarDadosContatoNome() {
    this.fila.showNome = false;
    this.fila.campoNomeRequired = false;
  }

  ativarDadosContatoEmail() {
    this.fila.showEmail = true;
  }

  desativarDadosContatoEmail() {
    this.fila.showEmail = false;
    this.fila.campoEmailRequired = false;
  }

  ativarDadosContatoTelefone() {
    this.fila.showPhone = true;
  }

  desativarDadosContatoTelefone() {
    this.fila.showPhone = false;
    this.fila.campoPhoneRequired = false;
  }

  ativarPreferencial() {
    this.fila.chamadaManual = false;
    this.fila.preferencial = true;
  }

  desativarPreferencial() {
    this.fila.preferencial = false;
  }

  ativarCampos() {
    this.fila.showCampos = true;
  }

  desativarCampos() {
    this.fila.showCampos = false;
  }

  ativaDesativarCamposObrigatorios(campo: string) {
    this.fila[campo] = !this.fila[campo];
  }

  newItemCampo() {
    this.isNewCampo = true;
    this.indexSelected = -1;
    this.campo = {
      show: true,
      key: '',
      required: false,
    };
  }

  addItemCampo() {
    if (this.campos)
      if (!!this.campo) {
        if (this.indexSelected >= 0) {
          this.deleteItemCampo();
        }
        this.campos.push(this.campo);
      }
    this.campo = null;
    this.isNewCampo = false;
  }

  indexSelected: number = -1;

  selectItemCampo(index: number) {
    this.indexSelected = index;
  }

  editItemCampo(index: number, obj: any) {
    this.indexSelected = index;
    this.isNewCampo = true;
    this.campo = obj;
  }

  deleteItemCampo() {
    this.campos.splice(this.indexSelected, 1);
    this.indexSelected = -1;
  }

  emitSound() {
    const tipoVoz = this.fila.tipoVoz;
    const audio = tipoVoz.toLowerCase();
    const tipoChamada = this.fila.tipoChamada;
    const sound = new Howl({
      src: [`../../assets/audiosenha/${audio}/words/${tipoChamada}.mp3`],
    });
    sound.play();
  }

  toggleCustomCampoObrigatorio() {
    this.campo.required = !this.campo.required;
  }

  keyPressChangeLettersUpperCase(event: Event) {
    this.fila.prefixo = event.target['value'].toUpperCase();
  }
}
