import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { AvaliacaoModule } from '../avaliacao/avaliacao.module';
import { RelatorioAtendimentoComponent } from './relatorio-atendimento/relatorio-atendimento.component';
import { RelatorioAvaliacaoComponent } from './relatorio-avaliacao/relatorio-avaliacao.component';
import { RelatorioClientesComponent } from './relatorio-clientes/relatorio-clientes.component';
import { RelatorioRankingAtendimentoComponent } from './relatorio-ranking-atendimento/relatorio-ranking-atendimento.component';
import { RelatorioSenhaComponent } from './relatorio-senha/relatorio-senha.component';
import { RelatorioTipoAtendimentoComponent } from './relatorio-tipos-atendimento/relatorio-tipo-atendimento.component';
import { RelatorioComponent } from "./relatorio.component";

@NgModule({
  imports: [
    AvaliacaoModule,
    SharedModule
  ],
  declarations: [
    RelatorioComponent,
    RelatorioAvaliacaoComponent,
    RelatorioAtendimentoComponent,
    RelatorioSenhaComponent,
    RelatorioClientesComponent,
    RelatorioRankingAtendimentoComponent,
    RelatorioTipoAtendimentoComponent
  ],
  exports: [
    RelatorioComponent,
    RelatorioAvaliacaoComponent,
    RelatorioAtendimentoComponent,
    RelatorioSenhaComponent,
    RelatorioClientesComponent,
    RelatorioRankingAtendimentoComponent,
    RelatorioTipoAtendimentoComponent
  ],
  providers: [
  ]
})
export class RelatorioModule { }