<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Fila</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li>
        <a [routerLink]="['/franquia']">Estabelecimentos</a>
      </li>
      <li>
        <a href="#">{{franquia?.nome}}</a>
      </li>
      <li>
        <a href="#">Filas</a>
      </li>
      <li class="active">
        <strong *ngIf="isNew">Adicionar</strong>
        <strong *ngIf="!isNew">Alterar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">

        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" #filaForm="ngForm" novalidate>
            <h2>Dados da Fila</h2>
            <hr>
            <div class="form-group">
              <label>Nome</label>
              <input type="text" placeholder="Insira o Nome da Fila" class="form-control" name="nome" id="nome"
                #nome="ngModel" [(ngModel)]="fila.nome" required>
            </div>
            <div class="form-group">
              <label>Prefixo</label>
              <input type="text" placeholder="Insira o prefixo" class="form-control" name="prefixo" id="prefixo"
                #prefixo="ngModel" [(ngModel)]="fila.prefixo" (change)="keyPressChangeLettersUpperCase($event)" maxlength="2">

            </div>

            <div class="form-group">
              <label>Tipo de Voz</label>
              <select [(ngModel)]="fila.tipoVoz" class="form-control" name="tipoVoz" id="tipoVoz" #tipoVoz="ngModel"
                (ngModelChange)="getTipoChamadaPorTipoVoz($event)" [ngModelOptions]="{standalone: true}" required>
                <option value="MALE">Masculino</option>
                <option value="FEMALE">Feminino</option>
              </select>
            </div>

            <div class="form-group">
              <label>Chamada</label>
              <select [(ngModel)]="fila.tipoChamada" class="form-control" name="tipoChamada" id="tipoChamada"
                #tipoChamada="ngModel" [ngModelOptions]="{standalone: true}" required>
                <option [value]="''">Nenhuma</option>
                <option *ngFor="let item of tipoChamadas" [value]="item">{{getNomeChamada(item)}}</option>
              </select>

              <button type="button" class="btn btn-primary" (click)="emitSound()">
                <i class="fa fa-play mr-1"></i>Testar Chamada
              </button>
            </div>
            <br><br>
            <h2>Configurações</h2>
            <hr>

            <div class="form-group d-flex align-items-center">
              <div class="m-r-sm">
                <a *ngIf="fila.visivelTablet" href="javascript:;" (click)="desativarVisivelTablet()">
                  <i class="fa fa-toggle-on fa-3x"></i>
                </a>
                <a *ngIf="!fila.visivelTablet" href="javascript:;" (click)="ativarVisivelTablet()">
                  <i class="fa fa-toggle-off fa-3x"></i>
                </a>
              </div>
              <div>
                <h3 class="no-margins">Visível para o Cliente</h3>
                <p class="no-paddings no-margins">Quando ativo, a fila será exibida no totem e no celular do cliente.</p>
              </div>
            </div>

            <hr class="hr-line-dashed" />

            <div class="form-group d-flex align-items-center">
              <div class="m-r-sm">
                <a *ngIf="fila.chamadaManual" href="javascript:;" (click)="desativarChamadaManual()">
                  <i class="fa fa-toggle-on fa-3x"></i>
                </a>
                <a *ngIf="!fila.chamadaManual" href="javascript:;" (click)="ativarChamadaManual()">
                  <i class="fa fa-toggle-off fa-3x"></i>
                </a>
              </div>
              <div>
                <h3 class="no-margins">Chamada Manual</h3>
                <p class="no-paddings no-margins">Quando ativo, a fila não aparecerá no totem e no celular; o cliente retirará a senha através de um rolo de senha destacável (bico de pato).</p>
              </div>
            </div>

            <hr class="hr-line-dashed" />

            <div class="form-group d-flex align-items-center">
              <div class="m-r-sm">
                <a *ngIf="fila.preferencial" href="javascript:;" (click)="desativarPreferencial()">
                  <i class="fa fa-toggle-on fa-3x"></i>
                </a>
                <a *ngIf="!fila.preferencial" href="javascript:;" (click)="ativarPreferencial()">
                  <i class="fa fa-toggle-off fa-3x"></i>
                </a>
              </div>
              <div>
                <h3 class="no-margins">Status de Normal/Preferencial</h3>
                <p class="no-paddings no-margins">Quando ativo, a fila terá opções de senhas normais e preferenciais, diferenciando o atendimento por prioridade.</p>

              </div>
            </div>

            <br><br>
            <h2>Formulário Dados do Cliente</h2>
            <hr />

            <div class="form-group d-flex align-items-center">
              <div class="m-r-sm">
                <a *ngIf="fila.dadosContato" href="javascript:;" (click)="desativarDadosContato()">
                  <i class="fa fa-toggle-on fa-3x"></i>
                </a>
                <a *ngIf="!fila.dadosContato" href="javascript:;" (click)="ativarDadosContato()">
                  <i class="fa fa-toggle-off fa-3x"></i>
                </a>
              </div>
              <div>
                <h3 class="no-margins">Dados do Cliente</h3>
                <p class="no-paddings no-margins">Quando ativo, permite solicitar dados específicos do cliente para entrar na fila via QR Code.</p>
              </div>
            </div>

            <hr class="hr-line-dashed" *ngIf="fila.dadosContato" />

            <div class="form-group" *ngIf="fila.dadosContato">
              <h3>Campos Padrões</h3>
              <p>
                <code>Customização de campos</code>
              </p>
              <br>

              <div class="i-checks">
                <div class="form-group d-flex align-items-start">
                  <div class="m-r-sm">
                    <a *ngIf="fila.showNome" href="javascript:;" (click)="desativarDadosContatoNome()">
                      <i class="fa fa-toggle-on fa-2x"></i>
                    </a>
                    <a *ngIf="!fila.showNome" href="javascript:;" (click)="ativarDadosContatoNome()">
                      <i class="fa fa-toggle-off fa-2x"></i>
                    </a>
                  </div>
                  <div>
                    <h4 class="no-margins">Visualizar campo 'NOME'</h4>
                    <p class="no-paddings no-margins"> Quando ativo, o campo 'NOME' estará disponível para preenchimento ao entrar na fila.</p>
                    <div *ngIf="fila.showNome" class="icheckbox_square mt-2" (click)="ativaDesativarCamposObrigatorios('campoNomeRequired')">
                      <div
                        class="icheckbox_square-blue"
                        [class]="{'checked': fila.campoNomeRequired}"
                        style="position: relative">
                        <input
                          type="checkbox"
                          class="icheckbox_square-blue__icheckbox"
                          ngModel="fila.campoNomeRequired"/>
                        <span class="icheckbox_square-blue__icheckbox-help"></span>
                      </div>
                      Campo obrigatório
                    </div>
                  </div>
                </div>
              </div>

              <div class="i-checks">
                <div class="form-group d-flex align-items-center">
                  <div class="m-r-sm">
                    <a *ngIf="fila.showEmail" href="javascript:;" (click)="desativarDadosContatoEmail()">
                      <i class="fa fa-toggle-on fa-2x"></i>
                    </a>
                    <a *ngIf="!fila.showEmail" href="javascript:;" (click)="ativarDadosContatoEmail()">
                      <i class="fa fa-toggle-off fa-2x"></i>
                    </a>
                  </div>
                  <div>
                    <h4 class="no-margins">Visualizar campo 'EMAIL'</h4>
                    <p class="no-paddings no-margins">Quando ativo, o campo 'EMAIL' estará disponível para preenchimento ao entrar na fila.</p>
                    <div *ngIf="fila.showEmail" class="icheckbox_square mt-2" (click)="ativaDesativarCamposObrigatorios('campoEmailRequired')">
                      <div
                        class="icheckbox_square-blue"
                        [class]="{'checked': fila.campoEmailRequired}"
                        style="position: relative">
                        <input
                          type="checkbox"
                          class="icheckbox_square-blue__icheckbox"
                          ngModel="fila.campoEmailRequired"/>
                        <span class="icheckbox_square-blue__icheckbox-help"></span>
                      </div>
                      Campo obrigatório
                    </div>
                  </div>
                </div>
              </div>

              <div class="i-checks">
                <div class="form-group d-flex align-items-start">
                  <div class="m-r-sm">
                    <a *ngIf="fila.showPhone" href="javascript:;" (click)="desativarDadosContatoTelefone()">
                      <i class="fa fa-toggle-on fa-2x"></i>
                    </a>
                    <a *ngIf="!fila.showPhone" href="javascript:;" (click)="ativarDadosContatoTelefone()">
                      <i class="fa fa-toggle-off fa-2x"></i>
                    </a>
                  </div>
                  <div>
                    <h4 class="no-margins">Visualizar campo 'TELEFONE'</h4>
                    <p class="no-paddings no-margins">Quando ativo, o campo 'TELEFONE' estará disponível para preenchimento ao entrar na fila.</p>
                    <div *ngIf="fila.showPhone" class="icheckbox_square mt-2" (click)="ativaDesativarCamposObrigatorios('campoPhoneRequired')">
                      <div
                        class="icheckbox_square-blue"
                        [class]="{'checked': fila.campoPhoneRequired}"
                        style="position: relative">
                        <input
                          type="checkbox"
                          class="icheckbox_square-blue__icheckbox"
                          ngModel="fila.campoPhoneRequired"/>
                        <span class="icheckbox_square-blue__icheckbox-help"></span>
                      </div>
                      Campo obrigatório
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="hr-line-dashed" *ngIf="fila.dadosContato" />
            <div>

              <h3>Campos Customizados</h3>
              <p>
                <code>Customização de campos</code>
              </p>
              <br>
            </div>

            <div class="form-group d-flex align-items-center" *ngIf="fila.dadosContato">
              <div class="m-r-sm">
                <a *ngIf="fila.showCampos" href="javascript:;" (click)="desativarCampos()">
                  <i class="fa fa-toggle-on fa-3x"></i>
                </a>
                <a *ngIf="!fila.showCampos" href="javascript:;" (click)="ativarCampos()">
                  <i class="fa fa-toggle-off fa-3x"></i>
                </a>
              </div>
              <div>
                <h4 class="no-margins">Habilitar Campos Customizados</h4>
                <p class="no-paddings no-margins">Quando ativo, a empresa pode criar e habilitar campos customizados para solicitação de dados adicionais ao cliente ao entrar na fila (ex.: CPF)</p>
              </div>
            </div>
            <div *ngIf="fila.showCampos">
              <div class="ibox">
                <div class="ibox-title d-flex align-items-center justify-content-between">
                  <h5>Campos customizados</h5>
                  <div class="ibox-tools">
                    <button type="button" class="btn btn-sm btn-success" (click)="newItemCampo()">Adicionar campo
                      customizado</button>
                  </div>
                </div>
                <div class="ibox-content p-0">
                  <div class="table-responsive">
                    <div class="row mb-3" *ngIf="isNewCampo">
                      <!-- Nome  -->
                      <div class="col-12 col-md-10">
                        <div class="form-group">
                          <input type="text" class="form-control" id="campo" name="campo" [(ngModel)]="campo.key"
                            [ngModelOptions]="{standalone: true}">
                          <div class="icheckbox_square mt-2" (click)="toggleCustomCampoObrigatorio()">
                            <div
                              class="icheckbox_square-blue"
                              [class]="{'checked': campo.required}"
                              style="position: relative">
                              <input
                                type="checkbox"
                                class="icheckbox_square-blue__icheckbox"
                                ngModel="campo.required"/>
                              <span class="icheckbox_square-blue__icheckbox-help"></span>
                            </div>
                            Campo obrigatório
                          </div>
                        </div>
                      </div>

                      <!-- Email  -->
                      <div class="col-12 col-md-2">
                        <div class="form-group">
                          <button type="button" class="btn btn-primary btn-block" (click)="addItemCampo()"
                            *ngIf="!loading">Salvar</button>
                          <button type="button" class="btn btn-primary btn-block" disabled *ngIf="loading">
                            Salvando...
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th class="text-right" width="10%">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of campos; let i = index">
                          <th>{{ item.key }}</th>
                          <td class="text-right">
                            <a class="btn btn-sm btn-outline-danger btn-icon btn-inline-block mr-1" href="javascript:;"
                              (click)="editItemCampo(i, item)">
                              <i class="fa fa-edit"></i>
                            </a>
                            <a class="btn btn-sm btn-outline-danger btn-icon btn-inline-block" href="javascript:;"
                              (click)="selectItemCampo(i)" data-toggle="modal" data-target="#modalDeleteCampo">
                              <i class="fa fa-times"></i>
                            </a>
                          </td>
                        </tr>

                      </tbody>
                    </table>

                    <div class="row justify-content-center" *ngIf="!campos?.length">
                      <div class="col-12">

                        <!-- Card -->
                        <div class="card card-inactive">
                          <div class="card-body text-center">

                            <!-- Title -->
                            <h2>
                              Nenhum campo customizado encontrado.
                            </h2>

                            <!-- Subtitle -->
                            <p class="text-muted">
                              Customize campos no cadastro de clientes ao entrarem na fila.
                            </p>

                            <!-- Button -->
                            <button type="button" class="btn btn-sm btn-primary" (click)="newItemCampo()">Adicionar
                              campo
                              customizado</button>

                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>


            </div>

            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!filaForm.form.valid">Salvar</button>
              <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modalDeleteCampo" tabindex="-1" aria-labelledby="modalDeleteCampo"
    style="display: none; padding-left: 0px;" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-danger">
          <h5 class="modal-title text-white" id="exampleModalLabel">Remover Funcionalidade do Plano</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" class="ki ki-close  text-white"></i>
          </button>
        </div>
        <div class="modal-body">
          Tem certeza que deseja remover este item??
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Fechar</button>
          <button type="button" class="btn btn-primary font-weight-bold" data-dismiss="modal"
            (click)="deleteItemCampo()">
            Confirmar
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
