<nav class="navbar-default navbar-static-side" role="navigation" id="MENU_NOTV">
  <div class="sidebar-collapse">
    <ul class="nav metismenu" id="side-menu">
      <li class="nav-header">
        <div class="dropdown profile-element">
          <span>
            <img alt="image" class="img-circle" src="{{imageDefaultProfile}}" style="width: 42px;">
          </span>
          <a data-toggle="dropdown" class="dropdown-toggle" href="#">
            <span class="clear">
              <span class="block m-t-xs">
                <strong class="font-bold">{{userLogged?.nome}}</strong>
              </span>
              <span class="text-muted text-xs block">{{userLogged?.email}}
                <b class="caret"></b>
              </span>
            </span>
          </a>
          <ul class="dropdown-menu animated fadeInRight m-t-xs">
            <li>
              <a href="javascript:void(0);" [routerLink]="['/usuario/edit', userLogged?._id]"
                routerLinkActive="router-link-active">Alterar Dados</a>
            </li>
            <li>
              <a href="javascript:void(0);" data-toggle="modal" (click)="alterarSenha()"
                data-target="#modalAlterarSenhaUsuario">Alterar Senha</a>
            </li>
            <li>
              <a href="javascript:void(0);" (click)="logout()">Logout</a>
            </li>
          </ul>
        </div>
        <div class="logo-element">
          <img src="/assets/img/logo-icon.svg" width="40" alt="logo" />
        </div>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['/empresa']">
          <i class="fa fa-bar-chart-o"></i>
          <span class="nav-label">Dashboard</span>
        </a>
      </li>

      <li routerLinkActive="active" [ngStyle]="{display: (smartFilas ? 'block' : 'none')  }">
        <a [routerLink]="['/atendimento']">
          <i class="fa fa-tablet"></i>
          <span class="nav-label">SmartFilas</span>
        </a>
      </li>

      <li routerLinkActive="active" [ngStyle]="{display: (smartFilasLite ? 'block' : 'none')  }">
        <a [routerLink]="['/smartfilas-lite']">
          <i class="fa fa-qrcode"></i>
          <span class="nav-label">SmartFilas Lite</span>
        </a>
      </li>

      <li routerLinkActive="active" [ngStyle]="{display: (smartAvalia ? 'block' : 'none')  }">
        <a [routerLink]="['/smartavalia']">
          <i class="fa fa-star-half-o"></i>
          <span class="nav-label">SmartAvalia</span>
        </a>
      </li>

      <li routerLinkActive="active" [ngStyle]="{display: (smartPager ? 'block' : 'none')  }">
        <a href="javascript:;" (click)="goToSmartPager()">
          <i class="fa fa-mobile"></i>
          <span class="nav-label">SmartPager</span>
        </a>
      </li>

      <li routerLinkActive="active" *ngIf="showReports">
        <a href="javascript:;">
          <i class="fa fa-table"></i>
          <span class="nav-label">Relatórios</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse out" aria-expanded="true">
          <li><a routerLinkActive="active" [routerLink]="['/relatorio/clientes']">Clientes</a></li>
          <li><a routerLinkActive="active" [routerLink]="['/relatorio/senhas']">Senhas</a></li>
          <li><a routerLinkActive="active" [routerLink]="['/relatorio/atendimentos']">Atendimentos</a></li>
          <li><a routerLinkActive="active" [routerLink]="['/relatorio/tipos/atendimento']">Tipos de Atendimento</a></li>
          <li><a routerLinkActive="active" [routerLink]="['/relatorio/ranking/atendimento']">Ranking Atendimento</a>
          </li>
          <li><a routerLinkActive="active" [routerLink]="['/relatorio/avaliacoes']">Avaliações</a></li>
        </ul>
      </li>

      <li routerLinkActive="active">
        <a href="javascript:;">
          <i class="fa fa-qrcode"></i>
          <span class="nav-label">Cadastros</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse out" aria-expanded="true">
          <li>
            <a routerLinkActive="active" [routerLink]="['/aviso']">Avisos</a>
          </li>
          <li>
            <a *ngIf="isAdmin()" routerLinkActive="active" [routerLink]="['/playlist']">Playlists</a>
          </li>
          <li><a routerLinkActive="active" [routerLink]="['/tipo-atendimento']">Tipos de Atendimento</a>
          </li>
          <li>
            <a *ngIf="isAdmin() && locationEnabled" routerLinkActive="active"
              [routerLink]="['/localizacao']">Localização</a>
          </li>
        </ul>
      </li>

      <li routerLinkActive="active" *ngIf="isAdmin() || isManager()">
        <a href="javascript:;">
          <i class="fa fa-gear"></i>
          <span class="nav-label">Configurações</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse out" aria-expanded="true">
          <li><a routerLinkActive="active" [routerLink]="['/config']">Empresa</a></li>
          <li *ngIf="isAdmin() || isManager()">
            <a [routerLink]="['/usuario']" routerLinkActive="active">Usuários</a>
          </li>
          <li>
            <a routerLinkActive="active" [routerLink]="['/franquia']">Estabelecimentos</a>
          </li>
        </ul>
      </li>

      <li routerLinkActive="active"  *ngIf="apiEnabled">
        <a href="javascript:void(0);" data-toggle="modal" data-target="#modalAPI">
          <i class="fa fa-code"></i>
          <span class="nav-label">API</span>
        </a>
      </li>
      <li routerLinkActive="active">
        <a href="javascript:void(0);" (click)="goToSuporte()">
          <i class="fa fa-book"></i>
          <span class="nav-label">Suporte</span>
        </a>
      </li>

    </ul>

  </div>

</nav>
<div class="modal inmodal fade in" id="modalAlterarSenhaUsuario" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog ">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Alterar Senha</h4>
      </div>
      <div class="modal-body">

        <form #fAlterarSenha="ngForm">
          <div class="form-group">
            <label for="senhaAtual">Senha Atual <span style="color: red">*</span></label>
            <input type="password" class="form-control" required name="senhaAtual" id="senhaAtual" #senhaAtual="ngModel"
              [(ngModel)]="model.senhaAtual" placeholder="Senha">
            <div *ngIf="senhaAtual.invalid && (senhaAtual.dirty || senhaAtual.touched)">
              <div *ngIf="senhaAtual.errors['required']">
                <p class="form-text">Senha Atual é obrigatório.</p>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="novaSenha">Nova Senha <span style="color: red">*</span></label>
            <input type="password" class="form-control" required name="novaSenha" id="novaSenha" #novaSenha="ngModel"
              [(ngModel)]="model.novaSenha" placeholder="Nova Senha">
            <div *ngIf="novaSenha.invalid && (novaSenha.dirty || novaSenha.touched)">
              <div *ngIf="novaSenha.errors['required']">
                <p class="form-text">Nova Senha é obrigatório.</p>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="confirmaNovaSenha">Confirmação de Nova Senha <span style="color: red">*</span></label>
            <input type="password" class="form-control" required name="confirmaNovaSenha" id="confirmaNovaSenha"
              #confirmaNovaSenha="ngModel" [(ngModel)]="model.confirmaNovaSenha"
              placeholder="Confirmação de Nova Senha">
            <div *ngIf="confirmaNovaSenha.invalid && (confirmaNovaSenha.dirty || confirmaNovaSenha.touched)">
              <div *ngIf="confirmaNovaSenha.errors['required']">
                <p class="form-text">Confirmação de Nova Senha é obrigatório.</p>
              </div>
            </div>
          </div>
        </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="confirmarAlterarSenha()">Alterar
          Senha</button>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="modalAPI" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Dados da API</h4>
        <small class="font-bold">Abaixo segue os dados de autenticação da API</small>
      </div>
      <div class="modal-body">

        <div class="ibox-content text-center">
          <h3>Usuário/Email</h3>
          <h2>{{userAPI?.email}}</h2>
          <h3>Token</h3>
          <h2>{{userAPI?.senha}}</h2>
        </div>

        <div class="ibox-content text-center">
          <p class="font-bold">
            <a [href]="apiRef" target="_blank">API - Reference</a>
          </p>
          <p class="font-bold">
            <a [href]="postmanDoc" target="_blank">Documentação POSTMAN</a>
          </p>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>