import { DOCUMENT } from '@angular/common';
import { AfterContentInit, Component, Inject, Injector, OnInit, Renderer2 } from '@angular/core';
import { Validators } from '@angular/forms';
import { AbstractComponent, AuthenticationService, MustMatch, Usuario, UsuarioService } from 'lib-smart-core';

@Component({
  selector: 'usuario-forms',
  templateUrl: 'usuario-esqueci-minha-senha.component.html',
})
export class UsuarioEsqueciMinhaSenhaComponent extends AbstractComponent implements OnInit, AfterContentInit {

  usuario: Usuario;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authenticationService: AuthenticationService,
    private usuarioService: UsuarioService,
    private renderer2: Renderer2,
    public injector: Injector
  ) {
    super(injector);
    this.validaForm();
    this.usuario = {} as Usuario;
  }

  ngOnInit() {
    this.authenticationService.logout();
    let id = super.getParam('id');
    if (id) {
      this.getUsuarioPorId(id);
    }
  }

  ngAfterContentInit(): void {
    this.renderer2.addClass(this.document.body, 'blank');
  }

  getUsuarioPorId(id: string) {
    this.usuarioService.getPublicUsuarioPorId(id).subscribe(
      (usuario: Usuario) => {
        this.usuario = usuario;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  validaForm(): void {
    this.formGroup = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    }, { validator: MustMatch('password', 'confirmPassword') });
  }

  onSubmit() {
    if (this.formGroup.valid) {
      var obj = { id: this.usuario._id, novaSenha: this.formGroup.value.password, confirmarNovaSenha: this.formGroup.value.confirmPassword };
      this.usuarioService.confirmarAlterarSenha(obj).subscribe(
        data => {
          this.alertService.warning('Operação realizada com sucesso.');
          this.router.navigate(['/login']);
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
    } else {
      this.validateAllFormFields(this.formGroup);
    }
  }

}

