import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, EmpresaService, FirebaseService, Franquia, Link, UploadTypeEnum, UtilHelper } from 'lib-smart-core';

@Component({

  selector: 'config-lista',
  templateUrl: './config-lista.component.html'
})
export class ConfigListaComponent extends AbstractComponent implements OnInit {

  empresa: Empresa;
  franquias: Franquia[];

  link: Link;
  linkSelecionado: Link;
  isNewLink: boolean = true;
  links: Array<Link>;

  loadingImage: boolean = false;

  constructor(
    private empresaService: EmpresaService,
    private firebaseService: FirebaseService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.empresa = {} as Empresa;;
    this.link = {} as Link;
    let id: string = super.getIDEmpresaSession();
    if (id) {
      this.loadEmpresa();
    }
  }

  loadEmpresa() {
    this.empresaService.getById(super.getIDEmpresaSession()).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
        console.log(empresa)
        this.links = empresa.links || [];
      }, err => this.alertService.error(err.error.message)
    );
  }

  private file: File;
  private bytes: string;

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('localImage') as HTMLElement;
    element.click();
  }

  openFileBrowserLite(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('localImageLite') as HTMLElement;
    element.click();
  }

  openFileBrowserAvalia(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('localImageAvalia') as HTMLElement;
    element.click();
  }

  openFileBrowserPager(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('localImagePager') as HTMLElement;
    element.click();
  }

  onChange(event: any) {
    let eventObj: any = <any>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    if (files[0].size > 1000000) { // 1 MB
      this.alertService.warning('Imagem inválida. O tamanho do arquivo de imagem deve ser inferior a 1 MB.');
      return;
    }

    if (!UtilHelper.mimeTypesImages.includes(files[0].type)) {
      this.alertService.warning('Imagem inválido. O arquivo deve estar no formato jpg, jpeg, gif, png ou bmp');
      return;
    }

    this.file = files[0];

    let reader = new FileReader();
    reader.onload = file => {
      if (this.file) {
        this.loadingImage = true;
        let filename = this.empresa._id;
        this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.EMPRESA).then((snapshot: { url: string, urlSafe: string }) => {
          this.empresa.image = snapshot.url;
          this.loadingImage = false;
          this.onSubmitEmpresa();
        });
      }
    };
    reader.readAsArrayBuffer(this.file);
  }

  onChangeTemplateLite(event: any) {
    let eventObj: any = <any>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    if (files[0].size > 1000000) { // 1 MB
      this.alertService.warning('Imagem inválida. O tamanho do arquivo de imagem deve ser inferior a 1 MB.');
      return;
    }

    if (!UtilHelper.mimeTypesImages.includes(files[0].type)) {
      this.alertService.warning('Imagem inválido. O arquivo deve estar no formato jpg, jpeg, gif, png ou bmp');
      return;
    }

    this.file = files[0];

    let reader = new FileReader();
    reader.onload = file => {
      if (this.file) {
        this.loadingImage = true;
        let filename = `template_lite_${this.empresa._id}`;
        this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.EMPRESA).then((snapshot: { url: string, urlSafe: string }) => {
          this.empresa.imageTemplateLite = snapshot.url;
          this.loadingImage = false;
          this.onSubmitEmpresa();
        });
      }
    };
    reader.readAsArrayBuffer(this.file);
  }

  onChangeTemplateAvalia(event: any) {
    let eventObj: any = <any>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    if (files[0].size > 1000000) { // 1 MB
      this.alertService.warning('Imagem inválida. O tamanho do arquivo de imagem deve ser inferior a 1 MB.');
      return;
    }

    if (!UtilHelper.mimeTypesImages.includes(files[0].type)) {
      this.alertService.warning('Imagem inválido. O arquivo deve estar no formato jpg, jpeg, gif, png ou bmp');
      return;
    }

    this.file = files[0];

    let reader = new FileReader();
    reader.onload = file => {
      if (this.file) {
        this.loadingImage = true;
        let filename = `template_avalia_${this.empresa._id}`;
        this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.EMPRESA).then((snapshot: { url: string, urlSafe: string }) => {
          this.empresa.imageTemplateAvalia = snapshot.url;
          this.loadingImage = false;
          this.onSubmitEmpresa();
        });
      }
    };
    reader.readAsArrayBuffer(this.file);
  }

  onChangeTemplatePager(event: any) {
    let eventObj: any = <any>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    if (files[0].size > 1000000) { // 1 MB
      this.alertService.warning('Imagem inválida. O tamanho do arquivo de imagem deve ser inferior a 1 MB.');
      return;
    }

    if (!UtilHelper.mimeTypesImages.includes(files[0].type)) {
      // this.alertService.warning('Arquivo inválido. O arquivo deve estar no formato jpg, jpeg, gif, png, bmp, pdf, zip, rar, epub, xls, xlsx, mp3, doc, docx, ppt ou pptx.');
      this.alertService.warning('Imagem inválido. O arquivo deve estar no formato jpg, jpeg, gif, png ou bmp');
      return;
    }

    this.file = files[0];
    // console.log('onChange');
    // console.log(this.file);

    let reader = new FileReader();
    reader.onload = file => {
      if (this.file) {
        this.loadingImage = true;
        let filename = `template_pager_${this.empresa._id}`;
        this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.EMPRESA).then((snapshot: { url: string, urlSafe: string }) => {
          // console.log(snapshot.url);
          this.empresa.imageTemplatePager = snapshot.url;
          this.loadingImage = false;
          this.onSubmitEmpresa();
        });
      }
    };
    reader.readAsArrayBuffer(this.file);
  }

  removerImagem() {
    this.loadingImage = true;
    this.empresa.image = null;
    this.loadingImage = false;
    this.onSubmitEmpresa();
  }

  removerImagemLite() {
    this.loadingImage = true;
    this.empresa.imageTemplateLite = null;
    this.loadingImage = false;
    this.onSubmitEmpresa();
  }

  removerImagemAvalia() {
    this.loadingImage = true;
    this.empresa.imageTemplateAvalia = null;
    this.loadingImage = false;
    this.onSubmitEmpresa();
  }

  removerImagemPager() {
    this.loadingImage = true;
    this.empresa.imageTemplatePager = null;
    this.loadingImage = false;
    this.onSubmitEmpresa();
  }

  onSubmitEmpresa(): void {
    this.empresaService.update(this.empresa).subscribe(
      () => {
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    )
  }

  indexSelected: number = -1;

  newLink() {
    this.isNewLink = true;
    this.indexSelected = -1;
    this.link = {} as Link;
    this.link.active = true;
    this.isNewLink = true;
  }

  selectLink(index: number, link: Link) {
    this.indexSelected = index;
    this.linkSelecionado = link;
  }

  editLink(index: number, link: Link) {
    this.indexSelected = index;
    this.isNewLink = false;
    this.link = link;
  }

  deleteLink() {
    this.links.splice(this.indexSelected, 1);
    this.links.forEach((item, index) => {
      item.index = index + 1;
    });
    this.indexSelected = -1;
    this.linkSelecionado = null;
    this.onSubmitEmpresa();
  }

  onSubmitLink(): void {
    if (this.isNewLink) {
      if (this.link !== null && this.link !== undefined) {
        this.link.index = this.links.length + 1;
        this.links.push(this.link);
      }
    } else if (!this.isNewLink) {
      if (this.linkSelecionado) {
        this.links.forEach((item) => {
          if (item.index === this.indexSelected) {
            item.url = this.linkSelecionado.url;
            item.active = this.linkSelecionado.active;
            item.icon = this.linkSelecionado.icon;
          }
        });
        this.linkSelecionado = null;
        this.indexSelected = -1;
      }
      this.empresa.links = this.links;
    }
    this.onSubmitEmpresa();
  }

  setIcon(icon: string): void {
    this.link.icon = icon;
  }

  ativar() {
    this.link.active = true;
  }

  desativar() {
    this.link.active = false;
  }

  ativarBloqueioAutomatico() {
    this.empresa.bloqueioAutomatico = true;
  }

  desativarBloqueioAutomatico() {
    this.empresa.bloqueioAutomatico = false;
  }

  ativarEncaminhamentoEntreFranquia() {
    this.empresa.encaminhamentoEntreFranquias = true;
  }

  desativarEncaminhamentoEntreFranquia() {
    this.empresa.encaminhamentoEntreFranquias = false;
  }

}
