
import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractComponent } from "lib-smart-core";

@Component({
  selector: 'avaliacao-embeded',
  templateUrl: './avaliacao-embeded.component.html'
})

export class AvaliacaoEmbededComponent extends AbstractComponent implements OnInit {

  @Input() pontuacao: number;

  star_full: string = 'fa fa-star fa-2x';
  star_empty: string = 'fa fa-star-o fa-2x';
  star_half: string = 'fa fa-star-half-o fa-2x';

  star_1: string;
  star_2: string;
  star_3: string;
  star_4: string;
  star_5: string;

  constructor(
    public injector: Injector
  ) {
    super(injector);
    this.star_1 = this.star_empty;
    this.star_2 = this.star_empty;
    this.star_3 = this.star_empty;
    this.star_4 = this.star_empty;
    this.star_5 = this.star_empty;
  }

  ngOnInit() {
    if (this.pontuacao) {
      if (this.pontuacao === 0) {
        this.star_1 = this.star_empty;
        this.star_2 = this.star_empty;
        this.star_3 = this.star_empty;
        this.star_4 = this.star_empty;
        this.star_5 = this.star_empty;
      } else if (this.pontuacao === 1 || (this.pontuacao > 0.75 && this.pontuacao < 1.25)) {
        this.star_1 = this.star_full;
      } else if (this.pontuacao === 2 || (this.pontuacao > 1.75 && this.pontuacao < 2.25)) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
      } else if (this.pontuacao === 3 || (this.pontuacao > 2.75 && this.pontuacao < 3.25)) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_full;
      } else if (this.pontuacao === 4 || (this.pontuacao > 3.75 && this.pontuacao < 4.25)) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_full;
        this.star_4 = this.star_full;
      } else if (this.pontuacao === 5 || (this.pontuacao > 4.75)) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_full;
        this.star_4 = this.star_full;
        this.star_5 = this.star_full;
      } else if (this.pontuacao > 0 && this.pontuacao < 0.75) {
        this.star_1 = this.star_half;
      } else if (this.pontuacao >= 0.75 && this.pontuacao < 1.75) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_half;
      } else if (this.pontuacao >= 1.75 && this.pontuacao < 2.75) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_half;
      } else if (this.pontuacao >= 2.75 && this.pontuacao < 3.75) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_full;
        this.star_4 = this.star_half;
      } else if (this.pontuacao >= 3.75 && this.pontuacao < 4.75) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_full;
        this.star_4 = this.star_full;
        this.star_5 = this.star_half;
      } else if (this.pontuacao >= 4.75) {
        this.star_1 = this.star_full;
        this.star_2 = this.star_full;
        this.star_3 = this.star_full;
        this.star_4 = this.star_full;
        this.star_5 = this.star_full;
      }
    }
  }

}