<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-6">
    <h2>Atendimento</h2>
    <ol class="breadcrumb hidden-xs">
      <li>
        <a [routerLink]="['/atendimento']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li>
        <a>Atendimento</a>
      </li>
      <li class="active">
        <a>Filas de Atendimento</a>
      </li>
    </ol>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="title-action">
      <a (click)="relaodPage()" class="btn btn-default"><i class="fa fa-refresh"></i></a>
      <a (click)="exibirModalGerarNovaSenha()" class="btn btn-success">Adicionar Senha</a>
      <a (click)="iniciarNovoAtendimento()" class="btn btn-danger"><span class="hidden-xs">Encerrar Atendimento</span> <span class="hidden-sm hidden-md hidden-lg">Enc. Atendimento</span></a>
      <div class="btn-group">
        <a data-toggle="dropdown" class="btn btn-default dropdown-toggle" aria-expanded="false">
          <i class="fa fa-ellipsis-v"></i>
        </a>
        <ul class="dropdown-menu dropdown-menu-right" style="position:absolute; right: 0 !important; left: inherit;">
          <li>
            <a (click)="setupQRCodeFranquia()" class="dropdown-item" data-toggle="modal" data-target="#qrcodetv">TV</a>
          </li>
          <li>
            <a (click)="setupQRCodeFranquia()" class="dropdown-item" data-toggle="modal"
              data-target="#qrcodetablet">Simulador</a>
          </li>
          <li style="display: none">
            <a (click)="atualizarListaChamada()" #btnAtualizarChamada class="dropdown-item">ATUALIZA LISTA
              CHAMADA</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>


<div class="attendance" *ngIf="!franquia.bloqueado">
  <div class="attendance__sidebar attendance__sidebar--mobile" [ngClass]="{'attendance__sidebar--show': isShowMenuFilas}">
    <button (click)="showMenuFilas()" class="btn btn-secondary attendance__button hidden-sm hidden-md hidden-lg">
      <i class="fa fa-bars"></i> Filas
    </button>
    <h3 class="attendance__title">
      Filas
    </h3>
    <nav class="attendance__nav">
      <a class="attendance__link" *ngFor="let fila of filas"
        [ngClass]="{'active' : isFilaCurrent(fila?._id), 'btn-primary' : isFilaCurrent(fila?._id), 'btn-white' : !isFilaCurrent(fila?._id)}"
        (click)="selecionarFilaTab(fila?._id)">{{fila.nome}}
        <span *ngIf="!fila.chamadaManual" class="badge badge-primary">{{getTotalPorFila(fila?._id)}}</span>
      </a>
      <a class="attendance__link"
        [ngClass]="{'active' : viewHistorico, 'btn-primary' : viewHistorico, 'btn-white' : !viewHistorico}"
        (click)="selecionarHistoricoTab()"> Histórico
      </a>
    </nav>
  </div>
  <div class="attendance__main">

    <!-- Item-1 -->
    <div class="widget white-bg " style="display: flex; justify-content: space-between; align-items: center;">
      <div class="hidden-xs">
        Tempo estimado de espera {{previsaoAtendimentoMinutos}} minutos
      </div>
      <div>
        <button class="btn btn-lg btn-white" *ngIf="config?.chamadaInstantanea" data-toggle="modal"
          (click)="abrirModalSenhaAnonimia()" data-target="#modalSenhaAnonima">Chamada Instantânea</button>

        <button class="btn btn-lg btn-warning" aria-labelledby="Chamar preferencial"
          *ngIf="!!chamadasPreferencial && chamadasPreferencial.length > 0 && !viewHistorico"
          [disabled]="loadingChamar || loadingRechamar" (click)="chamarProximaSenhaPreferencial()"><span class="hidden-xs">Chamar Preferencial</span><span class="hidden-sm hidden-md hidden-lg">Chamar Prefer...</span>
          <img *ngIf="loadingChamar"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </button>

        <button class="btn btn-lg btn-primary" aria-labelledby="Chamar próxima" [disabled]="loadingChamar || loadingRechamar" *ngIf="!viewHistorico"
          (click)="filaSelecionada?.chamadaManual ? chamarProximaSenhaManual() : chamarProximaSenha()"><span class="hidden-xs">Chamar Próxima</span><span class="hidden-sm hidden-md hidden-lg">Chamar Próxima</span> <img *ngIf="loadingChamar"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </button>
      </div>
    </div>

    <!-- Item 2 -->
    <div class="widget-senhas" *ngIf="senhaSelecionada" style="padding: 0 15px">
      <div class="widget white-bg b-r-lg bg-muted p-sm widget-senhas__senha-atual chamada-senha">
        <h1 class="no-margins">Senha {{senhaSelecionada?.senha}}</h1>
        <div>
          <button class="btn btn-md btn-white" [disabled]="loadingChamar || loadingRechamar" *ngIf="senhaSelecionada"
            (click)="rechamarSenha()">Rechamar
            <img *ngIf="loadingRechamar"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </button>
          <a class="btn btn-warning"
            [routerLink]="['/atendimento-form', senhaSelecionada?._id, senhaSelecionada?.fila._id ?? senhaSelecionada?.fila]">Iniciar
            Atendimento
          </a>
        </div>
      </div>
    </div>

    <!-- Filas Preferencial -->
    <div class="widget white-bg widget-senhas" *ngIf="!!chamadasPreferencial && chamadasPreferencial.length > 0">
      <div class="widget-senhas__header">
        <h3 class="widget-senhas__heading">Preferencial</h3>
        <div class="widget-senhas__actions text-right">

        </div>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th class="text-center" style="width: 5%;">#</th>
            <th class="text-center" style="width: 10%;">Senha</th>
            <th class="text-center">Data/Hora</th>
            <th class="text-center hidden-xs">Nome/Telefone/E-mail</th>
            <th class="text-center hidden-xs">Campos</th>
            <th class="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let chamada of chamadasPreferencial; let i = index">
            <th class="text-center">
              {{(pager.page === 1 ? i + 1 : ((pager.perPage * pager.page) + i + 1))}}
            </th>
            <th class="text-center">
              <a href="javascript:void(0);" data-toggle="modal" data-target="#modalSenhaLinkSmartLite"
                (click)="selecionarSenhaNaChamadaQRCode(chamada.senha)">
                {{chamada.senha.senha}}
              </a>
            </th>
            <td class="text-center">{{chamada.data | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td class="text-center hidden-xs">{{chamada.senha.nome}} {{chamada.senha.numero ? ' | ' +
              chamada.senha.numero : ''}} {{chamada.senha.email ? ' | ' + chamada.senha.email : ''}}</td>
            <td class="text-center hidden-xs">
              <span *ngFor="let campo of chamada.senha.campos">
                <b>{{campo.key}}:</b> {{campo.value}}<br>
              </span>
            </td>
            <!-- <td class="text-center hidden-xs">{{chamada.localizacao.nome}}</td> -->
            <td class="text-right">
              <button type="button" class="btn btn-sm btn-primary" title="Atualizar dados senha" data-toggle="modal"
                (click)="exibeModalAlterarSenha(chamada.senha)" data-target="#alterarSenha">
                <i class="fa fa-edit"></i>
              </button>
              <button type="button" class="btn btn-sm btn-primary" title="Chamar Manual" data-toggle="modal"
                (click)="openModalSenhaManual(chamada)" data-target="#modalSenhaJaChamada">
                <i class="glyphicon glyphicon-volume-up"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <!-- Normal -->
    <div class="widget white-bg widget-senhas" *ngIf="temDadosListaChamadaCorrente()">

      <div class="widget-senhas__header" [class.widget-senhas__header--end]="!chamadasPreferencial">
        <h3 class="widget-senhas__heading" *ngIf="!!chamadasPreferencial && chamadasPreferencial.length > 0">
          Normal/Preferencial <small class="muted">(Por ordem de chegada)</small></h3>
        <div class="widget-senhas__actions text-right">

        </div>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th class="text-center" style="width: 5%;">#</th>
            <th class="text-center" style="width: 10%;">Senha</th>
            <th class="text-center">Data/Hora</th>
            <th class="text-center hidden-xs" *ngIf="viewHistorico">Comparecimento</th>
            <th class="text-center hidden-xs">Nome/Telefone/E-mail</th>
            <th class="text-center hidden-xs">Campos</th>
            <th class="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let chamada of pager.list; let i = index">
            <th class="text-center">
              {{ (pager.page === 1 ? i + 1 : '') }}
              {{ (pager.page === 2 ? i + 1 + pager.perPage : '') }}
              {{ (pager.page > 2 ? ((pager.perPage * (pager.page-1)) + i + 1) : '' ) }}
            </th>
            <th class="text-center">
              <a href="javascript:;" data-toggle="modal" data-target="#modalSenhaLinkSmartLite"
                (click)="selecionarSenhaNaChamadaQRCode(chamada.senha)">
                {{chamada.senha.senha}}
              </a>
            </th>
            <td class="text-center">{{chamada.data | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td class="text-center hidden-xs" *ngIf="viewHistorico">
              <span class="label label-danger" style="margin-right: 2px;" *ngIf="chamada.senha.saiuDaFila">SAIU DA
                FILA</span>
            </td>
            <td class="text-center hidden-xs">{{chamada.senha.nome}} {{chamada.senha.numero ? ' | ' +
              chamada.senha.numero : ''}} {{chamada.senha.email ? ' | ' + chamada.senha.email : ''}}</td>
            <td class="text-center hidden-xs">
              <span *ngFor="let campo of chamada.senha.campos">
                <b>{{campo.key}}:</b> {{campo.value}}
              </span>
            </td>
            <td class="text-right">
              <button type="button" class="btn btn-sm btn-primary" title="Atualizar dados senha" data-toggle="modal"
                (click)="exibeModalAlterarSenha(chamada.senha)" data-target="#alterarSenha">
                <i class="fa fa-edit"></i>
              </button>
              <button type="button" class="btn btn-sm btn-primary" title="Chamar Manual" data-toggle="modal"
                (click)="openModalSenhaManual(chamada)" data-target="#modalSenhaJaChamada">
                <i class="glyphicon glyphicon-volume-up"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center" *ngIf="!loading && (pager.total > pager.perPage)">
        <div class="text-center" *ngIf="pager.total > pager.perPage">
          <paginator class="pagination" [pager]="pager" (onPager)="loadPage($event)"></paginator>
        </div>
      </div>
    </div>

    <!-- Chamada manual -->
    <div class="row" *ngIf="filaSelecionada?.chamadaManual">
      <div class="col-lg-12">
        <div class="ibox ">
          <div class="ibox-content text-center p-md">
            <h1 class="m-b-xxs">Fila configurada em modo de <strong>Chamada Manual</strong></h1>
            <small>Esta fila está configurada com a chamada manual, você alterar as configurações da fila <a
                [routerLink]="['/fila', franquia?._id]">clicando aqui</a></small>
            <hr />
            <br />
            <br />
            <h2>A próxima senha chamada será
              <strong>{{filaSelecionada?.prefixo}}{{completeLeftZeros(filaSelecionada?.chamadaManualInicio,
                3)}}</strong>
            </h2>
            <small>Para alterar o início da chamada manual <a href="javascript:void(0)" data-toggle="modal"
                data-target="#modalChamadaManualInicio">clique aqui</a></small>
            <hr />
          </div>
        </div>
      </div>
    </div>

    <!-- Carregando... -->
    <div class="widget white-bg widget-senhas" *ngIf="loading">
      <div class="panel text-center" style="padding-top: 100px; padding-bottom: 100px;">
        <div class="panel-body">
          <h3><strong>Carregando...</strong></h3>
        </div>
      </div>
    </div>

    <!-- Lista vazia! -->
    <div class="widget white-bg widget-senhas"
      *ngIf="!loading && !filaSelecionada?.chamadaManual && !temDadosListaChamadaCorrente()">
      <div class="panel text-center" style="padding-top: 100px; padding-bottom: 100px;">
        <div class="panel-body">
          <p class="p-y-1">
            <i class="fa fa-table fa-3x font-size-46 line-height-1 text-primary"></i>
          </p>
          <h3><strong>Senhas</strong></h3>
          <p class="m-b-4">Não existe senha a ser chamada.</p>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="modal inmodal fade in" id="alterarSenha" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <i class="fa fa-tablet modal-icon"></i>
        <h4 class="modal-title">Atualizar Dados</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Senha</label>
              <input type="text" readonly disabled class="form-control" name="senha" id="senha"
                [(ngModel)]="dadosSenha.senha">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Data</label>
              <input type="text" readonly disabled class="form-control" name="data" id="data"
                [value]="dadosSenha.data | date: 'dd/MM/yyyy HH:mm:ss'">
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Nome</label>
          <input type="text" placeholder="Insira o Nome" class="form-control" name="nome" id="nome" #nome="ngModel"
            [(ngModel)]="dadosSenha.nome">
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Telefone</label>
              <input type="text" mask="(00) 00000-0000" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                placeholder="Insira o Telefone" class="form-control" name="telefone" id="telefone" #numero="ngModel"
                [(ngModel)]="dadosSenha.numero">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Email</label>
              <input type="text" placeholder="Insira o Email" class="form-control" name="email" id="email"
                #email="ngModel" [(ngModel)]="dadosSenha.email">
            </div>
          </div>
        </div>
        <div class="row" *ngIf="dadosSenha.campos">
          <div class="col-md-6" *ngFor="let item of dadosSenha.campos; let i = index">
            <div class="form-group">
              <label>{{item.key}}</label>
              <input type="text" id="campo_{{i}}" name="campo_{{i}}" placeholder="{{item.key}}" [(ngModel)]="item.value"
                class="form-control">
            </div>
          </div>
        </div>
        <ng-container *ngIf="!dadosSenha.campos">
          <div class="row">
            <div class="col-xs-12 col-md-6" *ngFor="let item of dadosSenha.fila?.campos; let i = index">
              <div class="form-group">
                <label>{{item.key}}</label>
                <input type="text" id="campo_{{i}}" name="campo_{{i}}" placeholder="{{item.key}}" class="form-control">
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" #btnAtualizarSenha data-dismiss="modal">Fechar</button>
        <button type="submit" class="btn btn-primary" (click)="alterarSenha()">
          Salvar
          <img *ngIf="loadingChamar"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </button>
      </div>
    </div>
  </div>
</div>

<nova-senha-component *ngIf="filas" [filas]="filas" (onSave)="selecionarFilaTab($event)"></nova-senha-component>

<!-- <div class="modal inmodal fade in" id="gerarNovaSenha" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Adicionar Senha</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xs-12 col-md-12">
            <div class="form-group">
              <label>Nome</label>
              <input type="text" placeholder="Insira o Nome" class="form-control input-lg" name="nome" id="nome2"
                #nome="ngModel" [(ngModel)]="dadosSenha.nome">
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="form-group">
              <label>Telefone</label>
              <input type="text" mask="(00) 00000-0000" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                placeholder="Insira o Telefone" class="form-control input-lg" name="telefone" id="telefone2"
                #numero="ngModel" [(ngModel)]="dadosSenha.numero">
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="form-group">
              <label>Email</label>
              <input type="text" placeholder="Insira o Email" class="form-control input-lg" name="email" id="email2"
                #email="ngModel" [(ngModel)]="dadosSenha.email">
            </div>
          </div>
        </div>
        <div class="hr-line-dashed"></div>
        <ng-container *ngIf="!isNormalPreferencial">
          <div class="row">
            <ng-container *ngFor="let fila of filas">
              <div class="col-md-6" *ngIf="fila.visivelTablet">
                <button *ngIf="!fila.preferencial" [disabled]="loading" class="btn btn-primary btn-xlg btn-block"
                  style="height: 55px;" (click)="gerarNovaSenhaMetadata(fila)">
                  {{fila.nome | uppercase}}
                </button>
                <button *ngIf="fila.preferencial" [disabled]="loading" class="btn btn-primary btn-xlg btn-block"
                  style="height: 55px;" (click)="selecionarFila(fila)">
                  {{fila.nome | uppercase}}
                </button>
                <br />
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="isNormalPreferencial">
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-primary btn-xlg btn-block" style="height: 55px;" [disabled]="loading"
                (click)="gerarNovaSenhaPreferencialMetadata(filaSelecionada, false)">NORMAL</button>
              <br />
              <button class="btn btn-primary btn-xlg btn-block" style="height: 55px;" [disabled]="loading"
                (click)="gerarNovaSenhaPreferencialMetadata(filaSelecionada, true)">PREFERENCIAL</button>
              <br />
              <button class="btn btn-secondary btn-xlg btn-block" style="height: 55px;"
                (click)="setNormalPreferencial(false)">VOLTAR</button>
              <br />
            </div>
          </div>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal" #btnCloseModalNovaSenha>Cancelar</button>
      </div>
    </div>
  </div>
</div> -->


<div class="modal inmodal" id="qrcodetablet" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <i class="fa fa-tablet modal-icon"></i>
        <h4 class="modal-title">Simulador</h4>
        <small class="font-bold">O QRCode abaixo indica a URL que deverá ser utilizada para este
          estabelecimento.</small>
      </div>
      <div class="modal-body">
        <div align="center" *ngIf="URL_TABLET_FRANQUIA">
          <qrcode [qrdata]="URL_TABLET_FRANQUIA" [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
          <p>
            <br /><br /> <a href="{{URL_TABLET_FRANQUIA}}" target="_blank">{{URL_TABLET_FRANQUIA}}</a>
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="qrcodetv" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <i class="fa fa-tv modal-icon"></i>
        <h4 class="modal-title">TV</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p *ngFor="let tv of tvs">
            <strong>{{tv.nome}}</strong><br /><a href="{{getURLTV(tv._id)}}" target="_blank">{{getURLTV(tv._id)}}</a>
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal fade in" id="modalSenhaJaChamada" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content animated bounceInRight">
      <form #form="ngForm" class="form-horizontal" novalidate>
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
              class="sr-only">Close</span></button>
          <h4 class="modal-title">Chamar senha manualmente</h4>
          <p>
            Chame a senha <strong>{{senhaManual}}</strong> presente na fila
            <code>{{filaSelecionada?.nome | uppercase}}</code>
          </p>
        </div>
        <div class="modal-body">

          <div class="form-group">
            <!-- <label class="col-lg-2 control-label">{{filaSelecionada?.prefixo}}</label> -->
            <div class="col-lg-12">
              Tem certeza que deseja chamar a senha <code>{{senhaManual | uppercase}}</code>?
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" #btnFecharModalSenhaManual data-dismiss="modal">Fechar</button>
          <button type="submit" class="btn btn-primary"
            (click)="viewHistorico ? rechamarSenha() : chamarSenha(senhaManual)" [disabled]="loadingRechamar">
            Chamar
            <img *ngIf="loadingChamar"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal inmodal fade in" id="modalChamadaManualInicio" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content animated bounceInRight">
      <form #form="ngForm" class="form-horizontal" novalidate>
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
              class="sr-only">Close</span></button>
          <h4 class="modal-title">Alterar Início da Chamada Manual</h4>
        </div>
        <div class="modal-body">
          <p>
            Altere o início da fila para chamada manual.
          </p>
          <div class="form-group">
            <!-- <label class="col-lg-2 control-label">{{filaSelecionada?.prefixo}}</label> -->
            <div class="col-lg-12">
              <input type="tel" maxlength="5" (keypress)="keyPressOnlyNumbers($event)" placeholder="123"
                class="form-control input-lg" name="chamadaManualInicio" id="chamadaManualInicio" #chamadaManualInicio
                [(ngModel)]="modelSenhaChamadaManualInicio.chamadaManualInicio" required>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" #btnCloseModalChamadaManualInicio
            data-dismiss="modal">Fechar</button>
          <button type="submit" class="btn btn-primary" (click)="onSubmitAlterarChamadaManualInicio()"
            [disabled]="loadingChamadaManual">
            Confirmar
            <img *ngIf="loadingChamadaManual"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal inmodal" id="modalSenhaLinkSmartLite" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <!-- <i class="fa fa-tablet modal-icon"></i> -->
        <h4 class="modal-title">QRCode de {{senhaSelecionadaQRCode?.senha}}</h4>
        <small>Chame a senha {{senhaSelecionadaQRCode?.senha}} presente na fila
          <code>{{filaSelecionada?.nome}}</code></small>
      </div>
      <div class="modal-body text-center" *ngIf="urlSenhaSelecionada">
        <qrcode [qrdata]="urlSenhaSelecionada" [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
        <p>
          <br /> <a href={{urlSenhaSelecionada}} target="_blank">{{urlSenhaSelecionada}}</a>
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>


<div class="modal inmodal fade in" id="modalSenhaAnonima" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <form #form="ngForm" class="form-horizontal" novalidate>
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
              class="sr-only">Close</span></button>
          <h4 class="modal-title">Chamada Instantânea</h4>
        </div>
        <div class="modal-body text-center">

          <p *ngFor="let tv of tvs">
            <strong>{{tv.nome}}</strong><br /><a href="{{getURLTVAnonima(tv._id)}}"
              target="_blank">{{getURLTVAnonima(tv._id)}}</a>
          </p>
          <hr />
          <p>
            Informe uma senha
          </p>
          <div class="form-group text-center">
            <!-- <label class="col-lg-2 control-label">{{filaSelecionada?.prefixo}}</label> -->
            <label class="col-lg-4 control-label">&nbsp;</label>
            <div class="col-lg-4">
              <input type="tel" placeholder="N123" class="form-control input-lg" maxlength="5" name="senhaAnonima"
                id="senhaAnonima" #senhaAnonima [(ngModel)]="modelSenhaAnonima.senha" required>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
          <button type="submit" class="btn btn-primary" (click)="chamarSenhaAnonima()" [disabled]="loadingChamar">Chamar
            <img *ngIf="loadingChamar"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
