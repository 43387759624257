import { Component, Input, OnInit } from '@angular/core';
import { AlertService, Empresa, EmpresaService } from 'lib-smart-core';

@Component({
  selector: 'images-list',
  templateUrl: 'images-list.component.html',
})

export class ImagesListComponent implements OnInit {

  @Input() empresa: Empresa;

  constructor(private empresaService: EmpresaService, private alertService: AlertService) {

  }

  ngOnInit() {
  }

  removeImageAdd(index) {
    this.empresa.images.splice(index, 1);
    this.onSubmitEmpresa();
  }

  onSubmitEmpresa(): void {
    this.empresaService.update(this.empresa).subscribe(
      () => {
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    )
  }


}
