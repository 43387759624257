import { Component, ElementRef, Input, OnInit } from '@angular/core';
// import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-atualizar-senha',
  templateUrl: 'atualizar-senha.component.html'
})

export class AtualizarSenhaComponent implements OnInit {

  closeResult = '';
  @Input() context: ElementRef;

  // constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) { }

  ngOnInit() { }

  open(content) {
    console.log(content)
    // this.modalService.open(content).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }

  private getDismissReason(reason: any): string {
    // if (reason === ModalDismissReasons.ESC) {
    //   return 'by pressing ESC';
    // } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    //   return 'by clicking on a backdrop';
    // } else {
    //   return `with: ${reason}`;
    // }
    return 'by pressing ESC'
  }
}
