import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Atendimento, AtendimentoService, Franquia } from 'lib-smart-core';
import { Constantes } from '../../../environments/constantes';

@Component({

  selector: 'atendimento-info',
  templateUrl: './atendimento-info.component.html'
})
export class AtendimentoInfoComponent extends AbstractComponent implements OnInit {

  atendimento: Atendimento;
  franquia: Franquia;

  constructor(
    private atendimentoService: AtendimentoService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    // this.atendimento = new Atendimento(null, null, null, null, null, null, null, null, null);
    this.atendimento = {} as Atendimento;
    this.franquia = JSON.parse(localStorage.getItem(Constantes.currentFranquia));
    let idAtendimento: string = super.getParam('idAtendimento');
    if (idAtendimento) {
      this.getAtendimentoPorId(idAtendimento);
    }
  }

  getAtendimentoPorId(idAtendimento: string) {
    return this.atendimentoService.getById(idAtendimento).subscribe(
      (atendimento) => {
        this.atendimento = atendimento
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

}