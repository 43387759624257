import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suporte',
  templateUrl: './suporte.component.html'
})
export class SuporteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
