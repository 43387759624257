import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, Playlist, PlaylistService } from 'lib-smart-core';

@Component({
  selector: 'playlist-forms',
  templateUrl: './playlist-forms.component.html'
})
export class PlaylistFormsComponent extends AbstractComponent implements OnInit {

  playlist: Playlist;
  isNew: boolean = true;

  constructor(
    private playlistService: PlaylistService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.playlist = {} as Playlist;
    this.playlist.empresa = { _id: this.getIDEmpresaSession() } as Empresa;
    let id: string = super.getParam('id');
    if (id) {
      this.isNew = false;
      this.getPlaylistPorId(id.toString());
    }
  }

  getPlaylistPorId(id: string) {
    return this.playlistService.getById(id).subscribe(
      (playlist) => {
        this.playlist = playlist
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  onSubmit(): void {
    if (this.isNew) {
      this.playlistService.create(this.playlist)
        .subscribe(
          mesa => {
            this.alertService.success('Operação realizada com sucesso');
            super.goBack();
          },
          err => {
            this.alertService.error(err.error.message);
          }
        );
    } else {
      this.playlistService.update(this.playlist)
        .subscribe(
          mesa => {
            this.alertService.success('Operação realizada com sucesso');
            super.goBack();
          },
          err => {
            this.alertService.error(err.error.message);
          }
        );
    }

  }

}