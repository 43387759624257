import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constantes } from '../../../environments/constantes';

@Component({
  template: ''
})
export class HomeComponent implements OnInit {

  constructor(private router: Router
  ) {
  }

  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem(Constantes.currentUser));
    if (currentUser.usuario.admin || currentUser.usuario.manager) {
      this.router.navigate(['/empresa']);
    } else {
      this.router.navigate(['/atendimento']);
    }
  }

}