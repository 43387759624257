import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';

import { AlertModule, AuthGuard, CONSTANTES, ENV, JwtService, customHttpProvider } from 'lib-smart-core';
import { Constantes } from '../environments/constantes';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppsModule } from './modules/apps/apps.module';
import { AtendimentoModule } from './modules/atendimento/atendimento.module';
import { AvaliacaoModule } from './modules/avaliacao/avaliacao.module';
import { AvisoModule } from './modules/aviso/aviso.module';
import { ConfigModule } from './modules/config/config.module';
import { EmpresaModule } from './modules/empresa/empresa.module';
import { FilaModule } from './modules/fila/fila.module';
import { FranquiaModule } from './modules/franquia/franquia.module';
import { LayoutModule } from './modules/layout/layout.module';
import { LocalizacaoModule } from './modules/localizacao/localizacao.module';
import { LoginComponent } from './modules/login/login.component';
import { MesaModule } from './modules/mesa/mesa.module';
import { PagamentoModule } from './modules/pagamento/pagamento.module';
import { PlaylistModule } from './modules/playlist/playlist.module';
import { RedirectModule } from './modules/redirect/redirect.module';
import { RelatorioModule } from './modules/relatorio/relatorio.module';
import { SmartAvaliaModule } from './modules/smartavalia/smartavalia.module';
import { SmartFilasLiteModule } from './modules/smartfilas-lite/smartfilas-lite.module';
import { SmartPagerModule } from './modules/smartpager/smartpager.module';
import { SuporteComponent } from './modules/suporte/suporte.component';
import { TipoAtendimentoModule } from './modules/tipo-atendimento/tipo-atendimento.module';
import { TVModule } from './modules/tv/tv.module';
import { UsuarioModule } from './modules/usuario/usuario.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SuporteComponent,
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    EmpresaModule,
    UsuarioModule,
    FranquiaModule,
    FilaModule,
    MesaModule,
    PagamentoModule,
    RelatorioModule,
    ConfigModule,
    AtendimentoModule,
    AppsModule,
    PlaylistModule,
    TVModule,
    AvaliacaoModule,
    AvisoModule,
    SmartFilasLiteModule,
    SmartPagerModule,
    SmartAvaliaModule,
    TipoAtendimentoModule,
    ToastrModule.forRoot(),
    RedirectModule,
    LocalizacaoModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AlertModule
  ],
  providers: [
    AuthGuard,
    customHttpProvider,
    { provide: HTTP_INTERCEPTORS, useClass: JwtService, multi: true },
    { provide: ENV, useValue: environment },
    { provide: CONSTANTES, useValue: Constantes },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
