import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, ConfigService, Empresa, EmpresaService, Franquia, FranquiaService, Pager, QRCodeTemplate, Questionario, QuestionarioService } from 'lib-smart-core';
import QRCode from 'qrcode';
import * as watermark from 'watermarkjs';
import { environment } from "../../../environments/environment";

@Component({

  selector: 'smartavalia',
  templateUrl: './smartavalia.component.html'
})
export class SmartAvaliaComponent extends AbstractComponent implements OnInit {

  empresa: Empresa;

  page: number = 1;
  perPage: number = 10;
  total: number = 0;
  list: Questionario[];
  franquias: Franquia[];
  title: string = 'Selecione o modo de avaliação';
  isCopyLink: boolean = false;

  questionarioSelecionado: Questionario;
  franquiaSelecionada: Franquia;

  imgSmartAvalia;
  urlSmartAvalia: string;

  constructor(
    private configService: ConfigService,
    private franquiaService: FranquiaService,
    private empresaService: EmpresaService,
    private questionarioService: QuestionarioService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let id: string = super.getIDEmpresaSession();
    if (id) {
      this.getQuesetionariosPorEmpresa(id);
      this.getFranquiasPorEmpresa();
      this.getEmpresaPorId();
    }
  }

  getEmpresaPorId() {
    return this.empresaService.getById(super.getIDEmpresaSession()).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  getFranquiasPorEmpresa() {
    return this.franquiaService.getFranquiasPorEmpresa(super.getIDEmpresaSession()).subscribe(
      (franquia: Franquia[]) => {
        this.franquias = franquia
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  getQuesetionariosPorEmpresa(idEmpresa: string) {
    return this.questionarioService.getAllPorEmpresaPager(this.page, this.perPage, idEmpresa).subscribe(
      (pager: Pager<Questionario>) => {
        this.list = pager.list;
        this.total = pager.total;
        this.page = pager.page;
      },
      err => {
        this.alertService.error(err.error.message)
      }
    )
  }

  selecionar(obj: Questionario) {
    this.questionarioSelecionado = obj;
    this.imgSmartAvalia = null;
    this.urlSmartAvalia = null;
    this.franquiaSelecionada = null;
  }

  delete() {
    return this.questionarioService.delete(this.questionarioSelecionado._id).subscribe(
      () => {
        this.getQuesetionariosPorEmpresa(super.getIDEmpresaSession());
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    )
  }

  generateBannerSmartAvalia(franquia: Franquia, reavaliacao: boolean = true) {
    this.franquiaSelecionada = franquia
    this.urlSmartAvalia = null;

    const baseUrl = (environment.smartAvaliaUrl)
    let URL = '';
    if (reavaliacao) {
      URL = `${baseUrl}/feedback/${franquia.codigo}/${this.questionarioSelecionado._id}?reavalia=true`;
      this.title = "Link para avaliação em totem";
      this.isCopyLink = true;
      this.imgSmartAvalia = null;
    } else {
      this.isCopyLink = false;
      this.title = "QRCode e Link para envio de avaliação";
      URL = `${baseUrl}/feedback/${franquia.codigo}/${this.questionarioSelecionado._id}`;

      let qrCodeEspecifico: QRCodeTemplate = null;

      this.empresa && this.empresa.qrCodeTemplates.forEach((qrCodeTemplate: QRCodeTemplate) => {
        qrCodeTemplate['franquias'].forEach((id: string) => {
          if (qrCodeTemplate.type === 'AVALIA' && id === franquia._id) {
            qrCodeEspecifico = qrCodeTemplate;
          }
        })
      })

      let imageQRCodeEspecifico: string = qrCodeEspecifico && qrCodeEspecifico.image ? qrCodeEspecifico.image : this.empresa.imageTemplateAvalia;

      if (!!imageQRCodeEspecifico) {
        this.empresaService.downloadFromFirebase(imageQRCodeEspecifico).subscribe(
          (arrayBuffer) => {
            let metadata = {
              type: 'image/jpeg'
            };
            const file = new File([new Blob([arrayBuffer])], "SmartAvaliaQRcode.jpg", metadata);
            QRCode.toDataURL(URL, { errorCorrectionLevel: 'L', scale: 20 })
              .then(qrcodeData => {
                watermark([file, qrcodeData])
                  .image(watermark.image.center())
                  .then(img => {
                    this.imgSmartAvalia = img.src;
                  });
              })
              .catch(err => {
                console.error(err)
              })
          }
        );
      } else {
        let blob = null;
        let xhr = new XMLHttpRequest();
        xhr.open("GET", './assets/img/SmartAvaliaQRcode.jpg');
        xhr.responseType = "blob";
        xhr.onload = () => {
          blob = xhr.response;
          let metadata = {
            type: 'image/jpeg'
          };
          let file = new File([blob], "SmartAvaliaQRcode.jpg", metadata);

          QRCode.toDataURL(URL, { errorCorrectionLevel: 'L', scale: 20 })
            .then(qrcodeData => {
              watermark([file, qrcodeData])
                .image(watermark.image.center())
                .then(img => {
                  this.imgSmartAvalia = img.src;
                });
            })
            .catch(err => {
              console.error(err)
            })
        }
        xhr.send();
      }
    }
    this.urlSmartAvalia = URL;
  }

  copyDynamicLink() {
    navigator.clipboard.writeText(this.urlSmartAvalia);
    this.alertService.success('Link copiado para área de transferência!');
  }

  closeModal(): void {
    this.title = "Selecione o modo de avaliação";
    this.isCopyLink = false;
  }
}
