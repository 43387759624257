import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Config, ConfigService, Empresa, EmpresaService, Franquia, FranquiaService, Pager, QRCodeTemplate, Usuario } from 'lib-smart-core';
import QRCode from 'qrcode';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import * as watermark from 'watermarkjs';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'smartfilas-lite',
  templateUrl: './smartfilas-lite.component.html'
})
export class SmartFilaLiteComponent extends AbstractComponent implements OnInit {

  pager: Pager<Franquia> = new Pager<Franquia>({ perPage: 25 });

  empresa: Empresa;
  franquias: Franquia[];
  franquiaSelecionada: Franquia;
  configSelecionado: Config;

  imgCertificados: any[];

  imgSmartFilas: any;
  imgSmartAvalia: any;

  urlSmartFilas: string;
  urlSmartFilasContinuo: string;

  userLogged: Usuario;

  listObservable: Observable<Pager<Franquia>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  constructor(
    private configService: ConfigService,
    private franquiaService: FranquiaService,
    private empresaService: EmpresaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.userLogged = super.getCurrentUserUser();
    this.imgCertificados = [];
    let id: string = super.getIDEmpresaSession();
    if (id) {
      this.loadEmpresa();
    }
  }

  loadEmpresa() {
    this.empresaService.getById(super.getIDEmpresaSession()).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
        this.getFranquiasPorEmpresa();
      }, err => this.alertService.error(err.error.message)
    );
  }

  getFranquiasPorEmpresa() {
    this.searchParams = {
      empresa: this.empresa._id
    }
    return this.franquiaService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe(
      (pager: Pager<Franquia>) => {
        this.pager = pager;
        this.franquias = pager.list;
        if (this.userLogged.admin) {
          this.franquias = this.pager.list
        } else {
          this.franquias = new Array<Franquia>();
          this.franquias.push(...this.userLogged.franquias);
        }
        this.setupObservableSearch();
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.franquiaService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Franquia>) => {
      this.pager = pager;
      this.franquias = pager.list;
    });
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getFranquiasPorEmpresa();
  }

  generateBannerSmartFilas(franquia: Franquia) {
    this.configService.getConfigPorFranquia(franquia._id).subscribe({
      next: (config: Config) => {
        this.imgSmartFilas = null;
        this.urlSmartFilas = null;
        this.franquiaSelecionada = franquia;
        this.configSelecionado = config;
        let qrCodeEspecifico: QRCodeTemplate = null;
        this.empresa && this.empresa.qrCodeTemplates.forEach((qrCodeTemplate: QRCodeTemplate) => {
          qrCodeTemplate['franquias'].forEach((id: string) => {
            if (qrCodeTemplate.type === 'LITE' && id === franquia._id) {
              qrCodeEspecifico = qrCodeTemplate;
            }
          })
        })

        let imageQRCodeEspecifico: string = qrCodeEspecifico && qrCodeEspecifico.image ? qrCodeEspecifico.image : this.empresa.imageTemplateLite;
        if (!!imageQRCodeEspecifico) {
          this.empresaService.downloadFromFirebase(imageQRCodeEspecifico).subscribe(
            (arrayBuffer) => {
              let metadata = {
                type: 'image/jpeg'
              };
              const file = new File([new Blob([arrayBuffer])], "SmartLiteFilasQRcode.jpg", metadata);
              const baseUrl = (environment.smartLiteUrl)

              const URL = `${baseUrl}/filas/${franquia.codigo}`;
              const URLContinuo = `${baseUrl}/filas-qrcode/${franquia.codigo}`;
              this.urlSmartFilas = URL;
              this.urlSmartFilasContinuo = URLContinuo;

              QRCode.toDataURL(URL, { errorCorrectionLevel: 'L', scale: 20 })
                .then(qrcodeData => {
                  watermark([file, qrcodeData])
                    .image(watermark.image.center())
                    .then(img => {
                      this.imgSmartFilas = img.src;
                    });
                })
                .catch(err => {
                  console.error(err)
                });
            }
          );
        } else {
          let blob = null;
          let xhr = new XMLHttpRequest();
          xhr.open("GET", './assets/img/SmartQRcode.jpg');
          xhr.responseType = "blob"; // force the HTTP response, response-type header to be blob
          xhr.onload = () => {
            blob = xhr.response;//xhr.response is now a blob object
            let metadata = {
              type: 'image/jpeg'
            };
            let file = new File([blob], "SmartLiteFilasQRcode.jpg", metadata);
            const baseUrl = (environment.smartLiteUrl)
            const URL = `${baseUrl}/filas/${franquia.codigo}`;
            const URLContinuo = `${baseUrl}/filas-qrcode/${franquia.codigo}`;
            this.urlSmartFilas = URL;
            this.urlSmartFilasContinuo = URLContinuo;

            QRCode.toDataURL(URL, { errorCorrectionLevel: 'L', scale: 20 })
              .then(qrcodeData => {
                watermark([file, qrcodeData])
                  .image(watermark.image.center())
                  .then(img => {
                    this.imgSmartFilas = img.src;
                  });
              })
              .catch(err => {
                console.error(err)
              });
          }
          xhr.send();
        }
      },
      error: err => this.alertService.error(err.error.message)
    });

  }

  generateBannerSmartAvalia(franquia: Franquia) {
    this.imgSmartAvalia = null;
    // console.log('setupCertificado')
    // console.log(this.curso.imagemCertificado)

    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", './assets/img/SmartAvaliaQRcode.jpg');
    xhr.responseType = "blob"; // force the HTTP response, response-type header to be blob
    xhr.onload = () => {
      // console.log('xhr.onload')
      // console.log(xhr.response)
      blob = xhr.response;//xhr.response is now a blob object
      let metadata = {
        type: 'image/jpeg'
      };
      let file = new File([blob], "SmartLiteAvaliaQRcode.jpg", metadata);

      const URL = `${environment.smartAvaliaUrl}/feedback/${franquia.codigo}/principal`;
      console.log(URL);

      // With promises
      QRCode.toDataURL(URL, { errorCorrectionLevel: 'L', scale: 20 })
        .then(qrcodeData => {
          // console.log('image qrcode')
          // console.log(qrcodeData)
          watermark([file, qrcodeData])
            .image(watermark.image.center())
            .then(img => {
              // this.waterMarkImage.nativeElement.src = img.src;
              this.imgSmartAvalia = img.src;
              // this.showCertificado = true;
            });
        })
        .catch(err => {
          console.error(err)
        })

    }
    xhr.send();
  }

  getImagemCertificadoByIndex(index: number) {
    return (this.imgCertificados[index]);
  }

  copyDynamicLink() {
    navigator.clipboard.writeText(this.urlSmartFilas);
    this.alertService.success('Link copiado para área de transferência!');
  }

  copyDynamicLinkContinuo() {
    navigator.clipboard.writeText(this.urlSmartFilasContinuo);
    this.alertService.success('Link copiado para área de transferência!');
  }

}
