import { Chart } from "chart.js";
import { saveAs } from 'file-saver';
import { DateUtil, Pager, RelatorioAtendimentoService, Senha, UtilHelper } from 'lib-smart-core';
import * as moment from 'moment'; // add this 1 of 4
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
import { SearchParams } from "../../../../../../lib-smart-core/src/lib/commons/abstract.types";

export class RelatorioAntedimentoHelper {

  private itensPerPage: number = 5000;

  constructor(
    private relatorioAtendimentoService: RelatorioAtendimentoService,
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  public reportCSVAtendimentos(searchParams: SearchParams) {
    return this.buildingArraySenhasComplete(1, this.itensPerPage, searchParams)
      .then((list: Senha[]) => {
        this.generateCSV(list, searchParams);
      });
  }

  private buildingArraySenhasComplete(page: number, perPage: number, searchParams: SearchParams, total?: number, leads?: Array<Senha>): Promise<Senha[]> {
    if (!leads) {
      total = 0;
      leads = new Array<Senha>();
    }

    return this.relatorioAtendimentoService.getAllPagerQuerySearch(page, perPage, null, searchParams)
      .toPromise()
      .then((pager: Pager<Senha>) => {

        pager.list.forEach((element) => {
          leads.push(element);
        });

        if ((page * perPage) >= pager.total) {
          return leads;
        } else {
          return this.buildingArraySenhasComplete((page + 1), perPage, searchParams, pager.total, leads);
        }
      });
  }

  private generateCSV(items: any[], searchParams: SearchParams) {
    let data: Array<any> = new Array<any>();
    items.forEach((item: any) => {
      let estabelecimento = `${item.franquia.nome}`;
      let created_at = DateUtil.toFormart(item.senha.data, 'dd/MM/yyyy HH:mm:ss');
      let created_at2 = DateUtil.toFormart(item.dataFim, 'dd/MM/yyyy HH:mm:ss');
      let duracao_fila = UtilHelper.getDuracao(item.senha.data, item.dataFim);
      let duracao = UtilHelper.getDuracao(item.dataInicio, item.dataFim);
      let fila = item.fila.nome;
      let senha = item.senha.senha;
      let atendente = item.usuario.nome;
      let campos: string = '';
      item.senha?.campos?.forEach((campo) => {
        campos += `${campo.key}: ${campo.value} | `;
      });
      let tiposAtendimento: string = '';
      item.tipoAtendimentos.forEach((tipo) => {
        tiposAtendimento += tipo.nome + ' | ';
      });
      let descricao = item.descricao;
      const obj = { estabelecimento, 'data_inicio': created_at, 'data_fim': created_at2, duracao_fila, duracao, fila, senha, atendente, campos, tiposAtendimento, descricao };
      data.push(obj);
    });

    const header = Object.keys({
      estabelecimento: 'item.estabelecimento',
      data_inicio: 'item.data_inicio',
      data_fim: 'item.data_fim',
      duracao_fila: 'item.duracao_fila',
      duracao: 'item.duracao',
      fila: 'item.fila',
      senha: 'item.senha',
      atendente: 'item.atendente',
      campos: 'item.campos',
      tiposAtendimento: 'item.tiposAtendimento',
      descricao: 'item.descricao',
    });
    let csv = data.map(row => header.map(fieldName => row[fieldName]).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    const startDateSplited = String(searchParams["startDate"]).split('-');
    const startDateDate = new Date(Date.UTC(+startDateSplited[0], +startDateSplited[1] - 1, +startDateSplited[2], 0, 0, 0));
    startDateDate.setTime(startDateDate.getTime() + startDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const endDateSplited = String(searchParams["endDate"]).split('-');
    const endDateDate = new Date(Date.UTC(+endDateSplited[0], +endDateSplited[1] - 1, +endDateSplited[2], 0, 0, 0));
    endDateDate.setTime(endDateDate.getTime() + endDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const startDate = moment(startDateDate).format('DDMMYYYY');
    const endDate = moment(endDateDate).format('DDMMYYYY');

    const blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${items[0].empresa.nome} - Relatório de Atendimentos ${startDate}_${endDate}.csv`);
  }

  public reportXLSXAtendimentos(searchParams: Partial<SearchParams>) {
    return this.buildingArraySenhasComplete(1, this.itensPerPage, searchParams)
      .then((list: Senha[]) => {
        this.generateXLSX(list, searchParams);
      });
  }

  private getCampos(item) {
    let campos = '';
    item.campos?.forEach((campo) => {
      campos += `${campo.key}: ${campo.value}` + '\n';
    });
    campos = campos.substring(0, campos.lastIndexOf('\n'));
    return campos;
  }

  private generateXLSX(items: any[], searchParams: Partial<SearchParams>) {
    let data: Array<any> = new Array<any>();
    items.forEach((item: any) => {
      let estabelecimento = `${item.franquia.nome}`;
      let created_at = DateUtil.toFormart(item.created_at, 'dd/MM/yyyy HH:mm:ss');
      let created_at2 = DateUtil.toFormart(item.dataFim, 'dd/MM/yyyy HH:mm:ss');
      let duracao_fila = UtilHelper.getDuracao(item.senha.data, item.dataFim);
      let duracao = UtilHelper.getDuracao(item.dataInicio, item.dataFim);
      let fila = item.fila.nome;
      let senha = item.senha.senha;
      let atendente = item.usuario.nome;
      let tiposAtendimento: string = '';
      let campos: string = '';
      item.senha?.campos?.forEach((campo) => {
        campos += `${campo.key}: ${campo.value} | `;
      });
      item.tipoAtendimentos.forEach((tipo) => {
        tiposAtendimento += tipo.nome + ' | ';
      });
      let descricao = item.descricao;
      const obj = { estabelecimento, 'data_inicio': created_at, 'data_fim': created_at2, duracao_fila, duracao, fila, senha, atendente, campos, tiposAtendimento, descricao };
      data.push(obj);
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const startDateSplited = String(searchParams["startDate"]).split('-');
    const startDateDate = new Date(Date.UTC(+startDateSplited[0], +startDateSplited[1] - 1, +startDateSplited[2], 0, 0, 0));
    startDateDate.setTime(startDateDate.getTime() + startDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const endDateSplited = String(searchParams["endDate"]).split('-');
    const endDateDate = new Date(Date.UTC(+endDateSplited[0], +endDateSplited[1] - 1, +endDateSplited[2], 0, 0, 0));
    endDateDate.setTime(endDateDate.getTime() + endDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const startDate = moment(startDateDate).format('DDMMYYYY');
    const endDate = moment(endDateDate).format('DDMMYYYY');

    XLSX.writeFile(wb, `${items[0].empresa.nome} - Relatório de Atendimentos - ${startDate}_${endDate}.xlsx`);
  }

  reportPDFSenhas(searchParams: Partial<SearchParams>, barChart: Chart, pieChart: Chart) {
    return this.buildingArraySenhasComplete(1, this.itensPerPage, searchParams)
      .then((list: Senha[]) => {
        this.generatePDFRelatorioSenhas(searchParams, list, barChart, pieChart);
      });
  }

  generatePDFRelatorioSenhas(searchParams: Partial<SearchParams>, list: any[], barChart: Chart, pieChart: Chart) {

    let body: any[] = [
      [
        { text: 'Estabelecimento', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Data do Atendimento', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Tempo de espera na fila', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Duração do Atendimento', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Fila/Senha', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Atendente', style: 'tableHeader', bold: true, fontSize: 8, alignment: 'center' },
        { text: 'Campos', style: 'tableHeader', bold: true, fontSize: 8, alignment: 'center' },
      ],
    ];

    list.forEach((item: any) => {
      body.push([
        { text: item.franquia.nome, fontSize: 8 },
        { text: moment(item.senha.data).format('DD/MM/YYYY HH:mm:ss') + "\n" + moment(item.dataFim).format('DD/MM/YYYY HH:mm:ss'), fontSize: 8 },
        { text: UtilHelper.getDuracao(item.senha.data, item.dataFim), fontSize: 8 },
        { text: UtilHelper.getDuracao(item.dataInicio, item.dataFim), fontSize: 8 },
        { text: item.fila.nome + '\n' + item.senha.senha, fontSize: 8, },
        { text: item.usuario.nome, fontSize: 8, alignment: 'center' },
        { text: this.getCampos(item), fontSize: 8, alignment: 'center' },
      ]);
    });

    const startDateSplited = String(searchParams["startDate"]).split('-');
    const startDateDate = new Date(Date.UTC(+startDateSplited[0], +startDateSplited[1] - 1, +startDateSplited[2], 0, 0, 0));
    startDateDate.setTime(startDateDate.getTime() + startDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const endDateSplited = String(searchParams["endDate"]).split('-');
    const endDateDate = new Date(Date.UTC(+endDateSplited[0], +endDateSplited[1] - 1, +endDateSplited[2], 0, 0, 0));
    endDateDate.setTime(endDateDate.getTime() + endDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const startDate = moment(startDateDate).format('DD/MM/YYYY');
    const endDate = moment(endDateDate).format('DD/MM/YYYY');

    const nomeEmpresa = list[0].empresa.nome;
    const titleDocument = `${nomeEmpresa} - Relatório de Atendimentos - ${startDate} - ${endDate}`;
    const pieChartURL = pieChart.canvas.toDataURL('image/png');
    const barChartURL = barChart.canvas.toDataURL('image/png');


    let docDefinition = {
      pageOrientation: 'landscape',
      header: { text: 'Relatório de Atendimentos', bold: true, margin: [0, 20, 0, 8], alignment: 'center' },
      footer: {
        columns: [
          { text: titleDocument, fontSize: 10, bold: true, margin: [10, 20, 10, 8] },
          { text: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'), fontSize: 10, bold: true, margin: [10, 20, 10, 8], alignment: 'right' }
        ]
      },
      content: [
        { text: 'Filtro: ' + startDate + ' - ' + endDate, style: 'header', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Atendimentos realizados por tipo', style: 'tableHeader', alignment: 'center', bold: true, fontSize: 8 },
                { text: 'Atendimentos realizados por Atendente', style: 'tableHeader', alignment: 'center', bold: true, fontSize: 8 },
              ],
              [
                { image: barChartURL, alignment: 'center', width: 350, },
                { image: pieChartURL, alignment: 'center', width: 350, },
              ],
            ]
          },
          layout: 'lightHorizontalLines'
        },

        { text: '', style: 'header', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        { text: `Relatórios de Atendimentos (${list.length})`, style: 'header', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: [100, 100, '*', '*', 100, '*', '*'],
            body: body
          },
          layout: 'lightHorizontalLines'
        },
      ]
    };

    // pdfMake.createPdf(docDefinition).open();
    // pdfMake.createPdf(docDefinition).open({}, window);

    pdfMake.createPdf(docDefinition).download(titleDocument + '.pdf');
  }

}