
import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractComponent, FeedbackService, Franquia, FranquiaService, Pergunta, PerguntaService } from 'lib-smart-core';

@Component({
  selector: 'feedback-modal-analitico',
  templateUrl: './feedback-modal-analitico.component.html'
})

export class FeedbackModalAnaliticoComponent extends AbstractComponent implements OnInit {

  @Input() idQuestionario: string;
  @Input() idFranquia: string;
  @Input() rating: number = 0;

  franquia: Franquia;
  perguntas: Pergunta[];

  star_full: string = 'fa fa-star fa-lg';
  star_empty: string = 'fa fa-star-o fa-lg';
  star_half: string = 'fa fa-star-half-o fa-lg';

  star_1: string;
  star_2: string;
  star_3: string;
  star_4: string;
  star_5: string;

  data: any;

  constructor(
    private feedbackService: FeedbackService,
    private franquiaService: FranquiaService,
    private perguntaService: PerguntaService,
    public injector: Injector
  ) {
    super(injector);
    this.star_1 = this.star_empty;
    this.star_2 = this.star_empty;
    this.star_3 = this.star_empty;
    this.star_4 = this.star_empty;
    this.star_5 = this.star_empty;
  }

  ngOnInit() {
    this.getFranquiaPorId();
    this.getPerguntasPorQuestionario();
  }

  getFranquiaPorId() {
    this.franquiaService.getById(this.idFranquia).subscribe(
      franquia => {
        this.franquia = franquia;
      },
      err => this.alertService.error(err.error.message)
    );
  }

  getPerguntasPorQuestionario() {
    this.perguntaService.getAllPorQuestionario(this.idQuestionario).subscribe(
      (perguntas: Pergunta[]) => {
        this.perguntas = perguntas;
        this.getResultadoAvaliacaoFranquiaDetalhada();
      },
      err => this.alertService.error(err.error.message)
    );
  }

  getResultadoAvaliacaoFranquiaDetalhada() {
    this.feedbackService.getFeedbackPorQuestionarioAnalitico(this.idQuestionario, this.idFranquia).subscribe(
      data => {
        this.data = data;
        // console.log(data);
      },
      err => this.alertService.error(err.error.message)
    );
  }

  getPerguntaPorId(idPergunta: string) {
    let result: Pergunta = null;
    if (this.perguntas && this.perguntas.length > 0) {
      for (let i = 0; i < this.perguntas.length; i++) {
        const obj = this.perguntas[i];
        if (obj._id === idPergunta) {
          result = obj;
          break;
        }
      }
    }
    return result;
  }

  getPercentualStr(valor: number): string {
    let width = 0;
    if (this.data) {
      width = (((valor) / (this.data.totalFeedbacks)) * 100);
    }

    let str = width.toString();
    if (str.startsWith('0')) {
      str = '1';
    } else {
      if (str.indexOf('.') != -1) {
        str = str.substring(0, str.indexOf('.'));
      }
    }
    // console.log(width);
    return str + '%';
  }

}
