import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { TipoAtendimentoFormsComponent } from './tipo-atendimento-forms.component';
import { TipoAtendimentoComponent } from './tipo-atendimento.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    TipoAtendimentoComponent,
    TipoAtendimentoFormsComponent,
  ],
  exports: [
    TipoAtendimentoComponent,
    TipoAtendimentoFormsComponent,
  ],
  providers: []
})

export class TipoAtendimentoModule { }