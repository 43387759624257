import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule, UsuarioService } from 'lib-smart-core';
import { TagInputModule } from 'ngx-chips';
import { UsuarioEsqueciMinhaSenhaComponent } from './usuario-esqueci-minha-senha.component';
import { UsuarioFormsComponent } from "./usuario-forms.component";
import { UsuarioListaComponent } from "./usuario-lista.component";
import { UsuarioSenhaFormsComponent } from "./usuario-senha-forms.component";
import { UsuarioComponent } from "./usuario.component";

@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    TagInputModule,
  ],
  declarations: [
    UsuarioComponent,
    UsuarioListaComponent,
    UsuarioFormsComponent,
    UsuarioSenhaFormsComponent,
    UsuarioEsqueciMinhaSenhaComponent,
  ],
  exports: [
    UsuarioComponent,
    UsuarioListaComponent,
    UsuarioFormsComponent,
    UsuarioSenhaFormsComponent,
    UsuarioEsqueciMinhaSenhaComponent,
  ],
  providers: [UsuarioService]
})

export class UsuarioModule { }