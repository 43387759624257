<div id="wrapper">

  <menu-component *ngIf="userLogged"></menu-component>

  <div id="page-wrapper" class="gray-bg" [ngClass]="!userLogged ? 'page-wrapper-login': ''">
    <div class="row border-bottom" *ngIf="userLogged" id="SIDE_NOTV">
      <nav class="navbar navbar-static-top white-bg" role="navigation" style="margin-bottom: 0">
        <div class="navbar-header">
          <a class="navbar-minimalize minimalize-styl-2 btn btn-primary"><i class="fa fa-bars"></i> </a>
          <a class=" minimalize-styl-2 btn btn-primary" *ngIf="smartFilas || smartFilasLite"
            [routerLink]="['/atendimento']">Atendimento</a>
        </div>
        <ul class="nav navbar-top-links navbar-right">
          <li class="hidden-xs">
            <strong><span class="m-r-sm text-muted welcome-message" *ngIf="FRANQUIA_SESSION">ESTABELECIMENTO:
                {{FRANQUIA_SESSION?.nome}}</span></strong>
          </li>
          <li class="hidden-xs">
            <strong><span class="m-r-sm text-muted welcome-message" *ngIf="MESA_SESSION">ATENDIMENTO:
                {{MESA_SESSION?.nome}}</span></strong>
          </li>
          <li class="hidden-xs" *ngIf="!!LOCALIZACAO_SESSION">
            <strong><span class="m-r-sm text-muted welcome-message" *ngIf="MESA_SESSION">LOCALIZAÇÃO:
                {{LOCALIZACAO_SESSION?.nome}}</span></strong>
          </li>
          <li>
            <a href="javascript:void(0);" (click)="logout()">
              <i class="fa fa-sign-out"></i> Sair
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <alert></alert>
    <router-outlet></router-outlet>
    <div class="footer" id="FOOTER_NOTV">
      <div class="pull-right">
        <strong>v6.13.0</strong>
      </div>
      <div>
        Smartline &copy; 2024
      </div>
    </div>

  </div>
</div>
