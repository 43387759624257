import { saveAs } from 'file-saver';
import { DateUtil, Pager, Senha, SenhaService } from 'lib-smart-core';
import * as moment from 'moment'; // add this 1 of 4
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';

export class RelatorioClientesHelper {

  constructor(
    private senhaService: SenhaService,
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  public reportCSVLeads(idEmpresa: string) {
    console.log('reportCSVLeads')
    return this.buildingArrayLeadsComplete(1, 500, idEmpresa)
      .then((list: Senha[]) => {
        console.log(list)
        this.generateCSV(list);
      });
  }

  private buildingArrayLeadsComplete(page: number, perPage: number, idEmpresa: string, total?: number, leads?: Array<Senha>): Promise<Senha[]> {
    if (!leads) {
      total = 0;
      leads = new Array<Senha>();
    }

    return this.senhaService.pagerClientes(page, perPage, idEmpresa)
      .toPromise()
      .then((pager: Pager<Senha>) => {

        pager.list.forEach((element) => {
          leads.push(element);
        });

        if ((page * perPage) >= pager.total) {
          return leads;
        } else {
          return this.buildingArrayLeadsComplete((page + 1), perPage, idEmpresa, pager.total, leads);
        }
      });
  }

  private generateCSV(leads: any[]) {
    let data: Array<any> = new Array<any>();
    leads.forEach((lead: any) => {
      let created_at = DateUtil.toFormart(lead.created_at, 'dd/MM/yyyy HH:mm:ss');
      let empresa = lead.empresa.nome;
      let franquia = lead.franquia.nome;
      let nome = lead.nome;
      let telefone = lead.numero;
      let email = lead.email;
      const obj = { 'data': created_at, empresa, 'estabelecimento': franquia, nome, telefone, email };
      data.push(obj);
    });

    const header = Object.keys({
      data: 'lead.data',
      empresa: 'lead.empresa',
      estabelecimento: 'lead.estabelecimento',
      nome: 'lead.nome',
      telefone: 'lead.telefone',
      email: 'lead.email',
    });
    let csv = data.map(row => header.map(fieldName => row[fieldName]).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${leads[0].empresa.nome} - Relatório de Clientes.csv`);
  }

  public reportXLSXLeads(idEmpresa: string) {
    return this.buildingArrayLeadsComplete(1, 500, idEmpresa)
      .then((list: Senha[]) => {
        console.log(list)
        this.generateXLSX(list);
      });
  }

  private generateXLSX(items: any[]) {
    let data: Array<any> = new Array<any>();
    items.forEach((lead: any) => {
      let created_at = DateUtil.toFormart(lead.created_at, 'dd/MM/yyyy HH:mm:ss');
      let empresa = lead.empresa.nome;
      let franquia = lead.franquia.nome;
      let nome = lead.nome;
      let telefone = lead.numero;
      let email = lead.email;
      const obj = { 'data': created_at, empresa, 'estabelecimento': franquia, nome, telefone, email };
      data.push(obj);
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${items[0].empresa.nome} - Relatório de Clientes.xlsx`);
  }

  reportPDFLeads(idEmpresa: string) {
    return this.buildingArrayLeadsComplete(1, 500, idEmpresa)
      .then((list: Senha[]) => {
        console.log(list)
        this.generatePDFRelatorioLeads(list);
      });
  }

  generatePDFRelatorioLeads(list: any[]) {

    let body: any[] = [
      [
        { text: 'Data', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Estabelecimento', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Nome', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Telefone', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'E-mail', style: 'tableHeader', bold: true, fontSize: 8 },
      ],
    ];

    list.forEach((item: any) => {
      body.push([
        { text: moment(item.created_at).format('DD/MM/YYYY HH:mm:ss'), fontSize: 8 },
        { text: item.franquia.nome, fontSize: 8 },
        { text: item.nome, fontSize: 8, },
        { text: item.numero, fontSize: 8, },
        { text: item.email, fontSize: 8 },
      ]);
    });

    const nomeEmpresa = list[0].empresa.nome;
    const titleDocument = `${nomeEmpresa} - Relatório de Clientes - ${moment(new Date()).format('DD/MM/YYYY')}`;

    let docDefinition = {
      pageOrientation: 'landscape',
      header: { text: 'Relatório Clientes', bold: true, margin: [0, 20, 0, 8], alignment: 'center' },
      footer: {
        columns: [
          { text: titleDocument, fontSize: 10, bold: true, margin: [10, 20, 10, 8] },
          { text: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'), fontSize: 10, bold: true, margin: [10, 20, 10, 8], alignment: 'right' }
        ]
      },
      content: [

        { text: '', style: 'header', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        { text: `Relatórios de Clientes (${list.length})`, style: 'header', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['*', '*', 100, 100, '*'],
            body: body
          },
          layout: 'lightHorizontalLines'
        },
      ]
    };

    // pdfMake.createPdf(docDefinition).open();
    // pdfMake.createPdf(docDefinition).open({}, window);

    pdfMake.createPdf(docDefinition).download(titleDocument + '.pdf');
  }

}