import { NgModule } from '@angular/core';

import { SharedModule } from 'lib-smart-core';
import { FranquiaModule } from '../franquia/franquia.module';
import { PagamentoModule } from '../pagamento/pagamento.module';
import { UsuarioModule } from '../usuario/usuario.module';
import { EmpresaFormsComponent } from './empresa-forms.component';
import { EmpresaComponent } from './empresa.component';
import { HomeComponent } from './home.component';
import { SmartAvaliaModule } from '../smartavalia/smartavalia.module';


@NgModule({
  imports: [
    SharedModule,
    FranquiaModule,
    UsuarioModule,
    PagamentoModule,
    SmartAvaliaModule
  ],
  declarations: [
    HomeComponent,
    EmpresaComponent,
    EmpresaFormsComponent,
  ],
  exports: [
    HomeComponent,
    EmpresaComponent,
    EmpresaFormsComponent
  ],
  providers: [
  ]
})

export class EmpresaModule { }
