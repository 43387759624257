import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Pager, Usuario, UsuarioService } from 'lib-smart-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';

@Component({
  selector: 'usuario-lista',
  templateUrl: './usuario-lista.component.html'
})
export class UsuarioListaComponent extends AbstractComponent implements OnInit {

  pager: Pager<Usuario> = new Pager<Usuario>({ perPage: 50 });

  imageDefaultProfile: string = '';

  idEmpresa: string;
  usuarioSelecionado: Usuario;

  listObservable: Observable<Pager<Usuario>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  constructor(
    private usuarioService: UsuarioService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let idEmpresa: string = super.getIDEmpresaSession();
    if (idEmpresa) {
      this.idEmpresa = idEmpresa;
      this.getUsuariosPorEmpresa();
    }
  }

  getUsuariosPorEmpresa() {
    this.searchParams = {
      empresa: this.idEmpresa
    }
    return this.usuarioService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe(
      (pager: Pager<Usuario>) => {
        this.pager = pager;
        this.setupObservableSearch();
      },
      err => {
        console.log(err)
      }
    )
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getUsuariosPorEmpresa();
  }

  selecionar(usuario: Usuario) {
    this.usuarioSelecionado = usuario;
  }

  delete() {
    return this.usuarioService.delete(this.usuarioSelecionado._id).subscribe(
      () => {
        this.getUsuariosPorEmpresa();
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    )
  }

  bloquear(): void {
    this.usuarioService.bloquear(this.usuarioSelecionado._id).subscribe(
      () => {
        this.getUsuariosPorEmpresa();
        this.alertService.success('Operação realizada com sucesso');
      },
      err => {
        console.log(err);
        this.alertService.error(err.error.message);
      }
    );
  }

  desbloquear(): void {
    this.usuarioService.desbloquear(this.usuarioSelecionado._id).subscribe(
      () => {
        this.getUsuariosPorEmpresa();
        this.alertService.success('Operação realizada com sucesso');
      },
      err => {
        console.log(err);
        this.alertService.error(err.error.message);
      }
    );
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.usuarioService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Usuario>) => {
      this.pager = pager;
    });
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

}