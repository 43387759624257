import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractComponent, CEPService, Empresa, Franquia, FranquiaService, GeocodeHereService, Localizacao, LocalizacaoService, MapComponent } from 'lib-smart-core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'localizacao-forms',
  templateUrl: './localizacao-forms.component.html'
})
export class LocalizacaoFormsComponent extends AbstractComponent implements OnInit {

  @ViewChild(MapComponent) mapComponent: MapComponent;

  loading: boolean = false;

  searchBox: string;
  model: any;

  localizacao: Localizacao;
  isNew: boolean = true;

  franquiasModal: Franquia[];
  franquiasSelecionadas: Franquia[];

  usuariosObs: Observable<Franquia[]>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  constructor(
    private localizacaoService: LocalizacaoService,
    private franquiaService: FranquiaService,
    private cepService: CEPService,
    private geocodeHereService: GeocodeHereService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model = {};
    this.franquiasSelecionadas = [];

    this.localizacao = {} as Localizacao;
    let idEmpresa: string = super.getIDEmpresaSession();
    if (idEmpresa) {
      this.localizacao.empresa = { _id: idEmpresa } as Empresa;
      this.getFranquiasPorEmpresa(idEmpresa);
      this.setupFranquiasLoad(idEmpresa);
    }
    let id: string = super.getParam('id');
    if (id) {
      this.isNew = false;
      this.getLocalizacaoPorId(id.toString());
    }
  }

  search(termo: string) {
    this.termoDaBusca.next(termo);
  }

  setupFranquiasLoad(idEmpresa: string) {
    this.usuariosObs = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        console.log(term);
        return term ? this.franquiaService.searchEmpresa(idEmpresa, term) : this.franquiaService.getFranquiasPorEmpresa(idEmpresa);
      }));
    this.usuariosObs.subscribe((franquias: Franquia[]) => {
      console.log(franquias);
      return this.franquiasModal = franquias;
    });
  }

  loadModalFranquias() {
    this.searchBox = '';
    this.model.searchBox = '';
    this.termoDaBusca.next('');
  }

  getLocalizacaoPorId(id: string) {
    return this.localizacaoService.getById(id).subscribe(
      (localizacao: Localizacao) => {
        this.localizacao = localizacao
        this.franquiasSelecionadas = this.localizacao.franquias
        this.mapComponent.setLatLng(this.localizacao.latitude, this.localizacao.longitude);
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      (franquias) => {
        this.franquiasModal = franquias;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  excluirFranquiaSelecionada(index: number) {
    this.franquiasSelecionadas.splice(index, 1);
  }

  selecionarFraquia(franquia: Franquia) {
    this.franquiasSelecionadas.push(franquia);
  }

  franquiaJaAdicionada(id: string) {
    let result = false;
    for (let i = 0; i < this.franquiasSelecionadas.length; i++) {
      let franquia: Franquia = this.franquiasSelecionadas[i];
      if (franquia._id.toString() === id.toString()) {
        result = true;
        break;
      }
    };
    return result;
  }

  onSubmit(): void {
    this.localizacao.franquias = this.franquiasSelecionadas;

    console.log(this.localizacao);

    if (this.isNew) {
      this.localizacaoService.create(this.localizacao).subscribe(
        franquia => {
          this.alertService.success('Operação realizada com sucesso');
          super.goBack();
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
    } else {
      this.localizacaoService.update(this.localizacao).subscribe(
        usuario => {
          this.alertService.success('Operação realizada com sucesso');
          super.goBack();
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
    }
  }

  searchingCep() {
    this.getCep();
    this.geocodeByCEP();
  }

  getCep() {
    this.loading = true;
    this.cepService.getCep(this.localizacao.cep).subscribe((resul: any) => {
      if (resul.length != 0) {
        this.localizacao.endereco = resul.logradouro
        this.localizacao.cidade = resul.cidade
        this.localizacao.estado = resul.uf
        this.localizacao.bairro = resul.bairro
        this.loading = false;
      } else {
        this.alertService.error('CEP não encontrado')
        this.loading = false;
      }
    })
  }

  endedLngLat(position: { latitude: number, longitude: number }) {
    this.localizacao.latitude = position.latitude;
    this.localizacao.longitude = position.longitude;
    this.geocodeByPosition(position.latitude, position.longitude);
  }

  geocodeByCEP() {
    this.loading = true;
    this.geocodeHereService.geocodeByCEP(this.localizacao.cep).subscribe((resul: any) => {
      if (!!resul.items && resul.items.length > 0) {
        const item = resul.items[0];
        const position = item.position;
        const latitude = position.lat;
        const longitude = position.lng;
        this.localizacao.latitude = latitude;
        this.localizacao.longitude = longitude;
        this.mapComponent.setLatLng(latitude, longitude);
      } else {
        this.alertService.error('CEP não encontrado')
        this.loading = false;
      }
    })
  }

  geocodeByPosition(latitude: number, longitude: number) {
    this.loading = true;
    this.geocodeHereService.geocodeByPosition(latitude, longitude).subscribe((resul: any) => {
      if (!!resul.items && resul.items.length > 0) {
        const item = resul.items[0];
        const address = item.address;
        this.localizacao.cep = address.postalCode;
        this.getCep();
      } else {
        this.alertService.error('CEP não encontrado')
        this.loading = false;
      }
    })
  }

}
