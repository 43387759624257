import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, Franquia, FranquiaService } from 'lib-smart-core';

@Component({
  selector: 'app-franquia-forms',
  templateUrl: './franquia-forms.component.html'
})
export class FranquiaFormsComponent extends AbstractComponent implements OnInit {

  franquia: Franquia;
  isNew: Boolean = true;

  constructor(
    private franquiaService: FranquiaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.franquia = {} as Franquia;
    const idEmpresa: string = super.getIDEmpresaSession();
    if (idEmpresa) {
      // this.franquia = new Franquia(null, '', '', idEmpresa, '', '', '', '');
      this.franquia = {} as Franquia;
      this.franquia.empresa = { _id: super.getIDEmpresaSession() } as Empresa;
    }
    const id: string = super.getParam('id');
    if (id) {
      this.isNew = false;
      this.getFranquiaPorId(id.toString());
    }
  }

  getFranquiaPorId(id: string) {
    return this.franquiaService.getById(id).subscribe(
      (franquia) => {
        this.franquia = franquia;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  onSubmit(): void {
    console.log('on submit add franquia >> ' + JSON.stringify(this.franquia));

    if (this.isNew) {
      this.franquiaService.create(this.franquia)
        .subscribe(
          franquia => {
            this.alertService.success('Operação realizada com sucesso');
            super.goBack();
          },
          err => {
            this.alertService.error(err.error.message);
          }
        );
    } else {
      this.franquiaService.update(this.franquia)
        .subscribe(
          franquia => {
            this.alertService.success('Operação realizada com sucesso');
            super.goBack();
          },
          err => {
            this.alertService.error(err.error.message);
          }
        );
    }

  }

}
