<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Configurações</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li>
        <a [routerLink]="['/franquia']">Estabelecimentos</a>
      </li>
      <li>
        <a href="#">{{franquia?.nome}}</a>
      </li>
      <li class="active">
        <a href="#">Parametrização do Estabelecimento</a>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">
        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" #configForma="ngForm" class="form-horizontal" novalidate>
            <h2>Parametrização do Estabelecimento</h2>
            <small></small>
            <br />
            <br />
            <div class="form-group">
              <label class="col-lg-3 control-label">Ativar solicitação de avaliação? </label>
              <div class="col-lg-8">
                <td>
                  <a *ngIf="config.habilitarSmartAvalia" href="javascript:;" (click)="desativarSmartAvalia()"><i
                      class="fa fa-toggle-on fa-3x"></i></a>
                  <a *ngIf="!config.habilitarSmartAvalia" href="javascript:;" (click)="ativarSmartAvalia()"><i
                      class="fa fa-toggle-off fa-3x"></i></a>
                  <br /><small>Quando a opção "Ativar solicitação de avaliação" estiver habilitada, o sistema enviará automaticamente, após o tempo programado, uma mensagem de WhatsApp com um link de avaliação para os clientes que forneceram nome e telefone ao entrar na fila. Se o cliente estiver acompanhando pelo painel online, um pop-up também aparecerá com o link para realizar a avaliação.</small>
                </td>
              </div>
            </div>
            <ng-container *ngIf="config.habilitarSmartAvalia">
              <div class="form-group">
                <label class="col-lg-3 control-label">Selecionar questionário de avaliação</label>
                <div class="col-lg-5">
  
                  <select class="form-control" name="questionario" id="questionario" #questionario="ngModel"
                    [(ngModel)]="config.questionario" required>
                    <option value="" checked>Selecione</option>
                    <option *ngFor="let q of questionarios" [value]="q._id">
                      {{q.nome}}
                    </option>
                  </select>
                  <small>Escolha um dos questionários previamente criados. Esta função permite selecionar qual avaliação será enviada aos clientes para coletar feedback específico após o atendimento.</small>
                </div>
              </div>
              <div class="form-group">
                <label class="col-lg-3 control-label">Definir o tempo de envio</label>
                <div class="col-lg-5">
                  <select class="form-control" name="duracaoAvalia" id="duracaoAvalia" #duracaoAvalia="ngModel"
                    [(ngModel)]="config.duracaoAvalia" required>
                    <option value="0">Desativado</option>
                    <option value="1">1 minuto</option>
                    <option value="5">5 minutos</option>
                    <option value="10">10 minutos</option>
                    <option value="15">15 minutos</option>
                    <option value="20">20 minutos</option>
                    <option value="25">25 minutos</option>
                    <option value="30">30 minutos</option>
                    <option value="60">1 hora</option>
                    <option value="120">2 horas</option>
                    <option value="180">3 horas</option>
                    <option value="240">4 horas</option>
                  </select>
                  <small>Defina o intervalo de tempo após o atendimento para o envio automático da avaliação ao cliente.</small>
                </div>
              </div>
            </ng-container>
            
            <hr />
            <div class="form-group">
              <label class="col-lg-3 control-label">Chamada Instantânea? </label>
              <div class="col-lg-8">
                <td>
                  <a *ngIf="config.chamadaInstantanea" href="javascript:;" (click)="desativarChamadaInstantanea()"><i
                      class="fa fa-toggle-on fa-3x"></i></a>
                  <a *ngIf="!config.chamadaInstantanea" href="javascript:;" (click)="ativarChamadaInstantanea()"><i
                      class="fa fa-toggle-off fa-3x"></i></a>
                  <br /><small>Esta função deve ser ativada apenas se a empresa não adotar métodos de entrada na fila que envolvam impressão de senha, uso de QR code ou inclusão de clientes na fila por um atendente.</small>
                </td>
              </div>
            </div>
            <div class="form-group">
              <label class="col-lg-3 control-label">Tipo de Atendimento Obrigatório?</label>
              <div class="col-lg-8">
                <td>
                  <a *ngIf="config.tipoAtendimentoObrigatorio" href="javascript:;"
                    (click)="desativarTipoAtendimentoObrigatorio()"><i class="fa fa-toggle-on fa-3x"></i></a>
                  <a *ngIf="!config.tipoAtendimentoObrigatorio" href="javascript:;"
                    (click)="ativarTipoAtendimentoObrigatorio()"><i class="fa fa-toggle-off fa-3x"></i></a>
                  <br /><small>Quando ativado, o atendente será obrigado a selecionar pelo menos um tipo de atendimento
                    antes de finalizar ou encaminhar o atendimento</small>
                </td>
              </div>
            </div>
            <div class="form-group">
              <label class="col-lg-3 control-label">Habilitar modo de entrada QRCode?</label>
              <div class="col-lg-8">
                <td>
                  <a *ngIf="config.habilitarQRCode" href="javascript:;" (click)="desativarHabilitarQRCode()"><i
                      class="fa fa-toggle-on fa-3x"></i></a>
                  <a *ngIf="!config.habilitarQRCode" href="javascript:;" (click)="ativarHabilitarQRCode()"><i
                      class="fa fa-toggle-off fa-3x"></i></a>
                  <br />
                  <small>
                    Com o <strong>Modo de Entrada QRCode</strong> ativado, no totem, proporcionamos a opção de entrada
                    na fila através do QR Code, com a escolha de também imprimir a senha. <br />
                    No tablet, a entrada pode ser realizada por QRCode ou manualmente, inserindo nome e telefone, sem a
                    necessidade de imprimir a senha.
                  </small>
                </td>
              </div>
            </div>
            <div class="hr-line-dashed"></div>

            <!-- <h2>Configurações de impressão</h2>
                        <small>As configurações abaixo são atualizadas automaticamente e não recomendamos alterações
                            manuais. </small>
                        <br />
                        <br />
                        <div class="form-group">
                            <label class="col-lg-2 control-label">IP do Servidor de Impressão</label>
                            <div class="col-lg-8">
                                <input type="text" placeholder="IP do Servidor de Impressão" class="form-control"
                                    name="ip" id="ip" #ip="ngModel" [(ngModel)]="config.ip" required>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-lg-2 control-label">Porta do Servidor de Impressão</label>
                            <div class="col-lg-8">
                                <input type="text" placeholder="Porta do Servidor de Impressão" class="form-control"
                                    name="port" id="port" #port="ngModel" [(ngModel)]="config.port" required>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-lg-2 control-label">Hostname do Servidor de Impressão</label>
                            <div class="col-lg-8">
                                <input type="text" placeholder="Porta do Servidor de Impressão" class="form-control"
                                    name="hostname" id="hostname" #hostname="ngModel" [(ngModel)]="config.hostname"
                                    required>
                            </div>
                        </div> -->
            <!-- <div class="form-group">
                            <label>Utilizar HTTPS?</label>
                            <input type="radio" [(ngModel)]="config.https" name="https" id="https" [value]="true">Sim
                            <input type="radio" [(ngModel)]="config.https" name="https" id="https" [value]="false">Não
                        </div> -->

            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <div class="col-sm-4 col-sm-offset-2">
                <button type="submit" class="btn btn-primary" [disabled]="!configForma.form.valid">Salvar</button>
                <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>