import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Config, ConfigService, Franquia, FranquiaService, Questionario, QuestionarioService } from 'lib-smart-core';

@Component({
  selector: 'config-forms',
  templateUrl: './config-forms.component.html'
})
export class ConfigFormsComponent extends AbstractComponent implements OnInit {

  config: Config;
  franquia: Franquia;
  questionarios: Array<Questionario>;

  constructor(
    private configService: ConfigService,
    private franquiaService: FranquiaService,
    private questionarioService: QuestionarioService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.config = {} as Config;
    let idFranquia: string = super.getParam('idFranquia');
    if (idFranquia) {
      this.getFranquiaPorId(idFranquia.toString());
      this.getConfigPorFranquia(idFranquia.toString());
      this.getQuestionariosPorFranquia();
    }
  }

  getFranquiaPorId(id: string) {
    this.franquiaService.getById(id).subscribe({
      next: (franquia) => this.franquia = franquia,
      error: err => this.alertService.error(err.error.message)
    });
  }

  getConfigPorFranquia(idFranquia: string) {
    this.configService.getConfigPorFranquia(idFranquia).subscribe({
      next: (config: Config) => this.config = config,
      error: err => this.alertService.error(err.error.message)
    });
  }

  getQuestionariosPorFranquia() {
    this.questionarioService.getAllPorEmpresa(super.getIDEmpresaSession()).subscribe({
      next: (questionarios: Array<Questionario>) => this.questionarios = questionarios,
      error: err => this.alertService.error(err.error.message)
    });
  }

  onSubmit(): void {
    this.configService.update(this.config).subscribe({
      next: () => {
        this.alertService.success();
        super.goBack();
      },
      error: err => this.alertService.error(err.error.message)
    });
  }

  ativarChamadaInstantanea() {
    this.config.chamadaInstantanea = true;
  }

  desativarChamadaInstantanea() {
    this.config.chamadaInstantanea = false;
  }

  ativarTipoAtendimentoObrigatorio() {
    this.config.tipoAtendimentoObrigatorio = true;
  }

  desativarTipoAtendimentoObrigatorio() {
    this.config.tipoAtendimentoObrigatorio = false;
  }

  ativarHabilitarQRCode() {
    this.config.habilitarQRCode = true;
  }

  desativarHabilitarQRCode() {
    this.config.habilitarQRCode = false;
  }

  ativarSmartAvalia() {
    this.config.habilitarSmartAvalia = true;
  }

  desativarSmartAvalia() {
    this.config.habilitarSmartAvalia = false;
  }

}
