<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>
      <strong *ngIf="isNew">Novo QRCode Template</strong>
      <strong *ngIf="!isNew">Alterar QRCode Template</strong>
    </h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li class="active">
        <a [routerLink]="['/config']">Configurações</a>
      </li>
      <li>
        QRCode Template
      </li>
      <li class="active">
        <strong *ngIf="isNew">Novo QRCode Template</strong>
        <strong *ngIf="!isNew">Alterar QRCode Template</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">

        <div class="ibox-content">
          <form #f="ngForm" novalidate>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Nome</label>
                  <input type="text" class="form-control" name="name" id="name"
                    [(ngModel)]="qrCodeTemplateSelecionado.name">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Tipo</label>
                  <select name="tipo" id="tipo" class="form-control" [(ngModel)]="qrCodeTemplateSelecionado.type">
                    <option value="LITE" selected="true">LITE</option>
                    <option value="PAGER">PAGER</option>
                    <option value="AVALIA">AVALIA</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="images" [ngClass]="{'sk-loading': loadingImage}">
              <div class="widget-head-color-box images__widget p-lg text-center">
                <div class="form-group" style="display: none">
                  <input type="file" class="form-control" id="localQRCodeTemplateAdd" (change)="onChange($event)"
                    accept=".jpg, .jpeg, .png">
                </div>
                <div class="text-center">
                  <img *ngIf="qrCodeTemplateSelecionado?.image" src="{{qrCodeTemplateSelecionado?.image}}"
                    style="max-height: 200px" />
                </div>
              </div>
              <div class="widget-text-box images__widget-bottom text-center">
                <div class="text-center">
                  <button type="button" class="btn btn-primary" [disabled]="loadingImage" *ngIf="isAdmin()"
                    (click)="openFileBrowser($event)">{{qrCodeTemplateSelecionado?.image ? 'Alterar' :
                    'Adicionar'}}</button>&nbsp;
                  <button type="button" class="btn btn-danger" (click)="removerImagem(qrCodeTemplateSelecionado)"
                    [disabled]="loadingImage" *ngIf="isAdmin() && qrCodeTemplateSelecionado?.image">
                    <i class="fa fa-trash"></i>
                  </button>
                  <img *ngIf="loadingImage"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                  <div class="m-t-2 text-muted font-size-12">JPG, JPEG ou PNG. Dimensões recomendadas 2480 × 3508
                    com
                    tamanho
                    máximo
                    1MB.
                  </div>
                </div>
              </div>
            </div>

            <div class="hr-line-dashed"></div>

            <div class="form-group">
              <label></label>
              <button type="button" class="btn btn-primary btn-xs" data-toggle="modal" data-target="#modalFranquias">
                <i class="fa fa-plus"></i> Adicionar Estabelecimento
              </button>
            </div>

            <div class="form-group">
              <label></label>

              <table class="table table-condensed" *ngIf="franquiasSelecionadas.length">
                <thead>
                  <tr>
                    <th>Estabelecimento</th>
                    <th>Código</th>
                    <th>Cidade/UF</th>
                    <!-- <th>Empresa</th> -->
                    <th width="10%" style="text-align: right;">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let fs of franquiasSelecionadas; let i = index">
                    <td scope="row">{{fs.nome}}</td>
                    <td>{{fs.codigo}}</td>
                    <td>{{fs.cidade}}/{{fs.estado}}</td>
                    <td style="text-align: right;">
                      <button type="button" class="btn btn-danger fa fa-trash" (click)="excluirFranquiaSelecionada(i)"
                        style="text-align: right;"></button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="panel text-center" *ngIf="!franquiasSelecionadas.length">
                <div class="panel-body">
                  <p class="p-y-1">
                    <i class="fa fa-cubes font-size-46 line-height-1 text-primary"></i>
                  </p>
                  <p>
                    <strong>Estabelecimentos</strong>
                  </p>
                  <p class="m-b-4">Nenhum registro encontrado.</p>
                </div>
              </div>

            </div>

            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="button" class="btn btn-primary" (click)="onSubmitEmpresa()">Salvar</button>
              <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal para pesquisar estabelecimentos -->
<div class="modal inmodal" id="modalFranquias" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Procurar Estabelecimento</h4>
        <small class="font-bold">Localize os estabelecimentos que procura</small>
      </div>
      <div class="modal-body">
        <form #f2="ngForm" novalidate>
          <div class="form-group">

            <label>Pesquise os estabelecimento por nome</label>
            <input #searchBox (keyup)="search(searchBox.value)" [(ngModel)]="model.searchBox"
              [ngModelOptions]="{standalone: true}" placeholder="Pesquise os estabelecimento por nome"
              class="form-control">
            <table class="table table-condensed" *ngIf="franquiasModal?.length">
              <thead>
                <tr>
                  <th>Estabelecimento</th>
                  <th>Código</th>
                  <th>Cidade/UF</th>
                  <th>Empresa</th>
                  <th width="10%" style="text-align: right;">Associar</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fm of franquiasModal; let i = index">
                  <th scope="row">{{fm.nome}}</th>
                  <td>{{fm.codigo}}</td>
                  <td>{{fm.cidade}}/{{fm.estado}}</td>
                  <td>{{fm.empresa ? fm.empresa.nome : 'Não associado'}}</td>
                  <td style="text-align: right;">
                    <button type="button" class="btn btn-primary fa fa-plus" (click)="selecionarFraquia(fm)"
                      [disabled]="franquiaJaAdicionada(fm._id)"></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <div class="panel text-center" *ngIf="!franquiasModal?.length">
              <div class="panel-body">
                <p class="p-y-1">
                  <i class="fa fa-cubes font-size-46 line-height-1 text-primary"></i>
                </p>
                <p>
                  <strong>Estabelecimentos</strong>
                </p>
                <p class="m-b-4">Nenhum registro encontrado.</p>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>