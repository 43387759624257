<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Configurações</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li class="active">
        <strong>Configurações</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">

  <div class="row">
    <div class="col-md-12">
      <div class="tabs-container">
        <div class="tabs-left">
          <ul class="nav nav-tabs">
            <li class="active"><a class="nav-link active" data-toggle="tab" href="#tab-1">Imagens</a></li>
            <li><a class="nav-link" data-toggle="tab" href="#tab-2">Tema</a></li>
            <li><a class="nav-link" data-toggle="tab" href="#tab-5">Templates</a></li>
            <li><a class="nav-link" data-toggle="tab" href="#tab-3">Redes Sociais</a></li>
            <li><a class="nav-link" data-toggle="tab" href="#tab-4">Gerais</a></li>
          </ul>
          <div class="tab-content">
            <div id="tab-1" class="tab-pane active">
              <div class="panel-body">
                <div class="images">
                  <div class="images__header">
                    <h3>Logo da empresa</h3>
                    <div>

                    </div>
                  </div>
                  <div class="images__body">
                    <div class="images" [ngClass]="{'sk-loading': loadingImage}">
                      <div class="widget-head-color-box images__widget p-lg text-center">
                        <div class="form-group" style="display: none">
                          <input type="file" class="form-control" id="localImage" (change)="onChange($event)"
                            accept=".jpg, .jpeg, .png">
                        </div>
                        <div class="text-center">
                          <img *ngIf="empresa?.image" src="{{empresa?.image}}" style="max-height: 200px" />
                        </div>
                      </div>
                      <div class="widget-text-box images__widget-bottom text-center">
                        <div class="text-center">
                          <button type="button" class="btn btn-primary" [disabled]="loadingImage" *ngIf="isAdmin()"
                            (click)="openFileBrowser($event)">Alterar</button>&nbsp;
                          <button type="button" class="btn btn-danger" (click)="removerImagem()"
                            [disabled]="loadingImage" *ngIf="isAdmin()">
                            <i class="fa fa-trash"></i>
                          </button>
                          <img *ngIf="loadingImage"
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                          <div class="m-t-2 text-muted font-size-12">JPG, JPEG ou PNG. Dimensões recomendadas 500 x 220
                            com
                            tamanho
                            máximo
                            1MB.</div>
                        </div>
                      </div>
                      <images-list [empresa]="empresa"></images-list>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div id="tab-2" class="tab-pane">
              <div class="panel-body">
                <h3>Tema</h3>
                <hr>
                <form class="form" (ngSubmit)="onSubmitEmpresa()" novalidate>
                  <div class="form-group">
                    <label>Cor do fundo</label>
                    <div class="form-inline">
                      <input type="color" class="form-control" name="bgColor" id="bgColor"
                        style="width: 50px; padding: 0;" [(ngModel)]="empresa.bgColor">
                      <input type="text" class="form-control" name="bgColor2" id="bgColor2"
                        style="width: 100px; padding: 0;" [(ngModel)]="empresa.bgColor">
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Cor do texto</label>
                    <div class="form-inline">
                      <input type="color" class="form-control" name="textColor" id="textColor"
                        style="width: 50px; padding: 0;" [(ngModel)]="empresa.textColor">
                      <input type="text" class="form-control" name="textColor2" id="textColor2"
                        style="width: 100px; padding: 0;" [(ngModel)]="empresa.textColor">
                    </div>
                  </div>
                  <hr class="hr-line-dashed">
                  <div class="form-group">
                    <button type="submit" *ngIf="isAdmin()" class="btn btn-primary">Salvar</button>&nbsp;
                  </div>
                </form>
              </div>
            </div>

            <div id="tab-3" class="tab-pane">
              <div class="panel-body">
                <h3>Redes Sociais</h3>
                <hr>
                <form class="form" (ngSubmit)="onSubmitEmpresa()" novalidate>
                  <div class="form-group" *ngIf="links">
                    <div class="ibox-tools" *ngIf="links?.length > 0">
                      <a class="btn btn-primary btn-xs" data-toggle="modal" href="#modalLinkForm" (click)="newLink()"
                        *ngIf="isAdmin()">
                        <i class="fa fa-plus"></i> Adicionar Link</a>
                    </div>
                  </div>
                  <table class="table table-striped" *ngIf="links?.length > 0">
                    <thead>
                      <tr>
                        <th width="5%">Link</th>
                        <th width="5%">Icone</th>
                        <th>Url</th>
                        <th>Status</th>
                        <th width="15%" *ngIf="isAdmin()" style="text-align: right;">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let link of links; let i = index">
                        <td style="vertical-align: middle;">
                          {{link.index}}
                        </td>
                        <td>
                          <i class="fa {{link.icon}} fa-2x"></i>
                        </td>
                        <td style="vertical-align: middle;">
                          <a href="{{link.url}}" target="_blank" *ngIf="link.url.length > 60"
                            title="{{link.url}}">{{link.url | slice: 0:60}}...</a>
                          <a href="{{link.url}}" target="_blank" *ngIf="link.url.length <= 60"
                            title="{{link.url}}">{{link.url}}</a>
                        </td>
                        <td style="vertical-align: middle;">
                          <span class="label label-primary" *ngIf="link.active">Ativo</span>
                          <span class="label label-danger" *ngIf="!link.active">Inativo</span>
                        </td>
                        <td style="text-align: right;" *ngIf="isAdmin()">
                          <a class="btn btn-white btn-sm btn-bitbucket" data-toggle="modal" href="#modalLinkForm"
                            (click)="editLink(i, link)">
                            <i class="fa fa-edit"></i>
                          </a>
                          <a class="btn btn-danger btn-sm btn-bitbucket" data-toggle="modal" href="#deleteModalLink"
                            (click)="selectLink(i, link)">
                            <i class="fa fa-trash"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="row" *ngIf="links?.length === 0">
                    <div class="blank-slate text-center">
                      <div class="blank-slate-icon">
                        <i class="fa fa-star-half-o fa-3x"></i>
                      </div>
                      <h3 class="blank-slate-title">Links</h3>
                      <p class="blank-slate-lead">Não foram encontrados links cadastrados.</p>
                      <a href="javascript:;" class="btn btn-primary" data-toggle="modal" href="#modalLinkForm"
                        (click)="newLink()">Adicionar Link</a>
                    </div>
                  </div>

                </form>
              </div>
            </div>

            <div id="tab-4" class="tab-pane">
              <div class="panel-body">

                <form class="form" (ngSubmit)="onSubmitEmpresa()" novalidate>
                  <h3>Gerais</h3>
                  <hr>
                  <div class="form-group">
                    <label>Encaminhamento entre estabelecimentos</label>
                    <div class="form-inline">
                      <a *ngIf="empresa?.encaminhamentoEntreFranquias" href="javascript:;"
                        (click)="desativarEncaminhamentoEntreFranquia()"><i class="fa fa-toggle-on fa-3x"></i></a>
                      <a *ngIf="!empresa?.encaminhamentoEntreFranquias" href="javascript:;"
                        (click)="ativarEncaminhamentoEntreFranquia()"><i class="fa fa-toggle-off fa-3x"></i></a>
                      <small>Quando ativado, o atendente consegue encaminhar uma senha para outras filas de outros estabelecimentos. </small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Segurança - Bloqueio Automático</label>
                    <div class="form-inline">
                      <a *ngIf="empresa?.bloqueioAutomatico" href="javascript:;"
                        (click)="desativarBloqueioAutomatico()"><i class="fa fa-toggle-on fa-3x"></i></a>
                      <a *ngIf="!empresa?.bloqueioAutomatico" href="javascript:;"
                        (click)="ativarBloqueioAutomatico()"><i class="fa fa-toggle-off fa-3x"></i></a>
                      <small>Quando ativado, usuários sem acessar o sistema por mais de 30 dias são automaticamente
                        bloqueados. </small>
                    </div>
                  </div>
                  <hr class="hr-line-dashed">
                  <div class="form-group">
                    <button type="submit" *ngIf="isAdmin()" class="btn btn-primary">Salvar</button>&nbsp;
                  </div>
                </form>
              </div>
            </div>

            <div id="tab-5" class="tab-pane">
              <div class="panel-body">
                <div class="images">
                  <div class="images__header">
                    <h3>Templates QRCode</h3>
                  </div>
                  <div class="images__body">
                    <label>Alterar Template LITE</label>
                    <div class="images" [ngClass]="{'sk-loading': loadingImage}">
                      <div class="widget-head-color-box images__widget p-lg text-center">
                        <div class="form-group" style="display: none">
                          <input type="file" class="form-control" id="localImageLite"
                            (change)="onChangeTemplateLite($event)" accept=".jpg, .jpeg, .png">
                        </div>
                        <div class="text-center">
                          <img *ngIf="empresa?.imageTemplateLite" src="{{empresa?.imageTemplateLite}}"
                            style="max-height: 200px" />
                        </div>
                      </div>
                      <div class="widget-text-box images__widget-bottom text-center">
                        <button type="button" class="btn btn-primary" [disabled]="loadingImage" *ngIf="isAdmin()"
                          (click)="openFileBrowserLite($event)">Alterar</button>&nbsp;
                        <button type="button" class="btn btn-danger" (click)="removerImagemLite()"
                          [disabled]="loadingImage" *ngIf="isAdmin()">
                          <i class="fa fa-trash"></i>
                        </button>
                        <img *ngIf="loadingImage"
                          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                        <div class="m-t-2 text-muted font-size-12">JPG, JPEG ou PNG. Dimensões devem ser 2480 × 3508 com
                          tamanho máximo 1MB.</div>
                      </div>
                    </div>
                  </div>
                  <div class="images__body">
                    <label>Alterar Template PAGER</label>
                    <div class="" [ngClass]="{'sk-loading': loadingImage}">
                      <div class="widget-head-color-box images__widget p-lg text-center">
                        <div class="form-group" style="display: none">
                          <input type="file" class="form-control" id="localImagePager"
                            (change)="onChangeTemplatePager($event)" accept=".jpg, .jpeg, .png">
                        </div>
                        <div class="text-center">
                          <img *ngIf="empresa?.imageTemplatePager" src="{{empresa?.imageTemplatePager}}"
                            style="max-height: 200px" />
                        </div>
                      </div>
                      <div class="widget-text-box images__widget-bottom text-center">
                        <button type="button" class="btn btn-primary" [disabled]="loadingImage" *ngIf="isAdmin()"
                          (click)="openFileBrowserPager($event)">Alterar</button>&nbsp;
                        <button type="button" class="btn btn-danger" (click)="removerImagemPager()"
                          [disabled]="loadingImage" *ngIf="isAdmin()">
                          <i class="fa fa-trash"></i>
                        </button>
                        <img *ngIf="loadingImage"
                          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                        <div class="m-t-2 text-muted font-size-12">JPG, JPEG ou PNG. Dimensões devem ser 2480 × 3508 com
                          tamanho máximo 1MB.</div>
                      </div>
                    </div>
                  </div>
                  <div class="images__body">
                    <label>Alterar Template AVALIA</label>
                    <div class="mb-5" [ngClass]="{'sk-loading': loadingImage}">
                      <div class="widget-head-color-box images__widget p-lg text-center">

                        <div class="form-group" style="display: none">
                          <input type="file" class="form-control" id="localImageAvalia"
                            (change)="onChangeTemplateAvalia($event)" accept=".jpg, .jpeg, .png">
                        </div>
                        <div class="text-center">
                          <img *ngIf="empresa?.imageTemplateAvalia" src="{{empresa?.imageTemplateAvalia}}"
                            style="max-height: 200px" />
                        </div>
                      </div>
                      <div class="widget-text-box images__widget-bottom text-center">
                        <button type="button" class="btn btn-primary" [disabled]="loadingImage" *ngIf="isAdmin()"
                          (click)="openFileBrowserAvalia($event)">Alterar</button>&nbsp;
                        <button type="button" class="btn btn-danger" (click)="removerImagemAvalia()"
                          [disabled]="loadingImage" *ngIf="isAdmin()">
                          <i class="fa fa-trash"></i>
                        </button>
                        <img *ngIf="loadingImage"
                          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                        <div class="m-t-2 text-muted font-size-12">JPG, JPEG ou PNG. Dimensões devem ser 2480 × 3508 com
                          tamanho máximo 1MB.</div>
                      </div>
                    </div>
                  </div>
                </div>

                <qrcode-template-list [empresa]="empresa"></qrcode-template-list>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>

  <div class="modal inmodal" id="modalLinkForm" tabindex="-1" role="dialog" aria-hidden="true">
    <form #fLink="ngForm" novalidate>
      <div class="modal-dialog">
        <div class="modal-content animated bounceInRight">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span class="sr-only">Close</span>
            </button>
            <h4 class="modal-title">Link</h4>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Icone</label>
              <div class="row">
                <div class="col-xs-12" style="display: flex; gap: 10px;">
                  <button class="btn" (click)="setIcon('fa-instagram')"
                    [ngClass]="{'btn-default': link.icon !== 'fa-instagram', 'btn-primary': link.icon === 'fa-instagram'}"
                    type="button">
                    <i class="fa fa-instagram"></i>
                  </button>
                  <button class="btn" (click)="setIcon('fa-facebook')"
                    [ngClass]="{'btn-default': link.icon !== 'fa-facebook', 'btn-primary': link.icon === 'fa-facebook'}"
                    type="button">
                    <i class="fa fa-facebook"></i>
                  </button>
                  <button class="btn" (click)="setIcon('fa-twitter')"
                    [ngClass]="{'btn-default': link.icon !== 'fa-twitter', 'btn-primary': link.icon === 'fa-twitter'}"
                    type="button">
                    <i class="fa fa-twitter"></i>
                  </button>
                  <button class="btn" (click)="setIcon('fa-whatsapp')"
                    [ngClass]="{'btn-default': link.icon !== 'fa-whatsapp', 'btn-primary': link.icon === 'fa-whatsapp'}"
                    type="button">
                    <i class="fa fa-whatsapp"></i>
                  </button>
                  <button class="btn" (click)="setIcon('fa-spotify')"
                    [ngClass]="{'btn-default': link.icon !== 'fa-spotify', 'btn-primary': link.icon === 'fa-spotify'}"
                    type="button">
                    <i class="fa fa-spotify"></i>
                  </button>
                  <button class="btn" (click)="setIcon('fa-youtube-play')"
                    [ngClass]="{'btn-default': link.icon !== 'fa-youtube-play', 'btn-primary': link.icon === 'fa-youtube-play'}"
                    type="button">
                    <i class="fa fa-youtube-play"></i>
                  </button>
                  <button class="btn" (click)="setIcon('fa-vimeo')"
                    [ngClass]="{'btn-default': link.icon !== 'fa-vimeo', 'btn-primary': link.icon === 'fa-vimeo'}"
                    type="button">
                    <i class="fa fa-vimeo"></i>
                  </button>
                  <button class="btn " (click)="setIcon('fa-id-card')"
                    [ngClass]="{'btn-default': link.icon !== 'fa-id-card', 'btn-primary': link.icon === 'fa-id-card'}"
                    type="button">
                    <i class="fa fa-id-card"></i>
                  </button>
                  <button class="btn " (click)="setIcon('fa-shield')"
                    [ngClass]="{'btn-default': link.icon !== 'fa-shield', 'btn-primary': link.icon === 'fa-shield'}"
                    type="button">
                    <i class="fa fa-shield"></i>
                  </button>
                  <button class="btn " (click)="setIcon('fa-globe')"
                    [ngClass]="{'btn-default': link.icon !== 'fa-globe', 'btn-primary': link.icon === 'fa-globe'}"
                    type="button">
                    <i class="fa fa-globe"></i>
                  </button>
                  <button class="btn " (click)="setIcon('fa-thumbs-o-up')"
                    [ngClass]="{'btn-default': link.icon !== 'fa-thumbs-o-up', 'btn-primary': link.icon === 'fa-thumbs-o-up'}"
                    type="button">
                    <i class="fa fa-thumbs-o-up"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>URL</label>
              <input type="text" placeholder="URL" class="form-control" name="linkUrl" id="linkUrl" #linkUrl="ngModel"
                [(ngModel)]="link.url" required>
            </div>
            <div class="form-group">
              <label>Ativar</label>
              <div>
                <a *ngIf="link.active" href="javascript:;" (click)="desativar()"><i
                    class="fa fa-toggle-on fa-3x"></i></a>
                <a *ngIf="!link.active" href="javascript:;" (click)="ativar()"><i
                    class="fa fa-toggle-off fa-3x"></i></a>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="onSubmitLink()"
              [disabled]="!fLink.form.valid">Salvar</button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal inmodal" id="deleteModalLink" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content animated bounceInRight">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
            <span class="sr-only">Close</span>
          </button>
          <h4 class="modal-title">Remover Link</h4>
        </div>
        <div class="modal-body">
          <div align="center">
            <p>
              Esta ação não poderá ser desfeita. Tem certeza que deseja excluir o link
              <b>{{linkSelecionado?.url}}</b>?
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteLink()">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
