import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Pergunta, PerguntaService, Questionario, QuestionarioService } from 'lib-smart-core';

@Component({
  selector: 'questionario-form',
  templateUrl: './questionario-form.component.html'
})
export class QuestionarioFormComponent extends AbstractComponent implements OnInit {

  questionario: Questionario;
  isNew: boolean = true;
  isNewPergunta: boolean = true;

  pergunta: Pergunta;
  perguntas: Pergunta[];

  perguntaSelecionada: Pergunta;

  constructor(
    private perguntaService: PerguntaService,
    private questionarioService: QuestionarioService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.questionario = {} as Questionario;
    this.pergunta = {} as Pergunta;
    let id: string = super.getParam('id');
    if (id) {
      this.isNew = false;
      this.getQuestionarioPorId(id.toString());
    } else {
      this.isNew = true;
      this.questionario.empresa = super.getIDEmpresaSession();
    }
  }

  getQuestionarioPorId(id: string) {
    return this.questionarioService.getById(id).subscribe(
      (questionario) => {
        this.questionario = questionario
        this.getPerguntas();
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  onSubmit(): void {
    if (this.isNew) {
      this.questionarioService.create(this.questionario)
        .subscribe(questionario => {
          this.alertService.success();
          this.router.navigate(['questionario/edit', questionario._id]);
        }, err => {
          this.alertService.error(err.error.message);
        });
    } else {
      this.questionarioService.update(this.questionario)
        .subscribe(questionario => {
          this.alertService.success();
          this.router.navigate(['smartavalia']);
        }, err => {
          this.alertService.error(err.error.message);
        });
    }
  }

  addPergunta() {
    this.isNewPergunta = true;
    this.pergunta = {} as Pergunta;
    this.pergunta.tipo = 'NOTA';
    this.pergunta.respostaPositiva = 'SIM';
    this.pergunta.questionario = { _id: this.questionario._id } as Questionario;
  }

  editPergunta(pergunta: Pergunta) {
    this.isNewPergunta = false;
    this.pergunta = pergunta;
  }

  getPerguntas() {
    return this.perguntaService.getAllPorQuestionario(this.questionario._id).subscribe(
      (pergunta: Pergunta[]) => {
        this.perguntas = pergunta;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  onSubmitPergunta(): void {
    if (this.isNewPergunta) {
      this.perguntaService.create(this.pergunta)
        .subscribe(pergunta => {
          this.alertService.success('Operação realizada com sucesso');
          this.getPerguntas();

        }, err => {
          this.alertService.error(err.error.message);
        });
    } else {
      this.perguntaService.update(this.pergunta)
        .subscribe(pergunta => {
          this.alertService.success('Operação realizada com sucesso');
          this.getPerguntas();
        }, err => {
          this.alertService.error(err.error.message);
        });
    }
  }

  selecionarPergunta(obj: Pergunta) {
    this.perguntaSelecionada = obj;
  }

  deletePergunta() {
    return this.perguntaService.delete(this.perguntaSelecionada._id).subscribe(
      () => {
        this.alertService.success('Operação realizada com sucesso');
        this.getPerguntas();
      },
      err => this.alertService.error(err.error.message)
    )
  }

  ativarDadosContato() {
    this.questionario.dadosContato = true;
  }

  desativarDadosContato() {
    this.questionario.dadosContato = false;
  }

  cancel() {
    this.router.navigate(['smartavalia']);
  }

}
