<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Local</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li>
        <a [routerLink]="['/franquia']">Estabelecimentos</a>
      </li>
      <li>
        <a href="#">{{franquia?.nome}}</a>
      </li>
      <li>
        <a href="#">Locais</a>
      </li>
      <li class="active">
        <strong *ngIf="isNew">Adicionar</strong>
        <strong *ngIf="!isNew">Alterar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">

        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" #mesaForma="ngForm" novalidate>
            <div class="form-group">
              <label>Número/Letra</label>
              <input type="text" placeholder="Insira o Número/Letra do Local (mesa, caixa, etc)" class="form-control"
                name="nome" maxlength="3" id="nome" #nome="ngModel" [(ngModel)]="mesa.nome" required>
              <!-- <select name="nome" id="nome" [(ngModel)]="mesa.nome" class="form-control"
                                required>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="CAIXA">CAIXA</option>
                                <option value="ESTOQUE">ESTOQUE</option>
                            </select> -->
            </div>
            <div class="form-group">
              <label>Descrição</label>
              <input type="text" placeholder="Insira Descrição da Local" class="form-control" name="descricao"
                id="descricao" #descricao="ngModel" [(ngModel)]="mesa.descricao" required>
            </div>
            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!mesaForma.form.valid">Salvar</button>
              <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>