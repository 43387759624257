<div class="modal inmodal" id="modalAvaliacaoGeral_{{franquia?._id}}" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <i class="fa fa-stars modal-icon"></i>
        <h4 class="modal-title">Avaliação Geral</h4>
        <small class="font-bold">Os dados abaixo representam as avaliações ocorridas na franquia
          <strong>{{franquia?.nome}}</strong>.</small>
      </div>
      <div class="modal-body">

        <div class="row">

          <div class="col-lg-4">
            <div class="widget  p-lg text-center">
              <div class="m-b-md">
                <!-- <i class="fa fa-flash fa-4x"></i> -->
                <h1 class="m-xs" style="font-size: 100px">{{pontuacao | currency: ' '}}</h1>
                <!-- <h3 class="font-bold no-margins">
                                    {{franquia?.nome}}
                                </h3> -->
                <!-- <small>amount</small> -->
                <avaliacao-embeded [pontuacao]="pontuacao"></avaliacao-embeded>
              </div>

              <div class="row">
                <div class="col-12 text-center">
                  <span> Total de Avaliações </span>
                  <h2 class="font-bold">{{data?.totalAvaliacoes}}</h2>
                </div>
              </div>
            </div>

          </div>

          <div class="col-lg-8">

            <div class="row">
              <div class="col-lg-12">
                <dl class="row mb-0">
                  <div class="col-sm-2 text-sm-right">
                    <dt>5 <i class="fa fa-star"></i></dt>
                  </div>
                  <div class="col-sm-10 text-sm-left">
                    <dd>
                      <div class="progress m-b-1">
                        <div [ngStyle]="{'width': getPercentualStr(data?.stars5) }" style="background-color: green"
                          class="progress-bar progress-bar-striped progress-bar-animated"></div>
                        <!-- <strong class="text-sm-right">{{data?.stars5}}</strong> -->
                      </div>
                    </dd><small>{{data?.stars5}} avaliações</small>
                  </div>
                </dl>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <dl class="row mb-0">
                  <div class="col-sm-2 text-sm-right">
                    <dt>4 <i class="fa fa-star"></i></dt>
                  </div>
                  <div class="col-sm-10 text-sm-left">
                    <dd>
                      <div class="progress m-b-1">
                        <div [ngStyle]="{'width': getPercentualStr(data?.stars4) }"
                          style="background-color: yellowgreen"
                          class="progress-bar progress-bar-striped progress-bar-animated"></div>
                        <!-- <strong>{{data?.stars4}}</strong> -->
                      </div>
                    </dd><small>{{data?.stars4}} avaliações</small>
                  </div>
                </dl>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <dl class="row mb-0">
                  <div class="col-sm-2 text-sm-right">
                    <dt>3 <i class="fa fa-star"></i></dt>
                  </div>
                  <div class="col-sm-10 text-sm-left">
                    <dd>
                      <div class="progress m-b-1">
                        <div [ngStyle]="{'width': getPercentualStr(data?.stars3) }" style="background-color: yellow"
                          class="progress-bar progress-bar-striped progress-bar-animated"></div>
                        <!-- <strong>{{data?.stars3}}</strong> -->
                      </div>
                    </dd><small>{{data?.stars3}} avaliações</small>
                  </div>
                </dl>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <dl class="row mb-0">
                  <div class="col-sm-2 text-sm-right">
                    <dt>2 <i class="fa fa-star"></i></dt>
                  </div>
                  <div class="col-sm-10 text-sm-left">
                    <dd>
                      <div class="progress m-b-1">
                        <div [ngStyle]="{'width': getPercentualStr(data?.stars2) }" style="background-color: tomato"
                          class="progress-bar progress-bar-striped progress-bar-animated"></div>
                        <!-- <strong>{{data?.stars2}}</strong> -->
                      </div>
                    </dd><small>{{data?.stars2}} avaliações</small>
                  </div>
                </dl>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <dl class="row mb-0">
                  <div class="col-sm-2 text-sm-right">
                    <dt>1 <i class="fa fa-star"></i></dt>
                  </div>
                  <div class="col-sm-10 text-sm-left">
                    <dd>
                      <div class="progress m-b-1">
                        <div [ngStyle]="{'width': getPercentualStr(data?.stars1) }" style="background-color: red"
                          class="progress-bar progress-bar-striped progress-bar-animated"></div>
                        <!-- <strong>{{data?.stars1}}</strong> -->
                      </div>
                    </dd><small>{{data?.stars1}} avaliações</small>
                  </div>
                </dl>
              </div>
            </div>

          </div>

        </div>



      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>