import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { SmartAvaliaModule } from '../smartavalia/smartavalia.module';
import { SmartFilaLiteComponent } from "./smartfilas-lite.component";

@NgModule({
  imports: [
    SharedModule,
    SmartAvaliaModule
  ],
  declarations: [
    SmartFilaLiteComponent,
  ],
  exports: [
    SmartFilaLiteComponent,
  ],
  providers: [
  ]
})

export class SmartFilasLiteModule { }
