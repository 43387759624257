import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Pager, Playlist, PlaylistService } from 'lib-smart-core';

@Component({
  selector: 'playlist-lista',
  templateUrl: './playlist-lista.component.html'
})
export class PlaylistListaComponent extends AbstractComponent implements OnInit {

  pager: Pager<Playlist> = new Pager<Playlist>({ perPage: 25 });

  playlistSelecionada: Playlist;

  constructor(
    private playlistService: PlaylistService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getPlaylists();
  }

  getPlaylists() {
    return this.playlistService.getAllPager(this.pager.page, this.pager.perPage).subscribe(
      (pager: Pager<Playlist>) => {
        this.pager = pager;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getPlaylists();
  }

  selecionar(obj: Playlist) {
    this.playlistSelecionada = obj;
  }

  delete() {
    return this.playlistService.delete(this.playlistSelecionada._id).subscribe(
      () => {
        this.getPlaylists();
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    )
  }

}