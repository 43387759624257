import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Config, ConfigService, Franquia, FranquiaService, Pager, TV, TVService } from 'lib-smart-core';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'tv-lista',
  templateUrl: './tv-lista.component.html'
})
export class TVListaComponent extends AbstractComponent implements OnInit {

  franquia: Franquia;
  config: Config;

  page: number = 1;
  perPage: number = 10;
  total: number = 0;
  tvs: TV[];

  tvSelecionada: TV;

  constructor(
    private tvService: TVService,
    private configService: ConfigService,
    private franquiaService: FranquiaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let idFranquia: string = super.getParam('idFranquia');
    if (idFranquia) {
      this.getFranquiaPorId(idFranquia);
      this.getConfigPorFranquia(idFranquia);
      this.getTVsPorFranquia(idFranquia);
    }
  }

  getConfigPorFranquia(idFranquia: string) {
    this.configService.getConfigPorFranquia(idFranquia).subscribe(
      (config: Config) => {
        return this.config = config
      },
      err => {
        this.alertService.error(err.error.message)
      }
    )
  }


  getFranquiaPorId(idFranquia: string) {
    return this.franquiaService.getById(idFranquia).subscribe(
      (franquia: Franquia) => {
        return this.franquia = franquia
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  getTVsPorFranquia(idFranquia: string) {
    return this.tvService.getTVsPorFranquiaPager(this.page, this.perPage, idFranquia).subscribe(
      (pager: Pager<TV>) => {
        this.tvs = pager.list;
        this.total = pager.total;
        this.page = pager.page;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  selecionar(obj: TV) {
    this.tvSelecionada = obj;
  }

  delete() {
    return this.tvService.delete(this.tvSelecionada._id).subscribe(
      () => {
        this.getTVsPorFranquia(this.franquia._id);
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    )
  }

  public get urlTVPrincipalFranquia() {
    let baseUrl = environment.production ? 'https://controle.smartline.com.br/#' : 'http://localhost:3002';
    return (baseUrl + '/app/tv/' + this.franquia.empresa + '/' + this.franquia._id);
  }

  goToTV(idTV: string) {
    window.open(environment.smartTVUrl + '/app/itv/' + idTV, '_blank');
  }

}