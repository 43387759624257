import { Component, Injector } from '@angular/core';
import { AbstractComponent, Pager, TipoAtendimento, TipoAtendimentoService } from 'lib-smart-core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'tipo-atendimento-component',
  templateUrl: './tipo-atendimento.component.html'
})
export class TipoAtendimentoComponent extends AbstractComponent {

  pager: Pager<TipoAtendimento> = new Pager<TipoAtendimento>({ perPage: 25 });

  listObservable: Observable<Pager<TipoAtendimento>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  tipoAtendimentoSelecionado: TipoAtendimento;

  constructor(
    private tipoAtendimentoService: TipoAtendimentoService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getTipoAtendimentos();
  }

  getTipoAtendimentos() {
    return this.tipoAtendimentoService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe(
      (pager: Pager<TipoAtendimento>) => {
        this.pager = pager;
        this.setupObservableSearch();
      },
      err => this.alertService.error(err.error.message)
    )
  }

  selecionar(tipoAtendimento: TipoAtendimento) {
    this.tipoAtendimentoSelecionado = tipoAtendimento;
  }

  delete() {
    return this.tipoAtendimentoService.delete(this.tipoAtendimentoSelecionado._id).subscribe(
      () => {
        this.getTipoAtendimentos();
        this.alertService.success('Operação realizada com sucesso');
      },
      err => this.alertService.error(err.error.message)
    )
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.tipoAtendimentoService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<TipoAtendimento>) => {
      this.pager = pager;
    });
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getTipoAtendimentos();
  }

}