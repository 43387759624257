import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Franquia, FranquiaService, Pager, Usuario } from 'lib-smart-core';
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'franquia-lista',
  templateUrl: './franquia-lista.component.html'
})
export class FranquiaListaComponent extends AbstractComponent implements OnInit {

  pager: Pager<Franquia> = new Pager<Franquia>({ perPage: 25 });

  idEmpresa: string;
  franquias: Franquia[];

  URL_TABLET_FRANQUIA: string;
  URL_TV_FRANQUIA: string;

  userLogged: Usuario;


  listObservable: Observable<Pager<Franquia>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  constructor(
    private franquiaService: FranquiaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.userLogged = super.getCurrentUserUser();
    let idEmpresa: string = super.getIDEmpresaSession();
    if (idEmpresa) {
      this.idEmpresa = idEmpresa;
      this.getFranquiasPorEmpresa();
    }
  }

  getFranquiasPorEmpresa() {
    this.searchParams = {
      empresa: this.idEmpresa
    }
    return this.franquiaService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe(
      (pager: Pager<Franquia>) => {
        this.pager = pager;
        this.franquias = pager.list;
        if (this.userLogged.admin) {
          this.franquias = this.pager.list
        } else {
          this.franquias = new Array<Franquia>();
          this.franquias.push(...this.userLogged.franquias);
        }
        this.setupObservableSearch();
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  setupQRCodeFranquia(idFranquia: string) {
    let baseUrl = environment.production ? 'https://controle.smartline.com.br' : 'http://localhost:3002';
    this.URL_TABLET_FRANQUIA = baseUrl + '/app/tablet/' + this.idEmpresa + '/' + idFranquia;
    this.URL_TV_FRANQUIA = baseUrl + '/app/tv/' + this.idEmpresa + '/' + idFranquia;
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.franquiaService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Franquia>) => {
      this.pager = pager;
      this.franquias = pager.list;
    });
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getFranquiasPorEmpresa();
  }

}
