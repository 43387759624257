import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { AvaliacaoModule } from '../avaliacao/avaliacao.module';
import { SmartAvaliaModule } from '../smartavalia/smartavalia.module';
import { FranquiaFormsComponent } from "./franquia-forms.component";
import { FranquiaListaComponent } from "./franquia-lista.component";
import { SharedModule } from 'lib-smart-core';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    AvaliacaoModule,
    SmartAvaliaModule,
  ],
  declarations: [
    FranquiaListaComponent,
    FranquiaFormsComponent
  ],
  exports: [
    FranquiaListaComponent,
    FranquiaFormsComponent
  ],
  providers: [
  ]
})

export class FranquiaModule { }
