<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Empresa</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>
        Empresa
      </li>
      <li class="active">
        <strong>Adicionar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">

        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" #empresaForm="ngForm" novalidate>
            <div class="form-group">
              <label>Nome</label>
              <input type="text" placeholder="Insira o Nome da Empresa" class="form-control" name="nome" id="nome"
                #nome="ngModel" [(ngModel)]="empresa.nome" required>
            </div>
            <div class="form-group">
              <label>Razão Social</label>
              <input type="text" placeholder="Insira a Razão Social da Empresa" class="form-control" name="razaoSocial"
                id="razaoSocial" #razaoSocial="ngModel" [(ngModel)]="empresa.razaoSocial" required>
            </div>
            <div class="form-group">
              <label>CPF/CNPJ</label>
              <input type="text" placeholder="Insira o CPF/CNPJ da Empresa" class="form-control" name="cpfCnpj"
                id="cpfCnpj" #cpfCnpj="ngModel" [(ngModel)]="empresa.cpfCnpj" required>
            </div>
            <div class="form-group">
              <label>Email Responsável</label>
              <input type="text" placeholder="Insira o Email do Responsável" class="form-control"
                name="emailResponsavel" id="emailResponsavel" #emailResponsavel="ngModel"
                [(ngModel)]="empresa.emailResponsavel">
            </div>
            <div class="form-group">
              <label>Nome Responsável</label>
              <input type="text" placeholder="Insira o Nome do Responsável" class="form-control" name="nomeResponsavel"
                id="nomeResponsavel" #nomeResponsavel="ngModel" [(ngModel)]="empresa.nomeResponsavel">
            </div>
            <div class="form-group">
              <label>Telefone</label>
              <input type="text" placeholder="Insira o Telefone de Contato do Responsável" class="form-control"
                name="telefoneResponsavel" id="telefoneResponsavel" #telefoneResponsavel="ngModel"
                [(ngModel)]="empresa.telefoneResponsavel">
            </div>
            <div class="form-group">
              <label>Celular</label>
              <input type="text" placeholder="Insira o Telefone Celular de Contato do Responsável" class="form-control"
                name="celularResponsavel" id="celularResponsavel" #celularResponsavel="ngModel"
                [(ngModel)]="empresa.celularResponsavel">
            </div>

            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!empresaForm.form.valid">Salvar</button>
              <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>