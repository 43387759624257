import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { SharedModule } from 'lib-smart-core';
import { AvaliacaoModule } from '../avaliacao/avaliacao.module';
import { SmartPagerComponent } from "./smartpager.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AvaliacaoModule,
    SharedModule
  ],
  declarations: [
    SmartPagerComponent,
  ],
  exports: [
    SmartPagerComponent,
  ],
  providers: [
  ]
})

export class SmartPagerModule { }
