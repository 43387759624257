import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Fila, FilaService, FirebaseService, Franquia, FranquiaService, Playlist, PlaylistService, TV, TVService, UploadTypeEnum, UtilHelper } from 'lib-smart-core';

export type ParamOption = 'showNome' | 'showAvisos' | 'showAlerta';

@Component({
  selector: 'tv-forms',
  templateUrl: './tv-forms.component.html'
})
export class TVFormsComponent extends AbstractComponent implements OnInit {

  tv: TV;
  franquia: Franquia;
  isNew: boolean = true;

  playlists: Playlist[];

  filas: Fila[];
  filasSelecionadas: Fila[];

  loadingImage: boolean = false;
  private file: File;

  constructor(
    private tvService: TVService,
    private franquiaService: FranquiaService,
    private playlistService: PlaylistService,
    private filaService: FilaService,
    private firebaseService: FirebaseService,
    public injector: Injector
  ) {
    super(injector);
    this.filas = new Array<Fila>();
    this.filasSelecionadas = new Array<Fila>();
  }

  ngOnInit() {
    this.tv = {} as TV;
    let idFranquia: string = super.getParam('idFranquia');
    if (idFranquia) {
      this.getFranquiaPorId(idFranquia);
      this.getFilasPorFranquia(idFranquia);
      this.tv.franquia = { _id: idFranquia } as Franquia;
    }
    let id: string = super.getParam('id');
    if (id) {
      this.isNew = false;
      this.getTVPorId(id.toString());
    }
    this.getAllPlaylists();
  }

  getAllPlaylists() {
    this.playlistService.getAll().subscribe(
      (playlists) => {
        this.playlists = playlists;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  getTVPorId(id: string) {
    this.tvService.getById(id).subscribe(
      (tv) => {
        this.tv = tv;
        this.getFilasPorFranquia(tv.franquia._id);
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  getFranquiaPorId(id: string) {
    this.franquiaService.getById(id).subscribe(
      (franquia) => {
        this.franquia = franquia;

      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  getFilasPorFranquia(idFranquia: string) {
    this.filaService.getFilasPorFranquia(idFranquia).subscribe(
      (filas) => {
        this.filas = filas;
        this.filasSelecionadas.push(...this.tv.filas);
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  onSubmit(): void {
    this.tv.filas = this.filasSelecionadas;
    if (this.isNew) {
      this.tvService.create(this.tv)
        .subscribe(
          mesa => {
            this.alertService.success('Operação realizada com sucesso');
            super.goBack();
          },
          err => {
            this.alertService.error(err.error.message);
          }
        );
    } else {
      this.updateTV();
      super.goBack();
    }

  }

  private updateTV() {
    this.tvService.update(this.tv)
      .subscribe(
        mesa => {
          this.alertService.success('Operação realizada com sucesso');
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
  }

  selecionarLayout(layout: string) {
    this.tv.layout = layout;
  }

  excluirFilaSelecionada(filaSelecionada: Fila) {
    let indexToRemove = -1;
    for (let i = 0; i < this.filasSelecionadas.length; i++) {
      let fila: Fila = this.filasSelecionadas[i];
      if (fila._id.toString() === filaSelecionada._id.toString()) {
        indexToRemove = i;
        break;
      }
    };
    this.filasSelecionadas.splice(indexToRemove, 1);
  }

  selecionarFila(fila: Fila) {
    this.filasSelecionadas.push(fila);
  }

  filaJaAdicionada(id: string) {
    let result = false;
    for (let i = 0; i < this.filasSelecionadas.length; i++) {
      let fila: Fila = this.filasSelecionadas[i];
      if (fila._id?.toString() === id?.toString()) {
        result = true;
        break;
      }
    };
    return result;
  }

  ativarDesativar(option: ParamOption) {
    this.tv[option] = !this.tv[option];
  }

  setAlpha(alpha: string) {
    this.tv.bgImageAlpha = alpha;
  }

  onChange(event: any) {
    let eventObj: any = <any>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    if (files[0].size > 3000000) { // 3 MB
      this.alertService.warning('Imagem inválida. O tamanho do arquivo de imagem deve ser inferior a 3 MB.');
      return;
    }

    if (!UtilHelper.mimeTypesImages.includes(files[0].type)) {
      this.alertService.warning('Imagem inválido. O arquivo deve estar no formato jpg, jpeg, gif, png ou bmp');
      return;
    }

    this.file = files[0];

    let reader = new FileReader();
    reader.onload = file => {
      if (this.file) {
        this.loadingImage = true;
        let filename = this.tv._id;
        this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.TV).then((snapshot: { url: string, urlSafe: string }) => {
          this.tv.bgImage = snapshot.url;
          this.loadingImage = false;
          this.updateTV();
        });
      }
    };
    reader.readAsArrayBuffer(this.file);
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('localBgImage') as HTMLElement;
    element.click();
  }

  removerImagem() {
    this.loadingImage = true;
    this.tv.bgImage = null;
    this.loadingImage = false;
    this.updateTV();
  }

}
