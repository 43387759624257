import { Chart } from "chart.js";
import { saveAs } from 'file-saver';
import { DateUtil, Feedback, Pager, SearchParams } from 'lib-smart-core';
import * as moment from 'moment'; // add this 1 of 4
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
import { RelatorioFeedbackService } from "../../../../../../lib-smart-core/src/lib/services/reports/relatorio-feedback.service";

export class RelatorioAvaliacaoHelper {

  private itensPerPage: number = 5000;

  constructor(
    private relatorioFeedbackService: RelatorioFeedbackService,
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  public reportCSVAvaliacoes(searchParams: Partial<SearchParams>) {
    return this.buildingArrayAvaliacoesComplete(1, this.itensPerPage, searchParams)
      .then((list: Feedback[]) => {
        this.generateCSV(list, searchParams);
      });
  }

  private buildingArrayAvaliacoesComplete(page: number, perPage: number, searchParams: Partial<SearchParams>, total?: number, feedbacks?: Array<Feedback>): Promise<Feedback[]> {
    if (!feedbacks) {
      total = 0;
      feedbacks = new Array<Feedback>();
    }

    return this.relatorioFeedbackService.getAllPagerQuerySearch(page, perPage, null, searchParams)
      .toPromise()
      .then((pager: Pager<Feedback>) => {

        pager.list.forEach((element) => {
          feedbacks.push(element);
        });

        if ((page * perPage) >= pager.total) {
          return feedbacks;
        } else {
          return this.buildingArrayAvaliacoesComplete((page + 1), perPage, searchParams, pager.total, feedbacks);
        }
      });
  }

  private generateCSV(items: any[], searchParams: Partial<SearchParams>) {
    let data: Array<any> = new Array<any>();
    items.forEach((item: any) => {
      let rating = (+item.rating).toFixed(1);
      let estabelecimento = item.franquia.nome;
      let created_at = DateUtil.toFormart(item.created_at, 'dd/MM/yyyy HH:mm:ss');
      let nome = item.nome;
      let email = item.email;
      let telefone = item.telefone;
      let comentario = item.comentario;
      const obj = { rating, estabelecimento, 'data': created_at, nome, email, telefone, comentario };
      data.push(obj);
    });

    const header = Object.keys({
      rating: 'item.rating',
      estabelecimento: 'item.franquia.nome',
      data: 'item.data',
      nome: 'item.nome',
      email: 'item.email',
      telefone: 'item.telefone',
      comentario: 'item.comentario',
    });
    let csv = data.map(row => header.map(fieldName => row[fieldName]).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    const startDateSplited = String(searchParams["startDate"]).split('-');
    const startDateDate = new Date(Date.UTC(+startDateSplited[0], +startDateSplited[1] - 1, +startDateSplited[2], 0, 0, 0));
    startDateDate.setTime(startDateDate.getTime() + startDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const endDateSplited = String(searchParams["endDate"]).split('-');
    const endDateDate = new Date(Date.UTC(+endDateSplited[0], +endDateSplited[1] - 1, +endDateSplited[2], 0, 0, 0));
    endDateDate.setTime(endDateDate.getTime() + endDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const startDate = moment(startDateDate).format('DDMMYYYY');
    const endDate = moment(endDateDate).format('DDMMYYYY');

    const blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${items[0].empresa.nome} - Relatório de Avaliações ${startDate}_${endDate}.csv`);
  }

  public reportXLSXAvaliacoes(searchParams) {
    return this.buildingArrayAvaliacoesComplete(1, this.itensPerPage, searchParams)
      .then((list: Feedback[]) => {
        this.generateXLSX(list, searchParams);
      });
  }

  private generateXLSX(items: any[], searchParams: Partial<SearchParams>) {
    let data: Array<any> = new Array<any>();
    items.forEach((item: any) => {
      let rating = (+item.rating).toFixed(1);
      let estabelecimento = item.franquia.nome;
      let created_at = DateUtil.toFormart(item.created_at, 'dd/MM/yyyy HH:mm:ss');
      let nome = item.nome;
      let email = item.email;
      let telefone = item.telefone;
      let comentario = item.comentario;
      const obj = { rating, estabelecimento, 'data': created_at, nome, email, telefone, comentario };
      data.push(obj);
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const startDateSplited = String(searchParams["startDate"]).split('-');
    const startDateDate = new Date(Date.UTC(+startDateSplited[0], +startDateSplited[1] - 1, +startDateSplited[2], 0, 0, 0));
    startDateDate.setTime(startDateDate.getTime() + startDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const endDateSplited = String(searchParams["endDate"]).split('-');
    const endDateDate = new Date(Date.UTC(+endDateSplited[0], +endDateSplited[1] - 1, +endDateSplited[2], 0, 0, 0));
    endDateDate.setTime(endDateDate.getTime() + endDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const startDate = moment(startDateDate).format('DDMMYYYY');
    const endDate = moment(endDateDate).format('DDMMYYYY');

    XLSX.writeFile(wb, `${items[0].empresa.nome} - Relatório de Avaliações ${startDate}_${endDate}.xlsx`);
  }

  reportPDFAvaliacoes(searchParams, areaChart: Chart, pieChart: Chart) {
    return this.buildingArrayAvaliacoesComplete(1, this.itensPerPage, searchParams)
      .then((list: Feedback[]) => {
        this.generatePDFRelatorioAvaliacoes(searchParams, list, areaChart, pieChart);
      });
  }

  generatePDFRelatorioAvaliacoes(searchParams: Partial<SearchParams>, list: any[], areaChart: Chart, pieChart: Chart) {

    let body: any[] = [
      [
        { text: 'Avaliação', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Estabelecimento', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Data', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Nome', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Email', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Telefone', style: 'tableHeader', bold: true, fontSize: 8 },
        { text: 'Comentário', style: 'tableHeader', bold: true, fontSize: 8, alignment: 'center' },
      ],
    ];

    list.forEach((item: any) => {
      body.push([
        { text: (+item.rating).toFixed(1), fontSize: 8 },
        { text: item.franquia.nome, fontSize: 8 },
        { text: DateUtil.toFormart(item.created_at, 'dd/MM/yyyy HH:mm:ss'), fontSize: 8 },
        { text: item.nome, fontSize: 8 },
        { text: item.email, fontSize: 8, },
        { text: item.telefone, fontSize: 8, alignment: 'center' },
        { text: item.comentario, fontSize: 8, alignment: 'center' },
      ]);
    });

    const startDateSplited = String(searchParams["startDate"]).split('-');
    const startDateDate = new Date(Date.UTC(+startDateSplited[0], +startDateSplited[1] - 1, +startDateSplited[2], 0, 0, 0));
    startDateDate.setTime(startDateDate.getTime() + startDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const endDateSplited = String(searchParams["endDate"]).split('-');
    const endDateDate = new Date(Date.UTC(+endDateSplited[0], +endDateSplited[1] - 1, +endDateSplited[2], 0, 0, 0));
    endDateDate.setTime(endDateDate.getTime() + endDateDate.getTimezoneOffset() * 60 * 1000); // deserializa

    const startDate = moment(startDateDate).format('DD/MM/YYYY');
    const endDate = moment(endDateDate).format('DD/MM/YYYY');

    const titleDocument = `Relatório de Avaliações - ${startDate}_${endDate}`;
    const areaChartURL = areaChart.canvas.toDataURL('image/png');
    const pieChartURL = pieChart.canvas.toDataURL('image/png');

    let docDefinition = {
      pageOrientation: 'landscape',
      header: { text: 'Relatório de Avaliações', bold: true, margin: [0, 20, 0, 8], alignment: 'center' },
      footer: {
        columns: [
          { text: titleDocument, fontSize: 10, bold: true, margin: [10, 20, 10, 8] },
          { text: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'), fontSize: 10, bold: true, margin: [10, 20, 10, 8], alignment: 'right' }
        ]
      },
      content: [
        { text: 'Filtro: ' + startDate + ' - ' + endDate, style: 'header', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Quantidade de Avalições no período', style: 'tableHeader', alignment: 'center', bold: true, fontSize: 8 },
                { text: 'Quantidade de Avaliações por Estabelecimento', style: 'tableHeader', alignment: 'center', bold: true, fontSize: 8 },
              ],
              [
                { image: areaChartURL, alignment: 'center', width: 350, },
                { image: pieChartURL, alignment: 'center', width: 350, },
              ],
            ]
          },
          layout: 'lightHorizontalLines'
        },

        { text: '', style: 'header', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        { text: `Relatórios de Avaliações (${list.length})`, style: 'header', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: [100, 120, '*', '*', 100, '*', '*'],
            body: body
          },
          layout: 'lightHorizontalLines'
        },
      ]
    };

    // pdfMake.createPdf(docDefinition).open();
    // pdfMake.createPdf(docDefinition).open({}, window);

    pdfMake.createPdf(docDefinition).download(titleDocument + '.pdf');
  }

}