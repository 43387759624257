import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Usuario, UsuarioService } from 'lib-smart-core';

@Component({
  selector: 'usuario-senha-forms',
  templateUrl: './usuario-senha-forms.component.html'
})

export class UsuarioSenhaFormsComponent extends AbstractComponent implements OnInit {

  usuario: Usuario;

  constructor(
    private usuarioService: UsuarioService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let id: string = super.getParam('id');
    if (id) {
      this.getFranquiaPorId(id.toString());
    }
  }

  getFranquiaPorId(id: string) {
    return this.usuarioService.getById(id).subscribe(
      (usuario) => {
        this.usuario = usuario
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  onSubmit(): void {
    this.usuarioService.updateSenha(this.usuario._id, this.usuario)
      .subscribe(
        usuario => {
          this.alertService.success('Operação realizada com sucesso');
          super.goBack();
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
  }

}