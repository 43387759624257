import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { SharedModule } from 'lib-smart-core';
import { ConfigFormsComponent } from "./config-forms.component";
import { ConfigListaComponent } from "./config-lista.component";
import { ImagesFormComponent } from './images/images-form/images-form.component';
import { ImagesListComponent } from './images/images-list/images-list.component';
import { QRCodeTemplateFormComponent } from './qrcode-template/qrcode-template-form/qrcode-template-form.component';
import { QRCodeTemplateListComponent } from './qrcode-template/qrcode-template-list/qrcode-template-list.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule
  ],
  declarations: [
    ConfigListaComponent,
    ConfigFormsComponent,
    ImagesListComponent,
    ImagesFormComponent,
    QRCodeTemplateListComponent,
    QRCodeTemplateFormComponent
  ],
  exports: [
    ConfigListaComponent,
    ConfigFormsComponent,
    ImagesListComponent,
    ImagesFormComponent,
    QRCodeTemplateListComponent,
    QRCodeTemplateFormComponent
  ],
  providers: [
  ]
})

export class ConfigModule { }
