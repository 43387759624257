import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, EmpresaService, FirebaseService, Franquia, FranquiaService, QRCodeTemplate, UploadTypeEnum, UtilHelper } from 'lib-smart-core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'qrcode-template-form',
  templateUrl: 'qrcode-template-form.component.html'
})

export class QRCodeTemplateFormComponent extends AbstractComponent implements OnInit {

  empresa: Empresa;
  qrCodeTemplates: QRCodeTemplate[];
  qrCodeTemplateSelecionado: QRCodeTemplate = {} as QRCodeTemplate;
  isNew: boolean = false;
  searchBox: string;
  model: any;
  franquiasModal: Franquia[];
  franquiasSelecionadas: Franquia[];
  loadingImage: boolean = false;
  private file: File;

  usuariosObs: Observable<Franquia[]>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  constructor(
    private empresaService: EmpresaService,
    private franquiaService: FranquiaService,
    private firebaseService: FirebaseService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model = {};
    this.franquiasSelecionadas = [];

    let idEmpresa: string = super.getParam('idEmpresa');
    let indexQRCode: string = super.getParam('index');

    if (indexQRCode && idEmpresa) {
      this.getEmpresaById(idEmpresa, indexQRCode);
    } else {
      this.isNew = !this.isNew;
      this.getEmpresaById(idEmpresa);
      this.qrCodeTemplateSelecionado.type = 'LITE';
    }
    this.getFranquiasPorEmpresa(idEmpresa);
    this.setupFranquiasLoad(idEmpresa);
  }

  getFranquiasSelecionadas(franquiaSelecionadas: any) {
    this.qrCodeTemplateSelecionado.franquias = franquiaSelecionadas;
  }

  search(termo: string) {
    this.termoDaBusca.next(termo);
  }

  loadModalFranquias() {
    this.searchBox = '';
    this.model.searchBox = '';
    this.termoDaBusca.next('');
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      (franquias) => {
        this.franquiasModal = franquias;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  excluirFranquiaSelecionada(index: number) {
    this.franquiasSelecionadas.splice(index, 1);
  }

  selecionarFraquia(franquia: Franquia) {
    this.franquiasSelecionadas.push(franquia);
  }

  getAllFranquiasSelecionadas(idFranquias: string[]) {
    let franquias: Franquia[] = [];
    idFranquias.map(id => {
      this.franquiaService.getById(id).subscribe(
        (franquia) => {
          franquias.push(franquia);
        },
        err => {
          this.alertService.error(err.error.message);
        }
      )
    })
    return franquias;
  }

  franquiaJaAdicionada(id: string) {
    let result = false;
    for (let i = 0; i < this.franquiasSelecionadas.length; i++) {
      let franquia: Franquia = this.franquiasSelecionadas[i];
      if (franquia._id.toString() === id.toString()) {
        result = true;
        break;
      }
    };
    return result;
  }

  getEmpresaById(idEmpresa, indexQRCodeTemplate?) {
    this.empresaService.get(idEmpresa).subscribe(
      empresa => {
        this.empresa = empresa;
        if (indexQRCodeTemplate) {
          this.qrCodeTemplateSelecionado = empresa.qrCodeTemplates[indexQRCodeTemplate];
          this.qrCodeTemplateSelecionado['index'] = indexQRCodeTemplate;
          this.franquiasSelecionadas = this.getAllFranquiasSelecionadas(empresa.qrCodeTemplates[indexQRCodeTemplate].franquias)
        };
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  setupFranquiasLoad(idEmpresa: string) {
    this.usuariosObs = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return term ? this.franquiaService.searchEmpresa(idEmpresa, term) : this.franquiaService.getFranquiasPorEmpresa(idEmpresa);
      }));
    this.usuariosObs.subscribe((franquias: Franquia[]) => {
      console.log(franquias);
      return this.franquiasModal = franquias;
    });
  }

  onSubmitEmpresa(): void {

    this.qrCodeTemplateSelecionado['franquias'] = this.getListIdFranquias(this.franquiasSelecionadas);

    if (this.isNew) {
      this.empresa.qrCodeTemplates.push(this.qrCodeTemplateSelecionado);
    } else {
      this.empresa.qrCodeTemplates[this.qrCodeTemplateSelecionado.index] = this.qrCodeTemplateSelecionado;
    }

    this.empresaService.update(this.empresa).subscribe(
      () => {
        this.alertService.success('Operação realizada com sucesso');
        this.router.navigate(['config'])
      },
      err => this.alertService.error(err.error.message)
    )
  }

  // uploadImagem
  onChange(event: any) {

    let eventObj: any = <any>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    let validateWidth = 2480;
    let validateHeight = 3508;

    if (files[0].size > 1000000) { // 1 MB
      this.alertService.warning('Imagem inválida. O tamanho do arquivo de imagem deve ser inferior a 1 MB.');
      return;
    }

    if (!UtilHelper.mimeTypesImages.includes(files[0].type)) {
      this.alertService.warning('Imagem inválido. O arquivo deve estar no formato jpg, jpeg, gif, png ou bmp');
      return;
    }

    this.file = files[0];

    let reader = new FileReader();
    reader.onload = file => {
      let img = new Image()
      img.src = <any>reader.result;
      img.onload = () => {
        if (img.width !== validateWidth || img.height !== validateHeight) {
          this.alertService.warning(`A imagem do template deve ter a resolução igual a ${validateWidth}x${validateHeight}px.`);
          this.file = null;
          return;
        }

        if (this.file) {
          this.loadingImage = true;
          let filename = `${this.empresa._id}_${uuidv4()}`;
          this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.EMPRESA).then((snapshot: { url: string, urlSafe: string }) => {
            this.qrCodeTemplateSelecionado['image'] = snapshot.url;
            this.loadingImage = false;
          });
        }
      }
    };
    reader.readAsDataURL(this.file);
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('localQRCodeTemplateAdd') as HTMLElement;
    element.click();
  }

  removerImagem(index) {
    this.loadingImage = true;
    this.qrCodeTemplateSelecionado = null;
    this.loadingImage = false;
    this.empresa.qrCodeTemplates.splice(index, 1);
    this.onSubmitEmpresa();
  }

  getListIdFranquias(franquias: Franquia[]): string[] {
    return franquias.map(f => { return f._id })
  }
}
