import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, FirebaseService, Franquia, FranquiaService, UploadTypeEnum, Usuario, UsuarioService, UtilHelper } from 'lib-smart-core';
import { Observable, of, timer } from 'rxjs';
import { Constantes } from '../../../environments/constantes';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'usuario-forms',
  templateUrl: './usuario-forms.component.html'
})
export class UsuarioFormsComponent extends AbstractComponent implements OnInit {

  estabelecimentos = [];

  usuario: Usuario;
  isNew: boolean = true;

  franquias: Franquia[];

  loadingImage: boolean = false;

  franquiasSelecionadas: Array<Franquia>;

  constructor(
    private usuarioService: UsuarioService,
    private franquiaService: FranquiaService,
    private firebaseService: FirebaseService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.franquiasSelecionadas = new Array<Franquia>();
    this.usuario = {} as Usuario;
    let idEmpresa: string = super.getIDEmpresaSession();
    if (idEmpresa) {
      this.usuario = {} as Usuario;
      this.usuario.empresa = { _id: idEmpresa };
      this.getFranquiasPorEmpresa(idEmpresa);
    }
    let id: string = super.getParam('id');
    if (id) {
      this.isNew = false;
      this.getUsuarioPorId(id.toString());
    }
  }

  getUsuarioPorId(id: string) {
    return this.usuarioService.getById(id).subscribe(
      (usuario) => {
        this.usuario = usuario
        if (this.usuario.franquias && this.usuario.franquias.length > 0) {
          this.franquiasSelecionadas = this.usuario.franquias;
        }
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    return this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      (franquia: Franquia[]) => {
        this.franquias = franquia
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  onSubmit(): void {
    this.usuario.franquias = this.franquiasSelecionadas;
    if (this.isNew) {
      this.usuarioService.create(this.usuario).subscribe(
        franquia => {
          this.alertService.success('Operação realizada com sucesso');
          super.goBack();
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
    } else {
      this.usuarioService.update(this.usuario).subscribe(
        usuario => {
          this.alertService.success('Operação realizada com sucesso');
          super.goBack();
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
    }

  }

  private file: File;
  private bytes: string;

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('localImage') as HTMLElement;
    element.click();
  }

  onChange(event: any) {
    let eventObj: any = event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    if (files[0].size > 1000000) { // 1 MB
      this.alertService.warning('Imagem inválida. O tamanho do arquivo de imagem deve ser inferior a 1 MB.');
      return;
    }

    if (!UtilHelper.mimeTypesImages.includes(files[0].type)) {
      // this.alertService.warning('Arquivo inválido. O arquivo deve estar no formato jpg, jpeg, gif, png, bmp, pdf, zip, rar, epub, xls, xlsx, mp3, doc, docx, ppt ou pptx.');
      this.alertService.warning('Imagem inválido. O arquivo deve estar no formato jpg, jpeg, gif, png ou bmp');
      return;
    }

    this.file = files[0];
    let reader = new FileReader();
    reader.onload = file => {
      if (this.file) {
        this.loadingImage = true;
        this.firebaseService.uploadFile(this.file, this.usuario.email, UploadTypeEnum.USUARIO).then((snapshot: { url: string, urlSafe: string }) => {
          this.usuario.image = snapshot.url;
          this.loadingImage = false;
          if (!this.isNew) {
            this.onSubmitUpdateImage();
          }
        });
      }
    };
    reader.readAsArrayBuffer(this.file);
  }

  removerImagem() {
    this.loadingImage = true;
    this.usuario.image = null;
    this.loadingImage = false;
    this.onSubmitUpdateImage();
  }

  onSubmitUpdateImage() {
    this.usuarioService.update(this.usuario).subscribe(
      usuario => {
        this.alertService.success('Operação realizada com sucesso');
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  atualizaSessao(id): void {
    if (super.getIDCurrentUserSession() === id) {
      this.usuarioService.getById(id).subscribe(
        usuario => {
          // this.router.navigate(['/usuario']);
          var currentUser = JSON.parse(localStorage.getItem(Constantes.currentUser));
          currentUser.usuario = usuario;
          localStorage.setItem(Constantes.currentUser, JSON.stringify(currentUser));
          this.router.navigate(['/']);
          this.alertService.success('Seus dados foram atualizados com sucesso. A página sera recarregada em 5 segundos para atualizar os dados da sessão.');
          timer(7000).subscribe(() => {
            if (environment.production) {
              window.location.href = '/';
            } else {
              window.location.href = '/';
            }
          });
        },
        err => this.alertService.error(err.error.message)
      );
    } else {
      super.goBack();
      this.alertService.success('Operação realizada com sucesso');
    }
  }

  public transform(value: string): Observable<string> {
    return of(value);
  }


  newAttendanceAttribute(item: Franquia) {
    if (item) {
      if (!this.franquiasSelecionadas || this.franquiasSelecionadas.length === 0) {
        this.franquiasSelecionadas = new Array<Franquia>();
      }
      if (!this.franquiasSelecionadas.includes(item)) {
        this.franquiasSelecionadas.push(item);
      }
    }
  }

  ativarAdmin() {
    this.usuario.admin = true;
    this.usuario.manager = false;
  }

  desativarAdmin() {
    this.usuario.admin = false;
    this.usuario.manager = false;
  }

  ativarGerente() {
    this.usuario.admin = false;
    this.usuario.manager = true;
  }

  desativarGerente() {
    this.usuario.admin = false;
    this.usuario.manager = false;
  }

  ativarAtendente() {
    this.usuario.admin = false;
    this.usuario.manager = false;
  }

}