import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, EmpresaService, FirebaseService, Franquia, FranquiaService, Image, UploadTypeEnum, UtilHelper } from 'lib-smart-core';
import { Observable, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'images-form',
  templateUrl: 'images-form.component.html'
})

export class ImagesFormComponent extends AbstractComponent implements OnInit {

  empresa: Empresa;
  images: Image[];
  imageSelecionada: Image = {} as Image;
  isNew: boolean = false;
  searchBox: string;
  model: any;
  franquiasModal: Franquia[];
  franquiasSelecionadas: Franquia[];
  loadingImage: boolean = false;
  private file: File;
  private bytes: string;


  usuariosObs: Observable<Franquia[]>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  constructor(
    private empresaService: EmpresaService,
    private franquiaService: FranquiaService,
    private firebaseService: FirebaseService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model = {};
    this.franquiasSelecionadas = [];

    let idEmpresa: string = super.getParam('idEmpresa');
    let indexImagem: string = super.getParam('index');

    if (indexImagem && idEmpresa) {
      this.getEmpresaById(idEmpresa, indexImagem)
    } else {
      this.isNew = !this.isNew;
      this.getEmpresaById(idEmpresa);
    }
    this.getFranquiasPorEmpresa(idEmpresa);
  }

  getFranquiasSelecionadas(franquiaSelecionadas: any) {
    this.imageSelecionada.franquias = franquiaSelecionadas;
  }

  search(termo: string) {
    this.termoDaBusca.next(termo);
  }

  loadModalFranquias() {
    this.searchBox = '';
    this.model.searchBox = '';
    this.termoDaBusca.next('');
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      (franquias) => {
        this.franquiasModal = franquias;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  excluirFranquiaSelecionada(index: number) {
    this.franquiasSelecionadas.splice(index, 1);
  }

  selecionarFraquia(franquia: Franquia) {
    this.franquiasSelecionadas.push(franquia);
  }

  getAllFranquiasSelecionadas(idFranquias: string[]) {
    let franquias: Franquia[] = [];
    idFranquias.map(id => {
      this.franquiaService.getById(id).subscribe(
        (franquia) => {
          franquias.push(franquia);
        },
        err => {
          this.alertService.error(err.error.message);
        }
      )
    })
    return franquias;
  }

  franquiaJaAdicionada(id: string) {
    let result = false;
    for (let i = 0; i < this.franquiasSelecionadas.length; i++) {
      let franquia: Franquia = this.franquiasSelecionadas[i];
      if (franquia._id.toString() === id.toString()) {
        result = true;
        break;
      }
    };
    return result;
  }

  getEmpresaById(idEmpresa, indexImagem?) {
    this.empresaService.get(idEmpresa).subscribe(
      empresa => {
        this.empresa = empresa;
        if (indexImagem) {
          this.imageSelecionada = empresa.images[indexImagem];
          this.imageSelecionada['index'] = indexImagem;
          this.franquiasSelecionadas = this.getAllFranquiasSelecionadas(empresa.images[indexImagem].franquias)
        };
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  onSubmitEmpresa(): void {

    this.imageSelecionada['franquias'] = this.getListIdFranquias(this.franquiasSelecionadas);

    if (this.isNew) {
      this.empresa.images.push(this.imageSelecionada);
    } else {
      this.empresa.images[this.imageSelecionada.index] = this.imageSelecionada;
    }

    this.empresaService.update(this.empresa).subscribe(
      () => {
        this.alertService.success('Operação realizada com sucesso');
        super.goBack();
      },
      err => this.alertService.error(err.error.message)
    )
  }

  // uploadImagem
  onChange(event: any) {
    let eventObj: any = <any>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    if (files[0].size > 1000000) { // 1 MB
      this.alertService.warning('Imagem inválida. O tamanho do arquivo de imagem deve ser inferior a 1 MB.');
      return;
    }

    if (!UtilHelper.mimeTypesImages.includes(files[0].type)) {
      this.alertService.warning('Imagem inválido. O arquivo deve estar no formato jpg, jpeg, gif, png ou bmp');
      return;
    }

    this.file = files[0];

    let reader = new FileReader();
    reader.onload = file => {
      if (this.file) {
        this.loadingImage = true;
        let filename = `${this.empresa._id}_${uuidv4()}`;
        this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.EMPRESA).then((snapshot: { url: string, urlSafe: string }) => {
          this.imageSelecionada['image'] = snapshot.url;
          this.loadingImage = false;
        });
      }
    };
    reader.readAsArrayBuffer(this.file);
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('localImageAdd') as HTMLElement;
    element.click();
  }

  removerImagem(index) {
    this.loadingImage = true;
    this.imageSelecionada = null;
    this.loadingImage = false;
    this.empresa.images.splice(index, 1);
    this.onSubmitEmpresa();
  }

  getListIdFranquias(franquias: Franquia[]): string[] {
    return franquias.map(f => { return f._id })
  }
}
