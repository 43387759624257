<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Avisos</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li>
        <a [routerLink]="['/aviso']">Avisos</a>
      </li>
      <li class="active">
        <strong *ngIf="isNew">Adicionar</strong>
        <strong *ngIf="!isNew">Alterar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">

        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
            <div class="form-group">
              <label>Titulo</label>
              <input type="text" placeholder="Insira o Titulo do Aviso" class="form-control" name="titulo" id="titulo"
                #titulo="ngModel" [(ngModel)]="aviso.titulo" required>
            </div>
            <div class="form-group">
              <label>Mensagem</label>
              <textarea class="form-control" rows="5"
                placeholder="Informe a mensagem que será emitida para as franquias" name="mensagem" id="mensagem"
                #mensagem="ngModel" [(ngModel)]="aviso.mensagem"></textarea>
            </div>

            <div class="hr-line-dashed"></div>

            <div class="form-group">
              <label></label>
              <button type="button" class="btn btn-primary btn-xs" data-toggle="modal" data-target="#modalFranquias">
                <i class="fa fa-plus"></i> Adicionar Estabelecimento
              </button>
            </div>

            <div class="form-group">
              <label></label>

              <table class="table table-condensed" *ngIf="franquiasSelecionadas?.length">
                <thead>
                  <tr>
                    <th>Estabelecimento</th>
                    <th>Código</th>
                    <th>Cidade/UF</th>
                    <!-- <th>Empresa</th> -->
                    <th width="10%" style="text-align: right;">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let fs of franquiasSelecionadas; let i = index">
                    <td scope="row">{{fs.nome}}</td>
                    <td>{{fs.codigo}}</td>
                    <td>{{fs.cidade}}/{{fs.estado}}</td>
                    <!-- <td>{{fs.empresa ? fs.empresa.nome : 'Não associado'}}</td> -->
                    <td style="text-align: right;">
                      <button type="button" class="btn btn-danger fa fa-trash" (click)="excluirFranquiaSelecionada(i)"
                        style="text-align: right;"></button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="panel text-center" *ngIf="!franquiasSelecionadas?.length">
                <div class="panel-body">
                  <p class="p-y-1">
                    <i class="fa fa-cubes font-size-46 line-height-1 text-primary"></i>
                  </p>
                  <p>
                    <strong>Estabelecimentos</strong>
                  </p>
                  <p class="m-b-4">Nenhum registro encontrado.</p>
                </div>
              </div>

            </div>

            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!f.form.valid">Salvar</button>
              <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal inmodal" id="modalFranquias" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Procurar Estabelecimento</h4>
        <small class="font-bold">Localize os estabelecimentos que procura</small>
      </div>
      <div class="modal-body">
        <form #f2="ngForm" novalidate>
          <div class="form-group">

            <label>Pesquise os estabelecimento por nome</label>
            <input #searchBox (keyup)="search(searchBox.value)" [(ngModel)]="model.searchBox"
              [ngModelOptions]="{standalone: true}" placeholder="Pesquise os estabelecimento por nome"
              class="form-control">

            <table class="table table-condensed" *ngIf="franquiasModal?.length">
              <thead>
                <tr>
                  <th>Estabelecimento</th>
                  <th>Código</th>
                  <th>Cidade/UF</th>
                  <th>Empresa</th>
                  <!-- <th>Grupo</th> -->
                  <th width="10%" style="text-align: right;">Associar</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fm of franquiasModal; let i = index">
                  <th scope="row">{{fm.nome}}</th>
                  <td>{{fm.codigo}}</td>
                  <td>{{fm.cidade}}/{{fm.estado}}</td>
                  <td>{{fm.empresa ? fm.empresa.nome : 'Não associado'}}</td>
                  <!-- <td>{{fm.grupo ? fm.grupo.nome : 'Não associado'}}</td> -->
                  <td style="text-align: right;">
                    <button type="button" class="btn btn-primary fa fa-plus" (click)="selecionarFraquia(fm)"
                      [disabled]="franquiaJaAdicionada(fm._id)"></button>
                  </td>
                </tr>
              </tbody>
            </table>

            <br />

            <div class="panel text-center" *ngIf="!franquiasModal?.length">
              <div class="panel-body">
                <p class="p-y-1">
                  <i class="fa fa-cubes font-size-46 line-height-1 text-primary"></i>
                </p>
                <p>
                  <strong>Estabelecimentos</strong>
                </p>
                <p class="m-b-4">Nenhum registro encontrado.</p>
              </div>
            </div>

          </div>
          <!-- <div class="modal-footer">
                            <button type="button" class="btn" data-dismiss="modal">Cancelar</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="delete()">Confirmar</button>
                          </div> -->
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>