import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, TipoAtendimento, TipoAtendimentoService } from 'lib-smart-core';

@Component({
  selector: 'tipo-atendimento-forms',
  templateUrl: './tipo-atendimento-forms.component.html'
})
export class TipoAtendimentoFormsComponent extends AbstractComponent implements OnInit {

  searchBox: string;
  model: any;

  tipoAtendimento: TipoAtendimento;
  isNew: boolean = true;

  constructor(
    private tipoAtendimentoService: TipoAtendimentoService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model = {};

    this.tipoAtendimento = {} as TipoAtendimento;
    let idEmpresa: string = super.getIDEmpresaSession();
    if (idEmpresa) {
      this.tipoAtendimento.empresa = { _id: idEmpresa } as Empresa;
    }
    let id: string = super.getParam('id');
    if (id) {
      this.isNew = false;
      this.getTipoAtendimentoPorId(id.toString());
    }
  }

  getTipoAtendimentoPorId(id: string) {
    return this.tipoAtendimentoService.getById(id).subscribe(
      (tipoAtendimento) => {
        this.tipoAtendimento = tipoAtendimento;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  onSubmit(): void {
    if (this.isNew) {
      this.tipoAtendimentoService.create(this.tipoAtendimento).subscribe(
        franquia => {
          this.alertService.success('Operação realizada com sucesso');
          super.goBack();
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
    } else {
      this.tipoAtendimentoService.update(this.tipoAtendimento).subscribe(
        usuario => {
          this.alertService.success('Operação realizada com sucesso');
          super.goBack();
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
    }

  }

}