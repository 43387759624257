<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Atendimento</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li class="active">
        <strong>Atendimento </strong>
      </li>
    </ol>
  </div>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-8">
      <form #atendimentoForm="ngForm" novalidate>
        <div class="jumbotron">
          <h1>Iniciando</h1>
          <p>Selecione o Estabelecimento e o local de atendimento para iniciar os atendimentos.</p>
          <div class="form-group">
            <select [(ngModel)]="idFranquiaSelecionada" (change)="onChangeFranquia($event)"
              class="form-control input-lg" [ngModelOptions]="{standalone: true}" required>
              <option value="" checked>Selecione</option>
              <option *ngFor="let f of franquias" [value]="f._id">
                {{f.nome}} {{!!f.nomeFantasia ? ' | ' + f.nomeFantasia : ''}} {{f.bloqueado ? ' [BLOQUEADO]' : '' }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <select [(ngModel)]="idMesaSelecionada" class="form-control input-lg" [ngModelOptions]="{standalone: true}"
              required>
              <option value="" checked>Selecione</option>
              <option *ngFor="let m of mesas" [value]="m._id">{{m.nome}} - {{m.descricao}}</option>
            </select>
          </div>
          <div class="form-group" *ngIf="locationEnabled">
            <select [(ngModel)]="idLocalizacaoSelecionada" class="form-control input-lg"
              [ngModelOptions]="{standalone: true}" required>
              <option value="" checked>Selecione</option>
              <option *ngFor="let m of localizacoes" [value]="m._id">{{m.cep}} - {{m.nome}} - {{m.bairro}} -
                {{m.cidade}}/{{m.estado}}</option>
            </select>
          </div>
          <p>
            <button (click)="continuar()" class="btn btn-primary btn-lg" role="button">Iniciar Atendimentos</button>
          </p>
        </div>
      </form>
    </div>
    <div class="col-lg-6">
      <!-- <div class="ibox">
                <div class="ibox-content">
                    <h1>Treinamento</h1>
                    <p>Disponibilizamos um espaço para você conhecer todas as funcionalidades que a ferramenta disponibiliza. Recomendas o treinamento antes de iniciar os atendimentos.</p>
                    <h5>Progresso</h5>
                    <h2>0%</h2>
                    <div class="progress progress-mini">
                        <div style="width: 0%;" class="progress-bar"></div>
                    </div>

                    <div class="m-t-sm small">
                        <a class="btn btn-primary btn-sm" role="button">Iniciar Treinamento</a>
                        <a class="btn btn-default btn-sm" role="button">
                            <span class="fa fa-support"></span> Suporte</a>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
</div>