import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { LocalizacaoFormsComponent } from './localizacao-forms.component';
import { LocalizacaoComponent } from './localizacao.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    LocalizacaoComponent,
    LocalizacaoFormsComponent,
  ],
  exports: [
    LocalizacaoComponent,
    LocalizacaoFormsComponent,
  ],
  providers: []
})

export class LocalizacaoModule { }
