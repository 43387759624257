<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-6">
    <h2>Relatório de Tipos de Atendimento</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>
        <a>Relatórios</a>
      </li>
      <li class="active">
        <strong>Relatório de Tipos de Atendimento</strong>
      </li>
    </ol>
  </div>
  <div class="col-sm-6">
    <div class="title-action">
      <button type="button" [disabled]="exporting" (click)="exportarCSV()" *ngIf="list?.length > 0"
        class="btn btn-primary">
        <img *ngIf="exporting"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        {{ exporting ? ' Exportando...' : 'Exportar CSV' }}
      </button>
      <button type="button" [disabled]="exporting" (click)="exportarExcel()" *ngIf="list?.length > 0"
        class="btn btn-primary">
        <img *ngIf="exporting"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        {{ exporting ? ' Exportando...' : 'Exportar XLSX' }}
      </button>
      <button type="button" [disabled]="exporting" (click)="exportarPDF()" *ngIf="list?.length > 0"
        class="btn btn-primary">
        <img *ngIf="exporting"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        {{ exporting ? ' Exportando...' : 'Exportar PDF' }}
      </button>
    </div>
  </div>
</div>

<div class="wrapper wrapper-content pb-0 animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Filtros </h5>
        </div>
        <div class="ibox-content">
          <form #atendimentoForm="ngForm" class=" " novalidate>
            <div class="row">
              <div class="col-lg-4">
                <mat-form-field style="width:25vw">
                  <mat-label>Selecione o período</mat-label>
                  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date">
                    <input matEndDate formControlName="end" placeholder="End date" (dateChange)="pagerReport()">
                  </mat-date-range-input>
                  <!-- <mat-hint>dd/mm/aaaa - dd/mm/aaaa</mat-hint> -->
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker>
                    <mat-date-range-picker-actions>
                      <select class="form-control" name="prefixo" id="prefixo" (change)="loadCustomDateRange($event)"
                        style="margin-right: 5px;">
                        <option [value]="0">Customizado</option>
                        <option [value]="7">Últimos 7 dias</option>
                        <option [value]="15">Últimos 15 dias</option>
                        <option [value]="30">Últimos 30 dias</option>
                        <option [value]="90">Últimos 90 dias</option>
                        <option [value]="180">Útlimos 6 meses</option>
                        <option [value]="365">Útlimos 12 meses</option>
                      </select>
                      <button matDateRangePickerCancel #matDateRangePickerCancelTipoAtendimwento
                        style="display: none">Close</button>
                      <button mat-raised-button color="primary" matDateRangePickerApply>OK</button>
                    </mat-date-range-picker-actions>
                  </mat-date-range-picker>

                  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Data de início
                    inválida</mat-error>
                  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Data de fim inválida</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-8">
                <div class="form-group">
                  <mat-form-field style="width:50vw">
                    <mat-label>Estabelecimento</mat-label>
                    <mat-select [(ngModel)]="idFranquiaSelecionada" name="idFranquiaSelecionada"
                      id="idFranquiaSelecionada" (selectionChange)="pagerReport()">
                      <mat-option [value]="''"> Todos</mat-option>
                      <mat-option *ngFor="let f of franquias" [value]="f._id">
                        {{f.nome}} {{!!f.nomeFantasia ? ' | ' + f.nomeFantasia : ''}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="wrapper wrapper-content animated fadeIn">

  <div class="row" style="display: none;">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Atendimentos realizados por Tipo de Atendimento</h5>
        </div>
        <div class="ibox-content">
          <canvas id="relatorio-tipos-atendimento2" height="70px"></canvas>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="ibox-title">
        <h5>Relatório de Tipos de Atendimento <span *ngIf="list?.length > 0">({{list?.length}})</span> </h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">

          <table class="table table-striped" *ngIf="list?.length > 0" id="table-relatorio-tipos-atendimento">
            <thead>
              <tr>
                <th class="text-center">Tipos de Atendimento</th>
                <th class="text-center">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of list; let i = index">
                <th class="text-center">{{item.name}}</th>
                <td class="text-center">{{item.count}}</td>
              </tr>
            </tbody>
          </table>

          <div class="panel text-center" *ngIf="list?.length === 0">
            <div class="panel-body">
              <p class="p-y-1">
                <i class="fa fa-table fa-3x font-size-46 line-height-1 text-primary"></i>
              </p>
              <h3><strong>Relatório de Tipos de Atendimento</strong></h3>
              <p class="m-b-4">Não existem registros de atendimentos no período.</p>
            </div>
          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

        </div>
      </div>


    </div>
  </div>
</div>
