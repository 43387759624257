import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { SharedModule } from 'lib-smart-core';
import { MesaFormsComponent } from "./mesa-forms.component";
import { MesaListaComponent } from "./mesa-lista.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule
  ],
  declarations: [
    MesaListaComponent,
    MesaFormsComponent
  ],
  exports: [
    MesaListaComponent,
    MesaFormsComponent
  ],
  providers: [
  ]
})

export class MesaModule { }