import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'lib-smart-core';
import { AvaliacaoEmbededComponent } from './avaliacao-embeded.component';
import { AvaliacaoFranquiaComponent } from './avaliacao-franquia.component';
import { AvaliacaoModalFranquiaComponent } from './avaliacao-modal-franquia.component';
import { AvaliacaoModalGeralComponent } from './avaliacao-modal-geral.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule
  ],
  declarations: [
    AvaliacaoEmbededComponent,
    AvaliacaoFranquiaComponent,
    AvaliacaoModalGeralComponent,
    AvaliacaoModalFranquiaComponent,
  ],
  exports: [
    AvaliacaoEmbededComponent,
    AvaliacaoFranquiaComponent,
    AvaliacaoModalGeralComponent,
    AvaliacaoModalFranquiaComponent,
  ],
  providers: [
  ]
})
export class AvaliacaoModule { }