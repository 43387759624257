import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { PlaylistFormsComponent } from './playlist-forms.component';
import { PlaylistListaComponent } from './playlist-lista.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    PlaylistListaComponent,
    PlaylistFormsComponent
  ],
  exports: [
    PlaylistListaComponent,
    PlaylistFormsComponent
  ],
  providers: [
  ]
})

export class PlaylistModule { }