<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Local</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>SmartFilas</li>
      <li>
        <a [routerLink]="['/franquia']">Estabelecimentos</a>
      </li>
      <li>
        <a [routerLink]="['/franquia']">{{franquia?.nome}}</a>
      </li>
      <li class="active">
        <strong>Locais</strong>
      </li>
    </ol>
  </div>
</div>
<div class="row wrapper wrapper-content animated fadeIn">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>Locais | {{franquia?.nome}}</h5>
        <div class="ibox-tools">
          <a [routerLink]="['/mesa/add/franquia', idFranquia]" class="btn btn-primary btn-xs">
            <i class="fa fa-plus"></i> Adicionar</a>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Número/Letra</th>
                <th>Descrição</th>
                <th class="text-right" width="10%">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let mesa of mesas">
                <th>{{mesa.nome}}</th>
                <td>{{mesa.descricao}}</td>
                <td class="text-right">
                  <a class="btn btn-white btn-sm btn-bitbucket" title="Editar" [routerLink]="['/mesa/edit/', mesa._id]">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a class="btn btn-danger btn-sm btn-bitbucket" title="Remover" data-toggle="modal" href="#deleteModel"
                    (click)="selecionar(mesa)">
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="deleteModel" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Remover Fila</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Esta ação não poderá ser desfeita. Tem certeza que deseja excluir a fila
            <b>{{mesaSelecionada?.nome}}</b>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="delete()">Confirmar</button>
      </div>
    </div>
  </div>
</div>