import { AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Chart } from "chart.js";
import { AbstractComponent, ChartHelper, DateUtil, Empresa, EmpresaService, Franquia, FranquiaService, Usuario } from 'lib-smart-core';
import { mergeMap } from 'rxjs';
import { Constantes } from '../../../environments/constantes';

@Component({
  templateUrl: './empresa.component.html'
})
export class EmpresaComponent extends AbstractComponent implements OnInit, AfterViewInit {

  @ViewChild('matDateRangePickerCancelAtendimento') matDateRangePickerCancelAtendimento: ElementRef;

  userLogged: Usuario;
  empresa: Empresa;
  totalFranquias: number = 0;
  totalUsuarios: number = 0;
  totalMesas: number = 0;
  totalAtendimentos: number = 0;

  franquia: Franquia;
  franquias: Franquia[];

  chartArea: Chart;
  chartPie: Chart;
  chartPolarArea: Chart;

  canvas: any;
  ctx: any;

  canvasTipoAtendimento: any;
  ctxTipoAtendimento: any;

  canvasFeedback: any;
  ctxFeedback: any;

  totalSenhas: any = {}

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  loadingCharts: boolean = false;

  idFranquiaSelecionada = '';

  constructor(
    private empresaService: EmpresaService,
    private franquiaService: FranquiaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 15);
    this.range.setValue({ start: startDate, end: endDate });

    this.userLogged = super.getCurrentUserUser();
    let id: string = super.getIDEmpresaSession();
    if (id) {
      this.empresaService.getById(id).pipe(
        mergeMap((empresa: Empresa) => {
          this.empresa = empresa;
          return this.empresaService.getCountFranquias(id);
        }),
        mergeMap((count: number) => {
          if (this.userLogged.admin) {
            this.totalFranquias = count;
          } else {
            this.totalFranquias = this.userLogged.franquias.length;
          }
          return this.empresaService.getCountUsuarios(id);
        }),
        mergeMap((count: number) => {
          this.totalUsuarios = count
          return this.empresaService.getCountMesas(id);
        }),
        mergeMap((count: number) => {
          this.totalMesas = count;
          return this.empresaService.getCountSenhas(id)
        }),
      ).subscribe((count: number) => {
        this.totalAtendimentos = count
      });
    }
  }

  ngAfterViewInit() {
    this.canvas = document.getElementById('canvas-chart-senha-atendimento');
    this.ctx = this.canvas.getContext('2d');

    this.canvasTipoAtendimento = document.getElementById('canvas-chart-tipo-atendimento');
    this.ctxTipoAtendimento = this.canvasTipoAtendimento.getContext('2d');

    this.canvasFeedback = document.getElementById('canvas-chart-feedback');
    this.ctxFeedback = this.canvasFeedback.getContext('2d');

    let id: string = super.getIDEmpresaSession();
    if (id) {
      this.getEmpresa(id);
      this.getFranquiasPorEmpresa(id);
      this.dashboardChartCounts();
    }
  }

  getEmpresa(id: string) {
    this.empresaService.getById(id).subscribe(
      empresa => {
        this.empresa = empresa;
      },
      err => this.alertService.error(err.error.message)
    );
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      franquias => {
        this.franquias = franquias;
        this.franquia = JSON.parse(localStorage.getItem(Constantes.currentFranquia));
        if (this.franquia != undefined) {
          this.idFranquiaSelecionada = this.franquia._id;
        }
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  dashboardChartCounts() {
    this.loadingCharts = true;
    const startDate = DateUtil.toFormart(this.range.get('start').value, 'yyyy-MM-dd');
    const endDate = DateUtil.toFormart(this.range.get('end').value, 'yyyy-MM-dd');
    this.empresaService.dasboardChartCounts(super.getIDEmpresaSession(), this.idFranquiaSelecionada, startDate, endDate).subscribe({
      next: result => {
        console.log('result', result);
        this.buildCounts(result);
        this.buildChart(result['resultChartCount']);
        this.buildChartTipoAtendimento(result['resultChartCountTipoAtendimento']);
        this.buildChartFeedback(result['resultChartCountFeedback']);
      },
      error: (err) => this.alertService.error(err.error.message),
      complete: () => this.loadingCharts = false

    });
  }

  private buildChart(resultChartCount: any[]) {

    if (!!this.chartArea) {
      this.chartArea.destroy();
    }

    const map: Map<string, number> = new Map<string, number>();
    resultChartCount.forEach((item: any) => {
      const key: string = String(DateUtil.toFormart(item._id, 'dd/MM'));
      if (map.has(key)) {
        map.set(key, +map.get(key) + item.total);
      } else {
        map.set(key, item.total);
      }
    });

    const labels = [];
    const data = []
    map.forEach((value: number, key: string) => {
      labels.push(key);
      data.push(value);
    });

    this.chartArea = new Chart(this.ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          data: data,
          label: 'Senhas e Atendimentos',
          borderWidth: ChartHelper.colorsDefault.borderWidth,
          backgroundColor: ChartHelper.colorsDefault.backgroundColor[0],
          borderColor: ChartHelper.colorsDefault.borderColor[0],
        }]
      },
      options: ChartHelper.optionsDefaultWhitoutLegend

    });
  }

  private buildChartTipoAtendimento(resultChartCountTipoAtendimento: any[]) {

    if (!!this.chartPie) {
      this.chartPie.destroy();
    }

    const map: Map<string, number> = new Map<string, number>();
    resultChartCountTipoAtendimento.forEach((item: any) => {
      const key: string = item.name;
      if (map.has(key)) {
        map.set(key, +map.get(key) + item.count);
      } else {
        map.set(key, item.count);
      }
    });

    const labels = [];
    const data = []
    const colors = [];
    map.forEach((value: number, key: string) => {
      labels.push(key);
      data.push(value);
      colors.push(ChartHelper.getRandomColor());
    });

    this.chartPie = new Chart(this.ctxTipoAtendimento, {
      type: 'doughnut',
      data: {
        labels: labels.reverse(),
        datasets: [{
          data: data.reverse(),
          label: 'Tipo de atendimento',
          backgroundColor: colors,
          hoverOffset: 4
        }]
      },
      options: {
        legend: { display: data.length > 5 ? false : true, position: 'top' },
        // responsive: false,
        // display: false
      } as any
    });
  }

  private buildChartFeedback(resultChartCountFeedback: any[]) {

    if (!!this.chartPolarArea) {
      this.chartPolarArea.destroy();
    }

    const map: Map<string, string> = new Map<string, string>();
    const mapName: Map<string, string> = new Map<string, string>();

    resultChartCountFeedback.forEach((feedback) => {
      if (feedback.rating > 0) {
        const key: string = feedback._id;
        map.set(key, String(Number(feedback.rating).toFixed(2)));
        mapName.set(key, feedback.nomeFantasia ?? feedback.nome);
      }
    });

    const labels = [];
    const data = [];
    const colors = [];
    map.forEach((value: string, key: string) => {
      labels.push(mapName.get(key));
      data.push(value);
      colors.push(ChartHelper.getRandomColor());
    });


    this.chartPolarArea = new Chart(this.ctxFeedback, {
      type: 'polarArea',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Dataset 1',
            data,
            backgroundColor: colors,
          }
        ]
      },
      options: {
        legend: { display: data.length > 5 ? false : true, position: 'top' },
        // responsive: false,
        // display: false
      } as any
    });
  }

  private buildCounts(result: any) {
    console.log('buildCounts', result);
    this.totalSenhas = {
      TOTAL_SENHAS_EMITIDAS: 0,
      TOTAL_SENHAS_CHAMDAS: 0,
      TOTAL_SENHAS_ATENDIMENTO: 0,
      TOTAL_SENHAS_NORMAL: 0,
      TOTAL_SENHAS_PREFERENCIAL: 0,
      PERC_SENHAS_EMITIDAS: 100,
      PERC_SENHAS_CHAMDAS: 0,
      PERC_SENHAS_ATENDIMENTO: 0,
      PERC_SENHAS_NORMAL: 0,
      PERC_SENHAS_PREFERENCIAL: 0
    }
    this.buildCountTotalSenhas(result['resultCountTotalSenhas']);
    this.buildCountTotalChamadas(result['resultCountTotalSenhasChamadas']);
    this.buildCountTotalAtendimentos(result['resultCountTotalSenhasComAtendimento']);
    this.buildCountTotalNormal(result['resultCountTotalSenhasNormal']);
    this.buildCountTotalPreferencial(result['resultCountTotalSenhasPreferencial']);
  }

  private buildCountTotalSenhas(resultCountTotalSenhas: any[]) {
    let result = 0;
    if (!!resultCountTotalSenhas && !!resultCountTotalSenhas[0] && !!resultCountTotalSenhas[0]['count']) {
      result = resultCountTotalSenhas[0]['count'];
    }
    this.totalSenhas['TOTAL_SENHAS_EMITIDAS'] = result;
  }

  private buildCountTotalChamadas(resultCountTotalSenhasChamadas: any[]) {
    let result = 0;
    if (!!resultCountTotalSenhasChamadas && !!resultCountTotalSenhasChamadas[0] && !!resultCountTotalSenhasChamadas[0]['count']) {
      result = resultCountTotalSenhasChamadas[0]['count'];
    }
    this.totalSenhas['TOTAL_SENHAS_CHAMADAS'] = result;
    this.totalSenhas['PERC_SENHAS_CHAMDAS'] = ((result / this.totalSenhas['TOTAL_SENHAS_EMITIDAS'] || 0) * 100).toFixed(2);
  }

  private buildCountTotalAtendimentos(resultCountTotalSenhasComAtendimento: any[]) {
    let result = 0;
    if (!!resultCountTotalSenhasComAtendimento && !!resultCountTotalSenhasComAtendimento[0] && !!resultCountTotalSenhasComAtendimento[0]['count']) {
      result = resultCountTotalSenhasComAtendimento[0]['count'];
    }
    this.totalSenhas['TOTAL_SENHAS_ATENDIMENTO'] = result;
    this.totalSenhas['PERC_SENHAS_ATENDIMENTO'] = ((result / this.totalSenhas['TOTAL_SENHAS_EMITIDAS'] || 0) * 100).toFixed(2);
  }

  private buildCountTotalNormal(resultCountTotalSenhasNormal: any[]) {
    let result = 0;
    if (!!resultCountTotalSenhasNormal && !!resultCountTotalSenhasNormal[0] && !!resultCountTotalSenhasNormal[0]['count']) {
      result = resultCountTotalSenhasNormal[0]['count'];
    }
    this.totalSenhas['TOTAL_SENHAS_NORMAL'] = result;
    this.totalSenhas['PERC_SENHAS_NORMAL'] = ((result / this.totalSenhas['TOTAL_SENHAS_EMITIDAS'] || 0) * 100).toFixed(2);
  }

  private buildCountTotalPreferencial(resultCountTotalSenhasPreferencial: any[]) {
    let result = 0;
    if (!!resultCountTotalSenhasPreferencial && !!resultCountTotalSenhasPreferencial[0] && !!resultCountTotalSenhasPreferencial[0]['count']) {
      result = resultCountTotalSenhasPreferencial[0]['count'];
    }
    this.totalSenhas['TOTAL_SENHAS_PREFERENCIAL'] = result;
    this.totalSenhas['PERC_SENHAS_PREFERENCIAL'] = ((result / this.totalSenhas['TOTAL_SENHAS_EMITIDAS'] || 0) * 100).toFixed(2);
  }

  public loadPage() {
    this.dashboardChartCounts();
  }

  public loadCustomDateRange(event) {
    const days = +event.target.value;

    if (!!days && days === 0) {
      return;
    }

    let startDate = new Date();
    let endDate = new Date();
    const daysOff = {
      7: DateUtil.getDateAddDays(-7),
      15: DateUtil.getDateAddDays(-15),
      30: DateUtil.getDateAddDays(-30),
      90: DateUtil.getDateAddDays(-90),
      180: DateUtil.getDateAddDays(-180),
      365: DateUtil.getDateAddDays(-365),
    };
    startDate = daysOff[days];

    this.matDateRangePickerCancelAtendimento.nativeElement.click();

    this.range.setValue({ start: startDate, end: endDate });
    this.dashboardChartCounts();
  }



}
